
/* TabView.css */

.tabview-pouls .tabview-header-icon i, .tabview-pouls .tabview-header-icon span {
  vertical-align: middle;
}

.tabview-pouls .tabview-header-icon span {
  margin: 0 .5rem;
}

.tabview-pouls .p-tabview p {
  line-height: 1.5;
  margin: 0;
}

p.rsp {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto; /* Centre l'élément horizontalement */
  box-sizing: border-box; 
}

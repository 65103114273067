/*
************
*************************
04. banner
******************************************************* 
*************************************************************** */
.single-banner {
    min-height: 670px;
    display: flex;
    align-items: end;
    position: relative;
    z-index: 1;
    background-color: var(--clr-color-lightBlue);
    border-radius: 20px;
    overflow: hidden;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .single-banner {
      align-items: center;
    }
  }
  
  .banner-img {
    margin-right: -75px;
    margin-left: 30px;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .banner-img {
      margin-right: -40px;
      margin-left: 0;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .banner-img {
      margin-left: 0;
      margin-right: -20px;
    }
  }
  
  .banner-content {
    padding-bottom: 45px;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .banner-content {
      padding-bottom: 135px;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .banner-content {
      padding-bottom: 160px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .banner-content {
      padding-bottom: 0;
    }
  }
  
  .banner-top-shape-1 {
    position: absolute;
    left: 85px;
    top: 60px;
    animation: animation-leftRight-1 3s linear 0s infinite alternate;
  }
  @media (max-width: 767px) {
    .banner-top-shape-1 {
      left: 30px;
    }
  }
  .banner-top-shape-2 {
    right: 50px;
    bottom: 150px;
    position: absolute;
    z-index: 1;
    animation: animation-popup-2 1.2s linear 0s infinite alternate;
  }
  @media only screen and (min-width: 1400px) and (max-width: 1599px) {
    .banner-top-shape-2 {
      right: 20px;
      bottom: 140px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .banner-top-shape-2 {
      right: 20px;
      bottom: 110px;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .banner-top-shape-2 {
      right: 20px;
      bottom: 100px;
    }
  }
  @media (max-width: 767px) {
    .banner-top-shape-2 {
      right: 30px;
      bottom: 70px;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .banner-top-shape-2 {
      right: 50px;
      bottom: 100px;
    }
  }
  .banner-top-shape-3 {
    position: absolute;
    top: 125px;
    right: 33%;
    z-index: 1;
    animation: hero-circle-1 6s linear 0s infinite;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .banner-top-shape-3 {
      top: 110px;
      right: 140px;
    }
  }
  @media (max-width: 767px) {
    .banner-top-shape-3 {
      right: 15%;
      top: 110px;
    }
  }
  
  .banner-right {
    position: relative;
    z-index: 1;
  }
  .banner-inner-meta {
    position: absolute;
  }
  .banner-inner-info {
    display: flex;
    gap: 20px;
  }
  .banner-meta-info {
    background-color: #fff;
    padding: 25px 30px 25px;
    bottom: 120px;
    left: -30px;
    border-radius: 6px;
    box-shadow: 0px 10px 50px rgba(30, 30, 30, 0.1);
  }
  .banner-meta-info img.inner-img {
    position: absolute;
    left: -35px;
    top: -35px;
    z-index: 1;
    animation: animation-popup-5 3s linear 0s infinite alternate;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .banner-meta-info {
      bottom: 90px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .banner-meta-info {
      display: none;
    }
  }
  .banner-info-img {
    width: 45px;
    height: 45px;
    flex-shrink: 0;
  }
  .banner-info-img img {
    width: 45px;
    height: 45px;
  }
  .banner-info-icon {
    position: absolute;
    width: 25px;
    height: 25px;
    background: #FFA121;
    font-size: 12px;
    display: grid;
    place-items: center;
    border-radius: 50%;
    color: #fff;
    top: 50px;
    left: 60px;
  }
  .banner-info-text h5 {
    font-size: 18px;
    margin-bottom: 5px;
    font-weight: 500;
  }
  .banner-info-text p {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
  }
  .banner-meta-rating {
    position: absolute;
    right: -40px;
    top: 200px;
    background: #fff;
    border-radius: 10px;
    padding: 25px 30px 25px;
    box-shadow: 0px 10px 50px rgba(30, 30, 30, 0.1);
    animation: hero-upDown-1 3s linear 0s infinite alternate;
  }
  .banner-meta-rating span {
    font-size: 15px;
    display: flex;
    font-weight: 500;
    align-items: center;
    gap: 5px;
    margin-bottom: 5px;
  }
  .banner-meta-rating span i {
    color: #FFA121;
    font-size: 15px;
  }
  .banner-meta-rating h5 {
    font-size: 18px;
    margin-bottom: 0;
    font-weight: 500;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .banner-meta-rating {
      right: -30px;
      top: 160px;
    }
  }
  
  .banner-right-shape img {
    position: absolute;
    z-index: -1;
  }
  .banner-right-shape .banner-shape-4 {
    top: 35px;
    right: 70px;
    animation: animation-popup-2 1.2s linear 0s infinite alternate;
  }
  .banner-right-shape .banner-shape-1 {
    right: -80px;
    top: 165px;
    animation: hero-circle-1 6s linear 0s infinite;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .banner-right-shape .banner-shape-1 {
      right: -20px;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .banner-right-shape .banner-shape-1 {
      right: 0;
      top: 150px;
    }
  }
  .banner-right-shape .banner-shape-2 {
    bottom: 80px;
    left: 55px;
    z-index: 1;
    animation: animation-upDown-2 3s linear 0s infinite alternate;
  }
  
  @keyframes hero-circle-1 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes hero-popup-1 {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(0.5);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes animation-popup-5 {
    0% {
      transform: scale(0.2) rotate(0deg);
    }
    40% {
      transform: scale(0.5) rotate(180deg);
    }
    80% {
      transform: scale(1) rotate(360deg);
    }
    100% {
      transform: scale(1) rotate(360deg);
    }
  }
  @keyframes hero-upDown-1 {
    0% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(20px);
    }
  }
  @keyframes hero-leftRight-1 {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(40px);
    }
  }
  @keyframes animation-upDown-2 {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(30px);
    }
  }
  .h2_single-banner {
    background-color: var(--clr-color-lightPurple);
    min-height: 730px;
    display: flex;
    align-items: center;
  }
  
  .h2_banner-form {
    max-width: 550px;
    position: relative;
    z-index: 1;
    margin-bottom: 15px;
  }
  .h2_banner-form input {
    width: 100%;
    height: 55px;
    line-height: 55px;
    padding: 0 20px;
    padding-right: 55px;
    font-size: 15px;
    background: #fff;
    border: none;
    border-radius: 6px;
    position: relative;
    z-index: 1;
    border-left: 2px solid var(--clr-theme-primary);
    box-shadow: 0px 6px 30px rgba(30, 30, 30, 0.1), inset 2px 0px 0px #1E40EC;
  }
  .h2_banner-form button {
    position: absolute;
    top: 0;
    color: var(--clr-body-heading);
    font-size: 16px;
    z-index: 99;
    background: transparent;
    height: 100%;
    padding: 0;
    border: 0;
    right: 20px;
  }
  .h2_banner-form button i {
    font-weight: 300;
  }
  .h2_banner-content-text {
    display: block;
    font-size: 14px;
    color: var(--clr-body-heading);
    margin-bottom: 25px;
    line-height: 22px;
  }
  .h2_banner-content-text a {
    color: var(--clr-theme-primary);
  }
  .h2_banner-right {
    position: relative;
    z-index: 1;
  }
  @media only screen and (min-width: 1400px) and (max-width: 1599px) {
    .h2_banner-right {
      padding-left: 60px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .h2_banner-right {
      padding-left: 30px;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .h2_banner-right {
      padding-left: 0;
    }
  }
  .h2_banner-inner-img {
    position: relative;
    z-index: 1;
    margin-left: -15px;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    overflow: hidden;
  }
  .h2_banner-inner-img:first-child {
    margin-left: 0;
  }
  .h2_banner-inner-img:last-child::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(30, 30, 30, 0.6);
    content: "";
    border-radius: 50%;
  }
  .h2_banner-inner-img span {
    position: absolute;
    left: 0;
    top: 0;
    display: grid;
    place-items: center;
    width: 100%;
    height: 100%;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
  }
  .h2_banner-tutor {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .h2_banner-tutor-img {
    display: flex;
  }
  .h2_banner-tutor-text span {
    display: block;
    font-size: 15px;
    color: var(--clr-body-heading);
    font-weight: 600;
    line-height: 20px;
  }
  .h2_banner-meta-rating {
    position: absolute;
    left: 0;
    bottom: 0;
    background: #fff;
    border-radius: 10px;
    padding: 25px 30px 25px;
    box-shadow: 0px 16px 70px rgba(30, 30, 30, 0.14);
    animation: h2_hero-leftRight-1 4.5s linear 0s infinite alternate;
  }
  .h2_banner-meta-rating span {
    font-size: 15px;
    display: flex;
    font-weight: 500;
    align-items: center;
    gap: 5px;
    margin-bottom: 5px;
  }
  .h2_banner-meta-rating span i {
    color: #FFA121;
    font-size: 15px;
  }
  .h2_banner-meta-rating h5 {
    font-size: 18px;
    margin-bottom: 0;
    font-weight: 500;
  }
  .h2_banner-meta-info {
    position: absolute;
    right: -90px;
    top: 155px;
    background: #fff;
    border-radius: 10px;
    padding: 20px 25px 20px;
    box-shadow: 0px 16px 70px rgba(30, 30, 30, 0.14);
    animation: h2_hero-upDown-1 4s linear 0s infinite alternate;
  }
  .h2_banner-meta-info span {
    font-size: 14px;
    font-weight: 500;
    display: block;
    line-height: 1;
    margin-bottom: 5px;
  }
  .h2_banner-meta-info h5 {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 0;
    line-height: 1;
  }
  @media only screen and (min-width: 1600px) and (max-width: 1799px) {
    .h2_banner-meta-info {
      right: -70px;
    }
  }
  @media only screen and (min-width: 1400px) and (max-width: 1599px) {
    .h2_banner-meta-info {
      right: -40px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .h2_banner-meta-info {
      right: -20px;
      top: 140px;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
    .h2_banner-meta-info {
      right: 0;
      top: 95px;
    }
  }
  .h2_banner-shape-1 {
    position: absolute;
    left: 80px;
    top: 0;
    animation: animation-popup-1 4s linear 0s infinite alternate;
  }
  @media only screen and (min-width: 1400px) and (max-width: 1599px) {
    .h2_banner-shape-1 {
      left: 60px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .h2_banner-shape-1 {
      left: 30px;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .h2_banner-shape-1 {
      left: 0;
    }
  }
  .h2_banner-shape-2 {
    position: absolute;
    left: -55px;
    bottom: 180px;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .h2_banner-shape-2 {
      left: -30px;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .h2_banner-shape-2 {
      left: -15px;
    }
  }
  
  @keyframes h2_hero-upDown-1 {
    0% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(20px);
    }
  }
  @keyframes h2_hero-leftRight-1 {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(80px);
    }
  }
  .h3_single-banner {
    background-color: var(--clr-color-lightPink);
    min-height: 870px;
    display: flex;
    align-items: end;
    position: relative;
    z-index: 1;
    overflow: hidden;
  }
  .h3_single-banner::before {
    position: absolute;
    bottom: -615px;
    left: -540px;
    content: "";
    width: 949px;
    height: 949px;
    border-radius: 949px;
    background: #E2C3E8;
    z-index: -1;
    animation: banner_before 2s linear 0s;
  }
  .h3_single-banner::after {
    position: absolute;
    top: -252px;
    right: -210px;
    content: "";
    width: 949px;
    height: 949px;
    border-radius: 949px;
    background: #E2C3E8;
    z-index: -1;
    animation: banner_after 1.2s linear 0s;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .h3_single-banner {
      align-items: center;
    }
  }
  @media (max-width: 767px) {
    .h3_single-banner {
      min-height: 750px;
    }
  }
  
  .h3_banner-form {
    border-radius: 6px;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(10, 10, 10, 0.14);
    display: flex;
    max-width: 570px;
    justify-content: space-between;
    padding: 10px;
  }
  @media (max-width: 767px) {
    .h3_banner-form {
      max-width: 100%;
      flex-wrap: wrap;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .h3_banner-form {
      flex-wrap: nowrap;
    }
  }
  .h3_banner-form-item {
    display: flex;
    align-items: center;
    max-width: 200px;
  }
  @media (max-width: 767px) {
    .h3_banner-form-item {
      max-width: 50%;
      width: 100%;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .h3_banner-form-item {
      max-width: 200px;
      width: auto;
    }
  }
  .h3_banner-form-item:last-child {
    margin-left: auto;
  }
  @media (max-width: 767px) {
    .h3_banner-form-item:last-child {
      padding-top: 10px;
      max-width: 100%;
      width: 100%;
    }
    .h3_banner-form-item:last-child .h3_banner-form-btn {
      width: 100%;
      justify-content: center;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .h3_banner-form-item:last-child {
      padding-top: 0;
      max-width: 200px;
      width: auto;
    }
    .h3_banner-form-item:last-child .h3_banner-form-btn {
      width: initial;
      justify-content: start;
    }
  }
  .h3_banner-form-item-search {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 20px;
    position: relative;
    z-index: 1;
  }
  .h3_banner-form-item-search::after {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    background-color: rgba(30, 30, 30, 0.1);
    width: 1px;
    height: 100%;
  }
  .h3_banner-form-item-search i {
    font-size: 16px;
    color: var(--clr-body-heading);
  }
  .h3_banner-form-item-search input {
    height: 30px;
    line-height: 30px;
    border: none;
    padding: 0;
    width: 100%;
  }
  @media (max-width: 490px) {
    .h3_banner-form-item-search {
      padding: 0 10px;
      width: 100%;
    }
    .h3_banner-form-item-search::after {
      display: none;
    }
  }
  @media (max-width: 490px) {
    .h3_banner-form-item {
      max-width: 100%;
    }
    .h3_banner-form-item:first-child {
      padding-bottom: 10px;
    }
  }
  .h3_banner-category {
    position: relative;
    z-index: 9;
    margin: 0 30px;
  }
  @media (max-width: 490px) {
    .h3_banner-category {
      margin: 0 10px;
    }
  }
  .h3_banner-category a {
    color: var(--clr-body-heading);
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px 0;
  }
  .h3_banner-category a span {
    display: block;
    font-size: 16px;
    line-height: 1;
    font-weight: 500;
    color: var(--clr-body-heading);
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
  }
  .h3_banner-category-submenu {
    position: absolute;
    left: 0;
    top: 100%;
    background: #fff;
    width: 200px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
    box-shadow: 0 9px 19px rgba(0, 0, 0, 0.1);
    z-index: 9999;
  }
  .h3_banner-category-submenu li {
    display: block;
    margin-right: 0;
    position: relative;
    z-index: 99;
  }
  .h3_banner-category-submenu li a {
    padding: 10px 25px;
    display: block;
    color: var(--clr-body-heading);
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
  }
  .h3_banner-category-submenu li .submenu {
    left: 100%;
    top: 0;
  }
  .h3_banner-category-submenu li:hover > a {
    background-color: var(--clr-theme-primary-3);
    color: #fff;
  }
  .h3_banner-category-submenu li:not(:last-child) {
    border-bottom: 1px solid #f2f2f2;
  }
  .h3_banner-category:hover > .h3_banner-category-submenu {
    opacity: 1;
    visibility: visible;
  }
  .h3_banner-category:hover > a {
    color: var(--clr-theme-primary-3);
  }
  .h3_banner-category:hover > a span {
    color: var(--clr-theme-primary-3);
  }
  .h3_banner-content {
    position: relative;
    z-index: 1;
    padding-bottom: 60px;
  }
  @media only screen and (min-width: 1600px) and (max-width: 1799px) {
    .h3_banner-content {
      padding-bottom: 120px;
    }
  }
  @media only screen and (min-width: 1400px) and (max-width: 1599px) {
    .h3_banner-content {
      padding-bottom: 160px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .h3_banner-content {
      padding-bottom: 200px;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .h3_banner-content {
      padding-bottom: 200px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .h3_banner-content {
      padding-bottom: 0;
    }
  }
  .h3_banner-content-text {
    display: block;
    font-size: 14px;
    color: var(--clr-body-heading);
    margin-bottom: 25px;
    line-height: 22px;
  }
  .h3_banner-content-text a {
    color: var(--clr-theme-primary);
  }
  .h3_banner-content-shape-1 {
    position: absolute;
    left: -75px;
    top: -65px;
    z-index: 1;
    animation: animation-leftRight-1 2s linear 0s infinite alternate;
  }
  @media only screen and (min-width: 1400px) and (max-width: 1599px) {
    .h3_banner-content-shape-1 {
      left: -45px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .h3_banner-content-shape-1 {
      left: -15px;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .h3_banner-content-shape-1 {
      left: 0;
    }
  }
  .h3_banner-right {
    position: relative;
    z-index: 1;
    margin-right: -90px;
  }
  @media only screen and (min-width: 1600px) and (max-width: 1799px) {
    .h3_banner-right {
      margin-right: -30px;
    }
  }
  @media only screen and (min-width: 1400px) and (max-width: 1599px) {
    .h3_banner-right {
      margin-right: 0px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .h3_banner-right {
      margin-right: 0;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .h3_banner-right {
      margin-right: 0;
    }
  }
  .h3_banner-meta-rating {
    position: absolute;
    left: -70px;
    bottom: 145px;
    background: #fff;
    border-radius: 10px;
    padding: 25px 30px 25px;
    box-shadow: 0px 16px 70px rgba(30, 30, 30, 0.14);
    animation: h3_hero-leftRight-1 4.5s linear 0s infinite alternate;
  }
  .h3_banner-meta-rating span {
    font-size: 15px;
    display: flex;
    font-weight: 500;
    align-items: center;
    gap: 5px;
    margin-bottom: 5px;
  }
  .h3_banner-meta-rating span i {
    color: #FFA121;
    font-size: 15px;
  }
  .h3_banner-meta-rating h5 {
    font-size: 18px;
    margin-bottom: 0;
    font-weight: 500;
  }
  .h3_banner-meta-info {
    position: absolute;
    right: -15px;
    top: 295px;
    background: #fff;
    border-radius: 10px;
    padding: 20px 25px 20px;
    box-shadow: 0px 16px 70px rgba(30, 30, 30, 0.14);
    animation: h3_hero-upDown-1 4s linear 0s infinite alternate;
  }
  .h3_banner-meta-info span {
    font-size: 14px;
    font-weight: 500;
    display: block;
    line-height: 1;
    margin-bottom: 5px;
  }
  .h3_banner-meta-info h5 {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 0;
    line-height: 1;
  }
  @media only screen and (min-width: 1600px) and (max-width: 1799px) {
    .h3_banner-meta-info {
      right: -70px;
    }
  }
  @media only screen and (min-width: 1400px) and (max-width: 1599px) {
    .h3_banner-meta-info {
      right: -40px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .h3_banner-meta-info {
      right: -20px;
      top: 140px;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
    .h3_banner-meta-info {
      right: 0;
      top: 95px;
    }
  }
  .h3_banner-shape-1 {
    position: absolute;
    right: -60px;
    bottom: 85px;
    animation: animation-upDown-1 3s linear 0s infinite alternate;
  }
  @media only screen and (min-width: 1400px) and (max-width: 1599px) {
    .h3_banner-shape-1 {
      right: -50px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .h3_banner-shape-1 {
      right: -45px;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .h3_banner-shape-1 {
      display: none;
    }
  }
  .h3_banner-shape-2 {
    position: absolute;
    left: -75px;
    bottom: 390px;
    animation: animation-leftRight-1 3s linear 0s infinite alternate;
  }
  @media only screen and (min-width: 1400px) and (max-width: 1599px) {
    .h3_banner-shape-2 {
      left: -35px;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .h3_banner-shape-2 {
      left: -10px;
    }
  }
  @keyframes h3_hero-upDown-1 {
    0% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(20px);
    }
  }
  @keyframes h3_hero-leftRight-1 {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(80px);
    }
  }
  @keyframes banner_after {
    0% {
      transform: translateX(80%);
    }
    100% {
      transform: translateX(0);
    }
  }
  @keyframes banner_before {
    0% {
      transform: translateX(-400px);
    }
    100% {
      transform: translateX(0);
    }
  }
  .h4_single-banner {
    background-color: #36348E;
    min-height: 950px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    overflow: hidden;
  }
  @media (max-width: 767px) {
    .h4_single-banner {
      min-height: 800px;
    }
  }
  
  .h4_banner-area {
    position: relative;
  }
  .h4_banner-form {
    border-radius: 100px;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(10, 10, 10, 0.14);
    display: flex;
    max-width: 550px;
    justify-content: space-between;
    padding: 7px;
    overflow: hidden;
    margin-bottom: 35px;
  }
  .h4_banner-form input {
    width: 100%;
    height: 40px;
    padding-left: 11px;
    padding-right: 20px;
    border: none;
    color: var(--clr-body-heading);
    font-size: 15px;
  }
  .h4_banner-form input::-webkit-input-placeholder {
    color: rgba(30, 30, 30, 0.5);
    font-size: 15px;
    opacity: 1;
  }
  .h4_banner-form input:-moz-placeholder {
    color: rgba(30, 30, 30, 0.5);
    font-size: 15px;
    opacity: 1;
  }
  .h4_banner-form input::-moz-placeholder {
    color: rgba(30, 30, 30, 0.5);
    font-size: 15px;
    opacity: 1;
  }
  .h4_banner-form input:-ms-input-placeholder {
    color: rgba(30, 30, 30, 0.5);
    font-size: 15px;
    opacity: 1;
  }
  .h4_banner-form button {
    width: auto;
    flex-shrink: 0;
  }
  .h4_banner-content {
    position: relative;
    z-index: 1;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .h4_banner-content {
      margin-right: 0;
    }
  }
  .h4_banner-content-text {
    display: block;
    font-size: 14px;
    color: var(--clr-body-heading);
    margin-bottom: 25px;
    line-height: 22px;
  }
  .h4_banner-content-text a {
    color: var(--clr-theme-primary);
  }
  .h4_banner-content-shape-1 {
    position: absolute;
    left: -75px;
    top: -65px;
    z-index: 1;
  }
  .h4_banner-shape {
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .h4_banner-img {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 42%;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .h4_banner-img {
      max-width: 50%;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .h4_banner-img {
      display: none;
    }
  }
  .h4_banner-tag {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    max-width: 420px;
  }
  .h4_banner-tag a {
    background: #fff;
    color: var(--clr-body-heading);
    font-size: 13px;
    padding: 0 14px;
    border-radius: 30px;
  }
  .h4_banner-tag a:hover {
    background-color: var(--clr-theme-primary-4);
  }
  
  .h5_single-banner {
    background-color: #DCECFF;
    min-height: 860px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    overflow: hidden;
  }
  
  .h5_banner-bg {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    max-width: 45%;
    width: 100%;
  }
  .h5_banner-bg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .h5_banner-contact {
    position: absolute;
    bottom: 40px;
    right: 50px;
    display: flex;
    gap: 40px;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .h5_banner-contact {
      right: auto;
      left: 50px;
    }
  }
  @media (max-width: 767px) {
    .h5_banner-contact {
      display: none;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .h5_banner-contact {
      display: flex;
      right: auto;
      left: 50px;
    }
  }
  .h5_banner-contact-item p {
    font-size: 16px;
    font-weight: 500;
    color: var(--clr-body-heading);
    margin-bottom: 0;
  }
  .h5_banner-contact-item-top span {
    color: #747474;
    font-size: 16px;
    font-weight: 500;
  }
  .h5_banner-contact-item-top i {
    font-size: 16px;
    font-weight: 500;
    color: #747474;
    margin-right: 10px;
  }
  
  .h5_banner-content .h2_banner-form input {
    border-radius: 0;
  }
  
  .h6_single-banner {
    min-height: 700px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    overflow: hidden;
    padding-top: 290px;
    padding-bottom: 285px;
  }
  .h6_single-banner::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(15, 15, 15, 0.85) 12.58%, rgba(15, 15, 15, 0.34) 83.62%);
    z-index: -1;
  }
  @media (max-width: 767px) {
    .h6_single-banner {
      padding-top: 200px;
      padding-bottom: 160px;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .h6_single-banner {
      padding-top: 250px;
      padding-bottom: 230px;
    }
  }
  
  .h6_banner-content-subtitle {
    color: #FFF;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    display: block;
    margin-bottom: 20px;
  }
  .h6_banner-content-subtitle i {
    color: var(--clr-theme-primary-6);
    margin-right: 5px;
  }
  @media (max-width: 767px) {
    .h6_banner-content-subtitle {
      font-size: 16px;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .h6_banner-content-subtitle {
      font-size: 20px;
    }
  }
  .h6_banner-content-title {
    color: #FFF;
    font-size: 80px;
    font-style: normal;
    font-weight: 600;
    line-height: 1;
    text-transform: capitalize;
    margin-bottom: 25px;
  }
  .h6_banner-content-title span {
    color: #FFF;
    font-family: "Sansita Swashed", cursive;
    font-size: 80px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    text-transform: capitalize;
  }
  @media (max-width: 767px) {
    .h6_banner-content-title {
      font-size: 50px;
      line-height: 1.1;
    }
    .h6_banner-content-title span {
      font-size: 40px;
      line-height: 1.1;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .h6_banner-content-title {
      font-size: 60px;
    }
    .h6_banner-content-title span {
      font-size: 55px;
    }
  }
  .h6_banner-content-text {
    color: #FFF;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 42px;
  }
  @media (max-width: 767px) {
    .h6_banner-content-text br {
      display: none;
    }
  }
  
  .h6_banner-navigation div {
    position: absolute;
    left: 80px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    font-size: 90px;
    color: rgba(255, 255, 255, 0.4);
    cursor: pointer;
  }
  .h6_banner-navigation .banner_6-swiper-next {
    right: 80px;
    left: auto;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .h6_banner-navigation div {
      left: 50px;
      font-size: 80px;
    }
    .h6_banner-navigation .banner_6-swiper-next {
      right: 50px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .h6_banner-navigation div {
      left: 20px;
      font-size: 60px;
    }
    .h6_banner-navigation .banner_6-swiper-next {
      right: 20px;
    }
  }
  @media (max-width: 767px) {
    .h6_banner-navigation div {
      display: none;
    }
  }
  
  .h7_single-banner {
    min-height: 600px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    overflow: hidden;
    padding-top: 245px;
    padding-bottom: 260px;
  }
  .h7_single-banner::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #0A0A0A 0%, rgba(10, 10, 10, 0) 81.54%);
    z-index: -1;
  }
  @media (max-width: 767px) {
    .h7_single-banner {
      padding-top: 175px;
      padding-bottom: 180px;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .h7_single-banner {
      padding-top: 210px;
      padding-bottom: 220px;
    }
  }
  
  .h7_banner-content-title {
    color: #FFF;
    font-size: 80px;
    font-weight: 800;
    line-height: 1.1;
    text-transform: capitalize;
    margin-bottom: 20px;
  }
  @media (max-width: 767px) {
    .h7_banner-content-title {
      font-size: 38px;
    }
    .h7_banner-content-title br {
      display: none;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .h7_banner-content-title {
      font-size: 60px;
    }
    .h7_banner-content-title span {
      font-size: 55px;
    }
  }
  .h7_banner-content-text {
    color: rgba(255, 255, 255, 0.74);
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 37px;
  }
  @media (max-width: 767px) {
    .h7_banner-content-text {
      font-size: 16px;
      line-height: 26px;
    }
    .h7_banner-content-text br {
      display: none;
    }
  }
  .h7_banner-content-btn {
    display: flex;
    gap: 10px;
  }
  @media (max-width: 767px) {
    .h7_banner-content-btn {
      flex-wrap: wrap;
    }
  }
  
  .h7_slider-active-nav .swiper-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  
  .h7_slider-thumb-item {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    gap: 25px;
    padding: 0 10px;
    height: 140px;
    justify-content: center;
    cursor: pointer;
  }
  @media (max-width: 767px) {
    .h7_slider-thumb-item {
      gap: 15px;
    }
  }
  
  .h7_slider-thumbs-info h4 {
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  @media (max-width: 767px) {
    .h7_slider-thumbs-info h4 {
      font-size: 22px;
    }
  }
  
  .h7_slider-thumbs-info a {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .h7_slider-thumbs-icon svg {
    color: #fff;
  }
  
  .h7_slider-thumbs-info a i {
    font-size: 15px;
  }
  
  .h7_slider-navigation {
    position: absolute;
    right: 6%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    display: grid;
    grid-gap: 10px;
  }
  .h7_slider-navigation div {
    width: 60px;
    height: 60px;
    background: #fff;
    border-radius: 50%;
    display: grid;
    place-items: center;
    font-size: 22px;
    color: var(--clr-theme-primary-6);
    gap: 10px;
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
  }
  .h7_slider-navigation div:hover {
    background-color: var(--clr-theme-primary-6);
    color: #fff;
  }
  
  .h8_banner-area {
    background-color: #F4F7FF;
    min-height: 600px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    overflow: hidden;
    padding-top: 90px;
    padding-bottom: 110px;
  }
  
  .h8_banner-content-subtitle {
    color: #F14D5D;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    display: block;
  }
  @media (max-width: 767px) {
    .h8_banner-content-subtitle {
      font-weight: 500;
    }
  }
  
  .h8_banner-content-title {
    color: rgba(2, 25, 54, 0.95);
    font-size: 56px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 20px;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .h8_banner-content-title {
      font-size: 45px;
    }
  }
  @media (max-width: 767px) {
    .h8_banner-content-title {
      font-size: 33px;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .h8_banner-content-title {
      font-size: 45px;
    }
  }
  
  .h8_banner-content-title span {
    color: #395BDF;
  }
  
  .h8_banner-content-text {
    color: rgba(2, 25, 54, 0.7);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 50px;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), (max-width: 767px) {
    .h8_banner-content-text br {
      display: none;
    }
  }
  
  .h8_banner-play {
    display: flex;
    align-items: center;
    gap: 12px;
    color: #021936;
    font-size: 15px;
    font-weight: 400;
  }
  
  .h8_banner-play span {
    height: 40px;
    width: 40px;
    border: 1px solid rgb(241, 77, 93);
    color: rgb(241, 77, 93);
    display: grid;
    place-items: center;
    border-radius: 50%;
    font-size: 14px;
  }
  
  .h8_banner-content-btn {
    display: flex;
    gap: 20px;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .h8_banner-img {
    margin-left: -20px;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .h8_banner-img {
      margin-left: 0;
      text-align: center;
    }
  }
  
  .h9_single-banner {
    min-height: 600px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    overflow: hidden;
    padding-top: 205px;
    padding-bottom: 165px;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .h9_single-banner::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(92deg, #21242C 8.67%, #21242C 8.68%, rgba(33, 36, 44, 0.3) 98.22%);
    z-index: -1;
  }
  @media (max-width: 767px) {
    .h9_single-banner {
      padding-top: 175px;
      padding-bottom: 180px;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .h9_single-banner {
      padding-top: 210px;
      padding-bottom: 220px;
    }
  }
  
  .h9_banner-content-subtitle {
    color: #FFF;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    display: block;
    margin-bottom: 10px;
  }
  @media (max-width: 767px) {
    .h9_banner-content-subtitle {
      font-size: 15px;
    }
  }
  .h9_banner-content-title {
    color: #FFF;
    font-size: 74px;
    font-weight: 700;
    line-height: 1.1;
    margin-bottom: 20px;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
    .h9_banner-content-title {
      font-size: 65px;
    }
  }
  @media (max-width: 767px) {
    .h9_banner-content-title {
      font-size: 40px;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .h9_banner-content-title {
      font-size: 52px;
    }
    .h9_banner-content-title span {
      font-size: 55px;
    }
  }
  .h9_banner-content-text {
    color: rgba(255, 255, 255, 0.7);
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 38px;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
    .h9_banner-content-text br {
      display: none;
    }
  }
  @media (max-width: 767px) {
    .h9_banner-content-text {
      font-size: 16px;
      line-height: 26px;
    }
    .h9_banner-content-text br {
      display: none;
    }
  }
  .h9_banner-content-btn a {
    padding: 0 35px;
  }
  
  .h9_slider-active-nav .swiper-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  
  .h9_slider-thumb-item {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding-left: 20px;
    padding-right: 20px;
    height: 140px;
    cursor: pointer;
  }
  @media (max-width: 767px) {
    .h9_slider-thumb-item {
      gap: 15px;
    }
  }
  
  .h9_slider-thumbs-info h4 {
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 0px;
  }
  @media (max-width: 767px) {
    .h9_slider-thumbs-info h4 {
      font-size: 22px;
    }
  }
  
  .h9_slider-thumbs-info a {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .h9_slider-thumbs-icon svg {
    color: #fff;
  }
  
  .h9_slider-thumbs-info a i {
    font-size: 15px;
  }
  
  .h9_slider-green {
    background-color: #10C45C;
  }
  
  .h9_slider-skyBlue {
    background-color: #1EC1D9;
  }
  
  .h9_slider-orange {
    background-color: #E1B12F;
  }
  
  .h9_slider-blue {
    background-color: #307AD5;
  }
  
  .h9_slider-pink {
    background-color: #D94DA6;
  }
  
  .h10_single-banner {
    min-height: 600px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    overflow: hidden;
    padding-top: 180px;
    padding-bottom: 180px;
    background-color: var(--clr-theme-primary-10);
  }
  @media (max-width: 767px) {
    .h10_single-banner {
      padding-top: 110px;
      padding-bottom: 115px;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .h10_single-banner {
      padding-top: 110px;
      padding-bottom: 120px;
    }
  }
  
  .h10_banner-content-title {
    color: #fff;
    font-size: 64px;
    font-weight: 700;
    line-height: 1.1;
    text-transform: capitalize;
    margin-bottom: 20px;
  }
  .h10_banner-content-title span {
    position: relative;
    z-index: 1;
  }
  .h10_banner-content-title span img {
    position: absolute;
    left: 0;
    bottom: -5px;
    width: calc(100% - 25px);
    display: inline-block;
    margin-left: 10px;
    animation: section-animation 5s linear 0s infinite;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .h10_banner-content-title {
      font-size: 55px;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .h10_banner-content-title {
      font-size: 45px;
    }
  }
  @media (max-width: 767px) {
    .h10_banner-content-title {
      font-size: 45px;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .h10_banner-content-title {
      font-size: 52px;
    }
  }
  .h10_banner-content-text {
    color: rgba(255, 255, 255, 0.8);
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 37px;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .h10_banner-content-text br {
      display: none;
    }
  }
  @media (max-width: 767px) {
    .h10_banner-content-text {
      font-size: 16px;
      line-height: 26px;
    }
    .h10_banner-content-text br {
      display: none;
    }
  }
  .h10_banner-content-btn {
    display: flex;
    gap: 15px;
  }
  @media (max-width: 767px) {
    .h10_banner-content-btn {
      flex-wrap: wrap;
    }
  }
  
  .h10_banner-bottom-info {
    display: flex;
    align-items: center;
    row-gap: 15px;
    column-gap: 40px;
    flex-wrap: wrap;
  }
  
  .h10_banner-bottom-info span {
    color: #FFF;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 8px;
    position: relative;
    z-index: 1;
  }
  
  .h10_banner-bottom-info span i {
    font-size: 22px;
  }
  
  .h10_banner-bottom-info span:not(:last-child)::before {
    position: absolute;
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
    content: "";
    background: #DDB586;
    width: 1px;
    height: 100%;
  }
  
  .h10_banner-right {
    margin-right: -165px;
    position: relative;
    z-index: 1;
  }
  @media only screen and (min-width: 1600px) and (max-width: 1799px) {
    .h10_banner-right.pl-110 {
      padding-left: 55px;
    }
  }
  @media only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .h10_banner-right.pl-110 {
      padding-left: 0;
    }
  }
  @media only screen and (min-width: 1600px) and (max-width: 1799px) {
    .h10_banner-right {
      margin-right: -110px;
    }
  }
  @media only screen and (min-width: 1400px) and (max-width: 1599px) {
    .h10_banner-right {
      margin-right: -30px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .h10_banner-right {
      margin-right: 0;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .h10_banner-img {
      text-align: center;
    }
  }
  .h10_banner-img img {
    animation: hero-border 5s linear infinite alternate;
  }
  
  @keyframes hero-border {
    0% {
      -webkit-border-radius: 51% 49% 77% 23%/65% 50% 50% 35%;
      -moz-border-radius: 51% 49% 77% 23%/65% 50% 50% 35%;
      border-radius: 51% 49% 77% 23%/65% 50% 50% 35%;
    }
    100% {
      -webkit-border-radius: 30% 70% 28% 72%/53% 69% 31% 47%;
      -moz-border-radius: 30% 70% 28% 72%/53% 69% 31% 47%;
      border-radius: 30% 70% 28% 72%/53% 69% 31% 47%;
    }
  }
  .waviy {
    position: relative;
    -webkit-box-reflect: below -20px linear-gradient(transparent, rgba(0, 0, 0, 0.2));
  }
  
  .waviy span {
    position: relative;
    display: inline-block;
    animation: waviy 1s infinite;
    animation-delay: calc(0.1s * var(--i));
  }
  
  @keyframes waviy {
    0%, 40%, 100% {
      transform: translateY(0);
    }
    20% {
      transform: translateY(-20px);
    }
  }
  .h10_banner-shape-1 {
    position: absolute;
    left: 5%;
    bottom: 31%;
    animation: animation-popup-2 1.2s linear 0s infinite alternate;
  }
  @media only screen and (min-width: 1400px) and (max-width: 1599px) {
    .h10_banner-shape-1 {
      left: 1%;
    }
  }
  .h10_banner-shape-2 {
    position: absolute;
    left: 11%;
    top: 12%;
    animation: animation-popup-1 3s linear 0s infinite alternate;
  }
  @media only screen and (min-width: 1400px) and (max-width: 1599px) {
    .h10_banner-shape-2 {
      left: 5%;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .h10_banner-shape-2 {
      left: 4%;
    }
  }
  .h10_banner-shape-3 {
    position: absolute;
    top: 6%;
    left: 32%;
    animation: animation-leftRight-1 3s linear 0s infinite alternate;
  }
  .h10_banner-shape-4 {
    position: absolute;
    left: 10px;
    top: -30px;
    animation: animation-upDown-2 3s linear 0s infinite alternate;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .h10_banner-shape-4 {
      width: 40px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .h10_banner-shape-4 {
      width: 50px;
      top: 0;
      left: 50px;
    }
  }
  .h10_banner-shape-5 {
    position: absolute;
    left: 30px;
    bottom: 25px;
    animation: animation-upDown-2 3s linear 0s infinite alternate;
  }
  @media only screen and (min-width: 1600px) and (max-width: 1799px) {
    .h10_banner-shape-5 {
      left: 0;
    }
  }
  @media only screen and (min-width: 1400px) and (max-width: 1599px) {
    .h10_banner-shape-5 {
      left: -40px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .h10_banner-shape-5 {
      left: -10px;
      width: 50px;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .h10_banner-shape-5 {
      left: -10px;
      width: 50px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .h10_banner-shape-5 {
      width: 50px;
    }
  }
  .h10_banner-shape-6 {
    position: absolute;
    right: 10px;
    bottom: -15px;
    animation: hero-circle-1 6s linear 0s infinite;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .h10_banner-shape-6 {
      width: 50px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .h10_banner-shape-6 {
      width: 50px;
      bottom: 0;
      right: 60px;
    }
  }
/*
************
*************************
02. header
******************************************************* 
*************************************************************** */
#Header  .header-area {
    padding: 22px 0;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    #Header .header-area {
      padding: 17px 0;
    }
  }
  @media (max-width: 767px) {
    #Header  .header-area {
      padding: 12px 0;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    #Header  .header-area {
      padding: 15px 0;
    }
  }
  #Header  .header-left {
    display: flex;
    align-items: center;
  }
  #Header .header-right {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: end;
  }
  #Header .header-logo {
    position: relative;
    z-index: 1;
    padding-right: 21px;
    margin-right: 20px;
  }
  #Header  .header-logo::after {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    background-color: rgba(30, 30, 30, 0.1);
    width: 1px;
    height: 100%;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    #Header  .header-logo {
      padding-right: 0;
    }
    .header-logo::after {
      display: none;
    }
  }
  
  #Header .main-menu {
    width: auto;
  }
  #Header .main-menu ul .menu-has-child {
    position: relative;
    z-index: 99;
    text-align: start;
  }
  #Header .main-menu ul li {
    display: inline-block;
    margin-right: 45px;
  }
  #Header .main-menu ul li:last-child {
    margin-right: 0;
  }
  #Header .main-menu ul li a {
    font-size: 16px;
    color: var(--clr-body-heading);
    padding: 10px 0;
    font-weight: 500;
    display: block;
  }
  .main-menu ul li .submenu {
    position: absolute;
    left: 0;
    top: 100%;
    background: #fff;
    width: 240px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
    box-shadow: 0 9px 19px rgba(0, 0, 0, 0.1);
  }
  #Header  .main-menu ul li .submenu li {
    display: block;
    margin-right: 0;
    position: relative;
    z-index: 99;
  }
  #Header .main-menu ul li .submenu li a {
    padding: 10px 25px;
    color: var(--clr-body-heading);
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
  }
  #Header .main-menu ul li .submenu li .submenu {
    left: 100%;
    top: 0;
  }
  #Header .main-menu ul li .submenu li:hover > a {
    background-color: var(--clr-theme-primary);
    color: #fff;
  }
  #Header .main-menu ul li .submenu li:not(:last-child) {
    border-bottom: 1px solid #f2f2f2;
  }
  #Header .main-menu ul li:hover > .submenu {
    opacity: 1;
    visibility: visible;
  }
  #Header .main-menu ul li:hover > a {
    color: var(--clr-theme-primary);
  }
  
  #Header .header-search form {
    position: relative;
    z-index: 1;
  }
  #Header .header-search form input {
    min-width: 300px;
    width: 100%;
    height: 45px;
    line-height: 45px;
    background-color: #F5F5F5;
    border: none;
    border-radius: 6px;
    padding: 0 20px;
    padding-right: 55px;
    font-size: 15px;
    color: rgba(30, 30, 30, 0.6);
  }
  #Header .header-search form input::-webkit-input-placeholder {
    color: rgba(30, 30, 30, 0.6);
    font-size: 15px;
    opacity: 1;
    -webkit-transition: all 0.5s linear 0s;
    -moz-transition: all 0.5s linear 0s;
    -ms-transition: all 0.5s linear 0s;
    -o-transition: all 0.5s linear 0s;
    transition: all 0.5s linear 0s;
  }
  #Header .header-search form input:-moz-placeholder {
    color: rgba(30, 30, 30, 0.6);
    font-size: 15px;
    opacity: 1;
    -webkit-transition: all 0.5s linear 0s;
    -moz-transition: all 0.5s linear 0s;
    -ms-transition: all 0.5s linear 0s;
    -o-transition: all 0.5s linear 0s;
    transition: all 0.5s linear 0s;
  }
  #Header .header-search form input::-moz-placeholder {
    color: rgba(30, 30, 30, 0.6);
    font-size: 15px;
    opacity: 1;
    -webkit-transition: all 0.5s linear 0s;
    -moz-transition: all 0.5s linear 0s;
    -ms-transition: all 0.5s linear 0s;
    -o-transition: all 0.5s linear 0s;
    transition: all 0.5s linear 0s;
  }
  #Header .header-search form input:-ms-input-placeholder {
    color: rgba(30, 30, 30, 0.6);
    font-size: 15px;
    opacity: 1;
    -webkit-transition: all 0.5s linear 0s;
    -moz-transition: all 0.5s linear 0s;
    -ms-transition: all 0.5s linear 0s;
    -o-transition: all 0.5s linear 0s;
    transition: all 0.5s linear 0s;
  }
  #Header .header-search form button {
    position: absolute;
    right: 20px;
    top: 0;
    height: 100%;
    font-size: 15px;
    color: #1E1E1E;
    border: none;
    background: transparent;
    padding: 0;
  }
  #Header .header-search form button i {
    font-weight: 300;
  }
  
  #Header .header-menu-bar-icon {
    font-size: 25px;
    display: block;
    color: var(--clr-body-heading);
    cursor: pointer;
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
  }
  #Header .header-menu-bar-icon:hover {
    color: var(--clr-theme-primary);
  }
  
  #Header .h2_header-area {
    padding: 22px 0;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    #Header .h2_header-area {
      padding: 17px 0;
    }
  }
  @media (max-width: 767px) {
    #Header .h2_header-area {
      padding: 12px 0;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    #Header .h2_header-area {
      padding: 15px 0;
    }
  }
  #Header .h2_header-left {
    display: flex;
    align-items: center;
  }
  #Header .h2_header-middle {
    text-align: center;
  }
  #Header .h2_header-right {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: end;
  }
  #Header .h2_header-logo {
    position: relative;
    z-index: 1;
    padding-right: 21px;
    margin-right: 20px;
  }
  #Header .h2_header-logo::after {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    background-color: rgba(30, 30, 30, 0.1);
    width: 1px;
    height: 100%;
  }
  @media (max-width: 767px) {
    #Header .h2_header-logo {
      margin-right: 10px;
      padding-right: 11px;
    }
    #Header .h2_header-logo::after {
      display: none;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    #Header .h2_header-logo {
      margin-right: 15px;
      padding-right: 16px;
    }
    #Header .h2_header-logo::after {
      display: block;
    }
  }
  #Header .h2_header-category {
    position: relative;
    z-index: 9;
  }
  #Header .h2_header-category a {
    color: var(--clr-body-heading);
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px 0;
  }
  #Header .h2_header-category a span {
    display: block;
    font-size: 16px;
    line-height: 1;
    font-weight: 500;
    color: var(--clr-body-heading);
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
  }
  #Header .h2_header-category-submenu {
    position: absolute;
    left: 0;
    top: 100%;
    background: #fff;
    width: 240px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
    box-shadow: 0 9px 19px rgba(0, 0, 0, 0.1);
    z-index: 9999;
  }
  #Header .h2_header-category-submenu li {
    display: block;
    margin-right: 0;
    position: relative;
    z-index: 99;
  }
  #Header .h2_header-category-submenu li a {
    padding: 15px 25px;
    display: block;
    color: var(--clr-body-heading);
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
  }
  #Header .h2_header-category-submenu li .submenu {
    left: 100%;
    top: 0;
  }
  #Header .h2_header-category-submenu li:hover > a {
    background-color: var(--clr-theme-primary);
    color: #fff;
  }
  #Header .h2_header-category-submenu li:not(:last-child) {
    border-bottom: 1px solid #f2f2f2;
  }
  #Header .h2_header-category:hover > .h2_header-category-submenu {
    opacity: 1;
    visibility: visible;
  }
  #Header .h2_header-category:hover > a {
    color: var(--clr-theme-primary);
  }
  #Header .h2_header-category:hover > a span {
    color: var(--clr-theme-primary);
  }
  
  #Header .h2_header-area.sticky {
    box-shadow: 0px 6px 50px rgba(30, 30, 30, 0.1);
  }
  
  #Header .h2_main-menu ul .menu-has-child {
    position: relative;
    z-index: 99;
    text-align: start;
  }
  #Header .h2_main-menu ul li {
    display: inline-block;
    margin-right: 45px;
  }
  #Header .h2_main-menu ul li:last-child {
    margin-right: 0;
  }
  #Header .h2_main-menu ul li:is(.menu-has-child) > a::after {
    display: inline-block;
    font-family: "Font Awesome 6 Pro";
    content: "\f107";
    font-size: 12px;
    font-weight: 500;
    margin-left: 5px;
  }
  #Header .h2_main-menu ul li a {
    font-size: 16px;
    color: var(--clr-body-heading);
    padding: 10px 0;
    font-weight: 500;
    display: block;
  }
  #Header .h2_main-menu ul li .submenu {
    position: absolute;
    left: 0;
    top: 100%;
    background: #fff;
    width: 240px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
    box-shadow: 0 9px 19px rgba(0, 0, 0, 0.1);
  }
  #Header .h2_main-menu ul li .submenu li {
    display: block;
    margin-right: 0;
    position: relative;
    z-index: 99;
  }
  #Header .h2_main-menu ul li .submenu li a {
    padding: 10px 25px;
    color: var(--clr-body-heading);
    -webkit-transition: all 0.4s linear 0s;
    -moz-transition: all 0.4s linear 0s;
    -ms-transition: all 0.4s linear 0s;
    -o-transition: all 0.4s linear 0s;
    transition: all 0.4s linear 0s;
  }
  #Header .h2_main-menu ul li .submenu li .submenu {
    left: 100%;
    top: 0;
  }
  #Header .h2_main-menu ul li .submenu li:hover > a {
    background-color: var(--clr-theme-primary);
    color: #fff;
  }
  #Header .h2_main-menu ul li .submenu li:not(:last-child) {
    border-bottom: 1px solid #f2f2f2;
  }
  #Header .h2_main-menu ul li:hover > .submenu {
    opacity: 1;
    visibility: visible;
  }
  #Header .h2_main-menu ul li:hover > a {
    color: var(--clr-theme-primary);
  }
  
  #Header .h3_header-area {
    padding: 16px 0;
  }
  #Header .h3_header-middle {
    text-align: center;
  }
  #Header .h3_header-right {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: end;
  }
  #Header .h3_header-logo {
    position: relative;
    z-index: 1;
    padding-right: 21px;
    margin-right: 20px;
  }
  @media (max-width: 767px) {
    #Header .h3_header-logo {
      margin-right: 10px;
      padding-right: 11px;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    #Header .h3_header-logo {
      margin-right: 15px;
      padding-right: 16px;
    }
  }
  
  #Header .h3_header-area.sticky {
    box-shadow: 0px 6px 50px rgba(30, 30, 30, 0.1);
  }
  
  #Header .h3_main-menu ul .menu-has-child {
    position: relative;
    z-index: 99;
    text-align: start;
  }
  #Header .h3_main-menu ul li {
    display: inline-block;
    margin-right: 45px;
  }
  #Header .h3_main-menu ul li:last-child {
    margin-right: 0;
  }
  #Header  .h3_main-menu ul li:is(.menu-has-child) > a::after {
    display: inline-block;
    font-family: "Font Awesome 6 Pro";
    content: "\f107";
    font-size: 12px;
    font-weight: 500;
    margin-left: 5px;
  }
  #Header .h3_main-menu ul li a {
    font-size: 16px;
    color: var(--clr-body-heading);
    padding: 10px 0;
    font-weight: 500;
    display: block;
  }
  #Header .h3_main-menu ul li .submenu {
    position: absolute;
    left: 0;
    top: 100%;
    background: #fff;
    width: 240px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
    box-shadow: 0 9px 19px rgba(0, 0, 0, 0.1);
  }
  #Header .h3_main-menu ul li .submenu li {
    display: block;
    margin-right: 0;
    position: relative;
    z-index: 99;
  }
  #Header .h3_main-menu ul li .submenu li a {
    padding: 10px 25px;
    color: var(--clr-body-heading);
    -webkit-transition: all 0.4s linear 0s;
    -moz-transition: all 0.4s linear 0s;
    -ms-transition: all 0.4s linear 0s;
    -o-transition: all 0.4s linear 0s;
    transition: all 0.4s linear 0s;
  }
  #Header .h3_main-menu ul li .submenu li .submenu {
    left: 100%;
    top: 0;
  }
  #Header  .h3_main-menu ul li .submenu li:hover > a {
    background-color: var(--clr-theme-primary-3);
    color: #fff;
  }
  #Header .h3_main-menu ul li .submenu li:not(:last-child) {
    border-bottom: 1px solid #f2f2f2;
  }
  #Header .h3_main-menu ul li:hover > .submenu {
    opacity: 1;
    visibility: visible;
  }
  #Header .h3_main-menu ul li:hover > a {
    color: var(--clr-theme-primary-3);
  }
  
  #Header .h4_main-menu ul li .submenu li:hover > a {
    background-color: var(--clr-theme-primary-4);
    color: var(--clr-body-heading);
  }
  #Header .h4_main-menu ul li:hover > a {
    color: var(--clr-body-heading);
  }
  
  #Header .h4_header-category-submenu li:hover > a {
    background-color: var(--clr-theme-primary-4);
    color: var(--clr-body-heading);
  }
  #Header .h4_header-category:hover > a {
    color: var(--clr-body-heading);
  }
  #Header .h4_header-category:hover > a span {
    color: var(--clr-body-heading);
  }
  
  #Header .h5_main-menu ul li .submenu li:hover > a {
    background-color: var(--clr-theme-primary-5);
    color: var(--clr-body-heading);
  }
  #Header .h5_main-menu ul li:hover > a {
    color: var(--clr-body-heading);
  }
  
  #Header .header-sticky.sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #ffffff;
    z-index: 99;
    animation: 300ms ease-in-out 0s normal none 1 running stickySlideDown;
    display: block;
    box-shadow: 0px 6px 50px rgba(30, 30, 30, 0.1);
  }
  
  @keyframes stickySlideDown {
    0% {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }
  #Header .h6_header-area {
    padding: 16px 0;
    background: linear-gradient(180deg, rgba(217, 217, 217, 0.05) 0%, rgba(217, 217, 217, 0.05) 100%);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 9;
  }
  #Header .h6_header-middle {
    text-align: center;
  }
  #Header .h6_header-right {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: end;
  }
  #Header .h6_header-logo {
    position: relative;
    z-index: 1;
    padding-right: 21px;
    margin-right: 20px;
  }
  @media (max-width: 767px) {
    #Header .h6_header-logo {
      margin-right: 10px;
      padding-right: 11px;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    #Header  .h6_header-logo {
      margin-right: 15px;
      padding-right: 16px;
    }
  }
  
  #Header .h6_header-area.sticky {
    background-color: var(--clr-body-heading);
  }
  
  #Header .h6_main-menu ul .menu-has-child {
    position: relative;
    z-index: 99;
    text-align: start;
  }
  #Header .h6_main-menu ul li {
    display: inline-block;
    margin-right: 45px;
  }
  #Header .h6_main-menu ul li:last-child {
    margin-right: 0;
  }
  #Header .h6_main-menu ul li:is(.menu-has-child) > a::after {
    display: inline-block;
    font-family: "Font Awesome 6 Pro";
    content: "\f107";
    font-size: 12px;
    font-weight: 500;
    margin-left: 5px;
  }
  #Header .h6_main-menu ul li a {
    font-size: 16px;
    color: var(--clr-common-white);
    padding: 10px 0;
    font-weight: 500;
    display: block;
  }
  #Header .h6_main-menu ul li .submenu {
    position: absolute;
    left: 0;
    top: 100%;
    background: #fff;
    width: 240px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
    box-shadow: 0 9px 19px rgba(0, 0, 0, 0.1);
  }
  #Header .h6_main-menu ul li .submenu li {
    display: block;
    margin-right: 0;
    position: relative;
    z-index: 99;
  }
  #Header .h6_main-menu ul li .submenu li a {
    padding: 10px 25px;
    color: var(--clr-body-heading);
    -webkit-transition: all 0.4s linear 0s;
    -moz-transition: all 0.4s linear 0s;
    -ms-transition: all 0.4s linear 0s;
    -o-transition: all 0.4s linear 0s;
    transition: all 0.4s linear 0s;
  }
  #Header .h6_main-menu ul li .submenu li .submenu {
    left: 100%;
    top: 0;
  }
  #Header .h6_main-menu ul li .submenu li:hover > a {
    background-color: var(--clr-theme-primary-6);
    color: #fff;
  }
  #Header .h6_main-menu ul li .submenu li:not(:last-child) {
    border-bottom: 1px solid #f2f2f2;
  }
  #Header .h6_main-menu ul li:hover > .submenu {
    opacity: 1;
    visibility: visible;
  }
  #Header .h6_main-menu ul li:hover > a {
    color: var(--clr-theme-primary-3);
  }
  
  #Header .h6_menu-bar .header-menu-bar-icon {
    color: #fff;
  }
  
  #Header .h7_header-search form {
    position: relative;
    z-index: 1;
  }
  #Header .h7_header-search form input {
    min-width: 300px;
    width: 100%;
    height: 45px;
    line-height: 45px;
    border: none;
    border-radius: 6px;
    padding: 0 20px;
    padding-right: 55px;
    font-size: 15px;
    color: rgba(30, 30, 30, 0.6);
    border-radius: 4px;
    background: rgba(44, 44, 44, 0.06);
  }
  #Header .h7_header-search form input::-webkit-input-placeholder {
    color: rgba(30, 30, 30, 0.6);
    font-size: 15px;
    opacity: 1;
    -webkit-transition: all 0.5s linear 0s;
    -moz-transition: all 0.5s linear 0s;
    -ms-transition: all 0.5s linear 0s;
    -o-transition: all 0.5s linear 0s;
    transition: all 0.5s linear 0s;
  }
  #Header .h7_header-search form input:-moz-placeholder {
    color: rgba(30, 30, 30, 0.6);
    font-size: 15px;
    opacity: 1;
    -webkit-transition: all 0.5s linear 0s;
    -moz-transition: all 0.5s linear 0s;
    -ms-transition: all 0.5s linear 0s;
    -o-transition: all 0.5s linear 0s;
    transition: all 0.5s linear 0s;
  }
  #Header .h7_header-search form input::-moz-placeholder {
    color: rgba(30, 30, 30, 0.6);
    font-size: 15px;
    opacity: 1;
    -webkit-transition: all 0.5s linear 0s;
    -moz-transition: all 0.5s linear 0s;
    -ms-transition: all 0.5s linear 0s;
    -o-transition: all 0.5s linear 0s;
    transition: all 0.5s linear 0s;
  }
  #Header .h7_header-search form input:-ms-input-placeholder {
    color: rgba(30, 30, 30, 0.6);
    font-size: 15px;
    opacity: 1;
    -webkit-transition: all 0.5s linear 0s;
    -moz-transition: all 0.5s linear 0s;
    -ms-transition: all 0.5s linear 0s;
    -o-transition: all 0.5s linear 0s;
    transition: all 0.5s linear 0s;
  }
  #Header .h7_header-search form button {
    position: absolute;
    right: 20px;
    top: 0;
    height: 100%;
    font-size: 15px;
    color: #2C2C2C;
    border: none;
    background: transparent;
    padding: 0;
  }
  #Header .h7_header-search form button i {
    font-weight: 300;
  }
  #Header .h7_header-left {
    display: flex;
    align-items: center;
    gap: 90px;
    height: 80px;
  }
  #Header .h7_header-right {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: end;
  }
  #Header .h7_header-logo {
    position: relative;
    z-index: 1;
  }
  #Header  .h7_header-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    padding: 0 30px;
    color: #fff;
    font-size: 16px;
    height: 45px;
    font-weight: 600;
    background-color: var(--clr-theme-primary-6);
  }
  #Header .h7_header-btn svg {
    color: #fff;
  }
  
  #Header .h7_main-menu ul .menu-has-child {
    position: relative;
    z-index: 99;
    text-align: start;
  }
  #Header .h7_main-menu ul li {
    display: inline-block;
    margin-right: 40px;
  }
  #Header .h7_main-menu ul li:last-child {
    margin-right: 0;
  }
  #Header .h7_main-menu ul li:is(.menu-has-child) > a::after {
    display: inline-block;
    font-family: "Font Awesome 6 Pro";
    content: "\f107";
    font-size: 12px;
    font-weight: 500;
    margin-left: 5px;
  }
  #Header .h7_main-menu ul li a {
    font-size: 16px;
    color: #2C2C2C;
    padding: 10px 0;
    font-weight: 500;
    display: block;
  }
  #Header .h7_main-menu ul li .submenu {
    position: absolute;
    left: 0;
    top: 100%;
    background: #fff;
    width: 240px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
    box-shadow: 0 9px 19px rgba(0, 0, 0, 0.1);
  }
  #Header .h7_main-menu ul li .submenu li {
    display: block;
    margin-right: 0;
    position: relative;
    z-index: 99;
  }
  #Header .h7_main-menu ul li .submenu li a {
    padding: 10px 25px;
    color: var(--clr-body-heading);
    -webkit-transition: all 0.4s linear 0s;
    -moz-transition: all 0.4s linear 0s;
    -ms-transition: all 0.4s linear 0s;
    -o-transition: all 0.4s linear 0s;
    transition: all 0.4s linear 0s;
  }
  #Header .h7_main-menu ul li .submenu li .submenu {
    left: 100%;
    top: 0;
  }
  #Header .h7_main-menu ul li .submenu li:hover > a {
    background-color: var(--clr-theme-primary-6);
    color: #fff;
  }
  #Header .h7_main-menu ul li .submenu li:not(:last-child) {
    border-bottom: 1px solid #f2f2f2;
  }
  #Header .h7_main-menu ul li:hover > .submenu {
    opacity: 1;
    visibility: visible;
  }
  #Header .h7_main-menu ul li:hover > a {
    color: var(--clr-theme-primary-3);
  }
  
  #Header .h7_menu-bar .header-menu-bar-icon {
    color: var(--clr-body-heading);
  }
  
  #Header .h7_header-top {
    background: var(--clr-theme-primary-6);
    height: 35px;
    line-height: 35px;
  }
  #Header .h7_header-top-list {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  #Header .h7_header-top-list li {
    position: relative;
    z-index: 1;
  }
  #Header .h7_header-top-list li:not(:last-child) {
    margin-right: 15px;
    padding-right: 25px;
  }
  #Header  .h7_header-top-list li:not(:last-child)::before {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    background: rgba(255, 255, 255, 0.2);
    height: 16px;
    content: "";
    z-index: 1;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    #Header .h7_header-top-list li:not(:last-child) {
      margin-right: 0;
      padding-right: 10px;
    }
  }
  #Header  .h7_header-top-list li a {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
  }
  #Header .h7_header-top-social ul {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: end;
  }
  #Header .h7_header-top-social ul li a {
    color: rgba(255, 255, 255, 0.3);
    font-size: 15px;
  }
  #Header .h7_header-top-social ul li a:hover {
    color: #fff;
  }
  
  #Header .inner_page_header-area {
    position: relative;
    z-index: 9;
    box-shadow: 0px 6px 50px rgba(30, 30, 30, 0.1);
  }
  
  #Header .h8_header-top {
    background: #021936;
    height: 40px;
    line-height: 40px;
  }
  #Header .h8_header-top-text {
    margin-bottom: 0;
    color: hsla(0, 0%, 100%, 0.8);
  }
  #Header .h8_header-top-text span {
    color: #F14D5D;
  }
  #Header .h8_header-top-social ul {
    display: flex;
    align-items: center;
    justify-content: end;
  }
  #Header .h8_header-top-social ul li a {
    color: #fff;
    font-size: 15px;
    position: relative;
    z-index: 1;
    padding-left: 9px;
    margin-left: 9px;
  }
  #Header .h8_header-top-social ul li a::before {
    position: absolute;
    left: 0;
    top: 50%;
    content: "";
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.2);
    width: 1px;
    height: 16px;
  }
  #Header .h8_header-top-social ul li a:hover {
    color: #fff;
  }
  #Header .h8_header-top-social ul li:first-child a::before {
    display: none;
  }
  
  #Header .h8_header-search form {
    position: relative;
    z-index: 1;
  }
  #Header .h8_header-search form input {
    min-width: 300px;
    width: 100%;
    height: 45px;
    line-height: 45px;
    border: none;
    border-radius: 6px;
    padding: 0 20px;
    padding-right: 55px;
    font-size: 15px;
    color: rgba(30, 30, 30, 0.6);
    border-radius: 4px;
    background: rgba(44, 44, 44, 0.06);
  }
  #Header .h8_header-search form input::-webkit-input-placeholder {
    color: rgba(30, 30, 30, 0.6);
    font-size: 15px;
    opacity: 1;
    -webkit-transition: all 0.5s linear 0s;
    -moz-transition: all 0.5s linear 0s;
    -ms-transition: all 0.5s linear 0s;
    -o-transition: all 0.5s linear 0s;
    transition: all 0.5s linear 0s;
  }
  #Header .h8_header-search form input:-moz-placeholder {
    color: rgba(30, 30, 30, 0.6);
    font-size: 15px;
    opacity: 1;
    -webkit-transition: all 0.5s linear 0s;
    -moz-transition: all 0.5s linear 0s;
    -ms-transition: all 0.5s linear 0s;
    -o-transition: all 0.5s linear 0s;
    transition: all 0.5s linear 0s;
  }
  #Header .h8_header-search form input::-moz-placeholder {
    color: rgba(30, 30, 30, 0.6);
    font-size: 15px;
    opacity: 1;
    -webkit-transition: all 0.5s linear 0s;
    -moz-transition: all 0.5s linear 0s;
    -ms-transition: all 0.5s linear 0s;
    -o-transition: all 0.5s linear 0s;
    transition: all 0.5s linear 0s;
  }
  #Header .h8_header-search form input:-ms-input-placeholder {
    color: rgba(30, 30, 30, 0.6);
    font-size: 15px;
    opacity: 1;
    -webkit-transition: all 0.5s linear 0s;
    -moz-transition: all 0.5s linear 0s;
    -ms-transition: all 0.5s linear 0s;
    -o-transition: all 0.5s linear 0s;
    transition: all 0.5s linear 0s;
  }
  #Header .h8_header-search form button {
    position: absolute;
    right: 20px;
    top: 0;
    height: 100%;
    font-size: 15px;
    color: #2C2C2C;
    border: none;
    background: transparent;
    padding: 0;
  }
  #Header .h8_header-search form button i {
    font-weight: 300;
  }
  #Header .h8_header-left {
    display: flex;
    align-items: center;
    gap: 75px;
    height: 80px;
  }
  #Header .h8_header-right {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: end;
  }
  #Header .h8_header-action {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  #Header .h8_header-login {
    color: #021936;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 6px;
  }
  #Header .h8_header-login svg {
    color: #021936;
  }
  #Header  .h8_header-search {
    color: #021936;
  }
  #Header .h8_header-logo {
    position: relative;
    z-index: 1;
  }
  #Header  .h8_header-btn {
    height: 45px !important;
  }
  
  #Header .h8_main-menu ul .menu-has-child {
    position: relative;
    z-index: 99;
    text-align: start;
  }
  #Header .h8_main-menu ul li {
    display: inline-block;
    margin-right: 40px;
  }
  #Header .h8_main-menu ul li:last-child {
    margin-right: 0;
  }
  #Header .h8_main-menu ul li:is(.menu-has-child) > a::after {
    display: inline-block;
    font-family: "Font Awesome 6 Pro";
    content: "+";
    font-size: 12px;
    font-weight: 500;
    margin-left: 5px;
  }
  #Header .h8_main-menu ul li a {
    font-size: 16px;
    color: #2C2C2C;
    padding: 10px 0;
    font-weight: 500;
    display: block;
  }
  #Header .h8_main-menu ul li .submenu {
    position: absolute;
    left: 0;
    top: 100%;
    background: #fff;
    width: 240px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
    box-shadow: 0 9px 19px rgba(0, 0, 0, 0.1);
  }
  #Header .h8_main-menu ul li .submenu li {
    display: block;
    margin-right: 0;
    position: relative;
    z-index: 99;
  }
  #Header .h8_main-menu ul li .submenu li a {
    padding: 10px 25px;
    color: var(--clr-body-heading);
    -webkit-transition: all 0.4s linear 0s;
    -moz-transition: all 0.4s linear 0s;
    -ms-transition: all 0.4s linear 0s;
    -o-transition: all 0.4s linear 0s;
    transition: all 0.4s linear 0s;
  }
  #Header .h8_main-menu ul li .submenu li .submenu {
    left: 100%;
    top: 0;
  }
  #Header .h8_main-menu ul li .submenu li:hover > a {
    background-color: var(--clr-theme-primary-6);
    color: #fff;
  }
  #Header .h8_main-menu ul li .submenu li:not(:last-child) {
    border-bottom: 1px solid #f2f2f2;
  }
  #Header .h8_main-menu ul li:hover > .submenu {
    opacity: 1;
    visibility: visible;
  }
  #Header .h8_main-menu ul li:hover > a {
    color: var(--clr-theme-primary-3);
  }
  
  #Header .h8_menu-bar .header-menu-bar-icon {
    color: var(--clr-body-heading);
  }
  
  #Header .h9_header-button-text {
    color: #21242C;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  #Header .h9_header-button:hover > a {
    color: var(--clr-theme-primary-9);
  }
  #Header .h9_header-button .h2_header-category-submenu li:hover > a {
    background-color: var(--clr-theme-primary-9);
    color: #fff;
  }
  
  #Header .h9_header-search form {
    position: relative;
    z-index: 1;
  }
  #Header .h9_header-search form input {
    min-width: 300px;
    width: 100%;
    height: 45px;
    line-height: 45px;
    padding: 0 15px;
    padding-right: 45px;
    color: rgba(33, 36, 44, 0.6);
    font-size: 13px;
    font-weight: 400;
    border-radius: 6px;
    border: 1.5px solid #1865F2;
    background: #F5F5F5;
  }
  #Header .h9_header-search form input::-webkit-input-placeholder {
    color: rgba(33, 36, 44, 0.6);
    font-size: 13px;
    opacity: 1;
  }
  #Header .h9_header-search form input:-moz-placeholder {
    color: rgba(33, 36, 44, 0.6);
    font-size: 13px;
    opacity: 1;
  }
  #Header .h9_header-search form input::-moz-placeholder {
    color: rgba(33, 36, 44, 0.6);
    font-size: 13px;
    opacity: 1;
  }
  #Header .h9_header-search form input:-ms-input-placeholder {
    color: rgba(33, 36, 44, 0.6);
    font-size: 13px;
    opacity: 1;
  }
  #Header .h9_header-search form button {
    position: absolute;
    right: 15px;
    top: 0;
    height: 100%;
    font-size: 14px;
    border: none;
    background: transparent;
    padding: 0;
  }
  #Header .h9_header-search form button svg {
    font-weight: 300;
    color: #21242C;
  }
  #Header .h9_header-left {
    display: flex;
    align-items: center;
    gap: 20px;
    height: 90px;
  }
  #Header .h9_header-right {
    display: flex;
    align-items: center;
    gap: 30px;
    justify-content: end;
  }
  #Header .h9_header-logo {
    position: relative;
    z-index: 1;
  }
  @media (max-width: 767px) {
    #Header .h9_header-logo {
      height: 70px;
      line-height: 70px;
    }
  }
  #Header .h9_header-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0 30px !important;
    height: 45px !important;
  }
  #Header .h9_header-btn svg {
    color: #fff;
  }
  
  #Header .h9_header-right-text ul {
    display: flex;
    align-items: center;
    gap: 55px;
  }
  
  #Header .h9_header-right-text ul li a {
    color: #21242C;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 10px;
  }


  .p-dropdown-panel {
    z-index: 9999 !important;
}



.header-sticky.sticky {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  z-index: 999 !important;
  background: #fff !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;
  transition: all 0.3s ease !important;
}

/* #classe.p-dropdown,   #matiere.p-dropdown,  #periode.p-dropdown, #periode.p-dropdown, #matricule.p-dropdown, .p-dropdown{
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 10px !important;
  width: 100% !important;
}
   */


   #HomePage .side-info {
    width: 100%;
    max-width: 400px;
    background-color: #fff !important;
    box-shadow: 0 13px 29px rgba(4, 0, 23, 0.1);
    height: 100%;
    position: fixed;
    z-index: 999;
    top: 0;
    right: -400px;
    transition: all 0.4s linear 0s;
    padding: 30px 40px 40px 40px;
}




.mean-nav ul {
  list-style: none;
  padding: 0;
}

.mean-nav .menu-has-child > a {
  display: block;
  padding: 10px;
  font-weight: bold;
  cursor: pointer;
}

.mean-nav .dropdown-submenu {
  display: block;
  padding-left: 20px;
}

/* .side-info .dropdown-submenu {
  display: none;
} */
/* 
.menu-has-child.open .dropdown-submenu {
  display: block;
} */


.theme-btn {
  background: var(--clr-theme-primary);
  color: var(--clr-common-white);
  display: inline-flex;
  align-items: center;
  height: 40px;
  padding: 0 25px;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  cursor: pointer;
  margin-bottom: 0;
  text-align: center;
  touch-action: manipulation;
  transition: all 0.3s ease 0s;
  white-space: nowrap;
  border: none;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
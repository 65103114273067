.customer-badge,
.product-badge,
.order-badge {
    border-radius: var(--border-radius);
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
}

.customer-badge {
    &.status-qualified {
        background: #C8E6C9;
        color: #256029;
    }

    &.status-unqualified {
        background: #FFCDD2;
        color: #C63737;
    }

    &.status-negotiation {
        background: #FEEDAF;
        color: #8A5340;
    }

    &.status-new {
        background: #B3E5FC;
        color: #23547B;
    }

    &.status-renewal {
        background: #ECCFFF;
        color: #694382;
    }

    &.status-proposal {
        background: #FFD8B2;
        color: #805B36;
    }
}

.product-badge {
    border-radius: var(--border-radius);
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;

    &.status-instock {
        background: #C8E6C9;
        color: #256029;
    }

    &.status-outofstock {
        background: #FFCDD2;
        color: #C63737;
    }

    &.status-lowstock {
        background: #FEEDAF;
        color: #8A5340;
    }
}

.moyenne-badge {
    border-radius: var(--border-radius);
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;

    &.status-Ex {
        background: #016d04;
        color: #ffffff;
    }
    &.status-TrB {
        background: #ffffff;
        color: #016d04;
    }

    &.status-Bi {
        background: #ffe600;
        color: #8A5340;
    }
    &.status-AsB {
        background: #e9b90c;
        color: #8A5340;
    }
    &.status-Pa {
        background: #0892ee;
        color: #f0f0f0;
    }

    &.status-In {
        background: #FFCDD2;
        color: #C63737;
    }

    &.status-Fa {
        background: #f84b5c;
        color: #cfcfcf;
    }

    &.status-TrF {
        background: #f84b5c;
        color: #ffffff;
    }

}

.first-badge {
    border-radius: var(--border-radius);
    padding: .25em .5rem;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;

    &.status-PRM {
        background: #b0bcd8;
        color: #1638f5;
    }
}

.order-badge {
    border-radius: var(--border-radius);
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;

    &.order-delivered {
        background: #C8E6C9;
        color: #256029;
    }

    &.order-cancelled {
        background: #FFCDD2;
        color: #C63737;
    }

    &.order-pending {
        background: #FEEDAF;
        color: #8A5340;
    }

    &.order-returned {
        background: #ECCFFF;
        color: #694382;
    }
}

.defile {
    display: block;
    -webkit-animation: linear marqueelike 20s infinite;
    -moz-animation: linear marqueelike 20s infinite;
    -o-animation: linear marqueelike 20s infinite;
    -ms-animation: linear marqueelike 20s infinite;
    animation: linear marqueelike 20s infinite;
    margin-left: -100%;
    padding: 0 5px;
    text-align: left;
    height: 25px;
}

.marquee-rtl {

    overflow: hidden;
    /* masque tout ce qui dépasse */

}

/*
* A déplacer dans un endroit approprié
* Style pour faire défiler le texte au niveau de la zone info de la page d'accueil
* Et faire tourner le logo de GAIN dans la zone PUBLICITE
*/
.marquee-rtl> :first-child {
    display: inline-block;
    /* modèle de boîte en ligne */
    padding-right: 2em;
    /* un peu d'espace pour la transition */
    padding-left: 100%;
    /* placement à droite du conteneur */
    // white-space: nowrap;                  /* pas de passage à la ligne */
    animation: defilement-rtl 40s infinite linear;
}

.tourne {
    -webkit-animation: spin 10s linear infinite;
    -moz-animation: spin 10s linear infinite;
    animation: spin 10s linear infinite;
}

//   @-moz-keyframes spin {
//     100% { -moz-transform: rotate(-360deg); }
//   }
//   @-webkit-keyframes spin {
//     100% { -webkit-transform: rotate(-360deg); }
//   }
//   @keyframes spin {
//     100% { -webkit-transform: rotate(-360deg); transform: rotate(-360deg); }
//   }

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate3d(0,1,0,360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate3d(0,1,0,360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate3d(0,1,0,360deg);
        transform: rotate3d(0,1,0,360deg);
    }
}

@keyframes defilement-rtl {
    0% {
        transform: translate3d(0, 0, 0);
        /* position initiale à droite */
    }

    100% {
        transform: translate3d(-100%, 0, 0);
        /* position finale à gauche */
    }
}
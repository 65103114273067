/*
************
*************************
05. section
******************************************************* 
*************************************************************** */
.section-area .section-subtitle {
    color: #D2093C;
    font-size: 15px;
    font-weight: 500;
    display: inline-block;
    background-color: rgba(210, 9, 59, 0.04);
    padding: 10px 25px;
    line-height: 1;
    position: relative;
    z-index: 1;
    margin-bottom: 10px;
  }
  @media (max-width: 767px) {
    .section-area .section-subtitle {
      font-size: 14px;
      padding: 10px 20px;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .section-area .section-subtitle {
      font-size: 15px;
      padding: 10px 25px;
    }
  }
#HomePage .section-area .section-subtitle::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 2px;
    height: 100%;
    background-color: #D2093C;
    content: "";
  }
#HomePage .section-area .section-title {
    font-size: 46px;
    font-weight: 700;
    line-height: 1.2;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .section-area .section-title {
      font-size: 40px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-area .section-title {
      font-size: 40px;
    }
  }
  @media (max-width: 767px) {
    .section-area .section-title {
      font-size: 32px;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .section-area .section-title {
      font-size: 40px;
    }
  }
#HomePage .section-area .section-text {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
    color: rgba(30, 30, 30, 0.7);
  }
  
#HomePage .section-area-top .section-subtitle::before {
    left: 0;
    top: 0;
    width: 100%;
    height: 2px;
  }
  
#HomePage .banner-content .section-subtitle {
    background-color: #fff;
    margin-bottom: 15px;
  }
#HomePage .banner-content .section-title {
    font-size: 70px;
    font-weight: 600;
    line-height: 1.1;
    margin-bottom: 15px;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .banner-content .section-title {
      font-size: 60px;
      margin-bottom: 20px;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .banner-content .section-title {
      font-size: 45px;
      margin-bottom: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .banner-content .section-title {
      font-size: 45px;
      margin-bottom: 20px;
    }
  }
  @media (max-width: 767px) {
    .banner-content .section-title {
      font-size: 35px;
      margin-bottom: 20px;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .banner-content .section-title {
      font-size: 45px;
    }
  }
#HomePage .banner-content .section-title span {
    display: inline-block;
    position: relative;
    z-index: 1;
  }
#HomePage .banner-content .section-title span img {
    position: absolute;
    left: 0;
    bottom: -5px;
    width: calc(100% - 25px);
    display: inline-block;
    margin-left: 10px;
    animation: section-animation 5s linear 0s infinite;
  }
#HomePage .banner-content .section-text {
    margin-bottom: 43px;
    color: rgba(30, 30, 30, 0.6);
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .banner-content .section-text br {
      display: none;
    }
  }
  
#HomePage .section-area-2 .section-title {
    font-size: 48px;
    font-weight: 700;
    line-height: 1.2;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .section-area-2 .section-title {
      font-size: 40px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-area-2 .section-title {
      font-size: 40px;
    }
  }
  @media (max-width: 767px) {
    .section-area-2 .section-title {
      font-size: 32px;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .section-area-2 .section-title {
      font-size: 40px;
    }
  }
#HomePage .section-area-2 .section-title span {
    display: inline-block;
    position: relative;
    z-index: 1;
    color: var(--clr-theme-primary);
  }
#HomePage .section-area-2 .section-title span img {
    position: absolute;
    left: 0;
    bottom: -7px;
    width: calc(100% - 25px);
    display: inline-block;
    margin-left: 10px;
    animation: section-animation 5s linear 0s infinite;
  }
#HomePage .section-area-2 .section-text {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 0;
    color: rgba(30, 30, 30, 0.7);
  }
  
#HomePage .h2_banner-content .section-title {
    font-size: 70px;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 15px;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .h2_banner-content .section-title {
      font-size: 60px;
      margin-bottom: 20px;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .h2_banner-content .section-title {
      font-size: 50px;
      margin-bottom: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .h2_banner-content .section-title {
      font-size: 45px;
      margin-bottom: 20px;
    }
  }
  @media (max-width: 767px) {
    .h2_banner-content .section-title {
      font-size: 33px;
      margin-bottom: 20px;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .h2_banner-content .section-title {
      font-size: 45px;
    }
  }
#HomePage .h2_banner-content .section-title span {
    display: inline-block;
    position: relative;
    z-index: 1;
    color: var(--clr-theme-primary);
  }
#HomePage .h2_banner-content .section-title span img {
    position: absolute;
    left: 0;
    bottom: -5px;
    width: calc(100% - 25px);
    display: inline-block;
    margin-left: 10px;
  }
#HomePage .h2_banner-content .section-text {
    margin-bottom: 43px;
    color: rgba(30, 30, 30, 0.6);
  }
  
#HomePage .section-area-3 .section-subtitle {
    color: var(--clr-theme-primary-3);
    font-size: 16px;
    display: block;
    margin-bottom: 15px;
  }
#HomePage .section-area-3 .section-title {
    font-size: 46px;
    font-weight: 700;
    line-height: 1.2;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .section-area-3 .section-title {
      font-size: 40px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-area-3 .section-title {
      font-size: 40px;
    }
  }
  @media (max-width: 767px) {
    .section-area-3 .section-title {
      font-size: 32px;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .section-area-3 .section-title {
      font-size: 40px;
    }
  }
#HomePage .section-area-3 .section-text {
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 0;
    color: rgba(30, 30, 30, 0.7);
    letter-spacing: 0.15px;
  }
  
#HomePage .h3_banner-content .section-subtitle {
    color: var(--clr-body-heading);
    font-size: 16px;
    font-weight: 500;
    display: inline-block;
    position: relative;
    z-index: 1;
    margin-bottom: 10px;
  }
#HomePage .h3_banner-content .section-title {
    font-size: 64px;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 15px;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .h3_banner-content .section-title {
      font-size: 60px;
      margin-bottom: 20px;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .h3_banner-content .section-title {
      font-size: 45px;
      margin-bottom: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .h3_banner-content .section-title {
      font-size: 45px;
      margin-bottom: 20px;
    }
  }
  @media (max-width: 767px) {
    .h3_banner-content .section-title {
      font-size: 35px;
      margin-bottom: 20px;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .h3_banner-content .section-title {
      font-size: 45px;
    }
  }
#HomePage .h3_banner-content .section-title span {
    display: inline-block;
    position: relative;
    z-index: 1;
    color: var(--clr-theme-primary-3);
  }
#HomePage .h3_banner-content .section-text {
    font-size: 17px;
    margin-bottom: 43px;
    color: rgba(30, 30, 30, 0.7);
  }
  
#HomePage .section-area-4 .section-subtitle {
    color: var(--clr-theme-primary-3);
    font-size: 16px;
    display: block;
    margin-bottom: 15px;
  }
#HomePage .section-area-4 .section-title {
    font-size: 46px;
    font-weight: 700;
    line-height: 1.2;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .section-area-4 .section-title {
      font-size: 40px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-area-4 .section-title {
      font-size: 40px;
    }
  }
  @media (max-width: 767px) {
    .section-area-4 .section-title {
      font-size: 32px;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .section-area-4 .section-title {
      font-size: 40px;
    }
  }
#HomePage .section-area-4 .section-text {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 0;
    color: rgba(30, 30, 30, 0.7);
  }
  
#HomePage .section-white-4 .section-title {
    color: #fff;
  }
#HomePage .section-white-4 .section-text {
    color: rgba(255, 255, 255, 0.7);
  }
  
#HomePage .h4_banner-content .section-title {
    font-size: 90px;
    font-weight: 400;
    line-height: 1.2;
    margin-bottom: 20px;
    color: #fff;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .h4_banner-content .section-title br {
      display: none;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .h4_banner-content .section-title {
      font-size: 80px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .h4_banner-content .section-title {
      font-size: 75px;
    }
  }
  @media (max-width: 767px) {
    .h4_banner-content .section-title {
      font-size: 45px;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .h4_banner-content .section-title {
      font-size: 60px;
    }
  }
#HomePage .h4_banner-content .section-title span {
    display: inline-block;
    font-weight: 900;
    color: var(--clr-theme-primary-4);
  }
#HomePage .h4_banner-content .section-text {
    font-size: 16px;
    margin-bottom: 40px;
    color: rgba(255, 255, 255, 0.7);
  }
  
#HomePage .section-area-5 .section-subtitle {
    color: var(--clr-body-heading);
    font-size: 16px;
    display: block;
  }
#HomePage .section-area-5 .section-title {
    font-size: 46px;
    font-weight: 700;
    line-height: 1.2;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .section-area-5 .section-title {
      font-size: 40px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-area-5 .section-title {
      font-size: 40px;
    }
  }
  @media (max-width: 767px) {
    .section-area-5 .section-title {
      font-size: 32px;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .section-area-5 .section-title {
      font-size: 40px;
    }
  }
#HomePage .section-area-5 .section-text {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 0;
    color: rgba(30, 30, 30, 0.7);
  }
  
#HomePage .section-area-6 .section-subtitle {
    color: var(--clr-theme-primary-6);
    font-size: 16px;
    display: block;
  }
#HomePage .section-area-6 .section-title {
    font-size: 44px;
    font-weight: 600;
    line-height: 1.2;
  }
  @media (max-width: 767px) {
    .section-area-6 .section-title {
      font-size: 33px;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .section-area-6 .section-title {
      font-size: 44px;
    }
  }
#HomePage .section-area-6 .section-text {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
    color: #666;
  }
  @media (max-width: 767px) {
    .section-area-6 .section-text br {
      display: none;
    }
  }
  
#HomePage .section-area-6-admin {
    position: relative;
    z-index: 1;
    display: block;
    color: #0A0A0A;
    font-size: 16px;
    font-weight: 500;
    padding-left: 55px;
    line-height: 21px;
    margin-top: 30px;
  }
  
#HomePage .section-area-6-admin::before {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    content: "";
    width: 45px;
    height: 1px;
    background: #0A0A0A;
  }
  
#HomePage .section-area-8 .section-subtitle {
    color: #F14D5D;
    font-size: 16px;
    display: block;
    margin-bottom: 8px;
    font-weight: 700;
    text-transform: uppercase;
  }
  @media (max-width: 767px) {
    .section-area-8 .section-subtitle {
      font-weight: 500;
    }
  }
#HomePage .section-area-8 .section-title {
    color: #021936;
    font-size: 40px;
    font-weight: 600;
    line-height: 1.2;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .section-area-8 .section-title {
      font-size: 40px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-area-8 .section-title {
      font-size: 40px;
    }
  }
  @media (max-width: 767px) {
    .section-area-8 .section-title {
      font-size: 32px;
    }
    .section-area-8 .section-title br {
      display: none;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .section-area-8 .section-title {
      font-size: 40px;
    }
  }
#HomePage .section-area-8 .section-text {
    margin-bottom: 0;
    color: rgba(2, 25, 54, 0.7);
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
  }
  
#HomePage .section-area-9 .section-subtitle {
    color: #1865F2;
    font-size: 16px;
    font-weight: 500;
    display: block;
    line-height: 24px;
    margin-bottom: 5px;
  }
#HomePage .section-area-9 .section-title {
    color: #0A0A0A;
    font-size: 46px;
    font-weight: 700;
    line-height: 1.2;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .section-area-9 .section-title {
      font-size: 40px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-area-9 .section-title {
      font-size: 40px;
    }
  }
  @media (max-width: 767px) {
    .section-area-9 .section-title {
      font-size: 32px;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .section-area-9 .section-title {
      font-size: 40px;
    }
  }
  
#HomePage .section-area-10 .section-title {
    color: #0A0A0A;
    font-size: 46px;
    font-weight: 600;
    line-height: 1.1;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .section-area-10 .section-title {
      font-size: 40px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-area-10 .section-title {
      font-size: 40px;
    }
  }
  @media (max-width: 767px) {
    .section-area-10 .section-title {
      font-size: 32px;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .section-area-10 .section-title {
      font-size: 40px;
    }
  }
#HomePage .section-area-10 .section-text {
    margin-bottom: 0;
    color: #666;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
  }
  
#HomePage .small-section-area-2 .section-title {
    font-size: 40px;
  }
#HomePage .small-section-area-2 .section-title span img {
    bottom: -10px;
  }
  @media (max-width: 767px) {
    .small-section-area-2 .section-title {
      font-size: 32px;
    }
    .small-section-area-2 .section-title br {
      display: none;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .small-section-area-2 .section-title {
      font-size: 35px;
    }
    .small-section-area-2 .section-title br {
      display: block;
    }
  }
  
#HomePage .small-section-area-3 .section-title {
    font-size: 40px;
    line-height: 1.25;
  }
  @media (max-width: 767px) {
    .small-section-area-3 .section-title {
      font-size: 32px;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .small-section-area-3 .section-title {
      font-size: 35px;
    }
  }
  
#HomePage .h2_blog-section-area .section-title span {
    color: var(--clr-body-heading);
  }
  
#HomePage .h2_cta-content .section-area-2 .section-title span {
    color: var(--clr-body-heading);
  }
  
#HomePage .h2_teacher-section .section-area-2 .section-title span {
    color: var(--clr-body-heading);
  }
  
  @keyframes section-animation {
    0% {
      width: 0;
    }
    15% {
      width: 100%;
    }
    85% {
      opacity: 1;
    }
    90% {
      width: 100%;
      opacity: 0;
    }
    to {
      width: 0;
      opacity: 0;
    }
  }
/*
************
*************************

Template Name: Eduan - School University & Online Education Template
Description: School University & Online Education HTML Template Description
Author: author name
Version: 1.0

-----------------------------------------------------------------------------------

CSS INDEX
=================== 
01. common
02. header
03. sidebar
04. banner
05. section
06. brand
07. category
08. course
09. course-details
10. button
11. about
12. event
13. event-details
14. testimonial
15. counter
16. blog
17. blog-details
18. cta
19. teacher
20. price
21. breadcrumb
22. gallery
23. account
24. team details
25. contact
26. 404
27. admission
28. education
29. faq
30. feature
31. video
32. footer

******************************************************* 
*************************************************************** */
/*
************
*************************
01. common
******************************************************* 
*************************************************************** */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&amp;family=Sansita+Swashed:wght@400;500;600;700;800&amp;display=swap");
body {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: var(--clr-body-text);
  font-size: 16px;
  line-height: 28px;
}

#HomePage .img, img {
  max-width: 100%;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}

a,
#HomePage .button, button {
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}

a:focus,
#HomePage .button:focus {
  text-decoration: none;
  outline: none;
}

a:focus,
a:hover {
  color: inherit;
  text-decoration: none;
}

a,
button {
  color: inherit;
  outline: medium none;
  text-decoration: none;
}

button:focus, input:focus, input:focus, textarea, textarea:focus {
  outline: 0;
}

#HomePage .uppercase {
  text-transform: uppercase;
}

#HomePage .capitalize {
  text-transform: capitalize;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
  color: var(--clr-body-heading);
  margin-top: 0px;
  line-height: 1.2;
  font-weight: 700;
  margin-bottom: 15px;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

h1 {
  font-size: 70px;
}

h2 {
  font-size: 46px;
}

h3 {
  font-size: 40px;
}

h4 {
  font-size: 28px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 16px;
}

ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

p {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: var(--clr-body-text);
}

*::-moz-selection {
  background-color: var(--clr-theme-primary);
  color: var(--clr-common-white);
  text-shadow: none;
}

::-moz-selection {
  background-color: var(--clr-theme-primary);
  color: var(--clr-common-white);
  text-shadow: none;
}

::selection {
  background-color: var(--clr-theme-primary);
  color: var(--clr-common-white);
  text-shadow: none;
}

/* <<<<<<<    Input Placeholder   >>>>>>>>> */
input::-webkit-input-placeholder {
  color: var(--clr-body-text);
  font-size: 16px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
input:-moz-placeholder {
  color: var(--clr-body-text);
  font-size: 16px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
input::-moz-placeholder {
  color: var(--clr-body-text);
  font-size: 16px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
input:-ms-input-placeholder {
  color: var(--clr-body-text);
  font-size: 16px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}

textarea::-webkit-input-placeholder {
  color: var(--clr-body-text);
  font-size: 16px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
textarea:-moz-placeholder {
  color: var(--clr-body-text);
  font-size: 16px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
textarea::-moz-placeholder {
  color: var(--clr-body-text);
  font-size: 16px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
textarea:-ms-input-placeholder {
  color: var(--clr-body-text);
  font-size: 16px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}

input:focus::placeholder, textarea:focus::placeholder {
  opacity: 0;
}

/* <<<<<<<    Common Classes   >>>>>>>>> */
#HomePage .w_img img {
  width: 100%;
}

#HomePage .fix {
  overflow: hidden;
}

/* <<<<<<<    Overlay   >>>>>>>>> */
[data-overlay] {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 1;
}
[data-overlay]::before {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  content: "";
}

/* <<<<<<<    Margin & Padding     >>>>>>>>> */
#HomePage .mt-5 {
  margin-top: 5px;
}

#HomePage .mt-10 {
  margin-top: 10px;
}

#HomePage .mt-15 {
  margin-top: 15px;
}

#HomePage .mt-20 {
  margin-top: 20px;
}

#HomePage .mt-25 {
  margin-top: 25px;
}

#HomePage .mt-30 {
  margin-top: 30px;
}

#HomePage .mt-35 {
  margin-top: 35px;
}

#HomePage .mt-40 {
  margin-top: 40px;
}

#HomePage .mt-45 {
  margin-top: 45px;
}

#HomePage .mt-50 {
  margin-top: 50px;
}

#HomePage .mt-55 {
  margin-top: 55px;
}

#HomePage .mt-60 {
  margin-top: 60px;
}

#HomePage .mt-65 {
  margin-top: 65px;
}

#HomePage .mt-70 {
  margin-top: 70px;
}

#HomePage .mt-75 {
  margin-top: 75px;
}

#HomePage .mt-80 {
  margin-top: 80px;
}

#HomePage .mt-85 {
  margin-top: 85px;
}

#HomePage .mt-90 {
  margin-top: 90px;
}

#HomePage .mt-95 {
  margin-top: 95px;
}

#HomePage .mt-100 {
  margin-top: 100px;
}

#HomePage .mt-105 {
  margin-top: 105px;
}

#HomePage .mt-110 {
  margin-top: 110px;
}

#HomePage .mt-115 {
  margin-top: 115px;
}

#HomePage .mt-120 {
  margin-top: 120px;
}

#HomePage .mt-125 {
  margin-top: 125px;
}

#HomePage .mt-130 {
  margin-top: 130px;
}

#HomePage .mt-135 {
  margin-top: 135px;
}

#HomePage .mt-140 {
  margin-top: 140px;
}

#HomePage .mt-145 {
  margin-top: 145px;
}

#HomePage .mt-150 {
  margin-top: 150px;
}

#HomePage .mt-155 {
  margin-top: 155px;
}

#HomePage .mt-160 {
  margin-top: 160px;
}

#HomePage .mt-165 {
  margin-top: 165px;
}

#HomePage .mt-170 {
  margin-top: 170px;
}

#HomePage .mt-175 {
  margin-top: 175px;
}

#HomePage .mt-180 {
  margin-top: 180px;
}

#HomePage .mt-185 {
  margin-top: 185px;
}

#HomePage .mt-190 {
  margin-top: 190px;
}

#HomePage .mt-195 {
  margin-top: 195px;
}

#HomePage .mt-200 {
  margin-top: 200px;
}

#HomePage .mb-5 {
  margin-bottom: 5px;
}

#HomePage .mb-10 {
  margin-bottom: 10px;
}

#HomePage .mb-15 {
  margin-bottom: 15px;
}

#HomePage .mb-20 {
  margin-bottom: 20px;
}

#HomePage .mb-25 {
  margin-bottom: 25px;
}

#HomePage .mb-30 {
  margin-bottom: 30px;
}

#HomePage .mb-35 {
  margin-bottom: 35px;
}

#HomePage .mb-40 {
  margin-bottom: 40px;
}

#HomePage .mb-45 {
  margin-bottom: 45px;
}

#HomePage .mb-50 {
  margin-bottom: 50px;
}

#HomePage .mb-55 {
  margin-bottom: 55px;
}

#HomePage .mb-60 {
  margin-bottom: 60px;
}

#HomePage .mb-65 {
  margin-bottom: 65px;
}

#HomePage .mb-70 {
  margin-bottom: 70px;
}

#HomePage .mb-75 {
  margin-bottom: 75px;
}

#HomePage .mb-80 {
  margin-bottom: 80px;
}

#HomePage .mb-85 {
  margin-bottom: 85px;
}

#HomePage .mb-90 {
  margin-bottom: 90px;
}

#HomePage .mb-95 {
  margin-bottom: 95px;
}

#HomePage .mb-100 {
  margin-bottom: 100px;
}

#HomePage .mb-105 {
  margin-bottom: 105px;
}

#HomePage .mb-110 {
  margin-bottom: 110px;
}

#HomePage .mb-115 {
  margin-bottom: 115px;
}

#HomePage .mb-120 {
  margin-bottom: 120px;
}

#HomePage .mb-125 {
  margin-bottom: 125px;
}

#HomePage .mb-130 {
  margin-bottom: 130px;
}

#HomePage .mb-135 {
  margin-bottom: 135px;
}

#HomePage .mb-140 {
  margin-bottom: 140px;
}

#HomePage .mb-145 {
  margin-bottom: 145px;
}

#HomePage .mb-150 {
  margin-bottom: 150px;
}

#HomePage .mb-155 {
  margin-bottom: 155px;
}

#HomePage .mb-160 {
  margin-bottom: 160px;
}

#HomePage .mb-165 {
  margin-bottom: 165px;
}

#HomePage .mb-170 {
  margin-bottom: 170px;
}

#HomePage .mb-175 {
  margin-bottom: 175px;
}

#HomePage .mb-180 {
  margin-bottom: 180px;
}

#HomePage .mb-185 {
  margin-bottom: 185px;
}

#HomePage .mb-190 {
  margin-bottom: 190px;
}

#HomePage .mb-195 {
  margin-bottom: 195px;
}

#HomePage .mb-200 {
  margin-bottom: 200px;
}

#HomePage .ml-5 {
  margin-left: 5px;
}

#HomePage .ml-10 {
  margin-left: 10px;
}

#HomePage .ml-15 {
  margin-left: 15px;
}

#HomePage .ml-20 {
  margin-left: 20px;
}

#HomePage .ml-25 {
  margin-left: 25px;
}

#HomePage .ml-30 {
  margin-left: 30px;
}

#HomePage .ml-35 {
  margin-left: 35px;
}

#HomePage .ml-40 {
  margin-left: 40px;
}

#HomePage .ml-45 {
  margin-left: 45px;
}

#HomePage .ml-50 {
  margin-left: 50px;
}

#HomePage .ml-55 {
  margin-left: 55px;
}

#HomePage .ml-60 {
  margin-left: 60px;
}

#HomePage .ml-65 {
  margin-left: 65px;
}

#HomePage .ml-70 {
  margin-left: 70px;
}

#HomePage .ml-75 {
  margin-left: 75px;
}

#HomePage .ml-80 {
  margin-left: 80px;
}

#HomePage .ml-85 {
  margin-left: 85px;
}

#HomePage .ml-90 {
  margin-left: 90px;
}

#HomePage .ml-95 {
  margin-left: 95px;
}

#HomePage .ml-100 {
  margin-left: 100px;
}

#HomePage .ml-105 {
  margin-left: 105px;
}

#HomePage .ml-110 {
  margin-left: 110px;
}

#HomePage .ml-115 {
  margin-left: 115px;
}

#HomePage .ml-120 {
  margin-left: 120px;
}

#HomePage .ml-125 {
  margin-left: 125px;
}

#HomePage .ml-130 {
  margin-left: 130px;
}

#HomePage .ml-135 {
  margin-left: 135px;
}

#HomePage .ml-140 {
  margin-left: 140px;
}

#HomePage .ml-145 {
  margin-left: 145px;
}

#HomePage .ml-150 {
  margin-left: 150px;
}

#HomePage .ml-155 {
  margin-left: 155px;
}

#HomePage .ml-160 {
  margin-left: 160px;
}

#HomePage .ml-165 {
  margin-left: 165px;
}

#HomePage .ml-170 {
  margin-left: 170px;
}

#HomePage .ml-175 {
  margin-left: 175px;
}

#HomePage .ml-180 {
  margin-left: 180px;
}

#HomePage .ml-185 {
  margin-left: 185px;
}

#HomePage .ml-190 {
  margin-left: 190px;
}

#HomePage .ml-195 {
  margin-left: 195px;
}

#HomePage .ml-200 {
  margin-left: 200px;
}

#HomePage .mr-5 {
  margin-right: 5px;
}

#HomePage .mr-10 {
  margin-right: 10px;
}

#HomePage .mr-15 {
  margin-right: 15px;
}

#HomePage .mr-20 {
  margin-right: 20px;
}

#HomePage .mr-25 {
  margin-right: 25px;
}

#HomePage .mr-30 {
  margin-right: 30px;
}

#HomePage .mr-35 {
  margin-right: 35px;
}

#HomePage .mr-40 {
  margin-right: 40px;
}

#HomePage .mr-45 {
  margin-right: 45px;
}

#HomePage .mr-50 {
  margin-right: 50px;
}

#HomePage .mr-55 {
  margin-right: 55px;
}

#HomePage .mr-60 {
  margin-right: 60px;
}

#HomePage .mr-65 {
  margin-right: 65px;
}

#HomePage .mr-70 {
  margin-right: 70px;
}

#HomePage .mr-75 {
  margin-right: 75px;
}

#HomePage .mr-80 {
  margin-right: 80px;
}

#HomePage .mr-85 {
  margin-right: 85px;
}

#HomePage .mr-90 {
  margin-right: 90px;
}

#HomePage .mr-95 {
  margin-right: 95px;
}

#HomePage .mr-100 {
  margin-right: 100px;
}

#HomePage .mr-105 {
  margin-right: 105px;
}

#HomePage .mr-110 {
  margin-right: 110px;
}

#HomePage .mr-115 {
  margin-right: 115px;
}

#HomePage .mr-120 {
  margin-right: 120px;
}

#HomePage .mr-125 {
  margin-right: 125px;
}

#HomePage .mr-130 {
  margin-right: 130px;
}

#HomePage .mr-135 {
  margin-right: 135px;
}

#HomePage .mr-140 {
  margin-right: 140px;
}

#HomePage .mr-145 {
  margin-right: 145px;
}

#HomePage .mr-150 {
  margin-right: 150px;
}

#HomePage .mr-155 {
  margin-right: 155px;
}

#HomePage .mr-160 {
  margin-right: 160px;
}

#HomePage .mr-165 {
  margin-right: 165px;
}

#HomePage .mr-170 {
  margin-right: 170px;
}

#HomePage .mr-175 {
  margin-right: 175px;
}

#HomePage .mr-180 {
  margin-right: 180px;
}

#HomePage .mr-185 {
  margin-right: 185px;
}

#HomePage .mr-190 {
  margin-right: 190px;
}

#HomePage .mr-195 {
  margin-right: 195px;
}

#HomePage .mr-200 {
  margin-right: 200px;
}

#HomePage .pt-5 {
  padding-top: 5px;
}

#HomePage .pt-10 {
  padding-top: 10px;
}

#HomePage .pt-15 {
  padding-top: 15px;
}

#HomePage .pt-20 {
  padding-top: 20px;
}

#HomePage .pt-25 {
  padding-top: 25px;
}

#HomePage .pt-30 {
  padding-top: 30px;
}

#HomePage .pt-35 {
  padding-top: 35px;
}

#HomePage .pt-40 {
  padding-top: 40px;
}

#HomePage .pt-45 {
  padding-top: 45px;
}

#HomePage .pt-50 {
  padding-top: 50px;
}

#HomePage .pt-55 {
  padding-top: 55px;
}

#HomePage .pt-60 {
  padding-top: 60px;
}

#HomePage .pt-65 {
  padding-top: 65px;
}

#HomePage .pt-70 {
  padding-top: 70px;
}

#HomePage .pt-75 {
  padding-top: 75px;
}

#HomePage .pt-80 {
  padding-top: 80px;
}

#HomePage .pt-85 {
  padding-top: 85px;
}

#HomePage .pt-90 {
  padding-top: 90px;
}

#HomePage .pt-95 {
  padding-top: 95px;
}

#HomePage .pt-100 {
  padding-top: 100px;
}

#HomePage .pt-105 {
  padding-top: 105px;
}

#HomePage .pt-110 {
  padding-top: 110px;
}

#HomePage .pt-115 {
  padding-top: 115px;
}

#HomePage .pt-120 {
  padding-top: 120px;
}

#HomePage .pt-125 {
  padding-top: 125px;
}

#HomePage .pt-130 {
  padding-top: 130px;
}

#HomePage .pt-135 {
  padding-top: 135px;
}

#HomePage .pt-140 {
  padding-top: 140px;
}

#HomePage .pt-145 {
  padding-top: 145px;
}

#HomePage .pt-150 {
  padding-top: 150px;
}

#HomePage .pt-155 {
  padding-top: 155px;
}

#HomePage .pt-160 {
  padding-top: 160px;
}

#HomePage .pt-165 {
  padding-top: 165px;
}

#HomePage .pt-170 {
  padding-top: 170px;
}

#HomePage .pt-175 {
  padding-top: 175px;
}

#HomePage .pt-180 {
  padding-top: 180px;
}

#HomePage .pt-185 {
  padding-top: 185px;
}

#HomePage .pt-190 {
  padding-top: 190px;
}

#HomePage .pt-195 {
  padding-top: 195px;
}

#HomePage .pt-200 {
  padding-top: 200px;
}

#HomePage .pt-205 {
  padding-top: 205px;
}

#HomePage .pt-210 {
  padding-top: 210px;
}

#HomePage .pt-215 {
  padding-top: 215px;
}

#HomePage .pt-220 {
  padding-top: 220px;
}

#HomePage .pt-225 {
  padding-top: 225px;
}

#HomePage .pt-230 {
  padding-top: 230px;
}

#HomePage .pt-235 {
  padding-top: 235px;
}

#HomePage .pt-240 {
  padding-top: 240px;
}

#HomePage .pt-245 {
  padding-top: 245px;
}

#HomePage .pt-250 {
  padding-top: 250px;
}

#HomePage .pt-255 {
  padding-top: 255px;
}

#HomePage .pt-260 {
  padding-top: 260px;
}

#HomePage .pt-265 {
  padding-top: 265px;
}

#HomePage .pt-270 {
  padding-top: 270px;
}

#HomePage .pt-275 {
  padding-top: 275px;
}

#HomePage .pt-280 {
  padding-top: 280px;
}

#HomePage .pt-285 {
  padding-top: 285px;
}

#HomePage .pt-290 {
  padding-top: 290px;
}

#HomePage .pt-295 {
  padding-top: 295px;
}

#HomePage .pt-300 {
  padding-top: 300px;
}

#HomePage .pb-5 {
  padding-bottom: 5px;
}

#HomePage .pb-10 {
  padding-bottom: 10px;
}

#HomePage .pb-15 {
  padding-bottom: 15px;
}

#HomePage .pb-20 {
  padding-bottom: 20px;
}

#HomePage .pb-25 {
  padding-bottom: 25px;
}

#HomePage .pb-30 {
  padding-bottom: 30px;
}

#HomePage .pb-35 {
  padding-bottom: 35px;
}

#HomePage .pb-40 {
  padding-bottom: 40px;
}

#HomePage .pb-45 {
  padding-bottom: 45px;
}

#HomePage .pb-50 {
  padding-bottom: 50px;
}

#HomePage .pb-55 {
  padding-bottom: 55px;
}

#HomePage .pb-60 {
  padding-bottom: 60px;
}

#HomePage .pb-65 {
  padding-bottom: 65px;
}

#HomePage .pb-70 {
  padding-bottom: 70px;
}

#HomePage .pb-75 {
  padding-bottom: 75px;
}

#HomePage .pb-80 {
  padding-bottom: 80px;
}

#HomePage .pb-85 {
  padding-bottom: 85px;
}

#HomePage .pb-90 {
  padding-bottom: 90px;
}

#HomePage .pb-95 {
  padding-bottom: 95px;
}

#HomePage .pb-100 {
  padding-bottom: 100px;
}

#HomePage .pb-105 {
  padding-bottom: 105px;
}

#HomePage .pb-110 {
  padding-bottom: 110px;
}

#HomePage .pb-115 {
  padding-bottom: 115px;
}

#HomePage .pb-120 {
  padding-bottom: 120px;
}

#HomePage .pb-125 {
  padding-bottom: 125px;
}

#HomePage .pb-130 {
  padding-bottom: 130px;
}

#HomePage .pb-135 {
  padding-bottom: 135px;
}

#HomePage .pb-140 {
  padding-bottom: 140px;
}

#HomePage .pb-145 {
  padding-bottom: 145px;
}

#HomePage .pb-150 {
  padding-bottom: 150px;
}

#HomePage .pb-155 {
  padding-bottom: 155px;
}

#HomePage .pb-160 {
  padding-bottom: 160px;
}

#HomePage .pb-165 {
  padding-bottom: 165px;
}

#HomePage .pb-170 {
  padding-bottom: 170px;
}

#HomePage .pb-175 {
  padding-bottom: 175px;
}

#HomePage .pb-180 {
  padding-bottom: 180px;
}

#HomePage .pb-185 {
  padding-bottom: 185px;
}

#HomePage .pb-190 {
  padding-bottom: 190px;
}

#HomePage .pb-195 {
  padding-bottom: 195px;
}

#HomePage .pb-200 {
  padding-bottom: 200px;
}

#HomePage .pb-205 {
  padding-bottom: 205px;
}

#HomePage .pb-210 {
  padding-bottom: 210px;
}

#HomePage .pb-215 {
  padding-bottom: 215px;
}

#HomePage .pb-220 {
  padding-bottom: 220px;
}

#HomePage .pb-225 {
  padding-bottom: 225px;
}

#HomePage .pb-230 {
  padding-bottom: 230px;
}

#HomePage .pb-235 {
  padding-bottom: 235px;
}

#HomePage .pb-240 {
  padding-bottom: 240px;
}

#HomePage .pb-245 {
  padding-bottom: 245px;
}

#HomePage .pb-250 {
  padding-bottom: 250px;
}

#HomePage .pb-255 {
  padding-bottom: 255px;
}

#HomePage .pb-260 {
  padding-bottom: 260px;
}

#HomePage .pb-265 {
  padding-bottom: 265px;
}

#HomePage .pb-270 {
  padding-bottom: 270px;
}

#HomePage .pb-275 {
  padding-bottom: 275px;
}

#HomePage .pb-280 {
  padding-bottom: 280px;
}

#HomePage .pb-285 {
  padding-bottom: 285px;
}

#HomePage .pb-290 {
  padding-bottom: 290px;
}

#HomePage .pb-295 {
  padding-bottom: 295px;
}

#HomePage .pb-300 {
  padding-bottom: 300px;
}

#HomePage .pl-5 {
  padding-left: 5px;
}

#HomePage .pl-10 {
  padding-left: 10px;
}

#HomePage .pl-15 {
  padding-left: 15px;
}

#HomePage .pl-20 {
  padding-left: 20px;
}

#HomePage .pl-25 {
  padding-left: 25px;
}

#HomePage .pl-30 {
  padding-left: 30px;
}

#HomePage .pl-35 {
  padding-left: 35px;
}

#HomePage .pl-40 {
  padding-left: 40px;
}

#HomePage .pl-45 {
  padding-left: 45px;
}

#HomePage .pl-50 {
  padding-left: 50px;
}

#HomePage .pl-55 {
  padding-left: 55px;
}

#HomePage .pl-60 {
  padding-left: 60px;
}

#HomePage .pl-65 {
  padding-left: 65px;
}

#HomePage .pl-70 {
  padding-left: 70px;
}

#HomePage .pl-75 {
  padding-left: 75px;
}

#HomePage .pl-80 {
  padding-left: 80px;
}

#HomePage .pl-85 {
  padding-left: 85px;
}

#HomePage .pl-90 {
  padding-left: 90px;
}

#HomePage .pl-95 {
  padding-left: 95px;
}

#HomePage .pl-100 {
  padding-left: 100px;
}

#HomePage .pl-105 {
  padding-left: 105px;
}

#HomePage .pl-110 {
  padding-left: 110px;
}

#HomePage .pl-115 {
  padding-left: 115px;
}

#HomePage .pl-120 {
  padding-left: 120px;
}

#HomePage .pl-125 {
  padding-left: 125px;
}

#HomePage .pl-130 {
  padding-left: 130px;
}

#HomePage .pl-135 {
  padding-left: 135px;
}

#HomePage .pl-140 {
  padding-left: 140px;
}

#HomePage .pl-145 {
  padding-left: 145px;
}

#HomePage .pl-150 {
  padding-left: 150px;
}

#HomePage .pl-155 {
  padding-left: 155px;
}

#HomePage .pl-160 {
  padding-left: 160px;
}

#HomePage .pl-165 {
  padding-left: 165px;
}

#HomePage .pl-170 {
  padding-left: 170px;
}

#HomePage .pl-175 {
  padding-left: 175px;
}

#HomePage .pl-180 {
  padding-left: 180px;
}

#HomePage .pl-185 {
  padding-left: 185px;
}

#HomePage .pl-190 {
  padding-left: 190px;
}

#HomePage .pl-195 {
  padding-left: 195px;
}

#HomePage .pl-200 {
  padding-left: 200px;
}

#HomePage .pr-5 {
  padding-right: 5px;
}

#HomePage .pr-10 {
  padding-right: 10px;
}

#HomePage .pr-15 {
  padding-right: 15px;
}

#HomePage .pr-20 {
  padding-right: 20px;
}

#HomePage .pr-25 {
  padding-right: 25px;
}

#HomePage .pr-30 {
  padding-right: 30px;
}

#HomePage .pr-35 {
  padding-right: 35px;
}

#HomePage .pr-40 {
  padding-right: 40px;
}

#HomePage .pr-45 {
  padding-right: 45px;
}

#HomePage .pr-50 {
  padding-right: 50px;
}

#HomePage .pr-55 {
  padding-right: 55px;
}

#HomePage .pr-60 {
  padding-right: 60px;
}

#HomePage .pr-65 {
  padding-right: 65px;
}

#HomePage .pr-70 {
  padding-right: 70px;
}

#HomePage .pr-75 {
  padding-right: 75px;
}

#HomePage .pr-80 {
  padding-right: 80px;
}

#HomePage .pr-85 {
  padding-right: 85px;
}

#HomePage .pr-90 {
  padding-right: 90px;
}

#HomePage .pr-95 {
  padding-right: 95px;
}

#HomePage .pr-100 {
  padding-right: 100px;
}

#HomePage .pr-105 {
  padding-right: 105px;
}

#HomePage .pr-110 {
  padding-right: 110px;
}

#HomePage .pr-115 {
  padding-right: 115px;
}

#HomePage .pr-120 {
  padding-right: 120px;
}

#HomePage .pr-125 {
  padding-right: 125px;
}

#HomePage .pr-130 {
  padding-right: 130px;
}

#HomePage .pr-135 {
  padding-right: 135px;
}

#HomePage .pr-140 {
  padding-right: 140px;
}

#HomePage .pr-145 {
  padding-right: 145px;
}

#HomePage .pr-150 {
  padding-right: 150px;
}

#HomePage .pr-155 {
  padding-right: 155px;
}

#HomePage .pr-160 {
  padding-right: 160px;
}

#HomePage .pr-165 {
  padding-right: 165px;
}

#HomePage .pr-170 {
  padding-right: 170px;
}

#HomePage .pr-175 {
  padding-right: 175px;
}

#HomePage .pr-180 {
  padding-right: 180px;
}

#HomePage .pr-185 {
  padding-right: 185px;
}

#HomePage .pr-190 {
  padding-right: 190px;
}

#HomePage .pr-195 {
  padding-right: 195px;
}

#HomePage .pr-200 {
  padding-right: 200px;
}

#HomePage .container {
  padding-right: 15px;
  padding-left: 15px;
}

#HomePage .row {
  --bs-gutter-x: 30px;
}
#HomePage .row.g-0 {
  --bs-gutter-x: 0;
}
#HomePage .row.g-15 {
  --bs-gutter-x: 15px;
}

@media (min-width: 1400px) {
  .container.container-custom-1 {
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
  }
}
#HomePage .container.container-custom-2 {
  max-width: calc(100% - 50px);
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .container.container-custom-2 {
    max-width: 100%;
  }
}

@media (min-width: 1700px) {
  .container.container-custom-2 {
    max-width: 1660px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1700px) {
  .container.container-custom-3 {
    max-width: calc(100% - 320px);
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1600px) and (max-width: 1699px) {
  .container.container-custom-3 {
    max-width: calc(100% - 220px);
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1600px) {
  .container-fluid.container-custom-1 {
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
  }
}
/* <<<<<<<    Declaration   >>>>>>>>> */
:root {
  /**
  @color declaration
  */
  --clr-common-white: #fff;
  --clr-common-black: #222222;
  --clr-common-lightBlack: #38383A;
  --clr-common-darkBlack: #000000;
  --clr-body-heading: #1E1E1E;
  --clr-body-text: rgba(30, 30, 30, 0.6);
  --clr-color-lightBlue: #BBE8EB;
  --clr-color-darkPink: #D2093C;
  --clr-color-lightPink: #F7ECF9;
  --clr-color-lightPurple: #F3F4FD;
  --clr-theme-primary: #1268EB;
  --clr-theme-primaryDark: #08449E;
  --clr-theme-primary-3: #F5530D;
  --clr-theme-primary-4: #F6BA2A;
  --clr-theme-primary-5: #F3EB1E;
  --clr-theme-primary-6: #B1040E;
  --clr-theme-primary-8: #395BDF;
  --clr-theme-primary-9: #1865F2;
  --clr-theme-primary-10: #4846AB;
  --clr-bg-gray: #F6F6F6;
  --clr-bg-gray-2: #F1F1F1;
}

#HomePage .bg-default {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

#HomePage .light-bg {
  background-color: #F8F8F8;
}

#HomePage .no-after::after {
  display: none;
}

#HomePage .p-relative {
  position: relative;
}

#HomePage .theme-bg {
  background-color: var(--clr-theme-primary);
}

#HomePage .swiper-wrapper.roll-slider {
  transition-timing-function: linear;
}

#HomePage .pt-295 {
  padding-top: 295px;
}
@media (max-width: 767px) {
  .pt-295 {
    padding-top: 250px;
  }
}

/*
************
*************************
02. header
******************************************************* 
*************************************************************** */
#HomePage .header-area {
  padding: 22px 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area {
    padding: 17px 0;
  }
}
@media (max-width: 767px) {
  .header-area {
    padding: 12px 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-area {
    padding: 15px 0;
  }
}
#HomePage .header-left {
  display: flex;
  align-items: center;
}
#HomePage .header-right {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: end;
}
#HomePage .header-logo {
  position: relative;
  z-index: 1;
  padding-right: 21px;
  margin-right: 20px;
}
#HomePage .header-logo::after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  background-color: rgba(30, 30, 30, 0.1);
  width: 1px;
  height: 100%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .header-logo {
    padding-right: 0;
  }
  .header-logo::after {
    display: none;
  }
}

#HomePage .main-menu {
  width: auto;
}
#HomePage .main-menu ul .menu-has-child {
  position: relative;
  z-index: 99;
  text-align: start;
}
#HomePage .main-menu ul li {
  display: inline-block;
  margin-right: 45px;
}
#HomePage .main-menu ul li:last-child {
  margin-right: 0;
}
#HomePage .main-menu ul li a {
  font-size: 16px;
  color: var(--clr-body-heading);
  padding: 10px 0;
  font-weight: 500;
  display: block;
}
#HomePage .main-menu ul li .submenu {
  position: absolute;
  left: 0;
  top: 100%;
  background: #fff;
  width: 240px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  box-shadow: 0 9px 19px rgba(0, 0, 0, 0.1);
}
#HomePage .main-menu ul li .submenu li {
  display: block;
  margin-right: 0;
  position: relative;
  z-index: 99;
}
#HomePage .main-menu ul li .submenu li a {
  padding: 10px 25px;
  color: var(--clr-body-heading);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .main-menu ul li .submenu li .submenu {
  left: 100%;
  top: 0;
}
#HomePage .main-menu ul li .submenu li:hover > a {
  background-color: var(--clr-theme-primary);
  color: #fff;
}
#HomePage .main-menu ul li .submenu li:not(:last-child) {
  border-bottom: 1px solid #f2f2f2;
}
#HomePage .main-menu ul li:hover > .submenu {
  opacity: 1;
  visibility: visible;
}
#HomePage .main-menu ul li:hover > a {
  color: var(--clr-theme-primary);
}

#HomePage .header-search form {
  position: relative;
  z-index: 1;
}
#HomePage .header-search form input {
  min-width: 300px;
  width: 100%;
  height: 45px;
  line-height: 45px;
  background-color: #F5F5F5;
  border: none;
  border-radius: 6px;
  padding: 0 20px;
  padding-right: 55px;
  font-size: 15px;
  color: rgba(30, 30, 30, 0.6);
}
#HomePage .header-search form input::-webkit-input-placeholder {
  color: rgba(30, 30, 30, 0.6);
  font-size: 15px;
  opacity: 1;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
#HomePage .header-search form input:-moz-placeholder {
  color: rgba(30, 30, 30, 0.6);
  font-size: 15px;
  opacity: 1;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
#HomePage .header-search form input::-moz-placeholder {
  color: rgba(30, 30, 30, 0.6);
  font-size: 15px;
  opacity: 1;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
#HomePage .header-search form input:-ms-input-placeholder {
  color: rgba(30, 30, 30, 0.6);
  font-size: 15px;
  opacity: 1;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
#HomePage .header-search form button {
  position: absolute;
  right: 20px;
  top: 0;
  height: 100%;
  font-size: 15px;
  color: #1E1E1E;
  border: none;
  background: transparent;
  padding: 0;
}
#HomePage .header-search form button i {
  font-weight: 300;
}

#HomePage .header-menu-bar-icon {
  font-size: 25px;
  display: block;
  color: var(--clr-body-heading);
  cursor: pointer;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .header-menu-bar-icon:hover {
  color: var(--clr-theme-primary);
}

#HomePage .h2_header-area {
  padding: 22px 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h2_header-area {
    padding: 17px 0;
  }
}
@media (max-width: 767px) {
  .h2_header-area {
    padding: 12px 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h2_header-area {
    padding: 15px 0;
  }
}
#HomePage .h2_header-left {
  display: flex;
  align-items: center;
}
#HomePage .h2_header-middle {
  text-align: center;
}
#HomePage .h2_header-right {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: end;
}
#HomePage .h2_header-logo {
  position: relative;
  z-index: 1;
  padding-right: 21px;
  margin-right: 20px;
}
#HomePage .h2_header-logo::after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  background-color: rgba(30, 30, 30, 0.1);
  width: 1px;
  height: 100%;
}
@media (max-width: 767px) {
  .h2_header-logo {
    margin-right: 10px;
    padding-right: 11px;
  }
  .h2_header-logo::after {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h2_header-logo {
    margin-right: 15px;
    padding-right: 16px;
  }
  .h2_header-logo::after {
    display: block;
  }
}
#HomePage .h2_header-category {
  position: relative;
  z-index: 9;
}
#HomePage .h2_header-category a {
  color: var(--clr-body-heading);
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px 0;
}
#HomePage .h2_header-category a span {
  display: block;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  color: var(--clr-body-heading);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .h2_header-category-submenu {
  position: absolute;
  left: 0;
  top: 100%;
  background: #fff;
  width: 240px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  box-shadow: 0 9px 19px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}
#HomePage .h2_header-category-submenu li {
  display: block;
  margin-right: 0;
  position: relative;
  z-index: 99;
}
#HomePage .h2_header-category-submenu li a {
  padding: 15px 25px;
  display: block;
  color: var(--clr-body-heading);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .h2_header-category-submenu li .submenu {
  left: 100%;
  top: 0;
}
#HomePage .h2_header-category-submenu li:hover > a {
  background-color: var(--clr-theme-primary);
  color: #fff;
}
#HomePage .h2_header-category-submenu li:not(:last-child) {
  border-bottom: 1px solid #f2f2f2;
}
#HomePage .h2_header-category:hover > .h2_header-category-submenu {
  opacity: 1;
  visibility: visible;
}
#HomePage .h2_header-category:hover > a {
  color: var(--clr-theme-primary);
}
#HomePage .h2_header-category:hover > a span {
  color: var(--clr-theme-primary);
}

#HomePage .h2_header-area.sticky {
  box-shadow: 0px 6px 50px rgba(30, 30, 30, 0.1);
}

#HomePage .h2_main-menu ul .menu-has-child {
  position: relative;
  z-index: 99;
  text-align: start;
}
#HomePage .h2_main-menu ul li {
  display: inline-block;
  margin-right: 45px;
}
#HomePage .h2_main-menu ul li:last-child {
  margin-right: 0;
}
#HomePage .h2_main-menu ul li:is(.menu-has-child) > a::after {
  display: inline-block;
  font-family: "Font Awesome 6 Pro";
  content: "\f107";
  font-size: 12px;
  font-weight: 500;
  margin-left: 5px;
}
#HomePage .h2_main-menu ul li a {
  font-size: 16px;
  color: var(--clr-body-heading);
  padding: 10px 0;
  font-weight: 500;
  display: block;
}
#HomePage .h2_main-menu ul li .submenu {
  position: absolute;
  left: 0;
  top: 100%;
  background: #fff;
  width: 240px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  box-shadow: 0 9px 19px rgba(0, 0, 0, 0.1);
}
#HomePage .h2_main-menu ul li .submenu li {
  display: block;
  margin-right: 0;
  position: relative;
  z-index: 99;
}
#HomePage .h2_main-menu ul li .submenu li a {
  padding: 10px 25px;
  color: var(--clr-body-heading);
  -webkit-transition: all 0.4s linear 0s;
  -moz-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  -o-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
}
#HomePage .h2_main-menu ul li .submenu li .submenu {
  left: 100%;
  top: 0;
}
#HomePage .h2_main-menu ul li .submenu li:hover > a {
  background-color: var(--clr-theme-primary);
  color: #fff;
}
#HomePage .h2_main-menu ul li .submenu li:not(:last-child) {
  border-bottom: 1px solid #f2f2f2;
}
#HomePage .h2_main-menu ul li:hover > .submenu {
  opacity: 1;
  visibility: visible;
}
#HomePage .h2_main-menu ul li:hover > a {
  color: var(--clr-theme-primary);
}

#HomePage .h3_header-area {
  padding: 16px 0;
}
#HomePage .h3_header-middle {
  text-align: center;
}
#HomePage .h3_header-right {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: end;
}
#HomePage .h3_header-logo {
  position: relative;
  z-index: 1;
  padding-right: 21px;
  margin-right: 20px;
}
@media (max-width: 767px) {
  .h3_header-logo {
    margin-right: 10px;
    padding-right: 11px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h3_header-logo {
    margin-right: 15px;
    padding-right: 16px;
  }
}

#HomePage .h3_header-area.sticky {
  box-shadow: 0px 6px 50px rgba(30, 30, 30, 0.1);
}

#HomePage .h3_main-menu ul .menu-has-child {
  position: relative;
  z-index: 99;
  text-align: start;
}
#HomePage .h3_main-menu ul li {
  display: inline-block;
  margin-right: 45px;
}
#HomePage .h3_main-menu ul li:last-child {
  margin-right: 0;
}
#HomePage .h3_main-menu ul li:is(.menu-has-child) > a::after {
  display: inline-block;
  font-family: "Font Awesome 6 Pro";
  content: "\f107";
  font-size: 12px;
  font-weight: 500;
  margin-left: 5px;
}
#HomePage .h3_main-menu ul li a {
  font-size: 16px;
  color: var(--clr-body-heading);
  padding: 10px 0;
  font-weight: 500;
  display: block;
}
#HomePage .h3_main-menu ul li .submenu {
  position: absolute;
  left: 0;
  top: 100%;
  background: #fff;
  width: 240px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  box-shadow: 0 9px 19px rgba(0, 0, 0, 0.1);
}
#HomePage .h3_main-menu ul li .submenu li {
  display: block;
  margin-right: 0;
  position: relative;
  z-index: 99;
}
#HomePage .h3_main-menu ul li .submenu li a {
  padding: 10px 25px;
  color: var(--clr-body-heading);
  -webkit-transition: all 0.4s linear 0s;
  -moz-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  -o-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
}
#HomePage .h3_main-menu ul li .submenu li .submenu {
  left: 100%;
  top: 0;
}
#HomePage .h3_main-menu ul li .submenu li:hover > a {
  background-color: var(--clr-theme-primary-3);
  color: #fff;
}
#HomePage .h3_main-menu ul li .submenu li:not(:last-child) {
  border-bottom: 1px solid #f2f2f2;
}
#HomePage .h3_main-menu ul li:hover > .submenu {
  opacity: 1;
  visibility: visible;
}
#HomePage .h3_main-menu ul li:hover > a {
  color: var(--clr-theme-primary-3);
}

#HomePage .h4_main-menu ul li .submenu li:hover > a {
  background-color: var(--clr-theme-primary-4);
  color: var(--clr-body-heading);
}
#HomePage .h4_main-menu ul li:hover > a {
  color: var(--clr-body-heading);
}

#HomePage .h4_header-category-submenu li:hover > a {
  background-color: var(--clr-theme-primary-4);
  color: var(--clr-body-heading);
}
#HomePage .h4_header-category:hover > a {
  color: var(--clr-body-heading);
}
#HomePage .h4_header-category:hover > a span {
  color: var(--clr-body-heading);
}

#HomePage .h5_main-menu ul li .submenu li:hover > a {
  background-color: var(--clr-theme-primary-5);
  color: var(--clr-body-heading);
}
#HomePage .h5_main-menu ul li:hover > a {
  color: var(--clr-body-heading);
}

#HomePage .header-sticky.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #ffffff;
  z-index: 99;
  animation: 300ms ease-in-out 0s normal none 1 running stickySlideDown;
  display: block;
  box-shadow: 0px 6px 50px rgba(30, 30, 30, 0.1);
}

@keyframes stickySlideDown {
  0% {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
#HomePage .h6_header-area {
  padding: 16px 0;
  background: linear-gradient(180deg, rgba(217, 217, 217, 0.05) 0%, rgba(217, 217, 217, 0.05) 100%);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9;
}
#HomePage .h6_header-middle {
  text-align: center;
}
#HomePage .h6_header-right {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: end;
}
#HomePage .h6_header-logo {
  position: relative;
  z-index: 1;
  padding-right: 21px;
  margin-right: 20px;
}
@media (max-width: 767px) {
  .h6_header-logo {
    margin-right: 10px;
    padding-right: 11px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h6_header-logo {
    margin-right: 15px;
    padding-right: 16px;
  }
}

#HomePage .h6_header-area.sticky {
  background-color: var(--clr-body-heading);
}

#HomePage .h6_main-menu ul .menu-has-child {
  position: relative;
  z-index: 99;
  text-align: start;
}
#HomePage .h6_main-menu ul li {
  display: inline-block;
  margin-right: 45px;
}
#HomePage .h6_main-menu ul li:last-child {
  margin-right: 0;
}
#HomePage .h6_main-menu ul li:is(.menu-has-child) > a::after {
  display: inline-block;
  font-family: "Font Awesome 6 Pro";
  content: "\f107";
  font-size: 12px;
  font-weight: 500;
  margin-left: 5px;
}
#HomePage .h6_main-menu ul li a {
  font-size: 16px;
  color: var(--clr-common-white);
  padding: 10px 0;
  font-weight: 500;
  display: block;
}
#HomePage .h6_main-menu ul li .submenu {
  position: absolute;
  left: 0;
  top: 100%;
  background: #fff;
  width: 240px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  box-shadow: 0 9px 19px rgba(0, 0, 0, 0.1);
}
#HomePage .h6_main-menu ul li .submenu li {
  display: block;
  margin-right: 0;
  position: relative;
  z-index: 99;
}
#HomePage .h6_main-menu ul li .submenu li a {
  padding: 10px 25px;
  color: var(--clr-body-heading);
  -webkit-transition: all 0.4s linear 0s;
  -moz-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  -o-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
}
#HomePage .h6_main-menu ul li .submenu li .submenu {
  left: 100%;
  top: 0;
}
#HomePage .h6_main-menu ul li .submenu li:hover > a {
  background-color: var(--clr-theme-primary-6);
  color: #fff;
}
#HomePage .h6_main-menu ul li .submenu li:not(:last-child) {
  border-bottom: 1px solid #f2f2f2;
}
#HomePage .h6_main-menu ul li:hover > .submenu {
  opacity: 1;
  visibility: visible;
}
#HomePage .h6_main-menu ul li:hover > a {
  color: var(--clr-theme-primary-3);
}

#HomePage .h6_menu-bar .header-menu-bar-icon {
  color: #fff;
}

#HomePage .h7_header-search form {
  position: relative;
  z-index: 1;
}
#HomePage .h7_header-search form input {
  min-width: 300px;
  width: 100%;
  height: 45px;
  line-height: 45px;
  border: none;
  border-radius: 6px;
  padding: 0 20px;
  padding-right: 55px;
  font-size: 15px;
  color: rgba(30, 30, 30, 0.6);
  border-radius: 4px;
  background: rgba(44, 44, 44, 0.06);
}
#HomePage .h7_header-search form input::-webkit-input-placeholder {
  color: rgba(30, 30, 30, 0.6);
  font-size: 15px;
  opacity: 1;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
#HomePage .h7_header-search form input:-moz-placeholder {
  color: rgba(30, 30, 30, 0.6);
  font-size: 15px;
  opacity: 1;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
#HomePage .h7_header-search form input::-moz-placeholder {
  color: rgba(30, 30, 30, 0.6);
  font-size: 15px;
  opacity: 1;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
#HomePage .h7_header-search form input:-ms-input-placeholder {
  color: rgba(30, 30, 30, 0.6);
  font-size: 15px;
  opacity: 1;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
#HomePage .h7_header-search form button {
  position: absolute;
  right: 20px;
  top: 0;
  height: 100%;
  font-size: 15px;
  color: #2C2C2C;
  border: none;
  background: transparent;
  padding: 0;
}
#HomePage .h7_header-search form button i {
  font-weight: 300;
}
#HomePage .h7_header-left {
  display: flex;
  align-items: center;
  gap: 90px;
  height: 80px;
}
#HomePage .h7_header-right {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: end;
}
#HomePage .h7_header-logo {
  position: relative;
  z-index: 1;
}
#HomePage .h7_header-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  padding: 0 30px;
  color: #fff;
  font-size: 16px;
  height: 45px;
  font-weight: 600;
  background-color: var(--clr-theme-primary-6);
}
#HomePage .h7_header-btn svg {
  color: #fff;
}

#HomePage .h7_main-menu ul .menu-has-child {
  position: relative;
  z-index: 99;
  text-align: start;
}
#HomePage .h7_main-menu ul li {
  display: inline-block;
  margin-right: 40px;
}
#HomePage .h7_main-menu ul li:last-child {
  margin-right: 0;
}
#HomePage .h7_main-menu ul li:is(.menu-has-child) > a::after {
  display: inline-block;
  font-family: "Font Awesome 6 Pro";
  content: "\f107";
  font-size: 12px;
  font-weight: 500;
  margin-left: 5px;
}
#HomePage .h7_main-menu ul li a {
  font-size: 16px;
  color: #2C2C2C;
  padding: 10px 0;
  font-weight: 500;
  display: block;
}
#HomePage .h7_main-menu ul li .submenu {
  position: absolute;
  left: 0;
  top: 100%;
  background: #fff;
  width: 240px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  box-shadow: 0 9px 19px rgba(0, 0, 0, 0.1);
}
#HomePage .h7_main-menu ul li .submenu li {
  display: block;
  margin-right: 0;
  position: relative;
  z-index: 99;
}
#HomePage .h7_main-menu ul li .submenu li a {
  padding: 10px 25px;
  color: var(--clr-body-heading);
  -webkit-transition: all 0.4s linear 0s;
  -moz-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  -o-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
}
#HomePage .h7_main-menu ul li .submenu li .submenu {
  left: 100%;
  top: 0;
}
#HomePage .h7_main-menu ul li .submenu li:hover > a {
  background-color: var(--clr-theme-primary-6);
  color: #fff;
}
#HomePage .h7_main-menu ul li .submenu li:not(:last-child) {
  border-bottom: 1px solid #f2f2f2;
}
#HomePage .h7_main-menu ul li:hover > .submenu {
  opacity: 1;
  visibility: visible;
}
#HomePage .h7_main-menu ul li:hover > a {
  color: var(--clr-theme-primary-3);
}

#HomePage .h7_menu-bar .header-menu-bar-icon {
  color: var(--clr-body-heading);
}

#HomePage .h7_header-top {
  background: var(--clr-theme-primary-6);
  height: 35px;
  line-height: 35px;
}
#HomePage .h7_header-top-list {
  display: flex;
  align-items: center;
  gap: 10px;
}
#HomePage .h7_header-top-list li {
  position: relative;
  z-index: 1;
}
#HomePage .h7_header-top-list li:not(:last-child) {
  margin-right: 15px;
  padding-right: 25px;
}
#HomePage .h7_header-top-list li:not(:last-child)::before {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  background: rgba(255, 255, 255, 0.2);
  height: 16px;
  content: "";
  z-index: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h7_header-top-list li:not(:last-child) {
    margin-right: 0;
    padding-right: 10px;
  }
}
#HomePage .h7_header-top-list li a {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}
#HomePage .h7_header-top-social ul {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: end;
}
#HomePage .h7_header-top-social ul li a {
  color: rgba(255, 255, 255, 0.3);
  font-size: 15px;
}
#HomePage .h7_header-top-social ul li a:hover {
  color: #fff;
}

#HomePage .inner_page_header-area {
  position: relative;
  z-index: 9;
  box-shadow: 0px 6px 50px rgba(30, 30, 30, 0.1);
}

#HomePage .h8_header-top {
  background: #021936;
  height: 40px;
  line-height: 40px;
}
#HomePage .h8_header-top-text {
  margin-bottom: 0;
  color: hsla(0, 0%, 100%, 0.8);
}
#HomePage .h8_header-top-text span {
  color: #F14D5D;
}
#HomePage .h8_header-top-social ul {
  display: flex;
  align-items: center;
  justify-content: end;
}
#HomePage .h8_header-top-social ul li a {
  color: #fff;
  font-size: 15px;
  position: relative;
  z-index: 1;
  padding-left: 9px;
  margin-left: 9px;
}
#HomePage .h8_header-top-social ul li a::before {
  position: absolute;
  left: 0;
  top: 50%;
  content: "";
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.2);
  width: 1px;
  height: 16px;
}
#HomePage .h8_header-top-social ul li a:hover {
  color: #fff;
}
#HomePage .h8_header-top-social ul li:first-child a::before {
  display: none;
}

#HomePage .h8_header-search form {
  position: relative;
  z-index: 1;
}
#HomePage .h8_header-search form input {
  min-width: 300px;
  width: 100%;
  height: 45px;
  line-height: 45px;
  border: none;
  border-radius: 6px;
  padding: 0 20px;
  padding-right: 55px;
  font-size: 15px;
  color: rgba(30, 30, 30, 0.6);
  border-radius: 4px;
  background: rgba(44, 44, 44, 0.06);
}
#HomePage .h8_header-search form input::-webkit-input-placeholder {
  color: rgba(30, 30, 30, 0.6);
  font-size: 15px;
  opacity: 1;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
#HomePage .h8_header-search form input:-moz-placeholder {
  color: rgba(30, 30, 30, 0.6);
  font-size: 15px;
  opacity: 1;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
#HomePage .h8_header-search form input::-moz-placeholder {
  color: rgba(30, 30, 30, 0.6);
  font-size: 15px;
  opacity: 1;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
#HomePage .h8_header-search form input:-ms-input-placeholder {
  color: rgba(30, 30, 30, 0.6);
  font-size: 15px;
  opacity: 1;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
#HomePage .h8_header-search form button {
  position: absolute;
  right: 20px;
  top: 0;
  height: 100%;
  font-size: 15px;
  color: #2C2C2C;
  border: none;
  background: transparent;
  padding: 0;
}
#HomePage .h8_header-search form button i {
  font-weight: 300;
}
#HomePage .h8_header-left {
  display: flex;
  align-items: center;
  gap: 75px;
  height: 80px;
}
#HomePage .h8_header-right {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: end;
}
#HomePage .h8_header-action {
  display: flex;
  align-items: center;
  gap: 20px;
}
#HomePage .h8_header-login {
  color: #021936;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 6px;
}
#HomePage .h8_header-login svg {
  color: #021936;
}
#HomePage .h8_header-search {
  color: #021936;
}
#HomePage .h8_header-logo {
  position: relative;
  z-index: 1;
}
#HomePage .h8_header-btn {
  height: 45px !important;
}

#HomePage .h8_main-menu ul .menu-has-child {
  position: relative;
  z-index: 99;
  text-align: start;
}
#HomePage .h8_main-menu ul li {
  display: inline-block;
  margin-right: 40px;
}
#HomePage .h8_main-menu ul li:last-child {
  margin-right: 0;
}
#HomePage .h8_main-menu ul li:is(.menu-has-child) > a::after {
  display: inline-block;
  font-family: "Font Awesome 6 Pro";
  content: "+";
  font-size: 12px;
  font-weight: 500;
  margin-left: 5px;
}
#HomePage .h8_main-menu ul li a {
  font-size: 16px;
  color: #2C2C2C;
  padding: 10px 0;
  font-weight: 500;
  display: block;
}
#HomePage .h8_main-menu ul li .submenu {
  position: absolute;
  left: 0;
  top: 100%;
  background: #fff;
  width: 240px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  box-shadow: 0 9px 19px rgba(0, 0, 0, 0.1);
}
#HomePage .h8_main-menu ul li .submenu li {
  display: block;
  margin-right: 0;
  position: relative;
  z-index: 99;
}
#HomePage .h8_main-menu ul li .submenu li a {
  padding: 10px 25px;
  color: var(--clr-body-heading);
  -webkit-transition: all 0.4s linear 0s;
  -moz-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  -o-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
}
#HomePage .h8_main-menu ul li .submenu li .submenu {
  left: 100%;
  top: 0;
}
#HomePage .h8_main-menu ul li .submenu li:hover > a {
  background-color: var(--clr-theme-primary-6);
  color: #fff;
}
#HomePage .h8_main-menu ul li .submenu li:not(:last-child) {
  border-bottom: 1px solid #f2f2f2;
}
#HomePage .h8_main-menu ul li:hover > .submenu {
  opacity: 1;
  visibility: visible;
}
#HomePage .h8_main-menu ul li:hover > a {
  color: var(--clr-theme-primary-3);
}

#HomePage .h8_menu-bar .header-menu-bar-icon {
  color: var(--clr-body-heading);
}

#HomePage .h9_header-button-text {
  color: #21242C;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 10px;
}
#HomePage .h9_header-button:hover > a {
  color: var(--clr-theme-primary-9);
}
#HomePage .h9_header-button .h2_header-category-submenu li:hover > a {
  background-color: var(--clr-theme-primary-9);
  color: #fff;
}

#HomePage .h9_header-search form {
  position: relative;
  z-index: 1;
}
#HomePage .h9_header-search form input {
  min-width: 300px;
  width: 100%;
  height: 45px;
  line-height: 45px;
  padding: 0 15px;
  padding-right: 45px;
  color: rgba(33, 36, 44, 0.6);
  font-size: 13px;
  font-weight: 400;
  border-radius: 6px;
  border: 1.5px solid #1865F2;
  background: #F5F5F5;
}
#HomePage .h9_header-search form input::-webkit-input-placeholder {
  color: rgba(33, 36, 44, 0.6);
  font-size: 13px;
  opacity: 1;
}
#HomePage .h9_header-search form input:-moz-placeholder {
  color: rgba(33, 36, 44, 0.6);
  font-size: 13px;
  opacity: 1;
}
#HomePage .h9_header-search form input::-moz-placeholder {
  color: rgba(33, 36, 44, 0.6);
  font-size: 13px;
  opacity: 1;
}
#HomePage .h9_header-search form input:-ms-input-placeholder {
  color: rgba(33, 36, 44, 0.6);
  font-size: 13px;
  opacity: 1;
}
#HomePage .h9_header-search form button {
  position: absolute;
  right: 15px;
  top: 0;
  height: 100%;
  font-size: 14px;
  border: none;
  background: transparent;
  padding: 0;
}
#HomePage .h9_header-search form button svg {
  font-weight: 300;
  color: #21242C;
}
#HomePage .h9_header-left {
  display: flex;
  align-items: center;
  gap: 20px;
  height: 90px;
}
#HomePage .h9_header-right {
  display: flex;
  align-items: center;
  gap: 30px;
  justify-content: end;
}
#HomePage .h9_header-logo {
  position: relative;
  z-index: 1;
}
@media (max-width: 767px) {
  .h9_header-logo {
    height: 70px;
    line-height: 70px;
  }
}
#HomePage .h9_header-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 30px !important;
  height: 45px !important;
}
#HomePage .h9_header-btn svg {
  color: #fff;
}

#HomePage .h9_header-right-text ul {
  display: flex;
  align-items: center;
  gap: 55px;
}

#HomePage .h9_header-right-text ul li a {
  color: #21242C;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 10px;
}

/*
************
*************************
03. sidebar
******************************************************* 
*************************************************************** */
#HomePage .side-info {
  width: 100%;
  max-width: 400px;
  background-color: #000;
  box-shadow: 0 13px 29px rgba(4, 0, 23, 0.1);
  height: 100%;
  position: fixed;
  z-index: 999;
  top: 0;
  right: -400px;
  -webkit-transition: all 0.4s linear 0s;
  -moz-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  -o-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
  padding: 30px 40px 40px 40px;
}

#HomePage .side-info:is(.info-open) {
  right: 0;
  overflow-y: scroll;
  scrollbar-width: thin;
}

#HomePage .offcanvas-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: rgba(4, 0, 23, 0.5);
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.4s linear 0s;
  -moz-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  -o-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
  /* cursor: url(../img/bg/close.png), auto; */
}

#HomePage .offcanvas-overlay:is(.overlay-open) {
  left: 0;
  opacity: 1;
  visibility: visible;
}

#HomePage .mean-container .mean-nav {
  background: transparent;
  margin-top: 0;
}

#HomePage .mean-container .mean-nav ul li a {
  border: none;
  font-size: 16px;
  padding-left: 0;
  padding-right: 0;
  color: #fff;
}

#HomePage .mean-container .mean-nav ul li:hover > a {
  background: transparent;
  color: var(--clr-theme-primary);
}

#HomePage .pl-sidebar-close.side-info-close {
  background: transparent;
  border: none;
  font-size: 25px;
  color: #fff;
}

#HomePage .mean-container .mean-nav ul li a.mean-expand {
  padding: 8px 0;
  border: none;
  background-color: transparent;
  color: #fff;
  width: auto;
}

#HomePage .mean-container .mean-nav ul li a.mean-expand:hover {
  background: transparent;
}

#HomePage .sidebar-close {
  border: 0;
  background-color: transparent;
  font-size: 25px;
  color: #fff;
  padding-left: 0;
  padding-right: 0;
}

#HomePage .sidebar-close:hover {
  color: #fff;
}

/*
************
*************************
04. banner
******************************************************* 
*************************************************************** */
#HomePage .single-banner {
  min-height: 670px;
  display: flex;
  align-items: end;
  position: relative;
  z-index: 1;
  background-color: var(--clr-color-lightBlue);
  border-radius: 20px;
  overflow: hidden;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .single-banner {
    align-items: center;
  }
}

#HomePage .banner-img {
  margin-right: -75px;
  margin-left: 30px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .banner-img {
    margin-right: -40px;
    margin-left: 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-img {
    margin-left: 0;
    margin-right: -20px;
  }
}

#HomePage .banner-content {
  padding-bottom: 45px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .banner-content {
    padding-bottom: 135px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-content {
    padding-bottom: 160px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .banner-content {
    padding-bottom: 0;
  }
}

#HomePage .banner-top-shape-1 {
  position: absolute;
  left: 85px;
  top: 60px;
  animation: animation-leftRight-1 3s linear 0s infinite alternate;
}
@media (max-width: 767px) {
  .banner-top-shape-1 {
    left: 30px;
  }
}
#HomePage .banner-top-shape-2 {
  right: 50px;
  bottom: 150px;
  position: absolute;
  z-index: 1;
  animation: animation-popup-2 1.2s linear 0s infinite alternate;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .banner-top-shape-2 {
    right: 20px;
    bottom: 140px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .banner-top-shape-2 {
    right: 20px;
    bottom: 110px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-top-shape-2 {
    right: 20px;
    bottom: 100px;
  }
}
@media (max-width: 767px) {
  .banner-top-shape-2 {
    right: 30px;
    bottom: 70px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-top-shape-2 {
    right: 50px;
    bottom: 100px;
  }
}
#HomePage .banner-top-shape-3 {
  position: absolute;
  top: 125px;
  right: 33%;
  z-index: 1;
  animation: hero-circle-1 6s linear 0s infinite;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-top-shape-3 {
    top: 110px;
    right: 140px;
  }
}
@media (max-width: 767px) {
  .banner-top-shape-3 {
    right: 15%;
    top: 110px;
  }
}

#HomePage .banner-right {
  position: relative;
  z-index: 1;
}
#HomePage .banner-inner-meta {
  position: absolute;
}
#HomePage .banner-inner-info {
  display: flex;
  gap: 20px;
}
#HomePage .banner-meta-info {
  background-color: #fff;
  padding: 25px 30px 25px;
  bottom: 120px;
  left: -30px;
  border-radius: 6px;
  box-shadow: 0px 10px 50px rgba(30, 30, 30, 0.1);
}
#HomePage .banner-meta-info img.inner-img {
  position: absolute;
  left: -35px;
  top: -35px;
  z-index: 1;
  animation: animation-popup-5 3s linear 0s infinite alternate;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-meta-info {
    bottom: 90px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .banner-meta-info {
    display: none;
  }
}
#HomePage .banner-info-img {
  width: 45px;
  height: 45px;
  flex-shrink: 0;
}
#HomePage .banner-info-img img {
  width: 45px;
  height: 45px;
}
#HomePage .banner-info-icon {
  position: absolute;
  width: 25px;
  height: 25px;
  background: #FFA121;
  font-size: 12px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  color: #fff;
  top: 50px;
  left: 60px;
}
#HomePage .banner-info-text h5 {
  font-size: 18px;
  margin-bottom: 5px;
  font-weight: 500;
}
#HomePage .banner-info-text p {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0;
}
#HomePage .banner-meta-rating {
  position: absolute;
  right: -40px;
  top: 200px;
  background: #fff;
  border-radius: 10px;
  padding: 25px 30px 25px;
  box-shadow: 0px 10px 50px rgba(30, 30, 30, 0.1);
  animation: hero-upDown-1 3s linear 0s infinite alternate;
}
#HomePage .banner-meta-rating span {
  font-size: 15px;
  display: flex;
  font-weight: 500;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
}
#HomePage .banner-meta-rating span i {
  color: #FFA121;
  font-size: 15px;
}
#HomePage .banner-meta-rating h5 {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 500;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-meta-rating {
    right: -30px;
    top: 160px;
  }
}

#HomePage .banner-right-shape img {
  position: absolute;
  z-index: -1;
}
#HomePage .banner-right-shape .banner-shape-4 {
  top: 35px;
  right: 70px;
  animation: animation-popup-2 1.2s linear 0s infinite alternate;
}
#HomePage .banner-right-shape .banner-shape-1 {
  right: -80px;
  top: 165px;
  animation: hero-circle-1 6s linear 0s infinite;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .banner-right-shape .banner-shape-1 {
    right: -20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-right-shape .banner-shape-1 {
    right: 0;
    top: 150px;
  }
}
#HomePage .banner-right-shape .banner-shape-2 {
  bottom: 80px;
  left: 55px;
  z-index: 1;
  animation: animation-upDown-2 3s linear 0s infinite alternate;
}

@keyframes hero-circle-1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes hero-popup-1 {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes animation-popup-5 {
  0% {
    transform: scale(0.2) rotate(0deg);
  }
  40% {
    transform: scale(0.5) rotate(180deg);
  }
  80% {
    transform: scale(1) rotate(360deg);
  }
  100% {
    transform: scale(1) rotate(360deg);
  }
}
@keyframes hero-upDown-1 {
  0% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(20px);
  }
}
@keyframes hero-leftRight-1 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(40px);
  }
}
@keyframes animation-upDown-2 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(30px);
  }
}
#HomePage .h2_single-banner {
  background-color: var(--clr-color-lightPurple);
  min-height: 730px;
  display: flex;
  align-items: center;
}

#HomePage .h2_banner-form {
  max-width: 550px;
  position: relative;
  z-index: 1;
  margin-bottom: 15px;
}
#HomePage .h2_banner-form input {
  width: 100%;
  height: 55px;
  line-height: 55px;
  padding: 0 20px;
  padding-right: 55px;
  font-size: 15px;
  background: #fff;
  border: none;
  border-radius: 6px;
  position: relative;
  z-index: 1;
  border-left: 2px solid var(--clr-theme-primary);
  box-shadow: 0px 6px 30px rgba(30, 30, 30, 0.1), inset 2px 0px 0px #1E40EC;
}
#HomePage .h2_banner-form button {
  position: absolute;
  top: 0;
  color: var(--clr-body-heading);
  font-size: 16px;
  z-index: 99;
  background: transparent;
  height: 100%;
  padding: 0;
  border: 0;
  right: 20px;
}
#HomePage .h2_banner-form button i {
  font-weight: 300;
}
#HomePage .h2_banner-content-text {
  display: block;
  font-size: 14px;
  color: var(--clr-body-heading);
  margin-bottom: 25px;
  line-height: 22px;
}
#HomePage .h2_banner-content-text a {
  color: var(--clr-theme-primary);
}
#HomePage .h2_banner-right {
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h2_banner-right {
    padding-left: 60px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h2_banner-right {
    padding-left: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h2_banner-right {
    padding-left: 0;
  }
}
#HomePage .h2_banner-inner-img {
  position: relative;
  z-index: 1;
  margin-left: -15px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  overflow: hidden;
}
#HomePage .h2_banner-inner-img:first-child {
  margin-left: 0;
}
#HomePage .h2_banner-inner-img:last-child::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(30, 30, 30, 0.6);
  content: "";
  border-radius: 50%;
}
#HomePage .h2_banner-inner-img span {
  position: absolute;
  left: 0;
  top: 0;
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
}
#HomePage .h2_banner-tutor {
  display: flex;
  align-items: center;
  gap: 10px;
}
#HomePage .h2_banner-tutor-img {
  display: flex;
}
#HomePage .h2_banner-tutor-text span {
  display: block;
  font-size: 15px;
  color: var(--clr-body-heading);
  font-weight: 600;
  line-height: 20px;
}
#HomePage .h2_banner-meta-rating {
  position: absolute;
  left: 0;
  bottom: 0;
  background: #fff;
  border-radius: 10px;
  padding: 25px 30px 25px;
  box-shadow: 0px 16px 70px rgba(30, 30, 30, 0.14);
  animation: h2_hero-leftRight-1 4.5s linear 0s infinite alternate;
}
#HomePage .h2_banner-meta-rating span {
  font-size: 15px;
  display: flex;
  font-weight: 500;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
}
#HomePage .h2_banner-meta-rating span i {
  color: #FFA121;
  font-size: 15px;
}
#HomePage .h2_banner-meta-rating h5 {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 500;
}
#HomePage .h2_banner-meta-info {
  position: absolute;
  right: -90px;
  top: 155px;
  background: #fff;
  border-radius: 10px;
  padding: 20px 25px 20px;
  box-shadow: 0px 16px 70px rgba(30, 30, 30, 0.14);
  animation: h2_hero-upDown-1 4s linear 0s infinite alternate;
}
#HomePage .h2_banner-meta-info span {
  font-size: 14px;
  font-weight: 500;
  display: block;
  line-height: 1;
  margin-bottom: 5px;
}
#HomePage .h2_banner-meta-info h5 {
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 1;
}
@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .h2_banner-meta-info {
    right: -70px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h2_banner-meta-info {
    right: -40px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h2_banner-meta-info {
    right: -20px;
    top: 140px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .h2_banner-meta-info {
    right: 0;
    top: 95px;
  }
}
#HomePage .h2_banner-shape-1 {
  position: absolute;
  left: 80px;
  top: 0;
  animation: animation-popup-1 4s linear 0s infinite alternate;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h2_banner-shape-1 {
    left: 60px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h2_banner-shape-1 {
    left: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h2_banner-shape-1 {
    left: 0;
  }
}
#HomePage .h2_banner-shape-2 {
  position: absolute;
  left: -55px;
  bottom: 180px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h2_banner-shape-2 {
    left: -30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h2_banner-shape-2 {
    left: -15px;
  }
}

@keyframes h2_hero-upDown-1 {
  0% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(20px);
  }
}
@keyframes h2_hero-leftRight-1 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(80px);
  }
}
#HomePage .h3_single-banner {
  background-color: var(--clr-color-lightPink);
  min-height: 870px;
  display: flex;
  align-items: end;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
#HomePage .h3_single-banner::before {
  position: absolute;
  bottom: -615px;
  left: -540px;
  content: "";
  width: 949px;
  height: 949px;
  border-radius: 949px;
  background: #E2C3E8;
  z-index: -1;
  animation: banner_before 2s linear 0s;
}
#HomePage .h3_single-banner::after {
  position: absolute;
  top: -252px;
  right: -210px;
  content: "";
  width: 949px;
  height: 949px;
  border-radius: 949px;
  background: #E2C3E8;
  z-index: -1;
  animation: banner_after 1.2s linear 0s;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h3_single-banner {
    align-items: center;
  }
}
@media (max-width: 767px) {
  .h3_single-banner {
    min-height: 750px;
  }
}

#HomePage .h3_banner-form {
  border-radius: 6px;
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(10, 10, 10, 0.14);
  display: flex;
  max-width: 570px;
  justify-content: space-between;
  padding: 10px;
}
@media (max-width: 767px) {
  .h3_banner-form {
    max-width: 100%;
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h3_banner-form {
    flex-wrap: nowrap;
  }
}
#HomePage .h3_banner-form-item {
  display: flex;
  align-items: center;
  max-width: 200px;
}
@media (max-width: 767px) {
  .h3_banner-form-item {
    max-width: 50%;
    width: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h3_banner-form-item {
    max-width: 200px;
    width: auto;
  }
}
#HomePage .h3_banner-form-item:last-child {
  margin-left: auto;
}
@media (max-width: 767px) {
  .h3_banner-form-item:last-child {
    padding-top: 10px;
    max-width: 100%;
    width: 100%;
  }
  .h3_banner-form-item:last-child .h3_banner-form-btn {
    width: 100%;
    justify-content: center;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h3_banner-form-item:last-child {
    padding-top: 0;
    max-width: 200px;
    width: auto;
  }
  .h3_banner-form-item:last-child .h3_banner-form-btn {
    width: initial;
    justify-content: start;
  }
}
#HomePage .h3_banner-form-item-search {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 20px;
  position: relative;
  z-index: 1;
}
#HomePage .h3_banner-form-item-search::after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  background-color: rgba(30, 30, 30, 0.1);
  width: 1px;
  height: 100%;
}
#HomePage .h3_banner-form-item-search i {
  font-size: 16px;
  color: var(--clr-body-heading);
}
#HomePage .h3_banner-form-item-search input {
  height: 30px;
  line-height: 30px;
  border: none;
  padding: 0;
  width: 100%;
}
@media (max-width: 490px) {
  .h3_banner-form-item-search {
    padding: 0 10px;
    width: 100%;
  }
  .h3_banner-form-item-search::after {
    display: none;
  }
}
@media (max-width: 490px) {
  .h3_banner-form-item {
    max-width: 100%;
  }
  .h3_banner-form-item:first-child {
    padding-bottom: 10px;
  }
}
#HomePage .h3_banner-category {
  position: relative;
  z-index: 9;
  margin: 0 30px;
}
@media (max-width: 490px) {
  .h3_banner-category {
    margin: 0 10px;
  }
}
#HomePage .h3_banner-category a {
  color: var(--clr-body-heading);
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px 0;
}
#HomePage .h3_banner-category a span {
  display: block;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  color: var(--clr-body-heading);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .h3_banner-category-submenu {
  position: absolute;
  left: 0;
  top: 100%;
  background: #fff;
  width: 200px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  box-shadow: 0 9px 19px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}
#HomePage .h3_banner-category-submenu li {
  display: block;
  margin-right: 0;
  position: relative;
  z-index: 99;
}
#HomePage .h3_banner-category-submenu li a {
  padding: 10px 25px;
  display: block;
  color: var(--clr-body-heading);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .h3_banner-category-submenu li .submenu {
  left: 100%;
  top: 0;
}
#HomePage .h3_banner-category-submenu li:hover > a {
  background-color: var(--clr-theme-primary-3);
  color: #fff;
}
#HomePage .h3_banner-category-submenu li:not(:last-child) {
  border-bottom: 1px solid #f2f2f2;
}
#HomePage .h3_banner-category:hover > .h3_banner-category-submenu {
  opacity: 1;
  visibility: visible;
}
#HomePage .h3_banner-category:hover > a {
  color: var(--clr-theme-primary-3);
}
#HomePage .h3_banner-category:hover > a span {
  color: var(--clr-theme-primary-3);
}
#HomePage .h3_banner-content {
  position: relative;
  z-index: 1;
  padding-bottom: 60px;
}
@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .h3_banner-content {
    padding-bottom: 120px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h3_banner-content {
    padding-bottom: 160px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h3_banner-content {
    padding-bottom: 200px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h3_banner-content {
    padding-bottom: 200px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h3_banner-content {
    padding-bottom: 0;
  }
}
#HomePage .h3_banner-content-text {
  display: block;
  font-size: 14px;
  color: var(--clr-body-heading);
  margin-bottom: 25px;
  line-height: 22px;
}
#HomePage .h3_banner-content-text a {
  color: var(--clr-theme-primary);
}
#HomePage .h3_banner-content-shape-1 {
  position: absolute;
  left: -75px;
  top: -65px;
  z-index: 1;
  animation: animation-leftRight-1 2s linear 0s infinite alternate;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h3_banner-content-shape-1 {
    left: -45px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h3_banner-content-shape-1 {
    left: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h3_banner-content-shape-1 {
    left: 0;
  }
}
#HomePage .h3_banner-right {
  position: relative;
  z-index: 1;
  margin-right: -90px;
}
@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .h3_banner-right {
    margin-right: -30px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h3_banner-right {
    margin-right: 0px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h3_banner-right {
    margin-right: 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h3_banner-right {
    margin-right: 0;
  }
}
#HomePage .h3_banner-meta-rating {
  position: absolute;
  left: -70px;
  bottom: 145px;
  background: #fff;
  border-radius: 10px;
  padding: 25px 30px 25px;
  box-shadow: 0px 16px 70px rgba(30, 30, 30, 0.14);
  animation: h3_hero-leftRight-1 4.5s linear 0s infinite alternate;
}
#HomePage .h3_banner-meta-rating span {
  font-size: 15px;
  display: flex;
  font-weight: 500;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
}
#HomePage .h3_banner-meta-rating span i {
  color: #FFA121;
  font-size: 15px;
}
#HomePage .h3_banner-meta-rating h5 {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 500;
}
#HomePage .h3_banner-meta-info {
  position: absolute;
  right: -15px;
  top: 295px;
  background: #fff;
  border-radius: 10px;
  padding: 20px 25px 20px;
  box-shadow: 0px 16px 70px rgba(30, 30, 30, 0.14);
  animation: h3_hero-upDown-1 4s linear 0s infinite alternate;
}
#HomePage .h3_banner-meta-info span {
  font-size: 14px;
  font-weight: 500;
  display: block;
  line-height: 1;
  margin-bottom: 5px;
}
#HomePage .h3_banner-meta-info h5 {
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 1;
}
@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .h3_banner-meta-info {
    right: -70px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h3_banner-meta-info {
    right: -40px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h3_banner-meta-info {
    right: -20px;
    top: 140px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .h3_banner-meta-info {
    right: 0;
    top: 95px;
  }
}
#HomePage .h3_banner-shape-1 {
  position: absolute;
  right: -60px;
  bottom: 85px;
  animation: animation-upDown-1 3s linear 0s infinite alternate;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h3_banner-shape-1 {
    right: -50px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h3_banner-shape-1 {
    right: -45px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h3_banner-shape-1 {
    display: none;
  }
}
#HomePage .h3_banner-shape-2 {
  position: absolute;
  left: -75px;
  bottom: 390px;
  animation: animation-leftRight-1 3s linear 0s infinite alternate;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h3_banner-shape-2 {
    left: -35px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h3_banner-shape-2 {
    left: -10px;
  }
}
@keyframes h3_hero-upDown-1 {
  0% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(20px);
  }
}
@keyframes h3_hero-leftRight-1 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(80px);
  }
}
@keyframes banner_after {
  0% {
    transform: translateX(80%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes banner_before {
  0% {
    transform: translateX(-400px);
  }
  100% {
    transform: translateX(0);
  }
}
#HomePage .h4_single-banner {
  background-color: #36348E;
  min-height: 950px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
@media (max-width: 767px) {
  .h4_single-banner {
    min-height: 800px;
  }
}

#HomePage .h4_banner-area {
  position: relative;
}
#HomePage .h4_banner-form {
  border-radius: 100px;
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(10, 10, 10, 0.14);
  display: flex;
  max-width: 550px;
  justify-content: space-between;
  padding: 7px;
  overflow: hidden;
  margin-bottom: 35px;
}
#HomePage .h4_banner-form input {
  width: 100%;
  height: 40px;
  padding-left: 11px;
  padding-right: 20px;
  border: none;
  color: var(--clr-body-heading);
  font-size: 15px;
}
#HomePage .h4_banner-form input::-webkit-input-placeholder {
  color: rgba(30, 30, 30, 0.5);
  font-size: 15px;
  opacity: 1;
}
#HomePage .h4_banner-form input:-moz-placeholder {
  color: rgba(30, 30, 30, 0.5);
  font-size: 15px;
  opacity: 1;
}
#HomePage .h4_banner-form input::-moz-placeholder {
  color: rgba(30, 30, 30, 0.5);
  font-size: 15px;
  opacity: 1;
}
#HomePage .h4_banner-form input:-ms-input-placeholder {
  color: rgba(30, 30, 30, 0.5);
  font-size: 15px;
  opacity: 1;
}
#HomePage .h4_banner-form button {
  width: auto;
  flex-shrink: 0;
}
#HomePage .h4_banner-content {
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h4_banner-content {
    margin-right: 0;
  }
}
#HomePage .h4_banner-content-text {
  display: block;
  font-size: 14px;
  color: var(--clr-body-heading);
  margin-bottom: 25px;
  line-height: 22px;
}
#HomePage .h4_banner-content-text a {
  color: var(--clr-theme-primary);
}
#HomePage .h4_banner-content-shape-1 {
  position: absolute;
  left: -75px;
  top: -65px;
  z-index: 1;
}
#HomePage .h4_banner-shape {
  position: absolute;
  left: 0;
  bottom: 0;
}
#HomePage .h4_banner-img {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 42%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h4_banner-img {
    max-width: 50%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h4_banner-img {
    display: none;
  }
}
#HomePage .h4_banner-tag {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  max-width: 420px;
}
#HomePage .h4_banner-tag a {
  background: #fff;
  color: var(--clr-body-heading);
  font-size: 13px;
  padding: 0 14px;
  border-radius: 30px;
}
#HomePage .h4_banner-tag a:hover {
  background-color: var(--clr-theme-primary-4);
}

#HomePage .h5_single-banner {
  background-color: #DCECFF;
  min-height: 860px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

#HomePage .h5_banner-bg {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  max-width: 45%;
  width: 100%;
}
#HomePage .h5_banner-bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#HomePage .h5_banner-contact {
  position: absolute;
  bottom: 40px;
  right: 50px;
  display: flex;
  gap: 40px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h5_banner-contact {
    right: auto;
    left: 50px;
  }
}
@media (max-width: 767px) {
  .h5_banner-contact {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h5_banner-contact {
    display: flex;
    right: auto;
    left: 50px;
  }
}
#HomePage .h5_banner-contact-item p {
  font-size: 16px;
  font-weight: 500;
  color: var(--clr-body-heading);
  margin-bottom: 0;
}
#HomePage .h5_banner-contact-item-top span {
  color: #747474;
  font-size: 16px;
  font-weight: 500;
}
#HomePage .h5_banner-contact-item-top i {
  font-size: 16px;
  font-weight: 500;
  color: #747474;
  margin-right: 10px;
}

#HomePage .h5_banner-content .h2_banner-form input {
  border-radius: 0;
}

#HomePage .h6_single-banner {
  min-height: 700px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-top: 290px;
  padding-bottom: 285px;
}
#HomePage .h6_single-banner::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(15, 15, 15, 0.85) 12.58%, rgba(15, 15, 15, 0.34) 83.62%);
  z-index: -1;
}
@media (max-width: 767px) {
  .h6_single-banner {
    padding-top: 200px;
    padding-bottom: 160px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h6_single-banner {
    padding-top: 250px;
    padding-bottom: 230px;
  }
}

#HomePage .h6_banner-content-subtitle {
  color: #FFF;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  display: block;
  margin-bottom: 20px;
}
#HomePage .h6_banner-content-subtitle i {
  color: var(--clr-theme-primary-6);
  margin-right: 5px;
}
@media (max-width: 767px) {
  .h6_banner-content-subtitle {
    font-size: 16px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h6_banner-content-subtitle {
    font-size: 20px;
  }
}
#HomePage .h6_banner-content-title {
  color: #FFF;
  font-size: 80px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
  text-transform: capitalize;
  margin-bottom: 25px;
}
#HomePage .h6_banner-content-title span {
  color: #FFF;
  font-family: "Sansita Swashed", cursive;
  font-size: 80px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  text-transform: capitalize;
}
@media (max-width: 767px) {
  .h6_banner-content-title {
    font-size: 50px;
    line-height: 1.1;
  }
  .h6_banner-content-title span {
    font-size: 40px;
    line-height: 1.1;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h6_banner-content-title {
    font-size: 60px;
  }
  .h6_banner-content-title span {
    font-size: 55px;
  }
}
#HomePage .h6_banner-content-text {
  color: #FFF;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 42px;
}
@media (max-width: 767px) {
  .h6_banner-content-text br {
    display: none;
  }
}

#HomePage .h6_banner-navigation div {
  position: absolute;
  left: 80px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  font-size: 90px;
  color: rgba(255, 255, 255, 0.4);
  cursor: pointer;
}
#HomePage .h6_banner-navigation .banner_6-swiper-next {
  right: 80px;
  left: auto;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h6_banner-navigation div {
    left: 50px;
    font-size: 80px;
  }
  .h6_banner-navigation .banner_6-swiper-next {
    right: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h6_banner-navigation div {
    left: 20px;
    font-size: 60px;
  }
  .h6_banner-navigation .banner_6-swiper-next {
    right: 20px;
  }
}
@media (max-width: 767px) {
  .h6_banner-navigation div {
    display: none;
  }
}

#HomePage .h7_single-banner {
  min-height: 600px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-top: 245px;
  padding-bottom: 260px;
}
#HomePage .h7_single-banner::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #0A0A0A 0%, rgba(10, 10, 10, 0) 81.54%);
  z-index: -1;
}
@media (max-width: 767px) {
  .h7_single-banner {
    padding-top: 175px;
    padding-bottom: 180px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h7_single-banner {
    padding-top: 210px;
    padding-bottom: 220px;
  }
}

#HomePage .h7_banner-content-title {
  color: #FFF;
  font-size: 80px;
  font-weight: 800;
  line-height: 1.1;
  text-transform: capitalize;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .h7_banner-content-title {
    font-size: 38px;
  }
  .h7_banner-content-title br {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h7_banner-content-title {
    font-size: 60px;
  }
  .h7_banner-content-title span {
    font-size: 55px;
  }
}
#HomePage .h7_banner-content-text {
  color: rgba(255, 255, 255, 0.74);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 37px;
}
@media (max-width: 767px) {
  .h7_banner-content-text {
    font-size: 16px;
    line-height: 26px;
  }
  .h7_banner-content-text br {
    display: none;
  }
}
#HomePage .h7_banner-content-btn {
  display: flex;
  gap: 10px;
}
@media (max-width: 767px) {
  .h7_banner-content-btn {
    flex-wrap: wrap;
  }
}

#HomePage .h7_slider-active-nav .swiper-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

#HomePage .h7_slider-thumb-item {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  gap: 25px;
  padding: 0 10px;
  height: 140px;
  justify-content: center;
  cursor: pointer;
}
@media (max-width: 767px) {
  .h7_slider-thumb-item {
    gap: 15px;
  }
}

#HomePage .h7_slider-thumbs-info h4 {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 5px;
}
@media (max-width: 767px) {
  .h7_slider-thumbs-info h4 {
    font-size: 22px;
  }
}

#HomePage .h7_slider-thumbs-info a {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 10px;
}

#HomePage .h7_slider-thumbs-icon svg {
  color: #fff;
}

#HomePage .h7_slider-thumbs-info a i {
  font-size: 15px;
}

#HomePage .h7_slider-navigation {
  position: absolute;
  right: 6%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  display: grid;
  grid-gap: 10px;
}
#HomePage .h7_slider-navigation div {
  width: 60px;
  height: 60px;
  background: #fff;
  border-radius: 50%;
  display: grid;
  place-items: center;
  font-size: 22px;
  color: var(--clr-theme-primary-6);
  gap: 10px;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .h7_slider-navigation div:hover {
  background-color: var(--clr-theme-primary-6);
  color: #fff;
}

#HomePage .h8_banner-area {
  background-color: #F4F7FF;
  min-height: 600px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-top: 90px;
  padding-bottom: 110px;
}

#HomePage .h8_banner-content-subtitle {
  color: #F14D5D;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  display: block;
}
@media (max-width: 767px) {
  .h8_banner-content-subtitle {
    font-weight: 500;
  }
}

#HomePage .h8_banner-content-title {
  color: rgba(2, 25, 54, 0.95);
  font-size: 56px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h8_banner-content-title {
    font-size: 45px;
  }
}
@media (max-width: 767px) {
  .h8_banner-content-title {
    font-size: 33px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h8_banner-content-title {
    font-size: 45px;
  }
}

#HomePage .h8_banner-content-title span {
  color: #395BDF;
}

#HomePage .h8_banner-content-text {
  color: rgba(2, 25, 54, 0.7);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 50px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), (max-width: 767px) {
  .h8_banner-content-text br {
    display: none;
  }
}

#HomePage .h8_banner-play {
  display: flex;
  align-items: center;
  gap: 12px;
  color: #021936;
  font-size: 15px;
  font-weight: 400;
}

#HomePage .h8_banner-play span {
  height: 40px;
  width: 40px;
  border: 1px solid rgb(241, 77, 93);
  color: rgb(241, 77, 93);
  display: grid;
  place-items: center;
  border-radius: 50%;
  font-size: 14px;
}

#HomePage .h8_banner-content-btn {
  display: flex;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
}

#HomePage .h8_banner-img {
  margin-left: -20px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h8_banner-img {
    margin-left: 0;
    text-align: center;
  }
}

#HomePage .h9_single-banner {
  min-height: 600px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-top: 205px;
  padding-bottom: 165px;
  background-size: cover;
  background-repeat: no-repeat;
}
#HomePage .h9_single-banner::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(92deg, #21242C 8.67%, #21242C 8.68%, rgba(33, 36, 44, 0.3) 98.22%);
  z-index: -1;
}
@media (max-width: 767px) {
  .h9_single-banner {
    padding-top: 175px;
    padding-bottom: 180px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h9_single-banner {
    padding-top: 210px;
    padding-bottom: 220px;
  }
}

#HomePage .h9_banner-content-subtitle {
  color: #FFF;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  display: block;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .h9_banner-content-subtitle {
    font-size: 15px;
  }
}
#HomePage .h9_banner-content-title {
  color: #FFF;
  font-size: 74px;
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .h9_banner-content-title {
    font-size: 65px;
  }
}
@media (max-width: 767px) {
  .h9_banner-content-title {
    font-size: 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h9_banner-content-title {
    font-size: 52px;
  }
  .h9_banner-content-title span {
    font-size: 55px;
  }
}
#HomePage .h9_banner-content-text {
  color: rgba(255, 255, 255, 0.7);
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 38px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .h9_banner-content-text br {
    display: none;
  }
}
@media (max-width: 767px) {
  .h9_banner-content-text {
    font-size: 16px;
    line-height: 26px;
  }
  .h9_banner-content-text br {
    display: none;
  }
}
#HomePage .h9_banner-content-btn a {
  padding: 0 35px;
}

#HomePage .h9_slider-active-nav .swiper-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

#HomePage .h9_slider-thumb-item {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding-left: 20px;
  padding-right: 20px;
  height: 140px;
  cursor: pointer;
}
@media (max-width: 767px) {
  .h9_slider-thumb-item {
    gap: 15px;
  }
}

#HomePage .h9_slider-thumbs-info h4 {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0px;
}
@media (max-width: 767px) {
  .h9_slider-thumbs-info h4 {
    font-size: 22px;
  }
}

#HomePage .h9_slider-thumbs-info a {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 10px;
}

#HomePage .h9_slider-thumbs-icon svg {
  color: #fff;
}

#HomePage .h9_slider-thumbs-info a i {
  font-size: 15px;
}

#HomePage .h9_slider-green {
  background-color: #10C45C;
}

#HomePage .h9_slider-skyBlue {
  background-color: #1EC1D9;
}

#HomePage .h9_slider-orange {
  background-color: #E1B12F;
}

#HomePage .h9_slider-blue {
  background-color: #307AD5;
}

#HomePage .h9_slider-pink {
  background-color: #D94DA6;
}

#HomePage .h10_single-banner {
  min-height: 600px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-top: 180px;
  padding-bottom: 180px;
  background-color: var(--clr-theme-primary-10);
}
@media (max-width: 767px) {
  .h10_single-banner {
    padding-top: 110px;
    padding-bottom: 115px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h10_single-banner {
    padding-top: 110px;
    padding-bottom: 120px;
  }
}

#HomePage .h10_banner-content-title {
  color: #fff;
  font-size: 64px;
  font-weight: 700;
  line-height: 1.1;
  text-transform: capitalize;
  margin-bottom: 20px;
}
#HomePage .h10_banner-content-title span {
  position: relative;
  z-index: 1;
}
#HomePage .h10_banner-content-title span img {
  position: absolute;
  left: 0;
  bottom: -5px;
  width: calc(100% - 25px);
  display: inline-block;
  margin-left: 10px;
  animation: section-animation 5s linear 0s infinite;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h10_banner-content-title {
    font-size: 55px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h10_banner-content-title {
    font-size: 45px;
  }
}
@media (max-width: 767px) {
  .h10_banner-content-title {
    font-size: 45px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h10_banner-content-title {
    font-size: 52px;
  }
}
#HomePage .h10_banner-content-text {
  color: rgba(255, 255, 255, 0.8);
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 37px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h10_banner-content-text br {
    display: none;
  }
}
@media (max-width: 767px) {
  .h10_banner-content-text {
    font-size: 16px;
    line-height: 26px;
  }
  .h10_banner-content-text br {
    display: none;
  }
}
#HomePage .h10_banner-content-btn {
  display: flex;
  gap: 15px;
}
@media (max-width: 767px) {
  .h10_banner-content-btn {
    flex-wrap: wrap;
  }
}

#HomePage .h10_banner-bottom-info {
  display: flex;
  align-items: center;
  row-gap: 15px;
  column-gap: 40px;
  flex-wrap: wrap;
}

#HomePage .h10_banner-bottom-info span {
  color: #FFF;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  z-index: 1;
}

#HomePage .h10_banner-bottom-info span i {
  font-size: 22px;
}

#HomePage .h10_banner-bottom-info span:not(:last-child)::before {
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  background: #DDB586;
  width: 1px;
  height: 100%;
}

#HomePage .h10_banner-right {
  margin-right: -165px;
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .h10_banner-right.pl-110 {
    padding-left: 55px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h10_banner-right.pl-110 {
    padding-left: 0;
  }
}
@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .h10_banner-right {
    margin-right: -110px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h10_banner-right {
    margin-right: -30px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h10_banner-right {
    margin-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h10_banner-img {
    text-align: center;
  }
}
#HomePage .h10_banner-img img {
  animation: hero-border 5s linear infinite alternate;
}

@keyframes hero-border {
  0% {
    -webkit-border-radius: 51% 49% 77% 23%/65% 50% 50% 35%;
    -moz-border-radius: 51% 49% 77% 23%/65% 50% 50% 35%;
    border-radius: 51% 49% 77% 23%/65% 50% 50% 35%;
  }
  100% {
    -webkit-border-radius: 30% 70% 28% 72%/53% 69% 31% 47%;
    -moz-border-radius: 30% 70% 28% 72%/53% 69% 31% 47%;
    border-radius: 30% 70% 28% 72%/53% 69% 31% 47%;
  }
}
#HomePage .waviy {
  position: relative;
  -webkit-box-reflect: below -20px linear-gradient(transparent, rgba(0, 0, 0, 0.2));
}

#HomePage .waviy span {
  position: relative;
  display: inline-block;
  animation: waviy 1s infinite;
  animation-delay: calc(0.1s * var(--i));
}

@keyframes waviy {
  0%, 40%, 100% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-20px);
  }
}
#HomePage .h10_banner-shape-1 {
  position: absolute;
  left: 5%;
  bottom: 31%;
  animation: animation-popup-2 1.2s linear 0s infinite alternate;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h10_banner-shape-1 {
    left: 1%;
  }
}
#HomePage .h10_banner-shape-2 {
  position: absolute;
  left: 11%;
  top: 12%;
  animation: animation-popup-1 3s linear 0s infinite alternate;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h10_banner-shape-2 {
    left: 5%;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h10_banner-shape-2 {
    left: 4%;
  }
}
#HomePage .h10_banner-shape-3 {
  position: absolute;
  top: 6%;
  left: 32%;
  animation: animation-leftRight-1 3s linear 0s infinite alternate;
}
#HomePage .h10_banner-shape-4 {
  position: absolute;
  left: 10px;
  top: -30px;
  animation: animation-upDown-2 3s linear 0s infinite alternate;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h10_banner-shape-4 {
    width: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h10_banner-shape-4 {
    width: 50px;
    top: 0;
    left: 50px;
  }
}
#HomePage .h10_banner-shape-5 {
  position: absolute;
  left: 30px;
  bottom: 25px;
  animation: animation-upDown-2 3s linear 0s infinite alternate;
}
@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .h10_banner-shape-5 {
    left: 0;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h10_banner-shape-5 {
    left: -40px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h10_banner-shape-5 {
    left: -10px;
    width: 50px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h10_banner-shape-5 {
    left: -10px;
    width: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h10_banner-shape-5 {
    width: 50px;
  }
}
#HomePage .h10_banner-shape-6 {
  position: absolute;
  right: 10px;
  bottom: -15px;
  animation: hero-circle-1 6s linear 0s infinite;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h10_banner-shape-6 {
    width: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h10_banner-shape-6 {
    width: 50px;
    bottom: 0;
    right: 60px;
  }
}

/*
************
*************************
05. section
******************************************************* 
*************************************************************** */
#HomePage .section-area .section-subtitle {
  color: #D2093C;
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
  background-color: rgba(210, 9, 59, 0.04);
  padding: 10px 25px;
  line-height: 1;
  position: relative;
  z-index: 1;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .section-area .section-subtitle {
    font-size: 14px;
    padding: 10px 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-area .section-subtitle {
    font-size: 15px;
    padding: 10px 25px;
  }
}
#HomePage .section-area .section-subtitle::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 2px;
  height: 100%;
  background-color: #D2093C;
  content: "";
}
#HomePage .section-area .section-title {
  font-size: 46px;
  font-weight: 700;
  line-height: 1.2;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-area .section-title {
    font-size: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-area .section-title {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .section-area .section-title {
    font-size: 32px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-area .section-title {
    font-size: 40px;
  }
}
#HomePage .section-area .section-text {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
  color: rgba(30, 30, 30, 0.7);
}

#HomePage .section-area-top .section-subtitle::before {
  left: 0;
  top: 0;
  width: 100%;
  height: 2px;
}

#HomePage .banner-content .section-subtitle {
  background-color: #fff;
  margin-bottom: 15px;
}
#HomePage .banner-content .section-title {
  font-size: 70px;
  font-weight: 600;
  line-height: 1.1;
  margin-bottom: 15px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .banner-content .section-title {
    font-size: 60px;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-content .section-title {
    font-size: 45px;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-content .section-title {
    font-size: 45px;
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .banner-content .section-title {
    font-size: 35px;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-content .section-title {
    font-size: 45px;
  }
}
#HomePage .banner-content .section-title span {
  display: inline-block;
  position: relative;
  z-index: 1;
}
#HomePage .banner-content .section-title span img {
  position: absolute;
  left: 0;
  bottom: -5px;
  width: calc(100% - 25px);
  display: inline-block;
  margin-left: 10px;
  animation: section-animation 5s linear 0s infinite;
}
#HomePage .banner-content .section-text {
  margin-bottom: 43px;
  color: rgba(30, 30, 30, 0.6);
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .banner-content .section-text br {
    display: none;
  }
}

#HomePage .section-area-2 .section-title {
  font-size: 48px;
  font-weight: 700;
  line-height: 1.2;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-area-2 .section-title {
    font-size: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-area-2 .section-title {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .section-area-2 .section-title {
    font-size: 32px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-area-2 .section-title {
    font-size: 40px;
  }
}
#HomePage .section-area-2 .section-title span {
  display: inline-block;
  position: relative;
  z-index: 1;
  color: var(--clr-theme-primary);
}
#HomePage .section-area-2 .section-title span img {
  position: absolute;
  left: 0;
  bottom: -7px;
  width: calc(100% - 25px);
  display: inline-block;
  margin-left: 10px;
  animation: section-animation 5s linear 0s infinite;
}
#HomePage .section-area-2 .section-text {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 0;
  color: rgba(30, 30, 30, 0.7);
}

#HomePage .h2_banner-content .section-title {
  font-size: 70px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 15px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h2_banner-content .section-title {
    font-size: 60px;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h2_banner-content .section-title {
    font-size: 50px;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h2_banner-content .section-title {
    font-size: 45px;
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .h2_banner-content .section-title {
    font-size: 33px;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h2_banner-content .section-title {
    font-size: 45px;
  }
}
#HomePage .h2_banner-content .section-title span {
  display: inline-block;
  position: relative;
  z-index: 1;
  color: var(--clr-theme-primary);
}
#HomePage .h2_banner-content .section-title span img {
  position: absolute;
  left: 0;
  bottom: -5px;
  width: calc(100% - 25px);
  display: inline-block;
  margin-left: 10px;
}
#HomePage .h2_banner-content .section-text {
  margin-bottom: 43px;
  color: rgba(30, 30, 30, 0.6);
}

#HomePage .section-area-3 .section-subtitle {
  color: var(--clr-theme-primary-3);
  font-size: 16px;
  display: block;
  margin-bottom: 15px;
}
#HomePage .section-area-3 .section-title {
  font-size: 46px;
  font-weight: 700;
  line-height: 1.2;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-area-3 .section-title {
    font-size: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-area-3 .section-title {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .section-area-3 .section-title {
    font-size: 32px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-area-3 .section-title {
    font-size: 40px;
  }
}
#HomePage .section-area-3 .section-text {
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 0;
  color: rgba(30, 30, 30, 0.7);
  letter-spacing: 0.15px;
}

#HomePage .h3_banner-content .section-subtitle {
  color: var(--clr-body-heading);
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  position: relative;
  z-index: 1;
  margin-bottom: 10px;
}
#HomePage .h3_banner-content .section-title {
  font-size: 64px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 15px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h3_banner-content .section-title {
    font-size: 60px;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h3_banner-content .section-title {
    font-size: 45px;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h3_banner-content .section-title {
    font-size: 45px;
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .h3_banner-content .section-title {
    font-size: 35px;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h3_banner-content .section-title {
    font-size: 45px;
  }
}
#HomePage .h3_banner-content .section-title span {
  display: inline-block;
  position: relative;
  z-index: 1;
  color: var(--clr-theme-primary-3);
}
#HomePage .h3_banner-content .section-text {
  font-size: 17px;
  margin-bottom: 43px;
  color: rgba(30, 30, 30, 0.7);
}

#HomePage .section-area-4 .section-subtitle {
  color: var(--clr-theme-primary-3);
  font-size: 16px;
  display: block;
  margin-bottom: 15px;
}
#HomePage .section-area-4 .section-title {
  font-size: 46px;
  font-weight: 700;
  line-height: 1.2;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-area-4 .section-title {
    font-size: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-area-4 .section-title {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .section-area-4 .section-title {
    font-size: 32px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-area-4 .section-title {
    font-size: 40px;
  }
}
#HomePage .section-area-4 .section-text {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 0;
  color: rgba(30, 30, 30, 0.7);
}

#HomePage .section-white-4 .section-title {
  color: #fff;
}
#HomePage .section-white-4 .section-text {
  color: rgba(255, 255, 255, 0.7);
}

#HomePage .h4_banner-content .section-title {
  font-size: 90px;
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 20px;
  color: #fff;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h4_banner-content .section-title br {
    display: none;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h4_banner-content .section-title {
    font-size: 80px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h4_banner-content .section-title {
    font-size: 75px;
  }
}
@media (max-width: 767px) {
  .h4_banner-content .section-title {
    font-size: 45px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h4_banner-content .section-title {
    font-size: 60px;
  }
}
#HomePage .h4_banner-content .section-title span {
  display: inline-block;
  font-weight: 900;
  color: var(--clr-theme-primary-4);
}
#HomePage .h4_banner-content .section-text {
  font-size: 16px;
  margin-bottom: 40px;
  color: rgba(255, 255, 255, 0.7);
}

#HomePage .section-area-5 .section-subtitle {
  color: var(--clr-body-heading);
  font-size: 16px;
  display: block;
}
#HomePage .section-area-5 .section-title {
  font-size: 46px;
  font-weight: 700;
  line-height: 1.2;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-area-5 .section-title {
    font-size: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-area-5 .section-title {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .section-area-5 .section-title {
    font-size: 32px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-area-5 .section-title {
    font-size: 40px;
  }
}
#HomePage .section-area-5 .section-text {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 0;
  color: rgba(30, 30, 30, 0.7);
}

#HomePage .section-area-6 .section-subtitle {
  color: var(--clr-theme-primary-6);
  font-size: 16px;
  display: block;
}
#HomePage .section-area-6 .section-title {
  font-size: 44px;
  font-weight: 600;
  line-height: 1.2;
}
@media (max-width: 767px) {
  .section-area-6 .section-title {
    font-size: 33px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-area-6 .section-title {
    font-size: 44px;
  }
}
#HomePage .section-area-6 .section-text {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
  color: #666;
}
@media (max-width: 767px) {
  .section-area-6 .section-text br {
    display: none;
  }
}

#HomePage .section-area-6-admin {
  position: relative;
  z-index: 1;
  display: block;
  color: #0A0A0A;
  font-size: 16px;
  font-weight: 500;
  padding-left: 55px;
  line-height: 21px;
  margin-top: 30px;
}

#HomePage .section-area-6-admin::before {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  width: 45px;
  height: 1px;
  background: #0A0A0A;
}

#HomePage .section-area-8 .section-subtitle {
  color: #F14D5D;
  font-size: 16px;
  display: block;
  margin-bottom: 8px;
  font-weight: 700;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .section-area-8 .section-subtitle {
    font-weight: 500;
  }
}
#HomePage .section-area-8 .section-title {
  color: #021936;
  font-size: 40px;
  font-weight: 600;
  line-height: 1.2;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-area-8 .section-title {
    font-size: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-area-8 .section-title {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .section-area-8 .section-title {
    font-size: 32px;
  }
  .section-area-8 .section-title br {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-area-8 .section-title {
    font-size: 40px;
  }
}
#HomePage .section-area-8 .section-text {
  margin-bottom: 0;
  color: rgba(2, 25, 54, 0.7);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
}

#HomePage .section-area-9 .section-subtitle {
  color: #1865F2;
  font-size: 16px;
  font-weight: 500;
  display: block;
  line-height: 24px;
  margin-bottom: 5px;
}
#HomePage .section-area-9 .section-title {
  color: #0A0A0A;
  font-size: 46px;
  font-weight: 700;
  line-height: 1.2;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-area-9 .section-title {
    font-size: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-area-9 .section-title {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .section-area-9 .section-title {
    font-size: 32px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-area-9 .section-title {
    font-size: 40px;
  }
}

#HomePage .section-area-10 .section-title {
  color: #0A0A0A;
  font-size: 46px;
  font-weight: 600;
  line-height: 1.1;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-area-10 .section-title {
    font-size: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-area-10 .section-title {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .section-area-10 .section-title {
    font-size: 32px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-area-10 .section-title {
    font-size: 40px;
  }
}
#HomePage .section-area-10 .section-text {
  margin-bottom: 0;
  color: #666;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

#HomePage .small-section-area-2 .section-title {
  font-size: 40px;
}
#HomePage .small-section-area-2 .section-title span img {
  bottom: -10px;
}
@media (max-width: 767px) {
  .small-section-area-2 .section-title {
    font-size: 32px;
  }
  .small-section-area-2 .section-title br {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .small-section-area-2 .section-title {
    font-size: 35px;
  }
  .small-section-area-2 .section-title br {
    display: block;
  }
}

#HomePage .small-section-area-3 .section-title {
  font-size: 40px;
  line-height: 1.25;
}
@media (max-width: 767px) {
  .small-section-area-3 .section-title {
    font-size: 32px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .small-section-area-3 .section-title {
    font-size: 35px;
  }
}

#HomePage .h2_blog-section-area .section-title span {
  color: var(--clr-body-heading);
}

#HomePage .h2_cta-content .section-area-2 .section-title span {
  color: var(--clr-body-heading);
}

#HomePage .h2_teacher-section .section-area-2 .section-title span {
  color: var(--clr-body-heading);
}

@keyframes section-animation {
  0% {
    width: 0;
  }
  15% {
    width: 100%;
  }
  85% {
    opacity: 1;
  }
  90% {
    width: 100%;
    opacity: 0;
  }
  to {
    width: 0;
    opacity: 0;
  }
}
/*
************
*************************
06. brand
******************************************************* 
*************************************************************** */
#HomePage .brand-wrap {
  border: 1px solid rgba(30, 30, 30, 0.1);
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 20px;
  padding-right: 20px;
}
#HomePage .brand-item {
  text-align: center;
}
#HomePage .brand-content h5 {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 35px;
  color: rgba(30, 30, 30, 0.8);
}

#HomePage .brand-item .brand-hover-img {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  transform: translateY(-100%);
  opacity: 0;
  visibility: hidden;
  margin: auto;
  right: 0;
}

#HomePage .brand-item {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
#HomePage .brand-item img {
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .brand-item .brand-hover-img {
  left: 50%;
  transform: translate(-50%, -100%);
}
#HomePage .brand-item:hover .brand-hover-img {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  left: 50%;
  transform: translateX(-50%);
}
#HomePage .brand-item:hover .brand-main-img {
  transform: translateY(100%);
  opacity: 0;
  visibility: hidden;
}

#HomePage .h6_brand-area .brand-wrap {
  border: none;
  padding: 0;
}
#HomePage .h6_brand-area .brand-item {
  text-align: center;
}
#HomePage .h6_brand-area .brand-item .brand-hover-img {
  margin: 0;
}

/*
************
*************************
07. category
******************************************************* 
*************************************************************** */
#HomePage .category-wrap {
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .category-wrap {
    margin-left: 30px;
  }
}
#HomePage .category-content {
  padding: 21px 25px 22px;
}
#HomePage .category-content h5 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}
#HomePage .category-content h5:hover a {
  color: var(--clr-theme-primary);
}
#HomePage .category-content p {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
}
#HomePage .category-content p a {
  line-height: 1;
  position: relative;
  font-size: 15px;
  font-weight: 500;
  padding-right: 15px;
  display: inline-block;
}
#HomePage .category-content p a i {
  font-weight: 300;
  font-size: 12px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease-in-out;
}
#HomePage .category-content p a i:first-child {
  right: 10%;
  visibility: hidden;
  opacity: 0;
}
#HomePage .category-content p a i:last-child {
  right: 0;
}
#HomePage .category-content p a:hover i:last-child {
  right: -10%;
  visibility: hidden;
  opacity: 0;
}
#HomePage .category-content p a:hover i:first-child {
  right: 0;
  visibility: visible;
  opacity: 1;
}
#HomePage .category-content p:hover a {
  color: var(--clr-theme-primary);
}
#HomePage .category-item {
  background-color: #fff;
  box-shadow: 0px 10px 40px rgba(30, 30, 30, 0.1);
  border-radius: 10px;
  overflow: hidden;
}

#HomePage .category-navigation {
  display: flex;
  gap: 10px;
}
#HomePage .category-navigation i {
  font-weight: 300;
}
#HomePage .category-navigation div {
  width: 50px;
  height: 50px;
  background: #FFFFFF;
  box-shadow: 0px 2px 0px #E1E2E6;
  border-radius: 100px;
  display: grid;
  place-items: center;
  font-size: 14px;
  color: #1E1E1E;
  -webkit-transition: all 0.4s linear 0s;
  -moz-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  -o-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
}
#HomePage .category-navigation div:hover {
  background-color: var(--clr-theme-primary);
  color: #fff;
}

#HomePage .category-shape img {
  position: absolute;
}
#HomePage .category-shape-1 {
  top: -75px;
  left: -25px;
  animation: animation-popup-1 4s linear 0s infinite alternate;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .category-shape-1 {
    left: 150px;
  }
}
@media (max-width: 767px) {
  .category-shape-1 {
    top: -65px;
    left: 150px;
  }
}
#HomePage .category-shape-2 {
  top: -40px;
  right: 190px;
  animation: animation-leftRight-1 3s linear 0s infinite alternate;
}
@media (max-width: 767px) {
  .category-shape-2 {
    right: 15px;
  }
}
#HomePage .category-shape-3 {
  left: 49%;
  transform: translateX(-50%);
  top: 80px;
  animation: animation-popup-2 1.2s linear 0s infinite alternate;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .category-shape-3 {
    left: calc(32% - 4px);
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .category-shape-3 {
    left: 31%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .category-shape-3 {
    left: 31%;
  }
}
@media (max-width: 767px) {
  .category-shape-3 {
    display: none;
  }
}
#HomePage .category-shape-4 {
  right: -40px;
  top: 40px;
  animation: animation-upDown-1 3s linear 0s infinite alternate;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .category-shape-4 {
    right: -25px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .category-shape-4 {
    right: -30px;
  }
}
@media (max-width: 767px) {
  .category-shape-4 {
    display: none;
  }
}
#HomePage .category-img img {
  width: 100%;
}

@keyframes animation-popup-1 {
  0% {
    transform: scale(0.2) rotate(0deg);
  }
  50% {
    transform: scale(0.5) rotate(180deg);
  }
  90% {
    transform: scale(1) rotate(360deg);
  }
  100% {
    transform: scale(1) rotate(360deg);
  }
}
@keyframes animation-popup-2 {
  0% {
    transform: scale(0.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes animation-upDown-1 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-40px);
  }
}
@keyframes animation-leftRight-1 {
  0% {
    transform: translateX(15px);
  }
  100% {
    transform: translateX(-15px);
  }
}
#HomePage .h2_category-item {
  border: 1px solid rgba(30, 30, 30, 0.1);
  padding: 10px;
  background-color: #fff;
  border-radius: 6px;
}
#HomePage .h2_category-content h5 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 2px;
}
#HomePage .h2_category-content h5:hover a {
  color: var(--clr-theme-primary);
}
#HomePage .h2_category-content p {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
}
#HomePage .h2_category-item {
  display: flex;
  align-items: center;
  gap: 20px;
}
#HomePage .h2_category-img {
  width: 90px;
  flex-shrink: 0;
  border-radius: 6px 0 0 6px;
  overflow: hidden;
}

#HomePage .h3_category-area {
  background: var(--clr-color-lightPink);
}
#HomePage .h3_category-item {
  background: #FFF;
  box-shadow: 0px 2px 4px rgba(23, 22, 28, 0.1);
  padding: 40px;
  display: flex;
  gap: 25px;
  align-items: center;
  border-radius: 10px;
  position: relative;
  z-index: 1;
}
@media (max-width: 767px) {
  .h3_category-item {
    padding: 40px 25px;
    align-items: flex-start;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h3_category-item {
    padding: 40px 40px;
    align-items: center;
  }
}
#HomePage .h3_category-item-icon {
  width: 70px;
  height: 70px;
  background: linear-gradient(135deg, #E2C3E8 0%, rgba(226, 195, 232, 0) 100%);
  border-radius: 50%;
  display: grid;
  place-items: center;
}
#HomePage .h3_category-item-icon i {
  color: var(--clr-body-heading);
  font-size: 30px;
}
#HomePage .h3_category-item-content h5 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 5px;
}
#HomePage .h3_category-item-content h5:hover a {
  color: var(--clr-theme-primary-3);
}
#HomePage .h3_category-item-content p {
  margin-bottom: 0;
}
#HomePage .h3_category-btn {
  position: absolute;
  right: 35px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .h3_category-btn a {
  width: 40px;
  height: 40px;
  background: var(--clr-theme-primary-3);
  display: grid;
  place-items: center;
  color: #fff;
  font-size: 14px;
  border-radius: 6px;
  box-shadow: 0px 0px 5px 0px rgba(32, 42, 61, 0.4);
}
@media (max-width: 575px) {
  .h3_category-btn {
    position: static;
    transform: unset;
    right: unset;
    top: unset;
    opacity: 1;
    visibility: visible;
    margin-top: 12px;
  }
}

#HomePage .h3_category-item:hover .h3_category-btn {
  opacity: 1;
  visibility: visible;
}

#HomePage .h4_category-area {
  background-color: #ECECF9;
}
#HomePage .h4_category-item {
  display: grid;
  place-items: center;
  text-align: center;
  padding: 40px 0 35px;
  border: 1px solid rgba(30, 30, 30, 0.09);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .h4_category-item-icon {
  width: 80px;
  height: 80px;
  background: #fff;
  display: grid;
  place-items: center;
  border-radius: 5px;
  box-shadow: 0px 6px 30px 0px rgba(10, 10, 10, 0.1);
  margin-bottom: 25px;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  border: 1px solid transparent;
}
#HomePage .h4_category-item-icon i {
  font-size: 40px;
  color: var(--clr-theme-primary-4);
}
#HomePage .h4_category-item-content h5 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 5px;
}
#HomePage .h4_category-item-content h5:hover a {
  color: var(--clr-theme-primary-4);
}
#HomePage .h4_category-item-content p {
  margin-bottom: 0;
}
#HomePage .h4_category-item:hover {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 14px 50px 0px rgba(10, 10, 10, 0.14);
}
#HomePage .h4_category-item:hover .h4_category-item-icon {
  border-color: rgba(10, 10, 10, 0.1);
  box-shadow: none;
}

#HomePage .h4_category-area .col-xl-3:first-child .h4_category-item {
  border-width: 0;
}
@media (max-width: 767px) {
  .h4_category-area .col-xl-3:first-child .h4_category-item {
    border-width: 0 0 1px 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h4_category-area .col-xl-3:first-child .h4_category-item {
    border-width: 0;
  }
}
#HomePage .h4_category-area .col-xl-3:nth-child(2) .h4_category-item {
  border-width: 0 0 0 1px;
}
@media (max-width: 767px) {
  .h4_category-area .col-xl-3:nth-child(2) .h4_category-item {
    border-width: 0 0 1px 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h4_category-area .col-xl-3:nth-child(2) .h4_category-item {
    border-width: 0 0 0 1px;
  }
}
#HomePage .h4_category-area .col-xl-3:nth-child(3) .h4_category-item {
  border-width: 0 1px 0 1px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h4_category-area .col-xl-3:nth-child(3) .h4_category-item {
    border-width: 1px 0 0 0;
  }
}
@media (max-width: 767px) {
  .h4_category-area .col-xl-3:nth-child(3) .h4_category-item {
    border-width: 0 0 1px 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h4_category-area .col-xl-3:nth-child(3) .h4_category-item {
    border-width: 1px 0 0 0;
  }
}
#HomePage .h4_category-area .col-xl-3:nth-child(4) .h4_category-item {
  border-width: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h4_category-area .col-xl-3:nth-child(4) .h4_category-item {
    border-width: 1px 0 0 1px;
  }
}
@media (max-width: 767px) {
  .h4_category-area .col-xl-3:nth-child(4) .h4_category-item {
    border-width: 0 0 1px 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h4_category-area .col-xl-3:nth-child(4) .h4_category-item {
    border-width: 1px 0 0 1px;
  }
}
#HomePage .h4_category-area .col-xl-3:nth-child(5) .h4_category-item {
  border-width: 1px 0 0 0;
}
@media (max-width: 767px) {
  .h4_category-area .col-xl-3:nth-child(5) .h4_category-item {
    border-width: 0 0 1px 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h4_category-area .col-xl-3:nth-child(5) .h4_category-item {
    border-width: 1px 0 0 0;
  }
}
#HomePage .h4_category-area .col-xl-3:nth-child(6) .h4_category-item {
  border-width: 1px 0 0 1px;
}
@media (max-width: 767px) {
  .h4_category-area .col-xl-3:nth-child(6) .h4_category-item {
    border-width: 0 0 1px 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h4_category-area .col-xl-3:nth-child(6) .h4_category-item {
    border-width: 1px 0 0 1px;
  }
}
#HomePage .h4_category-area .col-xl-3:nth-child(7) .h4_category-item {
  border-width: 1px 1px 0 1px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h4_category-area .col-xl-3:nth-child(7) .h4_category-item {
    border-width: 1px 0 0 0;
  }
}
@media (max-width: 767px) {
  .h4_category-area .col-xl-3:nth-child(7) .h4_category-item {
    border-width: 0 0 1px 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h4_category-area .col-xl-3:nth-child(7) .h4_category-item {
    border-width: 1px 0 0 0;
  }
}
#HomePage .h4_category-area .col-xl-3:last-child .h4_category-item {
  border-width: 1px 0 0 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h4_category-area .col-xl-3:last-child .h4_category-item {
    border-width: 1px 0 0 1px;
  }
}
@media (max-width: 767px) {
  .h4_category-area .col-xl-3:last-child .h4_category-item {
    border-width: 0 0 0 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h4_category-area .col-xl-3:last-child .h4_category-item {
    border-width: 1px 0 0 1px;
  }
}

#HomePage .h5_category-item {
  border: 1px solid rgba(10, 10, 10, 0.14);
  padding: 41px 45px 41px;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h5_category-item {
    padding: 41px 30px 41px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h5_category-item {
    padding: 41px 30px 41px;
  }
}
#HomePage .h5_category-item-icon {
  margin-bottom: 85px;
}
#HomePage .h5_category-item-icon i {
  font-size: 50px;
  color: var(--clr-body-heading);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .h5_category-item-content h5 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 4px;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .h5_category-item-content h5:hover a {
  color: var(--clr-theme-primary-5);
}
#HomePage .h5_category-item-content p {
  font-size: 16px;
  margin-bottom: 0;
  color: var(--clr-body-heading);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .h5_category-item:hover {
  background-color: var(--clr-body-heading);
  border-color: transparent;
}
#HomePage .h5_category-item:hover .h5_category-item-icon i {
  color: #fff;
}
#HomePage .h5_category-item:hover .h5_category-item-content h5 {
  color: #fff;
}
#HomePage .h5_category-item:hover .h5_category-item-content p {
  color: #fff;
}

#HomePage .h8_category-item {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 5px 25px 0px rgba(8, 5, 33, 0.08);
  padding: 40px 35px 32px;
  text-align: center;
}

#HomePage .h8_category-icon i {
  color: rgb(57, 91, 223);
  font-size: 35px;
  display: inline-block;
  margin-bottom: 20px;
}

#HomePage .h8_category-title {
  color: #021936;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.33;
  margin-bottom: 5px;
}

#HomePage .h8_category-text {
  color: rgba(2, 25, 54, 0.7);
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 0;
}

#HomePage .h8_category-wrap {
  position: relative;
  z-index: 1;
}

#HomePage .h8_category-navigation div {
  border-radius: 40px;
  border: 1px solid rgba(2, 25, 54, 0.1);
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  position: absolute;
  left: -70px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h8_category-navigation div {
    left: -50px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h8_category-navigation div {
    left: -40px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h8_category-navigation div {
    left: -25px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h8_category-navigation div {
    left: -25px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h8_category-navigation div {
    left: -25px;
  }
}
#HomePage .h8_category-navigation div.h8_category-next {
  left: auto;
  right: -70px;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h8_category-navigation div.h8_category-next {
    right: -50px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h8_category-navigation div.h8_category-next {
    right: -40px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h8_category-navigation div.h8_category-next {
    right: -25px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h8_category-navigation div.h8_category-next {
    right: -25px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h8_category-navigation div.h8_category-next {
    right: -25px;
  }
}
#HomePage .h8_category-navigation div svg {
  color: #021936;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .h8_category-navigation div:hover {
  background-color: #395BDF;
}
#HomePage .h8_category-navigation div:hover svg {
  color: #fff;
}

#HomePage .h8_category-item .h8_category-icon > i {
  transition: all 0.3s linear 0s;
}

#HomePage .h8_category-item:hover .h8_category-icon > i {
  transform: scale(1.1) translateY(-5px);
}

#HomePage .h10_category-area {
  background: #E1EEDA;
}

#HomePage .h10_category-item {
  background: #F2F4F8;
  padding: 40px 40px 30px;
  border: 1px dashed transparent;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h10_category-item {
    padding: 40px 25px 30px;
  }
}
#HomePage .h10_category-item-icon {
  margin-bottom: 20px;
}
#HomePage .h10_category-item-icon svg {
  width: 50px;
  color: #2C2C2C;
}
#HomePage .h10_category-item-title {
  color: #2C2C2C;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 5px;
}
#HomePage .h10_category-item p {
  color: #2C2C2C;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
}
#HomePage .h10_category-item:hover {
  border-color: #4846AB;
}

#HomePage .h10_category_bg-2 {
  background-color: #F8F2F2;
}

#HomePage .h10_category_bg-3 {
  background-color: #E8F6EB;
}

#HomePage .h10_category_bg-4 {
  background-color: #F8F6F2;
}

#HomePage .h10_category_bg-5 {
  background-color: #F4F8F2;
}

#HomePage .h10_category_bg-6 {
  background-color: #E8F6F5;
}

#HomePage .h10_category_bg-7 {
  background-color: #F7F2F8;
}

#HomePage .h10_category_bg-8 {
  background-color: #E8F6F5;
}

#HomePage .h10_category-item-btn {
  display: flex;
  justify-content: center;
}
#HomePage .h10_category-item-btn a {
  color: #4846AB;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  gap: 8px;
  border: 1px solid #5F5DB4;
  border-radius: 4px;
  line-height: 1;
}
#HomePage .h10_category-item-btn a:hover {
  background-color: var(--clr-theme-primary-10);
  color: #fff;
}

#HomePage .h10_category-item .h10_category-item-icon > svg {
  transition: all 0.3s linear 0s;
}

#HomePage .h10_category-item:hover .h10_category-item-icon > svg {
  transform: scale(1.1) translateY(-5px);
}

/*
************
*************************
08. course
******************************************************* 
*************************************************************** */
#HomePage .course-wrap {
  background-color: var(--clr-color-lightPurple);
}
#HomePage .course-item {
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 20px 80px rgba(30, 30, 30, 0.1);
  position: relative;
  z-index: auto;
}
#HomePage .course-img {
  border-radius: 10px 10px 0 0;
  overflow: hidden;
}
#HomePage .course-img img {
  width: 100%;
}
#HomePage .course-content {
  padding-left: 15px;
  padding-right: 15px;
}
#HomePage .course-content-top {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-top: 25px;
  padding-bottom: 20px;
}
#HomePage .course-content-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 25px;
}
#HomePage .course-content-title:hover a {
  color: var(--clr-theme-primary);
}
#HomePage .course-content-bottom {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(30, 30, 30, 0.1);
  padding: 15px 0 6px;
  align-items: center;
}
#HomePage .course-top-icon {
  width: 30px;
  height: 30px;
  border: 1px solid rgba(30, 30, 30, 0.1);
  border-radius: 4px;
  display: grid;
  place-items: center;
}
#HomePage .course-top-icon img {
  width: 20px;
}
#HomePage .course-top-title h6 {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
  color: rgba(30, 30, 30, 0.6);
}
#HomePage .course-bottom-info {
  display: flex;
  align-items: center;
  gap: 20px;
}
#HomePage .course-bottom-info span {
  display: flex;
  align-items: center;
  gap: 7px;
  color: rgba(30, 30, 30, 0.6);
}
#HomePage .course-bottom-info span i {
  font-weight: 300;
}
#HomePage .course-bottom-price span {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--clr-theme-primary);
  font-weight: 700;
}
#HomePage .course-bottom-price span del {
  color: #787878;
}

#HomePage .course-hover-content {
  padding: 25px 25px 25px;
  position: absolute;
  left: 0;
  top: -100%;
  top: 0;
  background-color: #fff;
  border-radius: 10px;
  opacity: 0;
  z-index: 9;
  visibility: hidden;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  transform: scale(0.6);
  min-height: 100%;
}
#HomePage .course-hover-content-top {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 20px;
}
#HomePage .course-hover-content-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 10px;
}
#HomePage .course-hover-content-title:hover a {
  color: var(--clr-theme-primary);
}
#HomePage .course-hover-content-text {
  font-size: 14px;
  line-height: 22px;
  color: rgba(30, 30, 30, 0.6);
  margin-bottom: 20px;
}
#HomePage .course-hover-content-list {
  margin-bottom: 30px;
}
#HomePage .course-hover-content-list li {
  font-size: 14px;
  line-height: 22px;
  color: rgba(30, 30, 30, 0.7);
  display: flex;
  line-height: 22px;
  gap: 10px;
  margin-bottom: 10px;
}
#HomePage .course-hover-content-list li i {
  color: #1E1E1E;
  margin-top: 5px;
  font-weight: 300;
}
#HomePage .course-hover-content-list li:last-child {
  margin-bottom: 0;
}
#HomePage .course-hover-content-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}
#HomePage .course-hover-top-icon {
  width: 30px;
  height: 30px;
  border: 1px solid rgba(30, 30, 30, 0.1);
  border-radius: 4px;
  display: grid;
  place-items: center;
}
#HomePage .course-hover-top-icon img {
  width: 20px;
}
#HomePage .course-hover-top-title h6 {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
  color: rgba(30, 30, 30, 0.6);
}

#HomePage .course-hover-fav-btn a {
  width: 40px;
  height: 40px;
  border: 1.5px solid rgba(30, 30, 30, 0.14);
  border-radius: 50%;
  display: grid;
  place-items: center;
  flex-shrink: 0;
  font-size: 15px;
  color: #1E1E1E;
  background-color: rgba(10, 10, 10, 0.08);
}
#HomePage .course-hover-fav-btn a i {
  font-weight: 300;
}
#HomePage .course-hover-fav-btn a:hover {
  background-color: var(--clr-theme-primary);
  border-color: transparent;
  color: #fff;
}
#HomePage .course-hover-cart-btn {
  width: calc(100% - 40px);
}
#HomePage .course-hover-content::before {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  background-color: #fff;
  clip-path: polygon(0 50%, 100% 100%, 100% 0);
  width: 20px;
  height: 24px;
  z-index: -1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}

#HomePage .course-btn.theme-btn {
  width: 100%;
  justify-content: center;
}

#HomePage .course-item:hover .course-hover-content {
  opacity: 1;
  visibility: visible;
  top: 0;
  transform: scale(1);
}
#HomePage .course-item:hover .course-hover-content::before {
  left: -15px;
}

#HomePage .course-tab {
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
}
#HomePage .course-tab .nav {
  background-color: #fff;
  padding: 5px 5px;
  border-radius: 100px;
  display: flex;
  gap: 15px;
  box-shadow: 0px 2px 0px #E1E2E6;
}
#HomePage .course-tab .nav .nav-item .nav-link {
  padding: 8px 30px;
  border-radius: 100px;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  color: rgba(30, 30, 30, 0.6);
}
@media (max-width: 767px) {
  .course-tab .nav {
    justify-content: center;
    padding: 30px 30px;
  }
}

#HomePage .course-tab .nav-pills .nav-link:hover,
#HomePage .course-tab .nav-pills .nav-link.active,
#HomePage .course-tab .nav-pills .show > .nav-link {
  color: #fff;
  background-color: var(--clr-theme-primary);
}

#HomePage .h2_course-area {
  background-color: #F5F5F5;
}
#HomePage .h2_course-item {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 20px 80px rgba(30, 30, 30, 0.1);
}
#HomePage .h2_course-item-img {
  overflow: hidden;
}
#HomePage .h2_course-item-img img {
  width: 100%;
  -webkit-transition: all 0.6s linear 0s;
  -moz-transition: all 0.6s linear 0s;
  -ms-transition: all 0.6s linear 0s;
  -o-transition: all 0.6s linear 0s;
  transition: all 0.6s linear 0s;
}
#HomePage .h2_course-content {
  padding: 20px 30px 30px;
}
@media (max-width: 767px) {
  .h2_course-content {
    padding: 20px 25px 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h2_course-content {
    padding: 20px 30px 30px;
  }
}
#HomePage .h2_course-content-title {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 20px;
}
#HomePage .h2_course-content-title:hover a {
  color: var(--clr-theme-primary);
}
#HomePage .h2_course-content-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 11px;
  gap: 10px;
}
#HomePage .h2_course-content-info {
  display: flex;
  align-items: center;
  width: max-content;
  background: #F1F1F1;
  margin-bottom: 20px;
}
#HomePage .h2_course-content-info span {
  display: flex;
  padding: 2px 15px;
  align-items: center;
  gap: 7px;
  font-size: 14px;
}
#HomePage .h2_course-content-info span:first-child {
  border-right: 1px solid rgba(30, 30, 30, 0.14);
}
#HomePage .h2_course-content-info span i {
  font-weight: 300;
}
#HomePage .h2_course-content-text {
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 24px;
}
#HomePage .h2_course-content-author {
  display: flex;
  align-items: center;
  gap: 10px;
}
#HomePage .h2_course-rating {
  display: flex;
  gap: 10px;
  align-items: center;
}
#HomePage .h2_course-rating span {
  font-size: 15px;
  font-weight: 500;
  color: rgba(30, 30, 30, 0.7);
  display: block;
}
#HomePage .h2_course-rating ul {
  display: flex;
  gap: 5px;
  align-items: center;
}
#HomePage .h2_course-rating ul li {
  color: #FFA121;
  font-size: 14px;
}
@media (max-width: 767px) {
  .h2_course-rating {
    gap: 5px;
  }
  .h2_course-rating span {
    font-size: 14px;
  }
  .h2_course-rating ul li {
    font-size: 12px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h2_course-rating {
    gap: 10px;
  }
  .h2_course-rating span {
    font-size: 15px;
  }
  .h2_course-rating ul li {
    font-size: 14px;
  }
}

#HomePage .h2_course-save a {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border: 1px solid rgba(30, 30, 30, 0.1);
  border-radius: 50%;
  display: grid;
  place-items: center;
  font-size: 14px;
  color: var(--clr-body-heading);
  background-color: rgba(10, 10, 10, 0.08);
}
#HomePage .h2_course-save a i {
  font-weight: 300;
}
#HomePage .h2_course-save a:hover {
  background-color: var(--clr-theme-primary);
  color: #fff;
  border-color: var(--clr-theme-primary);
}
#HomePage .h2_course-author-info span {
  color: #A3A9B1;
  font-size: 14px;
  font-weight: 500;
}
#HomePage .h2_course-author-info span a {
  color: var(--clr-body-heading);
}
#HomePage .h2_course-author-info span a:hover {
  color: var(--clr-theme-primary);
}
#HomePage .h2_course-author-img {
  width: 45px;
  height: 45px;
  border: 1px solid rgba(30, 30, 30, 0.14);
  border-radius: 50%;
  display: grid;
  place-items: center;
  flex-shrink: 0;
}
#HomePage .h2_course-author-img img {
  width: 40px;
  height: 40px;
}

#HomePage .h2_course-content-bottom {
  border-top: 1px solid rgba(30, 30, 30, 0.1);
  padding: 15px 30px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#HomePage .h2_course-bottom-price span {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  font-weight: 700;
  color: var(--clr-color-darkPink);
}
#HomePage .h2_course-bottom-price span del {
  color: #787878;
  font-weight: 500;
}
#HomePage .h2_course-bottom-btn a {
  line-height: 1;
  position: relative;
  font-size: 15px;
  font-weight: 500;
  padding-right: 15px;
  display: inline-block;
}
#HomePage .h2_course-bottom-btn a i {
  font-size: 12px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease-in-out;
  font-weight: 400;
}
#HomePage .h2_course-bottom-btn a i:first-child {
  right: 10%;
  visibility: hidden;
  opacity: 0;
}
#HomePage .h2_course-bottom-btn a i:last-child {
  right: 0;
}
#HomePage .h2_course-bottom-btn a:hover i:last-child {
  right: -10%;
  visibility: hidden;
  opacity: 0;
}
#HomePage .h2_course-bottom-btn a:hover i:first-child {
  right: 0;
  visibility: visible;
  opacity: 1;
}
#HomePage .h2_course-bottom-btn a:hover {
  color: var(--clr-theme-primary);
}

#HomePage .h2_course-tab .nav {
  justify-content: end;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h2_course-tab .nav {
    justify-content: flex-start;
  }
}
#HomePage .h2_course-tab .nav .nav-item {
  margin-bottom: 10px;
}
#HomePage .h2_course-tab .nav .nav-item .nav-link {
  padding: 0;
  background: transparent;
  color: var(--clr-body-heading);
  font-size: 15px;
  margin-right: 25px;
  display: flex;
  position: relative;
  z-index: 1;
}
#HomePage .h2_course-tab .nav .nav-item .nav-link span {
  position: absolute;
  left: 0;
  top: -25px;
  background-color: var(--clr-theme-primary);
  box-shadow: 0px 3px 3px rgba(18, 104, 235, 0.24);
  color: #fff;
  display: block;
  font-size: 12px;
  padding: 4px 8px;
  line-height: 1;
}
#HomePage .h2_course-tab .nav .nav-item .nav-link span::before {
  position: absolute;
  left: 3px;
  bottom: -6px;
  content: "";
  width: 9px;
  height: 8px;
  background: var(--clr-theme-primary);
  clip-path: polygon(0 0, 50% 100%, 100% 0);
}
#HomePage .h2_course-tab .nav .nav-item:last-child .nav-link {
  margin-right: 0;
}
#HomePage .h2_course-tab .nav-pills .nav-link.active, .h2_course-tab .nav-pills .show > .nav-link {
  color: var(--clr-theme-primary);
}

#HomePage .h2_course-item:hover .h2_course-item-img img {
  transform: scale(1.2);
}

#HomePage .h3_course-tab {
  display: flex;
  justify-content: center;
}
#HomePage .h3_course-tab .nav {
  background-color: #fff;
  padding: 5px 5px;
  border-radius: 6px;
  display: flex;
  gap: 15px;
  border: 1px solid rgba(30, 30, 30, 0.14);
}
#HomePage .h3_course-tab .nav .nav-item .nav-link {
  padding: 8px 30px;
  border-radius: 6px;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  color: rgba(30, 30, 30, 0.6);
}
@media (max-width: 767px) {
  .h3_course-tab .nav {
    justify-content: center;
    padding: 30px 30px;
  }
}
#HomePage .h3_course-tab .nav-pills .nav-link:hover,
#HomePage .h3_course-tab .nav-pills .nav-link.active,
#HomePage .h3_course-tab .nav-pills .show > .nav-link {
  color: #fff;
  background-color: var(--clr-theme-primary-3);
}
#HomePage .h3_course-item {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 2px 20px 0px rgba(30, 30, 30, 0.1);
}
#HomePage .h3_course-item-top {
  padding: 25px 25px;
}
#HomePage .h3_course-item-img {
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin-bottom: 25px;
  border-radius: 6px;
}
#HomePage .h3_course-item-img img {
  width: 100%;
  -webkit-transition: all 0.6s linear 0s;
  -moz-transition: all 0.6s linear 0s;
  -ms-transition: all 0.6s linear 0s;
  -o-transition: all 0.6s linear 0s;
  transition: all 0.6s linear 0s;
}
#HomePage .h3_course-item-tag a {
  position: absolute;
  left: 15px;
  top: 15px;
  border-radius: 6px;
  background: var(--clr-theme-primary-3);
  box-shadow: 0px 2px 5px 0px rgba(32, 42, 61, 0.4);
  color: #fff;
  padding: 2px 13px;
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
}
#HomePage .h3_course-item-save a {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 1;
  width: 40px;
  height: 40px;
  display: inline-grid;
  background: rgba(30, 30, 30, 0.48);
  place-items: center;
  color: #fff;
  font-size: 16px;
  opacity: 1;
  border-radius: 4px;
}
#HomePage .h3_course-item-save a:hover {
  background: var(--clr-theme-primary-3);
}
#HomePage .h3_course-content-info {
  margin-bottom: 15px;
}
#HomePage .h3_course-content-info ul li {
  display: inline-block;
  margin-right: 20px;
  font-size: 14px;
  color: rgba(30, 30, 30, 0.6);
}
#HomePage .h3_course-content-info ul li:last-child {
  margin-right: 0;
}
#HomePage .h3_course-content-info ul li i {
  margin-right: 6px;
}
#HomePage .h3_course-content-title {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 25px;
}
#HomePage .h3_course-content-title:hover a {
  color: var(--clr-theme-primary-3);
}
#HomePage .h3_course-content-price {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 5px;
}
#HomePage .h3_course-content-price span {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
  font-weight: 600;
  color: var(--clr-theme-primary-3);
}
#HomePage .h3_course-content-price span del {
  color: rgba(30, 30, 30, 0.5);
  font-weight: 500;
  font-size: 18px;
}
#HomePage .h3_course-content-price h5 {
  color: #FF002C;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 0;
}
#HomePage .h3_course-item-bottom {
  border-top: 1px solid rgba(30, 30, 30, 0.1);
  padding: 15px 25px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .h3_course-item-bottom {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h3_course-item-bottom {
    flex-direction: row;
    gap: 0;
    align-items: center;
  }
}
#HomePage .h3_course-item-author {
  display: flex;
  align-items: center;
  gap: 10px;
}
#HomePage .h3_course-item-author-img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}
#HomePage .h3_course-item-author-info span {
  color: #79818D;
  font-size: 14px;
  font-weight: 500;
}
#HomePage .h3_course-item-author-info span a {
  color: var(--clr-body-heading);
}
#HomePage .h3_course-item-author-info span a:hover {
  color: var(--clr-theme-primary-3);
}
#HomePage .h3_course-item-rating {
  display: flex;
  gap: 10px;
  align-items: center;
}
#HomePage .h3_course-item-rating ul {
  display: flex;
  gap: 5px;
  align-items: center;
}
#HomePage .h3_course-item-rating ul li {
  color: #FFA121;
  font-size: 14px;
}
#HomePage .h3_course-item-rating span {
  font-size: 15px;
  font-weight: 500;
  color: rgba(30, 30, 30, 0.7);
  display: block;
}

#HomePage .h3_course-item:hover .h3_course-item-img img {
  transform: scale(1.2);
}

#HomePage .h4_course-tab .nav {
  display: flex;
  gap: 10px;
}
#HomePage .h4_course-tab .nav .nav-item .nav-link {
  padding: 10px 30px;
  line-height: 1;
  font-size: 15px;
  font-weight: 500;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  border: 1px solid rgba(10, 10, 10, 0.1);
  border-radius: 30px;
  color: rgba(30, 30, 30, 0.6);
}
#HomePage .h4_course-tab .nav-pills .nav-link:hover,
#HomePage .h4_course-tab .nav-pills .nav-link.active,
#HomePage .h4_course-tab .nav-pills .show > .nav-link {
  color: #fff;
  background-color: var(--clr-theme-primary-4);
  border-color: transparent;
}
#HomePage .h4_course-item {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid rgba(10, 10, 10, 0.1);
  padding: 30px 30px 23px;
}
#HomePage .h4_course-item-img {
  overflow: hidden;
  border-radius: 6px;
}
#HomePage .h4_course-item-img img {
  width: 100%;
  -webkit-transition: all 0.6s linear 0s;
  -moz-transition: all 0.6s linear 0s;
  -ms-transition: all 0.6s linear 0s;
  -o-transition: all 0.6s linear 0s;
  transition: all 0.6s linear 0s;
}
#HomePage .h4_course-content {
  position: relative;
  z-index: 1;
  padding-top: 25px;
}
#HomePage .h4_course-content-price {
  position: absolute;
  right: 30px;
  top: -12px;
  z-index: 9;
}
#HomePage .h4_course-content-price span {
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: var(--clr-body-heading);
  background-color: var(--clr-theme-primary-4);
  padding: 0 13px;
  height: 24px;
  line-height: 25px;
  border-radius: 4px;
}
#HomePage .h4_course-content-tag a {
  border-radius: 4px;
  border: 1px solid rgba(10, 10, 10, 0.1);
  color: rgba(30, 30, 30, 0.6);
  padding: 7px 13px;
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 12px;
}
#HomePage .h4_course-content-tag a:hover {
  background-color: var(--clr-theme-primary-4);
  color: var(--clr-body-heading);
  border-color: transparent;
  opacity: 1;
}
#HomePage .h4_course-content-info {
  margin-bottom: 15px;
}
#HomePage .h4_course-content-info ul li {
  display: inline-block;
  margin-right: 20px;
  font-size: 14px;
  color: rgba(30, 30, 30, 0.6);
}
#HomePage .h4_course-content-info ul li:last-child {
  margin-right: 0;
}
#HomePage .h4_course-content-info ul li i {
  margin-right: 6px;
}
#HomePage .h4_course-content-title {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 15px;
}
#HomePage .h4_course-content-title:hover a {
  color: var(--clr-theme-primary-4);
}
#HomePage .h4_course-content-rating {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
}
#HomePage .h4_course-content-rating ul {
  display: flex;
  gap: 5px;
  align-items: center;
}
#HomePage .h4_course-content-rating ul li {
  color: #FFA121;
  font-size: 14px;
}
#HomePage .h4_course-content-rating span {
  font-size: 15px;
  font-weight: 500;
  color: rgba(30, 30, 30, 0.7);
  display: block;
}
#HomePage .h4_course-content-btn a {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 500;
  color: var(--clr-body-heading);
}
#HomePage .h4_course-content-btn a:hover {
  color: var(--clr-theme-primary-4);
}

#HomePage .h4_course-item:hover .h4_course-item-img img {
  transform: scale(1.2);
}

#HomePage .h5_course-tab .nav {
  border-radius: 0;
}
#HomePage .h5_course-tab .nav .nav-item .nav-link {
  border-radius: 0;
}
#HomePage .h5_course-tab .nav-pills .nav-link:hover,
#HomePage .h5_course-tab .nav-pills .nav-link.active,
#HomePage .h5_course-tab .nav-pills .show > .nav-link {
  color: var(--clr-body-heading);
  background-color: var(--clr-theme-primary-5);
}
#HomePage .h5_course-area .course-hover-fav-btn a:hover {
  background-color: var(--clr-theme-primary-5);
  border-color: transparent;
  color: var(--clr-body-heading);
}
#HomePage .h5_course-area .course-hover-content-title:hover a {
  color: var(--clr-theme-primary);
}

#HomePage .h8_course-area {
  position: relative;
  z-index: 1;
}
#HomePage .h8_course-shape {
  position: absolute;
  left: 47%;
  transform: translateX(-50%);
  top: 130px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h8_course-shape {
    left: 50%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h8_course-shape {
    left: 57%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h8_course-shape {
    left: 70%;
  }
}
@media (max-width: 767px) {
  .h8_course-shape {
    display: none;
  }
}
#HomePage .h8_course-active {
  overflow-x: scroll;
}
#HomePage .h8_course-item {
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  background: #FFF;
  position: relative;
  min-height: 100%;
}
#HomePage .h8_course-item.h8_course_common .h8_course-content-3 {
  opacity: 0;
  visibility: hidden;
  transform: translateX(-80px);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .h8_course-item.small {
  width: 80px;
  flex-shrink: 0;
}
#HomePage .h8_course-item.expand {
  width: 690px;
  flex-shrink: 0;
  box-shadow: 0px 6px 60px 0px rgba(8, 5, 33, 0.1);
}
#HomePage .h8_course-item.expand .h8_course-content-3 {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
}
#HomePage .h8_course-item.expand .h8_course-m-title {
  background-color: #021936;
}
#HomePage .h8_course-item.expand .h8_course-m-count svg {
  color: #fff;
}
#HomePage .h8_course-item.expand .h8_course-m-title-vertical {
  color: #fff;
}
#HomePage .h8_course-m-title {
  width: 80px;
  z-index: 50;
  cursor: pointer;
  background-color: #F4F7FF;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 55px 0;
}
#HomePage .h8_course-m-title-vertical {
  display: block;
  color: #021936;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  writing-mode: vertical-rl;
  text-orientation: mixed;
}
#HomePage .h8_course-content-3 {
  position: relative;
  height: 100%;
  padding: 90px 85px 90px;
  background-color: #fff;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h8_course-content-3 {
    padding: 90px 60px 90px;
  }
}

#HomePage .h8_course-wrap {
  display: flex;
  width: 100%;
  padding: 0;
  gap: 50px;
}
@media (max-width: 767px) {
  .h8_course-wrap {
    gap: 30px;
  }
}
#HomePage .h8_course-wrap .accordion-body-img {
  position: relative;
  z-index: 1;
  margin-bottom: 35px;
}
#HomePage .h8_course-wrap .accordion-body-img img {
  width: 100%;
  z-index: -2;
  position: relative;
}
#HomePage .h8_course-wrap .accordion-body-img span {
  border-radius: 10px;
  background: #395BDF;
  color: #FFF;
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  line-height: 1;
  width: 135px;
  display: inline-grid;
  place-items: center;
  position: absolute;
  right: 0;
  top: 0;
  height: 85px;
}
#HomePage .h8_course-wrap .accordion-body-img span::before {
  position: absolute;
  left: -15px;
  top: 0;
  width: calc(100% + 15px);
  height: calc(100% + 15px);
  content: "";
  background: #fff;
  z-index: -1;
  border-radius: 0 0 0 10px;
}
#HomePage .h8_course-wrap .accordion-body-bottom {
  display: grid;
  grid-template-columns: 1fr 115px;
  grid-gap: 45px;
}
#HomePage .h8_course-wrap .accordion-body-content h4 {
  color: #021936;
  font-size: 26px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 13px;
}
#HomePage .h8_course-wrap .accordion-body-content p {
  color: #021936;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 10px;
}
#HomePage .h8_course-wrap .accordion-body-content-rating {
  display: flex;
  align-items: end;
  gap: 10px;
  margin-top: 32px;
}
#HomePage .h8_course-wrap .accordion-body-right {
  display: flex;
  flex-direction: column;
  align-items: end;
}
#HomePage .h8_course-wrap .accordion_left_rating {
  display: flex;
  gap: 5px;
}
#HomePage .h8_course-wrap .accordion_left_rating li {
  color: #F2A22A;
  font-size: 15px;
}
#HomePage .h8_course-wrap .accordion_left_img {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
#HomePage .h8_course-wrap .accordion_left_img span {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  background: #FFF;
  box-shadow: 0px 6px 30px 0px rgba(25, 35, 53, 0.15);
  display: grid;
  place-items: center;
  color: #021936;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  margin-left: -10px;
}
#HomePage .h8_course-wrap .accordion_right h2 {
  color: #021936;
  font-size: 60px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 0;
  letter-spacing: -2px;
}
#HomePage .h8_course-wrap .accordion_right h2 span {
  color: rgba(2, 25, 54, 0.7);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: normal;
}
#HomePage .h8_course-wrap .inner-info {
  text-align: center;
  width: 95px;
  display: grid;
  place-items: center;
  padding: 10px 10px 15px;
  position: relative;
  z-index: 99;
}
#HomePage .h8_course-wrap .inner-info::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0px 6px 50px 0px rgba(25, 35, 53, 0.1);
  backdrop-filter: blur(20px);
  border: 2px solid #FFF;
  border-radius: 10px;
  z-index: -1;
}
#HomePage .h8_course-wrap .inner-info::before {
  position: absolute;
  left: -20px;
  bottom: -30px;
  content: "";
  width: 70px;
  height: 80px;
  border-radius: 10px;
  opacity: 0.7;
  background: linear-gradient(143deg, #F14D5D 0%, rgba(241, 77, 93, 0) 100%);
  z-index: -2;
}
#HomePage .h8_course-wrap .inner-info-2::before {
  background: linear-gradient(143deg, #2F57EF 0%, rgba(47, 87, 239, 0) 100%);
}
#HomePage .h8_course-wrap .inner-info h3 {
  color: #021936;
  font-size: 50px;
  font-weight: 500;
  margin-bottom: 5px;
  line-height: 1.1;
}
#HomePage .h8_course-wrap .inner-info span {
  color: #021936;
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
}

#HomePage .h10_course-tab .nav {
  justify-content: end;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h10_course-tab .nav {
    justify-content: flex-start;
  }
}
#HomePage .h10_course-tab .nav .nav-item {
  margin-bottom: 10px;
}
#HomePage .h10_course-tab .nav .nav-item .nav-link {
  padding: 0;
  background: transparent;
  color: var(--clr-body-heading);
  font-size: 15px;
  margin-right: 25px;
  display: flex;
  position: relative;
  z-index: 1;
}
#HomePage .h10_course-tab .nav .nav-item .nav-link span {
  position: absolute;
  left: 0;
  top: -25px;
  background-color: var(--clr-theme-primary);
  box-shadow: 0px 3px 3px rgba(18, 104, 235, 0.24);
  color: #fff;
  display: block;
  font-size: 12px;
  padding: 4px 8px;
  line-height: 1;
}
#HomePage .h10_course-tab .nav .nav-item .nav-link span::before {
  position: absolute;
  left: 3px;
  bottom: -6px;
  content: "";
  width: 9px;
  height: 8px;
  background: var(--clr-theme-primary);
  clip-path: polygon(0 0, 50% 100%, 100% 0);
}
#HomePage .h10_course-tab .nav .nav-item:last-child .nav-link {
  margin-right: 0;
}
#HomePage .h10_course-tab .nav-pills .nav-link.active, .h10_course-tab .nav-pills .show > .nav-link {
  color: var(--clr-theme-primary);
}
#HomePage .h10_course-item {
  background: #fff;
  overflow: hidden;
  border: 1px solid rgba(44, 44, 44, 0.1);
  padding: 40px 30px 33px;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
@media (max-width: 767px) {
  .h10_course-item {
    padding: 40px 25px 33px;
  }
}
#HomePage .h10_course-item-img {
  overflow: hidden;
  border-radius: 6px;
  position: relative;
  z-index: 1;
}
#HomePage .h10_course-item-img img {
  width: 100%;
  -webkit-transition: all 0.6s linear 0s;
  -moz-transition: all 0.6s linear 0s;
  -ms-transition: all 0.6s linear 0s;
  -o-transition: all 0.6s linear 0s;
  transition: all 0.6s linear 0s;
}
#HomePage .h10_course-item-img-price {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 9;
}
#HomePage .h10_course-item-img-price span {
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  background-color: var(--clr-theme-primary-10);
  padding: 0 13px;
  height: 24px;
  line-height: 25px;
  border-radius: 4px;
}
#HomePage .h10_course-content {
  position: relative;
  z-index: 1;
  padding-top: 25px;
}
#HomePage .h10_course-content-tag a {
  border-radius: 4px;
  border: 1px solid rgba(44, 44, 44, 0.14);
  color: rgba(44, 44, 44, 0.6);
  padding: 7px 13px;
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
}
#HomePage .h10_course-content-tag a:hover {
  background-color: var(--clr-theme-primary-10);
  color: #fff;
  border-color: transparent;
  opacity: 1;
}
#HomePage .h10_course-content-info {
  margin-bottom: 15px;
}
#HomePage .h10_course-content-info ul li {
  display: inline-block;
  margin-right: 20px;
  font-size: 14px;
  color: rgba(44, 44, 44, 0.6);
}
#HomePage .h10_course-content-info ul li:last-child {
  margin-right: 0;
}
#HomePage .h10_course-content-info ul li i {
  margin-right: 6px;
}
#HomePage .h10_course-content-title {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 18px;
  color: #2C2C2C;
}
#HomePage .h10_course-content-title:hover a {
  color: var(--clr-theme-primary-10);
}
#HomePage .h10_course-content-rating {
  display: flex;
  gap: 10px;
  align-items: center;
}
#HomePage .h10_course-content-rating ul {
  display: flex;
  gap: 5px;
  align-items: center;
}
#HomePage .h10_course-content-rating ul li {
  color: #FFA121;
  font-size: 14px;
}
#HomePage .h10_course-content-rating span {
  font-size: 15px;
  font-weight: 400;
  color: rgba(44, 44, 44, 0.6);
  display: block;
}
#HomePage .h10_course-content-btn a {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #2C2C2C;
}
#HomePage .h10_course-content-btn a:hover {
  color: var(--clr-theme-primary-10);
}

#HomePage .h10_course-item:hover {
  border-color: #4846AB;
  border-style: dashed;
}
#HomePage .h10_course-item:hover .h10_course-item-img img {
  transform: scale(1.2);
}

#HomePage .gap-25 {
  gap: 25px;
  row-gap: 10px;
}

#HomePage .innerPage_course-left p {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
  color: rgba(30, 30, 30, 0.7);
}

#HomePage .innerPage_course-search form {
  position: relative;
  z-index: 1;
}
#HomePage .innerPage_course-search form input {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: #F5F5F5;
  border: none;
  border-radius: 6px;
  padding: 0 20px;
  font-size: 15px;
  color: rgba(30, 30, 30, 0.6);
  padding-right: 55px;
}
#HomePage .innerPage_course-search form input::-webkit-input-placeholder {
  color: rgba(30, 30, 30, 0.6);
  font-size: 15px;
  opacity: 1;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
#HomePage .innerPage_course-search form input:-moz-placeholder {
  color: rgba(30, 30, 30, 0.6);
  font-size: 15px;
  opacity: 1;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
#HomePage .innerPage_course-search form input::-moz-placeholder {
  color: rgba(30, 30, 30, 0.6);
  font-size: 15px;
  opacity: 1;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
#HomePage .innerPage_course-search form input:-ms-input-placeholder {
  color: rgba(30, 30, 30, 0.6);
  font-size: 15px;
  opacity: 1;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
@media (min-width: 992px) {
  .innerPage_course-search form input {
    min-width: 300px;
  }
}
#HomePage .innerPage_course-search form button {
  position: absolute;
  right: 20px;
  top: 0;
  height: 100%;
  font-size: 15px;
  color: #1E1E1E;
  border: none;
  background: transparent;
  padding: 0;
}
#HomePage .innerPage_course-search form button i {
  font-weight: 300;
}

#HomePage .innerPage_course-right {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 15px;
}
@media (max-width: 767px) {
  .innerPage_course-right {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}
#HomePage .innerPage_course-right .nice-select.innerPage_course-category-list.has-nice-select {
  height: 40px;
  line-height: 40px;
  padding: 0 16px;
  width: 190px;
  border-radius: 6px;
  border: 1px solid rgba(30, 30, 30, 0.1);
}
#HomePage .innerPage_course-right .nice-select.innerPage_course-category-list.has-nice-select ul li {
  font-size: 15px;
  font-weight: 400;
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  min-height: 40px;
  color: rgba(30, 30, 30, 0.6);
}
#HomePage .innerPage_course-right .nice-select.innerPage_course-category-list.has-nice-select.open ul {
  width: 100%;
  border-radius: 6px;
  border: none;
  margin-top: 1px;
}
#HomePage .innerPage_course-right .nice-select::after {
  border-bottom: 1.5px solid rgba(30, 30, 30, 0.6);
  border-right: 1.5px solid rgba(30, 30, 30, 0.6);
  content: "";
  display: block;
  height: 7px;
  margin-top: -1px;
  pointer-events: none;
  position: absolute;
  right: 18px;
  top: 50%;
  transform-origin: 50% 50%;
  transform: rotate(45deg) translateY(-50%);
  transition: all 0.3s ease-in-out;
  width: 7px;
}

#HomePage .pagination-area ul {
  display: flex;
  align-items: center;
  gap: 10px;
}
#HomePage .pagination-area ul li a {
  width: 45px;
  height: 45px;
  display: grid;
  place-items: center;
  font-size: 14px;
  font-weight: 500;
  color: rgba(30, 30, 30, 0.6);
  border: 1px solid rgba(30, 30, 30, 0.1);
  border-radius: 4px;
}
#HomePage .pagination-area ul li a:hover {
  background-color: var(--clr-theme-primary);
  color: #fff;
}
#HomePage .pagination-area ul li a i {
  font-size: 15px;
}

/*
************
*************************
09. course-details
******************************************************* 
*************************************************************** */
#HomePage .course_details-img {
  position: relative;
  margin-bottom: -40px;
  z-index: -1;
}
#HomePage .course_details-title {
  font-size: 32px;
  margin-bottom: 15px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .course_details-title {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .course_details-title {
    font-size: 25px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .course_details-title {
    font-size: 30px;
  }
}
#HomePage .course_details-top {
  padding: 27px 40px 35px;
  box-shadow: 0px 20px 80px rgba(30, 30, 30, 0.1);
  border-radius: 0 10px 10px 10px;
  background-color: #fff;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .course_details-top {
    padding: 27px 30px 35px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .course_details-top {
    border-radius: 10px;
  }
}
@media (max-width: 767px) {
  .course_details-top {
    padding: 27px 25px 35px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .course_details-top {
    padding: 27px 30px 35px;
  }
}
#HomePage .course_details-meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
#HomePage .course_details-meta-left {
  display: flex;
  align-items: center;
  gap: 35px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .course_details-meta-left {
    gap: 25px;
  }
}
@media (max-width: 767px) {
  .course_details-meta-left {
    gap: 20px;
    flex-direction: column;
    align-items: flex-start;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .course_details-meta-left {
    gap: 25px;
    flex-direction: row;
    align-items: center;
  }
}
@media (max-width: 767px) {
  .course_details-meta {
    flex-direction: column;
    align-items: flex-start;
  }
}
#HomePage .course_details-author {
  display: flex;
  align-items: center;
  gap: 10px;
}
#HomePage .course_details-author-img {
  width: 50px;
  height: 50px;
  border: 1px solid rgba(4, 0, 23, 0.1);
  border-radius: 50%;
  padding: 4px;
}
#HomePage .course_details-author-img img {
  width: 100%;
}
#HomePage .course_details-author-info span {
  font-size: 14px;
  font-weight: 500;
  color: #848B97;
  display: block;
  line-height: 20px;
}
#HomePage .course_details-author-info h5 {
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 500;
  line-height: 1.4;
}
#HomePage .course_details-author-info h5:hover a {
  color: var(--clr-theme-primary);
}
#HomePage .course_details-rating {
  position: relative;
  z-index: 1;
  padding-left: 15px;
}
#HomePage .course_details-rating::before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 1px;
  background: rgba(4, 0, 23, 0.14);
  z-index: 1;
}
@media (max-width: 767px) {
  .course_details-rating {
    padding-left: 0;
  }
  .course_details-rating::before {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .course_details-rating {
    padding-left: 15px;
  }
  .course_details-rating::before {
    display: block;
  }
}
#HomePage .course_details-rating span {
  font-size: 14px;
  font-weight: 500;
  display: block;
  color: #848B97;
  line-height: 22px;
}
#HomePage .course_details-rating ul {
  display: flex;
  gap: 5px;
}
#HomePage .course_details-rating ul li {
  display: block;
  font-size: 12px;
  color: #FFA121;
  line-height: 20px;
}
#HomePage .course_details-category {
  position: relative;
  z-index: 1;
  padding-left: 15px;
}
#HomePage .course_details-category::before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 40px;
  width: 1px;
  background: rgba(4, 0, 23, 0.14);
  z-index: 1;
}
@media (max-width: 767px) {
  .course_details-category {
    padding-left: 0;
  }
  .course_details-category::before {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .course_details-category {
    padding-left: 15px;
  }
  .course_details-category::before {
    display: block;
  }
}
#HomePage .course_details-category span {
  font-size: 14px;
  font-weight: 500;
  color: #848B97;
  display: block;
  line-height: 20px;
}
#HomePage .course_details-category h5 {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 1.4;
}
#HomePage .course_details-category h5:hover a {
  color: var(--clr-theme-primary);
}

#HomePage .course_details-tab-button {
  margin-bottom: 30px;
}
#HomePage .course_details-tab-button .nav {
  gap: 10px;
  flex-wrap: nowrap;
}
#HomePage .course_details-tab-button .nav .nav-item {
  width: 25%;
}
#HomePage .course_details-tab-button .nav .nav-item .nav-link {
  background: #F5F5F5;
  height: 55px;
  line-height: 55px;
  border: 1px solid rgba(30, 30, 30, 0.1);
  padding: 0;
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  justify-content: center;
  color: var(--clr-body-heading);
  font-size: 15px;
  font-weight: 500;
}
@media (max-width: 767px) {
  .course_details-tab-button .nav .nav-item .nav-link span {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .course_details-tab-button .nav .nav-item .nav-link span {
    display: block;
  }
}
#HomePage .course_details-tab-button .nav .nav-item .nav-link i {
  font-weight: 300;
}

#HomePage .course_details-tab-button .nav-pills .nav-link.active, .course_details-tab-button .nav-pills .show > .nav-link {
  color: var(--clr-body-heading);
  background-color: #fff;
  border-top: 2px solid var(--clr-theme-primary);
}
#HomePage .course_details-tab-button .nav-pills .nav-link.active i, .course_details-tab-button .nav-pills .show > .nav-link i {
  color: var(--clr-theme-primary);
}

#HomePage .course_details-content-title {
  font-size: 24px;
  line-height: 1.4;
}
#HomePage .course_details-content p {
  color: rgba(30, 30, 30, 0.7);
}
#HomePage .course_details-content-list ul li {
  display: block;
  position: relative;
  z-index: 1;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
  padding-left: 13px;
  line-height: 26px;
}
#HomePage .course_details-content-list ul li:last-child {
  margin-bottom: 0;
}
#HomePage .course_details-content-list ul li::before {
  position: absolute;
  left: 0;
  top: 50%;
  content: "";
  width: 4px;
  height: 4px;
  background-color: var(--clr-body-heading);
  border-radius: 50%;
  transform: translateY(-50%);
}

#HomePage .course_details-sidebar {
  background: #fff;
  box-shadow: 0px 20px 80px rgba(30, 30, 30, 0.1);
  padding: 45px 0 60px;
  border-radius: 10px 0 10px 10px;
}
#HomePage .course_details-sidebar-btn {
  margin-left: 40px;
  margin-right: 40px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .course_details-sidebar-btn {
    margin-left: 30px;
    margin-right: 30px;
  }
}
@media (max-width: 767px) {
  .course_details-sidebar-btn {
    margin-left: 25px;
    margin-right: 25px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .course_details-sidebar-btn {
    margin-left: 30px;
    margin-right: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .course_details-sidebar {
    border-radius: 10px;
  }
}
#HomePage .course_details-price {
  padding-left: 40px;
  padding-right: 40px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .course_details-price {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (max-width: 767px) {
  .course_details-price {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .course_details-price {
    padding-left: 30px;
    padding-right: 30px;
  }
}
#HomePage .course_details-price del {
  display: block;
  font-size: 16px;
  margin-bottom: 4px;
}
#HomePage .course_details-price h2 {
  font-size: 44px;
  font-weight: 600;
  margin-bottom: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .course_details-price h2 {
    font-size: 38px;
  }
}
@media (max-width: 767px) {
  .course_details-price h2 {
    font-size: 35px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .course_details-price h2 {
    font-size: 40px;
  }
}
#HomePage .course_details-list ul {
  margin-bottom: 40px;
}
#HomePage .course_details-list ul li {
  border-bottom: 1px solid rgba(30, 30, 30, 0.1);
  font-size: 16px;
  font-weight: 500;
  padding: 10px 40px;
  color: rgba(30, 30, 30, 0.7);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#HomePage .course_details-list ul li:first-child {
  border-top: 1px solid rgba(30, 30, 30, 0.1);
}
#HomePage .course_details-list ul li span i {
  margin-right: 10px;
  color: var(--clr-theme-primary);
  font-size: 16px;
  font-weight: 300;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .course_details-list ul li {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (max-width: 767px) {
  .course_details-list ul li {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .course_details-list ul li {
    padding-left: 30px;
    padding-right: 30px;
  }
}

#HomePage .course_details-instructor {
  display: flex;
  gap: 30px;
}
@media (max-width: 360px) {
  .course_details-instructor {
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .course_details-instructor {
    gap: 20px;
  }
}
#HomePage .course_details-thumbnail {
  min-width: 200px;
}
@media (max-width: 767px) {
  .course_details-thumbnail {
    min-width: 120px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .course_details-thumbnail {
    min-width: 170px;
  }
}
#HomePage .course_details-author-title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 5px;
}
#HomePage .course_details-author-subtitle {
  display: block;
  margin-bottom: 15px;
}
#HomePage .course_details-author-content p {
  margin-bottom: 15px;
}
#HomePage .course_details-social span {
  color: #747474;
  font-weight: 500;
  line-height: 30px;
  display: block;
  font-size: 16px;
  margin-bottom: 10px;
}
#HomePage .course_details-social ul {
  display: flex;
  gap: 10px;
  align-items: center;
}
#HomePage .course_details-social ul li a {
  display: grid;
  width: 40px;
  height: 40px;
  border: 1px solid rgba(30, 30, 30, 0.1);
  place-items: center;
  border-radius: 4px;
  color: rgba(30, 30, 30, 0.3);
  font-size: 15px;
}
#HomePage .course_details-social ul li a:hover {
  background: var(--clr-theme-primary);
  color: #fff;
}

#HomePage .course_details-review-content ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#HomePage .course_details-review-content ul li .review-rating {
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--clr-body-heading);
}

#HomePage .course_details-review-content ul li .review-rating i {
  color: #f8b81f;
  font-size: 12px;
}

#HomePage .course_details-review-content ul li .review-progress-bar {
  width: 100%;
  height: 5px;
  background: var(--clr-theme-primary);
  border-radius: 4px;
  margin-left: 30px;
  margin-right: 30px;
}

#HomePage .course_details-review-content ul li .review-rating-count {
  color: var(--clr-body-heading);
  font-size: 14px;
}

#HomePage .course_details-review-left h5 {
  font-size: 60px;
  margin-bottom: 0;
  font-weight: 600;
}

#HomePage .course_details-review-left {
  display: grid;
  place-items: center;
}
@media (max-width: 767px) {
  .course_details-review-left {
    margin-bottom: 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .course_details-review-left {
    margin-bottom: 0px;
  }
}

#HomePage .course_details-review-left ul {
  display: flex;
  align-items: center;
  gap: 5px;
}

#HomePage .course_details-review-left ul li i {
  font-size: 14px;
  color: #f8b81f;
}

#HomePage .course_details-review-left p {
  font-size: 14px;
  margin-bottom: 0;
}

#HomePage .course_details-review-wrap {
  padding: 40px 40px;
  padding-left: 30px;
  border: 1px solid rgba(4, 0, 23, 0.1);
  border-radius: 10px;
}

#HomePage .course_details-review-title {
  font-size: 22px;
  margin-bottom: 25px;
}

#HomePage .course_details-curriculum .accordion .accordion-item {
  border: 1px solid rgba(4, 0, 23, 0.1);
  margin-bottom: 30px;
  border-radius: 4px;
}

#HomePage .course_details-curriculum .accordion .accordion-header .accordion-button {
  border: 0;
  background: transparent;
  padding-left: 30px;
  padding-right: 50px;
  height: 70px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  border: none;
  border-radius: 0;
  box-shadow: none;
  color: var(--clr-body-heading);
}
@media (max-width: 767px) {
  .course_details-curriculum .accordion .accordion-header .accordion-button {
    font-size: 16px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .course_details-curriculum .accordion .accordion-header .accordion-button {
    font-size: 18px;
  }
}
#HomePage .course_details-curriculum .accordion .accordion-header .accordion-button:not(.collapsed) {
  color: var(--clr-theme-primary);
  border-bottom: 1px solid rgba(4, 0, 23, 0.1);
}

#HomePage .course_details-curriculum .accordion .accordion-body {
  padding: 25px 30px;
  line-height: 1.5;
}

#HomePage .course_details-curriculum .accordion-button::after {
  display: none;
}
#HomePage .course_details-curriculum .accordion-button {
  position: relative;
}
#HomePage .course_details-curriculum .accordion-button::before {
  position: absolute;
  right: 0;
  top: 0;
  content: "\f067";
  font-family: "Font Awesome 5 Pro";
  padding: 0 30px;
  font-size: 16px;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  font-weight: 300;
  height: 70px;
  line-height: 70px;
}
#HomePage .course_details-curriculum .accordion-button:not(.collapsed)::before {
  content: "\f068";
  transform: rotate(-180deg);
}
#HomePage .course_details-curriculum .accordion-body ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  gap: 30px;
}
#HomePage .course_details-curriculum .accordion-body ul li a {
  display: flex;
  align-items: center;
  gap: 10px;
}
#HomePage .course_details-curriculum .accordion-body ul li span {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 14px;
  color: var(--clr-body-text);
}
#HomePage .course_details-curriculum .accordion-body ul li a i {
  font-size: 14px;
}

/*
************
*************************
10. button
******************************************************* 
*************************************************************** */
#HomePage .theme-btn {
  background: var(--clr-theme-primary);
  color: var(--clr-common-white);
  display: inline-flex;
  align-items: center;
  height: 40px;
  padding: 0 25px;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  cursor: pointer;
  margin-bottom: 0;
  text-align: center;
  touch-action: manipulation;
  transition: all 0.3s ease 0s;
  white-space: nowrap;
  border: none;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
#HomePage .theme-btn::after {
  content: "";
  position: absolute;
  top: 10px;
  left: 0;
  width: 500px;
  height: 200px;
  background-color: #08449E;
  border-color: transparent;
  border-radius: 100px;
  transform: translate(-40px, -80px) scale(0.1);
  opacity: 0;
  z-index: -1;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}

#HomePage .theme-btn:hover {
  color: #fff;
}
#HomePage .theme-btn:hover::after {
  opacity: 0.5;
  transform-origin: 100px 100px;
  transform: scale(1) translate(-10px, -70px);
}

#HomePage .theme-btn-big {
  height: 55px;
  line-height: 55px;
  padding: 0 30px;
}

#HomePage .theme-btn-medium {
  height: 45px !important;
  line-height: 45px !important;
  padding: 0 20px;
}

#HomePage .theme-btn-full {
  width: 100%;
  justify-content: center;
}

#HomePage .theme-btn-3 {
  border-radius: 6px;
  background: var(--clr-theme-primary-3);
  box-shadow: 0px 2px 5px 0px rgba(32, 42, 61, 0.2);
}
#HomePage .theme-btn-3::after {
  background-color: #a73301;
}
#HomePage .theme-btn-3 i {
  margin-left: 5px;
}

#HomePage .theme-btn-4 {
  border-radius: 100px;
  background: var(--clr-theme-primary-4);
  color: var(--clr-body-heading);
  font-size: 15px;
  font-weight: 500;
}
#HomePage .theme-btn-4::after {
  color: var(--clr-body-heading);
  background-color: #c79216;
}
#HomePage .theme-btn-4:hover {
  color: var(--clr-body-heading);
}

#HomePage .theme-btn-5 {
  border-radius: 0;
  background: var(--clr-theme-primary-5);
  color: var(--clr-body-heading);
  font-size: 16px;
  font-weight: 600;
}
#HomePage .theme-btn-5::after {
  color: var(--clr-body-heading);
  background-color: #c7c108;
}
#HomePage .theme-btn-5:hover {
  color: var(--clr-body-heading);
}

#HomePage .theme-btn-6 {
  border-radius: 0;
  background: var(--clr-theme-primary-6);
}
#HomePage .theme-btn-6::after {
  background-color: #8b030a;
}
#HomePage .theme-btn-6 i {
  margin-left: 5px;
}

#HomePage .theme-btn-8 {
  border-radius: 6px;
  background: #395BDF;
  font-weight: 500;
  font-size: 15px;
  height: 50px;
}
#HomePage .theme-btn-8::after {
  background-color: #8b030a;
}
#HomePage .theme-btn-8 i {
  margin-left: 5px;
}

#HomePage .theme-btn-9 {
  border-radius: 6px;
  background: #1865F2;
  font-weight: 500;
  font-size: 15px;
  height: 50px;
}
#HomePage .theme-btn-9::after {
  background-color: #8b030a;
}
#HomePage .theme-btn-9 i {
  margin-left: 5px;
}

#HomePage .theme-btn-7 {
  height: 50px;
  padding: 0 35px;
  border-radius: 0;
  background: var(--clr-theme-primary-6);
}
#HomePage .theme-btn-7::after {
  background-color: #8b030a;
}
#HomePage .theme-btn-7 i {
  margin-left: 5px;
}
#HomePage .theme-btn-7-yellow {
  background-color: #F4B826;
  color: #17161C;
}

#HomePage .h7_header-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  padding: 0 30px;
  color: #fff;
  font-size: 16px;
  height: 45px;
  font-weight: 600;
  background-color: var(--clr-theme-primary-6);
}
#HomePage .h7_header-btn svg {
  color: #fff;
}

#HomePage .theme-btn-10 {
  height: 50px;
  padding: 0 25px;
  border-radius: 0;
  background-color: var(--clr-theme-primary-10);
  border-radius: 4px;
  gap: 7px;
}
#HomePage .theme-btn-10::after {
  background-color: #4240A6;
}
#HomePage .theme-btn-10 i {
  margin-left: 5px;
}
#HomePage .theme-btn-10-transparent {
  background-color: transparent;
  border: 1px solid #fff;
}
#HomePage .theme-btn-10-transparent:hover {
  background-color: #fff;
  color: var(--clr-theme-primary-10);
  border-color: #fff;
}
#HomePage .theme-btn-10-transparent::after {
  display: none;
}
#HomePage .theme-btn-10-white {
  background-color: #fff;
  color: var(--clr-theme-primary-10);
}

#HomePage .h3_banner-form-btn {
  padding: 0 35px;
}

#HomePage .h4_about-btn,
#HomePage .h4_blog-btn {
  height: 50px;
}

#HomePage .t-theme-btn {
  background-color: #fff;
  border: 1px solid rgba(30, 30, 30, 0.1);
  color: var(--clr-body-heading);
  padding: 0 20px;
}
#HomePage .t-theme-btn:hover {
  background-color: var(--clr-theme-primary);
  color: #fff;
}
#HomePage .t-theme-btn::after {
  display: none;
}

#HomePage .h5_play-btn {
  width: 150px;
  height: 150px;
  background: var(--clr-theme-primary-5);
  text-align: center;
  border-radius: 50%;
  transition: all 0.3s ease;
  display: grid;
  place-items: center;
  color: var(--clr-body-heading);
  font-size: 25px;
}
#HomePage .h5_play-btn::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  border-radius: 50%;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(5px);
  animation-name: popupBtn;
  animation-duration: 1.8s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  z-index: -1;
}
#HomePage .h5_play-btn:hover {
  background-color: var(--clr-theme-primary-4);
  color: var(--clr-body-heading);
}

@keyframes popupBtn {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    transform: scale(1.4);
    opacity: 0.3;
  }
  100% {
    transform: scale(1.8);
    opacity: 0;
  }
}
/*
************
*************************
11. about
******************************************************* 
*************************************************************** */
#HomePage .about-img {
  margin-right: 40px;
  position: relative;
  z-index: 1;
}
#HomePage .about-img img {
  width: 100%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-img {
    margin-right: 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .about-img {
    margin-right: 0;
  }
}
#HomePage .about-img-meta {
  position: absolute;
  left: 235px;
  bottom: 45px;
  background: #fff;
  border-radius: 6px;
  padding: 25px 30px 25px;
  box-shadow: 0px 10px 40px rgba(30, 30, 30, 0.1);
  animation: about-upDown-1 3s linear 0s infinite alternate;
  z-index: 1;
}
#HomePage .about-img-meta span {
  font-size: 15px;
  display: flex;
  font-weight: 500;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
}
#HomePage .about-img-meta span i {
  color: #FFA121;
  font-size: 15px;
}
#HomePage .about-img-meta h5 {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 500;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-img-meta {
    left: 150px;
  }
}
@media (max-width: 767px) {
  .about-img-meta {
    padding: 15px 20px;
    left: 130px;
  }
  .about-img-meta span {
    font-size: 12px;
    margin-bottom: 0;
  }
  .about-img-meta span i {
    font-size: 12px;
  }
  .about-img-meta h5 {
    font-size: 15px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-img-meta {
    padding: 25px 30px 25px;
    left: 230px;
  }
  .about-img-meta span {
    font-size: 15px;
    margin-bottom: 5px;
  }
  .about-img-meta span i {
    font-size: 15px;
  }
  .about-img-meta h5 {
    font-size: 18px;
  }
}

#HomePage .about-content-list {
  margin-bottom: 41px;
}
#HomePage .about-content-list ul li {
  color: var(--clr-body-heading);
  font-size: 15px;
  font-weight: 500;
  position: relative;
  z-index: 1;
  padding-left: 15px;
  margin-bottom: 5px;
  line-height: 28px;
}
#HomePage .about-content-list ul li::before {
  position: absolute;
  left: 0;
  top: 12px;
  content: "";
  width: 4px;
  height: 4px;
  background-color: var(--clr-body-heading);
  border-radius: 50%;
}
#HomePage .about-content-list ul li:last-child {
  margin-bottom: 0;
}

@keyframes about-upDown-1 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(40px);
  }
}
#HomePage .h2_about-area {
  position: relative;
  z-index: 1;
}
#HomePage .h2_about-top-shape {
  position: absolute;
  z-index: 1;
  top: 135px;
  right: 145px;
  animation: animation-upDown-2 3s linear 0s infinite alternate;
}
#HomePage .h2_about-img {
  position: relative;
  z-index: 1;
  padding-bottom: 120px;
}
#HomePage .h2_about-img-button a {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  right: 0;
  text-align: center;
  width: 90px;
  height: 90px;
  background-color: #fff;
  border-radius: 50%;
  line-height: 90px;
  font-size: 25px;
  color: var(--clr-body-heading);
  box-shadow: 0px 2px 0px #A5A6A9;
}
@media (max-width: 767px) {
  .h2_about-img-button a {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
  }
}
#HomePage .h2_about-img-button a:hover {
  background-color: var(--clr-theme-primary);
  box-shadow: 0px 2px 0px var(--clr-theme-primary);
  color: #fff;
}
#HomePage .h2_about-inner-img {
  position: relative;
  z-index: 1;
}
#HomePage .h2_about-inner-img .h2_inner-img {
  border-radius: 10px;
}
#HomePage .h2_about-inner-img2 {
  position: absolute;
  right: 50px;
  top: 120px;
  z-index: 9;
  border-radius: 10px;
  overflow: hidden;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h2_about-inner-img2 {
    right: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h2_about-inner-img2 {
    right: 100px;
  }
}
@media (max-width: 767px) {
  .h2_about-inner-img2 {
    right: 0;
    top: auto;
    bottom: 0;
    margin-left: 20%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h2_about-inner-img2 {
    right: 0;
    top: 120px;
  }
}
#HomePage .h2_about-shape-1 {
  position: absolute;
  right: -10px;
  top: 0;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h2_about-shape-1 {
    width: 40%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h2_about-shape-1 {
    right: -20px;
    z-index: 1;
    width: 26%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h2_about-shape-1 {
    right: 100px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h2_about-shape-1 {
    right: 0;
    width: 30%;
  }
}
#HomePage .h2_about-shape-2 {
  position: absolute;
  left: -65px;
  top: -25px;
  z-index: 1;
  animation: animation-leftRight-1 3s linear 0s infinite alternate;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .h2_about-shape-2 {
    left: 0;
  }
}
#HomePage .h2_about-shape-3 {
  position: absolute;
  left: 145px;
  bottom: 20px;
  animation: animation-popup-1 3s linear 0s infinite alternate;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h2_about-shape-3 {
    left: 120px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h2_about-shape-3 {
    left: 30px;
  }
}
#HomePage .h2_about-rating {
  position: absolute;
  left: 105px;
  bottom: 65px;
  background: #fff;
  border-radius: 10px;
  padding: 25px 30px 25px;
  box-shadow: 0px 16px 70px rgba(30, 30, 30, 0.14);
  z-index: 9;
  animation: animation-upDown-1 3s linear 0s infinite alternate;
}
#HomePage .h2_about-rating span {
  font-size: 15px;
  display: flex;
  font-weight: 500;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
}
#HomePage .h2_about-rating span i {
  color: #FFA121;
  font-size: 15px;
}
#HomePage .h2_about-rating h5 {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 500;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h2_about-rating {
    left: 50px;
  }
}

#HomePage .h2_inner-img-shape {
  position: absolute;
  left: -30px;
  bottom: -30px;
  z-index: 1;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h2_inner-img-shape {
    left: -20px;
    bottom: -20px;
  }
}
@media (max-width: 767px) {
  .h2_inner-img-shape {
    left: -10px;
    bottom: -10px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h2_inner-img-shape {
    left: -15px;
    bottom: -15px;
  }
}

#HomePage .h3_about-area {
  position: relative;
  z-index: 1;
}
#HomePage .h3_about-top-shape {
  position: absolute;
  top: 280px;
  right: 80px;
  animation: animation-upDown-1 2s linear 0s infinite alternate;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h3_about-top-shape {
    top: 230px;
    right: 40px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h3_about-top-shape {
    display: none;
  }
}
#HomePage .h3_about-img {
  position: relative;
  z-index: 1;
}
#HomePage .h3_about-img-shape-1 {
  position: absolute;
  top: 65px;
  left: 0;
  animation: animation-upDown-1 2s linear 0s infinite alternate;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h3_about-img-shape-1 {
    top: 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h3_about-img-shape-1 {
    top: 0;
  }
}
#HomePage .h3_about-img-shape-2 {
  position: absolute;
  top: 5px;
  right: -50px;
  animation: animation-leftRight-1 2s linear 0s infinite alternate;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h3_about-img-shape-2 {
    right: 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h3_about-img-shape-2 {
    right: 0;
  }
}
#HomePage .h3_about-img-shape-3 {
  position: absolute;
  bottom: 20px;
  left: -140px;
  animation: animation-leftRight-1 2s linear 0s infinite alternate;
}
@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .h3_about-img-shape-3 {
    left: -100px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h3_about-img-shape-3 {
    left: -40px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h3_about-img-shape-3 {
    left: -20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h3_about-img-shape-3 {
    left: 0;
    bottom: -35px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h3_about-img-shape-3 {
    left: 0;
    bottom: 0;
  }
}
#HomePage .h3_about-wrap {
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h3_about-wrap {
    margin-right: 0;
  }
}
#HomePage .h3_about-wrap-shape {
  position: absolute;
  right: 0;
  bottom: 25px;
  animation: animation-popup-1 2s linear 0s infinite alternate;
}
#HomePage .h3_about-content {
  max-width: 500px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h3_about-content {
    max-width: 100%;
  }
}
#HomePage .h3_about-content span {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--clr-body-heading);
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 15px;
  line-height: 1;
}
#HomePage .h3_about-content span i {
  background: linear-gradient(#20E448, #139E30);
  display: inline-grid;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: #fff;
  place-items: center;
  font-size: 10px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h3_about-inner-img {
    margin-right: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h3_about-inner-img {
    margin-right: 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h3_about-inner-img {
    margin-right: 0;
  }
}

#HomePage .h4_about-img {
  margin-right: -30px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h4_about-img {
    margin-right: -20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h4_about-img {
    margin-right: 0;
  }
}
#HomePage .h4_about-wrap {
  margin-left: 80px;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h4_about-wrap {
    margin-left: 60px;
    margin-right: 50;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h4_about-wrap {
    margin-left: 30px;
    margin-right: 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h4_about-wrap {
    margin-left: 0;
    margin-right: 0;
  }
}
#HomePage .h4_about-content ul li {
  border-radius: 30px;
  background: linear-gradient(90deg, rgba(246, 124, 55, 0.14) 0%, rgba(246, 124, 55, 0) 100%);
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 15px;
  color: #F67C37;
  width: max-content;
}
#HomePage .h4_about-content ul li i {
  width: 24px;
  height: 24px;
  background-color: #F67C37;
  border-radius: 50%;
  color: #fff;
  display: grid;
  place-items: center;
  flex-shrink: 0;
  font-size: 12px;
}
#HomePage .h4_about-content ul li:nth-child(2) {
  background: linear-gradient(90deg, rgba(21, 105, 253, 0.14) 0%, rgba(21, 105, 253, 0) 100%);
  color: #1569FD;
}
#HomePage .h4_about-content ul li:nth-child(2) i {
  background-color: #1569FD;
}
#HomePage .h4_about-content ul li:last-child {
  background: linear-gradient(90deg, rgba(246, 55, 88, 0.14) 0%, rgba(246, 55, 88, 0) 100%);
  color: #F63758;
}
#HomePage .h4_about-content ul li:last-child i {
  background-color: #F63758;
}
#HomePage .h4_about-button {
  display: flex;
  align-items: center;
  gap: 30px;
}
@media (max-width: 767px) {
  .h4_about-button {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h4_about-button {
    flex-direction: row;
    gap: 30px;
    align-items: center;
  }
}
#HomePage .h4_about-button-call {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 15px;
  color: var(--clr-body-heading);
  font-weight: 500;
}
#HomePage .h4_about-button-call i {
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 100px;
  background: #F6BA2A;
  display: grid;
  place-items: center;
  color: var(--clr-body-heading);
  font-size: 14px;
}

#HomePage .h5_about-button-call i {
  background: var(--clr-theme-primary-5);
}

#HomePage .h6_about-img {
  position: relative;
  z-index: 1;
  padding-bottom: 40px;
  transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  clip-path: inset(0 0 0 0);
}
#HomePage .h6_about-img img {
  transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transform: scale(1);
  width: 100%;
}
#HomePage .h6_about-img:hover {
  clip-path: inset(8px 8px 8px 8px);
}
#HomePage .h6_about-img:hover img {
  transform: scale(1.05);
}
#HomePage .h6_about-img-content {
  position: absolute;
  left: 0;
  bottom: 0;
  background: #fff;
  padding: 22px 35px 26px 0;
}
@media (max-width: 767px) {
  .h6_about-img-content {
    padding: 22px 25px 26px 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h6_about-img-content {
    padding: 22px 35px 26px 0;
  }
}
#HomePage .h6_about-img-content h2 {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #5C5C5C;
  font-size: 100px;
  font-weight: 800;
  line-height: 1;
  letter-spacing: -5px;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .h6_about-img-content h2 {
    font-size: 45px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h6_about-img-content h2 {
    font-size: 100px;
  }
}
#HomePage .h6_about-img-content span {
  color: #525252;
  font-size: 24px;
  font-weight: 400;
  position: relative;
  z-index: 1;
  padding-left: 150px;
}
#HomePage .h6_about-img-content span::before {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  background: #D9D9D9;
  width: 130px;
  height: 1px;
  z-index: 1;
}
@media (max-width: 767px) {
  .h6_about-img-content span {
    font-size: 16px;
    padding-left: 50px;
  }
  .h6_about-img-content span::before {
    width: 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h6_about-img-content span {
    font-size: 24px;
    padding-left: 150px;
  }
  .h6_about-img-content span::before {
    width: 130px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h6_about-content.ml-30 {
    margin-left: 10px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h6_about-content.ml-30 {
    margin-left: 0;
  }
}
#HomePage .h6_about-content-title {
  font-size: 36px;
  font-weight: 500;
  line-height: 1.25;
  margin-bottom: 22px;
}
@media (max-width: 767px) {
  .h6_about-content-title {
    font-size: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h6_about-content-title {
    font-size: 36px;
  }
}
#HomePage .h6_about-content p {
  font-size: 16px;
  color: #666;
  margin-bottom: 14px;
}
#HomePage .h6_about-btn {
  margin-top: 28px;
}
#HomePage .h6_about-bottom-title {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #C5C5C5;
  font-size: 120px;
  font-weight: 600;
  line-height: 1.12;
  text-align: center;
  margin-right: 100px;
  width: max-content !important;
}
@media (max-width: 767px) {
  .h6_about-bottom-title {
    font-size: 80px;
  }
}

#HomePage .h7_about-img {
  position: relative;
  z-index: 1;
}
#HomePage .h7_about-img-content {
  position: absolute;
  left: 0;
  bottom: 0;
  background: #fff;
  padding: 22px 35px 26px 0;
}
@media (max-width: 767px) {
  .h7_about-img-content {
    padding: 22px 25px 26px 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h7_about-img-content {
    padding: 22px 35px 26px 0;
  }
}
#HomePage .h7_about-img-content h2 {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #5C5C5C;
  font-size: 100px;
  font-weight: 800;
  line-height: 1;
  letter-spacing: -5px;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .h7_about-img-content h2 {
    font-size: 45px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h7_about-img-content h2 {
    font-size: 100px;
  }
}
#HomePage .h7_about-img-content span {
  color: #525252;
  font-size: 24px;
  font-weight: 400;
  position: relative;
  z-index: 1;
  padding-left: 150px;
}
#HomePage .h7_about-img-content span::before {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  background: #D9D9D9;
  width: 130px;
  height: 1px;
  z-index: 1;
}
@media (max-width: 767px) {
  .h7_about-img-content span {
    font-size: 16px;
    padding-left: 50px;
  }
  .h7_about-img-content span::before {
    width: 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h7_about-img-content span {
    font-size: 24px;
    padding-left: 150px;
  }
  .h7_about-img-content span::before {
    width: 130px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h7_about-content.ml-30 {
    margin-left: 10px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h7_about-content.ml-30 {
    margin-left: 0;
  }
}
#HomePage .h7_about-content-title {
  font-size: 36px;
  font-weight: 500;
  line-height: 1.25;
  margin-bottom: 22px;
}
@media (max-width: 767px) {
  .h7_about-content-title {
    font-size: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h7_about-content-title {
    font-size: 36px;
  }
}
#HomePage .h7_about-content p {
  font-size: 16px;
  color: #666;
  margin-bottom: 14px;
}
#HomePage .h7_about-btn {
  margin-top: 28px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h7_about-wrap.mr-70 {
    margin-right: 40px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h7_about-wrap.mr-70 {
    margin-right: 0;
  }
}

#HomePage .h7_about-img a {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
#HomePage .h7_about-img a svg {
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
@media (max-width: 767px) {
  .h7_about-img a svg {
    width: 80px;
    height: 80px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h7_about-img a svg {
    width: 100px;
    height: 100px;
  }
}
#HomePage .h7_about-img a i {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 22px;
  color: var(--clr-theme-primary-6);
}
#HomePage .h7_about-img a:hover svg {
  transform: rotate(180deg);
}

#HomePage .h7_about-content {
  display: flex;
  align-items: center;
  gap: 90px;
}
@media (max-width: 767px) {
  .h7_about-content {
    flex-wrap: wrap;
    gap: 20px;
  }
}

#HomePage .h7_about-admin {
  display: flex;
  gap: 20px;
  align-items: center;
}

#HomePage .h7_about-admin-info h5 {
  color: #17161C;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 1;
}

#HomePage .h7_about-admin-img {
  width: 55px;
  height: 55px;
  flex-shrink: 0;
}

#HomePage .h7_about-admin-info span {
  color: #17161C;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
}

#HomePage .h8_about-img {
  display: flex;
  align-items: start;
  grid-gap: 30px;
}
@media (max-width: 767px) {
  .h8_about-img {
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h8_about-img {
    flex-wrap: nowrap;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h8_about-img.mr-35 {
    margin-right: 20px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h8_about-img.mr-35 {
    margin-right: 10px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h8_about-img.mr-35 {
    margin-right: 0;
  }
}
#HomePage .h8_about-img-left {
  position: relative;
  z-index: 1;
  padding-top: 150px;
  width: 54%;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .h8_about-img-left {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .h8_about-img-left {
    width: 100%;
  }
}
#HomePage .h8_about-img-left img {
  border-radius: 10px;
}
#HomePage .h8_about-img-right {
  position: relative;
  z-index: 1;
  width: 46%;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .h8_about-img-right {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .h8_about-img-right {
    width: 100%;
  }
}
#HomePage .h8_about-img-right img {
  border-radius: 10px;
  margin-bottom: 30px;
}
#HomePage .h8_about-img-year {
  position: absolute;
  right: 0;
  top: 0;
  background: #F14D5D;
  padding: 30px 55px;
  border-radius: 10px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h8_about-img-year {
    padding: 30px 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), (max-width: 767px) {
  .h8_about-img-year {
    padding: 30px 20px;
    width: 100%;
  }
}
#HomePage .h8_about-img-year h3 {
  color: #FFF;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 0;
}
#HomePage .h8_about-img-year h3 span {
  color: rgba(255, 255, 255, 0.9);
  font-size: 20px;
  font-weight: 400;
  display: block;
  line-height: 1.2;
  margin-top: 6px;
}
#HomePage .h8_about-img-count {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 6px 100px 0px rgba(8, 5, 33, 0.1);
  padding: 20px 20px;
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
}
#HomePage .h8_about-img-count img {
  flex-shrink: 0;
  margin-bottom: 0;
}
#HomePage .h8_about-img-count-info h3 {
  color: #021936;
  font-size: 36px;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 0;
}
#HomePage .h8_about-img-count-info p {
  color: #021936;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 0;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h8_about-wrap.ml-30 {
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h8_about-wrap.ml-30 {
    margin-left: 10px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h8_about-wrap.ml-30 {
    margin-left: 0;
  }
}

#HomePage .h8_about-content {
  display: flex;
  gap: 35px;
  margin-bottom: 50px;
}
@media (max-width: 767px) {
  .h8_about-content {
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h8_about-content {
    flex-wrap: nowrap;
  }
}

#HomePage .h8_about-content-item {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 6px 100px 0px rgba(8, 5, 33, 0.1);
  padding: 25px 25px 20px;
  position: relative;
  z-index: 1;
}

#HomePage .h8_about-icon {
  position: absolute;
  right: 35px;
  top: 25px;
}

#HomePage .h8_about-content-item-icon svg {
  color: #395BDF;
}

#HomePage .h8_about-content-item-icon {
  margin-bottom: 20px;
}

#HomePage .h8_about-content-item-title {
  color: #021936;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 10px;
}

#HomePage .h8_about-content-item-text {
  color: rgba(2, 25, 54, 0.7);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 0;
}

#HomePage .h8_about-icon svg {
  color: #395BDF;
}

#HomePage .h8_about-content-item .h8_about-content-item-icon > svg {
  transition: all 0.3s linear 0s;
}

#HomePage .h8_about-content-item:hover .h8_about-content-item-icon > svg {
  transform: scale(1.1) translateY(-5px);
}

#HomePage .h10_about-area {
  overflow-x: hidden;
}

#HomePage .h10_about-img {
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h10_about-img {
    text-align: center;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h10_about-img.mr-25 {
    margin-right: 0;
  }
}

#HomePage .h10_about-img-shape {
  position: absolute;
  top: 20px;
  left: -45px;
  animation: animation-leftRight-1 3s linear 0s infinite alternate;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h10_about-img-shape {
    left: 0;
  }
}

#HomePage .h10_about-img-shape-1 {
  position: absolute;
  left: -90px;
  top: 150px;
  animation: animation-upDown-2 3s linear 0s infinite alternate;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h10_about-img-shape-1 {
    left: -45px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h10_about-img-shape-1 {
    left: -35px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h10_about-img-shape-1 {
    left: -25px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h10_about-img-shape-1 {
    left: 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h10_about-content.ml-35 {
    margin-left: 0;
  }
}

#HomePage .h10_about-content-title {
  color: #111720;
  font-size: 44px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 25px;
}
@media (max-width: 767px) {
  .h10_about-content-title {
    font-size: 34px;
  }
}

#HomePage .h10_about-content-inner {
  margin-bottom: 27px;
}
#HomePage .h10_about-content-inner h5 {
  color: #111720;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 10px;
}
#HomePage .h10_about-content-inner p {
  color: rgba(17, 23, 32, 0.7);
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 0;
}

#HomePage .h10_about-count {
  border: 1px dashed #FED3D5;
  padding: 15px 40px 15px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

#HomePage .h10_about-count-item h3 {
  color: #111720;
  font-size: 32px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 0;
}

#HomePage .h10_about-count-item span {
  color: #111720;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  display: block;
}

#HomePage .h10_about-count-item {
  position: relative;
  z-index: 1;
}

#HomePage .h10_about-count-item:not(:last-child)::before {
  position: absolute;
  right: -52px;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  width: 1px;
  height: 100%;
  border: 1px dashed #FED3D5;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h10_about-count-item:not(:last-child)::before {
    right: -38px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h10_about-count-item:not(:last-child)::before {
    right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h10_about-count-item:not(:last-child)::before {
    right: -76px;
  }
}
@media (max-width: 767px) {
  .h10_about-count-item:not(:last-child)::before {
    display: none;
  }
}

#HomePage .h10_about-bottom {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 90px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h10_about-bottom {
    gap: 40px;
  }
}
@media (max-width: 767px) {
  .h10_about-bottom {
    gap: 30px;
  }
}

#HomePage .h10_about-admin {
  display: flex;
  gap: 20px;
  align-items: center;
}
#HomePage .h10_about-admin img {
  width: 55px;
  height: 55px;
  flex-shrink: 0;
}

#HomePage .h10_about-admin-info h5 {
  color: #17161C;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 5px;
}

#HomePage .h10_about-admin-info span {
  color: rgba(23, 22, 29, 0.7);
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  display: block;
}

#HomePage .h10_about-bottom-btn a {
  display: flex;
  align-items: center;
  gap: 12px;
}
#HomePage .h10_about-bottom-btn a .inner-text {
  color: #18222F;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  text-decoration: underline;
}

#HomePage .h10_about-bottom-btn a .inner-btn {
  border-radius: 40px;
  background: var(--clr-theme-primary-10);
  backdrop-filter: blur(15px);
  width: 44px;
  height: 44px;
  display: grid;
  place-items: center;
  color: #fff;
  transform: rotate(45deg);
  font-size: 16px;
}

#HomePage .h10_about-shape-2 {
  width: 65px;
  height: 37px;
  transform: rotate(-20deg);
  position: relative;
  right: 75px;
  animation: animation-leftRight-1 3s linear 0s infinite alternate;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h10_about-shape-2 {
    right: 50px;
  }
}

/*
************
*************************
12. event
******************************************************* 
*************************************************************** */
#HomePage .event-section-area {
  margin-left: 125px;
}
@media (max-width: 1799px) {
  .event-section-area {
    margin-left: 0;
  }
}
#HomePage .event-area {
  background-color: var(--clr-color-lightPurple);
  position: relative;
  z-index: 1;
}
#HomePage .event-bg-img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  max-width: 400px;
  height: 100%;
}
@media (max-width: 1799px) {
  .event-bg-img {
    display: none;
  }
}
#HomePage .event-item {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 20px 40px rgba(30, 30, 30, 0.1);
}
#HomePage .event-img {
  border-radius: 10px 10px 0 0;
  overflow: hidden;
}
#HomePage .event-img img {
  width: 100%;
  -webkit-transition: all 0.6s linear 0s;
  -moz-transition: all 0.6s linear 0s;
  -ms-transition: all 0.6s linear 0s;
  -o-transition: all 0.6s linear 0s;
  transition: all 0.6s linear 0s;
}
#HomePage .event-content-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 27px;
}
#HomePage .event-content-title:hover a {
  color: var(--clr-theme-primary);
}
#HomePage .event-content-meta {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 6px;
}
#HomePage .event-content-meta span {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
}
#HomePage .event-content-meta span i {
  font-size: 14px;
  margin-right: 7px;
  font-weight: 300;
  color: #D2093C;
}

#HomePage .event-content {
  padding: 25px 0 10px;
}
#HomePage .event-navigation {
  display: flex;
  gap: 10px;
  justify-content: end;
}
#HomePage .event-navigation div {
  width: 50px;
  height: 50px;
  background: #FFFFFF;
  box-shadow: 0px 2px 0px #E1E2E6;
  border-radius: 100px;
  display: grid;
  place-items: center;
  font-size: 14px;
  color: #1E1E1E;
  -webkit-transition: all 0.4s linear 0s;
  -moz-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  -o-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
}
#HomePage .event-navigation div:hover {
  background-color: var(--clr-theme-primary);
  color: #fff;
}
@media (max-width: 767px) {
  .event-navigation {
    justify-content: flex-start;
  }
}

#HomePage .event-item:hover .event-img img {
  transform: scale(1.2);
}

#HomePage .h5_event-area {
  background-color: #F3F4FD;
}
#HomePage .h5_event-item {
  display: flex;
  align-items: center;
  height: 33.3333333333%;
}
@media (max-width: 767px) {
  .h5_event-item {
    flex-direction: column;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h5_event-item {
    flex-direction: row;
    align-items: center;
  }
}
#HomePage .h5_event-item-date {
  height: 100%;
  background: #F3EB1E;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 190px;
  flex-shrink: 0;
  text-align: center;
}
@media (max-width: 767px) {
  .h5_event-item-date {
    width: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h5_event-item-date {
    width: 170px;
  }
}
#HomePage .h5_event-item-date h1 {
  font-size: 70px;
  margin-bottom: 0;
  line-height: 1;
}
#HomePage .h5_event-item-date p {
  margin-bottom: 0;
  font-size: 17px;
  color: var(--clr-body-heading);
}
#HomePage .h5_event-item-content {
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 190px);
  padding: 0 60px;
}
@media (max-width: 767px) {
  .h5_event-item-content {
    width: 100%;
    padding: 30px 20px;
    height: auto;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h5_event-item-content {
    width: calc(100% - 170px);
    padding: 0 30px;
    height: 160px;
  }
}
#HomePage .h5_event-item-content ul {
  margin-bottom: 7px;
}
#HomePage .h5_event-item-content ul li {
  display: inline-block;
  margin-right: 15px;
}
#HomePage .h5_event-item-content ul li:last-child {
  margin-right: 0;
}
#HomePage .h5_event-item-content ul li a {
  font-size: 15px;
}
#HomePage .h5_event-item-content ul li a i {
  margin-right: 6px;
  color: var(--clr-body-heading);
}
#HomePage .h5_event-item-content h5 {
  font-size: 18px;
  margin-bottom: 0;
  line-height: 1.4;
}
#HomePage .h5_event-item-content h5:hover a {
  color: var(--clr-theme-primary);
}
#HomePage .h5_event-item:nth-child(even) .h5_event-item-date {
  background-color: #FEF523;
}
#HomePage .h5_event-item:nth-child(even) .h5_event-item-content {
  background-color: rgba(254, 247, 35, 0.1);
}

#HomePage .h6_event-area {
  background-color: #fff;
}
#HomePage .h6_event-area .h5_event-item {
  background: #FFF;
  box-shadow: 0px 20px 80px 0px rgba(30, 30, 30, 0.1);
  height: 33.3333333333%;
}
#HomePage .h6_event-area .h5_event-item-date {
  background: #4846AB;
}
#HomePage .h6_event-area .h5_event-item-date h1 {
  color: #fff;
}
#HomePage .h6_event-area .h5_event-item-date p {
  color: #fff;
}
#HomePage .h6_event-area .h5_event-item:nth-child(2n) .h5_event-item-date {
  background-color: #403E9D;
}
#HomePage .h6_event-area .h5_event-item-content h5:hover a {
  color: var(--clr-theme-primary-6);
}

#HomePage .h5_event-wrap {
  height: 100%;
  min-height: 420px;
}

#HomePage .h7_event-area {
  background-color: #F6F6F6;
}
@media (max-width: 1799px) {
  .h7_event-area .event-wrap.pb-40 {
    padding-bottom: 35px;
  }
}
#HomePage .h7_event-area .event-content-meta span i {
  color: #F63758;
}
#HomePage .h7_event-area .section-area .section-subtitle {
  background: rgba(246, 55, 88, 0.06);
  color: #F63758;
}
#HomePage .h7_event-area .section-area .section-subtitle::before {
  display: none;
}
#HomePage .h7_event-area .event-navigation div {
  color: #0A0A0A;
}
#HomePage .h7_event-area .event-navigation div:hover {
  background: var(--clr-theme-primary-6);
  color: #fff;
}
#HomePage .h7_event-area .event-item {
  border-radius: 0;
}
#HomePage .h7_event-area .event-content-title:hover a {
  color: var(--clr-theme-primary-6);
}
#HomePage .h7_event-area .t-theme-btn.theme-btn.event-btn {
  border-radius: 0;
}
#HomePage .h7_event-area .t-theme-btn.theme-btn.event-btn:hover {
  background-color: var(--clr-theme-primary-6);
}

#HomePage .h10_event-area {
  background-color: #E1EEDA;
}
#HomePage .h10_event-item {
  background-color: #fff;
}
#HomePage .h10_event-img {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
#HomePage .h10_event-img img {
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .h10_event-date {
  position: absolute;
  right: 0;
  top: 30px;
  color: #FFF;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  background-color: var(--clr-theme-primary-10);
  padding: 10px 15px;
}
#HomePage .h10_event-content {
  padding: 35px 30px 40px;
}
#HomePage .h10_event-content-meta {
  display: flex;
  align-items: center;
  gap: 10px;
  color: rgba(44, 44, 44, 0.7);
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 8px;
}
#HomePage .h10_event-content-meta i {
  color: var(--clr-theme-primary-10);
}
#HomePage .h10_event-content-title {
  color: #2C2C2C;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 22px;
}
#HomePage .h10_event-content .h10_event-btn {
  border-radius: 0;
}

#HomePage .h10_event-item:hover .h10_event-img img {
  transform: scale(1.1);
}

/*
************
*************************
13. event-details
******************************************************* 
*************************************************************** */
#HomePage .event_details-wrap {
  margin-top: 40px;
}
#HomePage .event_details-img {
  border-radius: 10px;
  overflow: hidden;
}
#HomePage .event_details-content {
  margin-right: 80px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .event_details-content {
    margin-right: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .event_details-content {
    margin-right: 30px;
  }
}
@media (max-width: 767px) {
  .event_details-content {
    margin-right: 0;
  }
}
#HomePage .event_details-content-title {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 16px;
  line-height: 1.43;
}
@media (max-width: 767px) {
  .event_details-content-title {
    font-size: 26px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .event_details-content-title {
    font-size: 30px;
  }
}
#HomePage .event_details-content p {
  color: rgba(30, 30, 30, 0.7);
}
#HomePage .event_details-content-list ul li {
  display: block;
  position: relative;
  z-index: 1;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
  padding-left: 13px;
  line-height: 26px;
}
#HomePage .event_details-content-list ul li::before {
  position: absolute;
  left: 0;
  top: 50%;
  content: "";
  width: 4px;
  height: 4px;
  background-color: var(--clr-body-heading);
  border-radius: 50%;
  transform: translateY(-50%);
}
#HomePage .event_details-content-list ul li:last-child {
  margin-bottom: 0;
}
#HomePage .event_details-inner-img {
  display: flex;
  gap: 30px;
  margin-bottom: 40px;
}
#HomePage .event_details-inner-img img {
  width: 100%;
}
@media (max-width: 767px) {
  .event_details-inner-img {
    flex-direction: column;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .event_details-inner-img {
    flex-direction: row;
  }
}

#HomePage .event_details-sidebar {
  position: relative;
  z-index: 1;
  margin-top: -120px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .event_details-sidebar {
    margin-top: 0;
  }
}
#HomePage .event_details-sidebar-content {
  background: #fff;
  box-shadow: 0px 20px 80px rgba(30, 30, 30, 0.1);
  padding: 39px 0 45px;
  border-radius: 10px 0 10px 10px;
}
#HomePage .event_details-sidebar-content-title {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 22px;
  margin-left: 40px;
  margin-right: 40px;
}
@media (max-width: 767px) {
  .event_details-sidebar-content-title {
    margin-left: 30px;
    margin-right: 30px;
    font-size: 22px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .event_details-sidebar-content-title {
    font-size: 24px;
  }
}
#HomePage .event_details-sidebar-content ul {
  margin-bottom: 30px;
}
#HomePage .event_details-sidebar-content ul li {
  border-bottom: 1px solid rgba(30, 30, 30, 0.1);
  font-size: 16px;
  font-weight: 500;
  padding: 10px 40px;
  color: rgba(30, 30, 30, 0.7);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#HomePage .event_details-sidebar-content ul li span i {
  margin-right: 10px;
  color: var(--clr-theme-primary);
  font-size: 16px;
}
#HomePage .event_details-sidebar-content ul li:first-child {
  border-top: 1px solid rgba(30, 30, 30, 0.1);
}
@media (max-width: 767px) {
  .event_details-sidebar-content ul li {
    padding-left: 30px;
    padding-right: 30px;
  }
}
#HomePage .event_details-sidebar-btn {
  margin-left: 40px;
  margin-right: 40px;
}
@media (max-width: 767px) {
  .event_details-sidebar-btn {
    margin-left: 30px;
    margin-right: 30px;
  }
}
#HomePage .event_details-sidebar-map {
  background: #fff;
  box-shadow: 0px 20px 80px rgba(30, 30, 30, 0.1);
  padding: 32px 0 32px;
  border-radius: 10px;
}
#HomePage .event_details-sidebar-map .inner-map {
  margin-left: 40px;
  margin-right: 40px;
}
@media (max-width: 767px) {
  .event_details-sidebar-map .inner-map {
    margin-left: 30px;
    margin-right: 30px;
  }
}
#HomePage .event_details-sidebar-map .inner-map iframe {
  height: 400px;
  width: 100%;
}

/*
************
*************************
14. testimonial
******************************************************* 
*************************************************************** */
#HomePage .testimonial-item {
  padding: 50px 35px 45px;
  background-color: #F5F5F5;
  border-radius: 10px;
}
#HomePage .testimonial-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 25px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 576px) and (max-width: 767px) {
  .testimonial-top {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
}
#HomePage .testimonial-admin {
  display: flex;
  gap: 10px;
  align-items: center;
}
#HomePage .testimonial-admin-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}
#HomePage .testimonial-admin-img img {
  width: 100%;
}
#HomePage .testimonial-admin-info h5 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}
#HomePage .testimonial-admin-info span {
  font-size: 14px;
  font-weight: 500;
  display: block;
}

#HomePage .testimonial-rating ul li {
  display: inline-block;
  font-size: 14px;
  color: #FFA121;
}

#HomePage .testimonial-content p {
  color: #1E1E1E;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 26px;
}

#HomePage .testimonial-scrollbar-wrap {
  position: relative;
}
#HomePage .testimonial-scrollbar-wrap .swiper-scrollbar.testimonial-scrollbar {
  height: 3px;
  border-radius: 0;
  background: rgba(30, 30, 30, 0.1);
}
#HomePage .testimonial-scrollbar-wrap .swiper-scrollbar-drag {
  border-radius: 0;
  background-color: var(--clr-theme-primary);
}

#HomePage .h3_testimonial-area {
  background-color: var(--clr-color-lightPink);
  overflow: hidden;
  position: relative;
  z-index: 1;
}
#HomePage .h3_testimonial-shape-1 {
  position: absolute;
  left: 65px;
  top: 120px;
  animation: animation-upDown-2 3s linear 0s infinite alternate;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h3_testimonial-shape-1 {
    left: 30px;
    top: 70px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h3_testimonial-shape-1 {
    left: 30px;
    top: 70px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h3_testimonial-shape-1 {
    left: 30px;
    top: 70px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h3_testimonial-shape-1 {
    left: 30px;
    top: 70px;
  }
}
@media (max-width: 767px) {
  .h3_testimonial-shape-1 {
    display: none;
  }
}
#HomePage .h3_testimonial-shape-2 {
  position: absolute;
  left: 180px;
  bottom: 180px;
  animation: animation-leftRight-1 3s linear 0s infinite alternate;
}
@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .h3_testimonial-shape-2 {
    left: 80px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h3_testimonial-shape-2 {
    left: 10px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h3_testimonial-shape-2 {
    left: 10px;
    bottom: 40px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h3_testimonial-shape-2 {
    display: none;
  }
}
#HomePage .h3_testimonial-shape-3 {
  position: absolute;
  top: 70px;
  right: 690px;
  animation: hero-circle-1 6s linear 0s infinite;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h3_testimonial-shape-3 {
    right: 390px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h3_testimonial-shape-3 {
    right: 300px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h3_testimonial-shape-3 {
    right: 200px;
  }
}
@media (max-width: 767px) {
  .h3_testimonial-shape-3 {
    display: none;
  }
}
#HomePage .h3_testimonial-shape-4 {
  position: absolute;
  bottom: 40px;
  right: 365px;
  animation: animation-leftRight-1 3s linear 0s infinite alternate;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h3_testimonial-shape-4 {
    right: 300px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h3_testimonial-shape-4 {
    right: 50%;
  }
}
@media (max-width: 767px) {
  .h3_testimonial-shape-4 {
    display: none;
  }
}
#HomePage .h3_testimonial-item {
  background: #fff;
  border-radius: 20px;
  padding: 105px 80px 90px;
  position: relative;
  z-index: 1;
}
@media (max-width: 767px) {
  .h3_testimonial-item {
    padding: 70px 30px 80px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h3_testimonial-item {
    padding: 70px 50px 80px;
  }
}
#HomePage .h3_testimonial-item-author {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-shrink: 0;
}
#HomePage .h3_testimonial-item-author img {
  width: 65px;
  height: 65px;
  border-radius: 50%;
}
#HomePage .h3_testimonial-item-author-info h5 {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 600;
}
#HomePage .h3_testimonial-item-author-info span {
  font-size: 16px;
  display: block;
}
#HomePage .h3_testimonial-item-content .quote {
  position: absolute;
  left: 80px;
  top: 50px;
  z-index: -1;
}
@media (max-width: 767px) {
  .h3_testimonial-item-content .quote {
    left: 50px;
    top: 40px;
  }
}
#HomePage .h3_testimonial-item-content p {
  margin-bottom: 50px;
  font-size: 20px;
  line-height: 40px;
  color: rgba(30, 30, 30, 0.7);
}
#HomePage .h3_testimonial-active {
  margin-right: -680px;
}
@media (max-width: 767px) {
  .h3_testimonial-active {
    margin-right: 0;
  }
}
#HomePage .h3_testimonial-navigation {
  display: flex;
  gap: 10px;
  justify-content: end;
}
#HomePage .h3_testimonial-navigation div {
  width: 50px;
  height: 50px;
  border-radius: 6px;
  display: grid;
  place-items: center;
  font-size: 14px;
  color: #1E1E1E;
  border: 1.5px solid rgba(30, 30, 30, 0.15);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .h3_testimonial-navigation div:hover {
  background-color: var(--clr-theme-primary-3);
  border-color: transparent;
  color: #fff;
}
@media (max-width: 767px) {
  .h3_testimonial-navigation {
    justify-content: flex-start;
  }
}

#HomePage .h4_testimonial-area {
  background-color: #ECECF9;
}

#HomePage .h6_testimonial-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
#HomePage .h6_testimonial-area::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: rgba(10, 10, 10, 0.4);
  z-index: -1;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h6_testimonial-wrap.mr-60 {
    margin-right: 0;
  }
}
#HomePage .h6_testimonial-title {
  font-size: 128px;
  font-weight: 700;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #fff;
  line-height: 1.25;
  margin-bottom: 0;
  margin-right: 150px;
  width: max-content !important;
  opacity: 0.2;
}
@media (max-width: 767px) {
  .h6_testimonial-title {
    font-size: 80px;
  }
}
#HomePage .h6_testimonial-navigation {
  display: flex;
  gap: 10px;
  justify-content: end;
}
#HomePage .h6_testimonial-navigation div {
  width: 50px;
  height: 50px;
  display: grid;
  place-items: center;
  font-size: 14px;
  background-color: #fff;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .h6_testimonial-navigation div svg {
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  color: rgba(0, 0, 0, 0.8);
}
#HomePage .h6_testimonial-navigation div:hover {
  background-color: var(--clr-theme-primary-6);
}
#HomePage .h6_testimonial-navigation div:hover svg {
  color: #fff;
}
@media (max-width: 767px) {
  .h6_testimonial-navigation {
    justify-content: flex-start;
  }
}

#HomePage .testimonial-text-wrap {
  position: absolute;
  top: 40px;
}

#HomePage .h6_testimonial-item blockquote {
  background: #fff;
  padding: 50px 60px 50px 145px;
  position: relative;
  z-index: 1;
  margin-bottom: 0;
}
#HomePage .h6_testimonial-item blockquote::before {
  content: "\f10d";
  font-weight: 700;
  position: absolute;
  font-family: "Font Awesome 5 Pro";
  font-size: 52px;
  color: #0A0A0A;
  line-height: 1;
  display: inline-block;
  left: 70px;
  top: 60px;
}
#HomePage .h6_testimonial-item blockquote p {
  color: #666;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}
@media (max-width: 767px) {
  .h6_testimonial-item blockquote p {
    font-size: 16px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h6_testimonial-item blockquote p {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .h6_testimonial-item blockquote {
    padding: 125px 30px 50px 30px;
  }
  .h6_testimonial-item blockquote::before {
    left: 30px;
    top: 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h6_testimonial-item blockquote {
    padding: 125px 50px 50px 50px;
  }
  .h6_testimonial-item blockquote::before {
    left: 50px;
    top: 40px;
  }
}
#HomePage .h6_testimonial-item .quote-admin {
  display: flex;
  justify-content: end;
}
#HomePage .h6_testimonial-item .quote-admin-inner h5 {
  color: #0A0A0A;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 0;
  position: relative;
  z-index: 1;
}
#HomePage .h6_testimonial-item .quote-admin-inner h5::before {
  position: absolute;
  left: -65px;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  background: #0A0A0A;
  width: 50px;
  height: 2px;
}
#HomePage .h6_testimonial-item .quote-admin-inner span {
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  display: inline-block;
}

#HomePage .h8_testimonial-area {
  background-color: #F4F7FF;
}
#HomePage .h8_testimonial-content {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 50px;
  column-gap: 30px;
}
#HomePage .h8_testimonial-item {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 6px 50px 0px rgba(8, 5, 33, 0.06);
  padding: 35px 40px 35px;
  position: relative;
  z-index: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h8_testimonial-item {
    padding: 35px 24px 35px;
  }
}
@media (max-width: 767px) {
  .h8_testimonial-item {
    padding: 35px 30px 35px;
  }
}
#HomePage .h8_testimonial-item:hover {
  box-shadow: 0px 20px 100px 0px rgba(25, 35, 53, 0.3);
}
#HomePage .h8_testimonial-item p {
  color: rgba(2, 25, 54, 0.7);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 28px;
}
@media (max-width: 767px) {
  .h8_testimonial-item p br {
    display: none;
  }
}
#HomePage .h8_testimonial-item-admin {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 20px;
}
#HomePage .h8_testimonial-item-img {
  width: 65px;
  height: 65px;
  flex-shrink: 0;
  border-radius: 50%;
}
#HomePage .h8_testimonial-item-info h4 {
  color: #021936;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 5px;
}
@media (max-width: 767px) {
  .h8_testimonial-item-info h4 {
    font-size: 16px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h8_testimonial-item-info h4 {
    font-size: 20px;
  }
}
#HomePage .h8_testimonial-item-info h4:hover a {
  color: #395BDF;
}
#HomePage .h8_testimonial-item-info span {
  color: rgba(2, 25, 54, 0.7);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  display: block;
}
#HomePage .h8_testimonial-item-rating ul {
  display: flex;
  gap: 5px;
}
#HomePage .h8_testimonial-item-rating ul li {
  font-size: 18px;
  color: #F2A22A;
}
#HomePage .h8_testimonial-quote {
  position: absolute;
  right: 40px;
  bottom: 40px;
}

#HomePage .h9_testimonial-area {
  background: #F5F5F5;
  position: relative;
  z-index: 1;
  overflow-x: hidden;
}
#HomePage .h9_testimonial-title {
  color: #21242C;
  font-size: 40px;
  font-weight: 600;
  line-height: 1.35;
  text-decoration-line: underline;
  margin-bottom: 67px;
}
@media (max-width: 767px) {
  .h9_testimonial-title {
    font-size: 32px;
  }
}
#HomePage .h9_testimonial-item p {
  color: #21242C;
  font-size: 30px;
  font-weight: 400;
  line-height: 46px;
  margin-bottom: 45px;
}
@media (max-width: 767px) {
  .h9_testimonial-item p {
    font-size: 20px;
    line-height: 32px;
  }
}
#HomePage .h9_testimonial-item h6 {
  color: #21242C;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  display: flex;
  align-items: center;
  gap: 34px;
  margin-bottom: 0;
}
#HomePage .h9_testimonial-item h6 span {
  color: rgba(11, 23, 40, 0.7);
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  display: block;
  position: relative;
  z-index: 1;
}
#HomePage .h9_testimonial-item h6 span::before {
  position: absolute;
  left: -19px;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  width: 4px;
  height: 4px;
  background: rgba(11, 23, 40, 0.2);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  border-radius: 50%;
}

#HomePage .h9_testimonial-img img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  cursor: pointer;
}

#HomePage .h9_testimonial-thumb-wrap {
  width: 300px;
}
@media (max-width: 767px) {
  .h9_testimonial-thumb-wrap {
    width: 280px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h9_testimonial-thumb-wrap {
    width: 300px;
  }
}
#HomePage .h9_testimonial-thumb-wrap .swiper-slide-thumb-active .h9_testimonial-img img {
  border: 1px solid #1865F2;
  padding: 9px;
}

#HomePage .h9_testimonial-wrap {
  border-radius: 500px 0px 0px 500px;
  background: #FFF;
  position: relative;
  z-index: 1;
}
#HomePage .h9_testimonial-wrap::after {
  position: absolute;
  top: 0;
  right: -100%;
  content: "";
  height: 100%;
  width: 100%;
  background-color: #fff;
  z-index: -1;
}

#HomePage .h9_testimonial-pagination.swiper-pagination-bullets {
  position: absolute;
  right: 120px;
  top: 50%;
  transform: translateY(-50%);
  bottom: auto;
  left: auto;
  width: auto;
  text-align: center;
  line-height: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  z-index: 9;
}
#HomePage .h9_testimonial-pagination .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  cursor: pointer;
  display: inline-block;
  border-radius: 50%;
  background: transparent;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  background-color: rgba(33, 36, 44, 0.14);
  position: relative;
  z-index: 1;
}
#HomePage .h9_testimonial-pagination .swiper-pagination-bullet::before {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  content: "";
  width: 8px;
  height: 8px;
  width: 24px;
  height: 24px;
  border: 1px solid #1865F2;
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
}
#HomePage .h9_testimonial-pagination.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0;
}
#HomePage .h9_testimonial-pagination.swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
  margin: 8px 0;
}
#HomePage .h9_testimonial-pagination.swiper-pagination-bullets .swiper-pagination-bullet:first-child.swiper-pagination-bullet-active {
  margin-bottom: 8px;
  margin-top: 0;
}
#HomePage .h9_testimonial-pagination.swiper-pagination-bullets .swiper-pagination-bullet:last-child.swiper-pagination-bullet-active {
  margin-top: 8px;
  margin-bottom: 0;
}
#HomePage .h9_testimonial-pagination .swiper-pagination-bullet-active {
  background-color: #1865F2;
}
#HomePage .h9_testimonial-pagination .swiper-pagination-bullet-active::before {
  opacity: 1;
  visibility: visible;
}
#HomePage .h9_testimonial-navigation {
  position: absolute;
  left: 120px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 99;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h9_testimonial-navigation {
    top: auto;
    transform: unset;
    bottom: 40px;
    flex-direction: row;
    left: 40px;
  }
}
@media (max-width: 767px) {
  .h9_testimonial-navigation {
    top: auto;
    transform: unset;
    bottom: 30px;
    flex-direction: row;
    left: 15px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h9_testimonial-navigation {
    left: 35px;
  }
}
#HomePage .h9_testimonial-navigation div {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(33, 36, 44, 0.14);
  color: rgb(17, 7, 24);
  display: grid;
  place-items: center;
  font-size: 14px;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .h9_testimonial-navigation div:hover {
  background: #1865F2;
  color: #fff;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h9_testimonial-navigation div {
    transform: rotate(-90deg);
  }
}

#HomePage .h10_testimonial-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-color: #fff;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h10_testimonial-wrap.mr-45 {
    margin-right: 0;
  }
}
#HomePage .h10_testimonial-text-wrap {
  position: absolute;
  top: 90px;
}
#HomePage .h10_testimonial-title {
  font-size: 128px;
  font-weight: 700;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #B7B7B7;
  line-height: 1.25;
  letter-spacing: -15px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h10_testimonial-title {
    font-size: 90px;
    letter-spacing: -10px;
  }
}
@media (max-width: 767px) {
  .h10_testimonial-title {
    font-size: 45px;
    letter-spacing: -3px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h10_testimonial-title {
    font-size: 80px;
  }
}
#HomePage .h10_testimonial-title span {
  position: relative;
}
#HomePage .h10_testimonial-title span img {
  position: absolute;
  top: -15px;
  left: -30px;
  transform: rotate(-15deg);
}
@media (max-width: 767px) {
  .h10_testimonial-title span img {
    top: -10px;
    left: -10px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h10_testimonial-title span img {
    top: -10px;
    left: -15px;
  }
}
#HomePage .h10_testimonial-navigation {
  display: flex;
  gap: 10px;
  position: absolute;
  right: 50px;
  bottom: 10px;
  z-index: 99;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h10_testimonial-navigation {
    right: 0;
  }
}
@media (max-width: 767px) {
  .h10_testimonial-navigation {
    right: 0;
    bottom: 50px;
  }
}
#HomePage .h10_testimonial-navigation div {
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  font-size: 14px;
  background-color: #F6F6F6;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .h10_testimonial-navigation div svg {
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  color: rgba(0, 0, 0, 0.8);
}
#HomePage .h10_testimonial-navigation div:hover {
  background-color: var(--clr-theme-primary-10);
}
#HomePage .h10_testimonial-navigation div:hover svg {
  color: #fff;
}
@media (max-width: 767px) {
  .h10_testimonial-navigation {
    justify-content: flex-start;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h10_testimonial-img.ml-10 {
    margin-left: 0;
  }
}

#HomePage .h10_testimonial-item blockquote {
  background: #fff;
  position: relative;
  z-index: 1;
  margin-bottom: 0;
}
#HomePage .h10_testimonial-item blockquote::before {
  content: "\f10d";
  font-weight: 700;
  font-family: "Font Awesome 5 Pro";
  font-size: 90px;
  color: #EDEDED;
  line-height: 56px;
  display: inline-block;
  margin-bottom: 35px;
}
#HomePage .h10_testimonial-item blockquote p {
  color: #666;
  font-size: 22px;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 55px;
}
@media (max-width: 767px) {
  .h10_testimonial-item blockquote p {
    font-size: 16px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h10_testimonial-item blockquote p {
    font-size: 18px;
  }
}
#HomePage .h10_testimonial-item .quote-admin-inner {
  margin-left: 132px;
}
#HomePage .h10_testimonial-item .quote-admin-inner h5 {
  color: #0A0A0A;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 0;
}
#HomePage .h10_testimonial-item .quote-admin-inner span {
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  display: inline-block;
}

#HomePage .h10_testimonial-admin {
  width: 60px;
  height: 60px;
}
#HomePage .h10_testimonial-admin img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  border: 5px solid transparent;
}

#HomePage .h10_testimonial-thumb-wrap {
  width: 112px;
  background-color: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 9;
}
@media (max-width: 767px) {
  .h10_testimonial-thumb-wrap {
    bottom: 110px;
  }
}
#HomePage .h10_testimonial-thumb-wrap::after {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  width: 2px;
  height: 45px;
  background-color: #D9D9D9;
}
#HomePage .h10_testimonial-thumbs .swiper-slide {
  z-index: 1;
}
#HomePage .h10_testimonial-thumbs .swiper-slide .h10_testimonial-admin {
  margin-left: 35px;
}
#HomePage .h10_testimonial-thumbs .swiper-slide.swiper-slide-thumb-active {
  z-index: 9;
}
#HomePage .h10_testimonial-thumbs .swiper-slide.swiper-slide-thumb-active .h10_testimonial-admin {
  margin-left: 0;
}
#HomePage .h10_testimonial-thumbs .swiper-slide.swiper-slide-thumb-active .h10_testimonial-admin img {
  border-color: #fff;
}
@media (max-width: 767px) {
  .h10_testimonial-active {
    padding-bottom: 110px;
  }
}

/*
************
*************************
15. counter
******************************************************* 
*************************************************************** */
#HomePage .counter-wrap {
  border: 1px solid rgba(30, 30, 30, 0.1);
}
#HomePage .counter-item {
  display: flex;
  gap: 20px;
  border-right: 1px solid rgba(30, 30, 30, 0.1);
  padding-top: 25px;
  padding-bottom: 30px;
  justify-content: center;
}
@media (max-width: 767px) {
  .counter-item {
    border-right: 0;
    border-bottom: 1px solid rgba(30, 30, 30, 0.1);
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .counter-item {
    border-right: 1px solid rgba(30, 30, 30, 0.1);
    border-bottom: 1px solid rgba(30, 30, 30, 0.1);
  }
}
#HomePage .counter-icon i {
  font-size: 38px;
  color: var(--clr-color-darkPink);
  flex-shrink: 0;
  display: block;
  margin-top: 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .counter-icon i {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .counter-icon i {
    font-size: 30px;
  }
}
#HomePage .counter-info-title {
  font-size: 55px;
  display: flex;
  font-weight: 600;
  margin-bottom: 0;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .counter-info-title {
    font-size: 50px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .counter-info-title {
    font-size: 45px;
  }
}
@media (max-width: 767px) {
  .counter-info-title {
    font-size: 45px;
  }
}
#HomePage .counter-info-text {
  font-size: 14px;
  text-transform: uppercase;
  color: #767676;
  display: inline-block;
  line-height: 1;
}

#HomePage .counter-wrap .col-xl-3:last-child .counter-item {
  border-right: 0;
}
@media (max-width: 767px) {
  .counter-wrap .col-xl-3:last-child .counter-item {
    border-bottom: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .counter-wrap .col-xl-3:nth-child(2) .counter-item {
    border-bottom: 1px solid rgba(30, 30, 30, 0.1);
    border-right: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .counter-wrap .col-xl-3:nth-child(2) .counter-item {
    border-right: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .counter-wrap .col-xl-3:nth-child(3) .counter-item {
    border-bottom: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .counter-wrap .col-xl-3:first-child .counter-item {
    border-bottom: 1px solid rgba(30, 30, 30, 0.1);
  }
}

#HomePage .h7_counter-item {
  display: flex;
}
#HomePage .h7_counter-info-title {
  font-size: 96px;
  font-weight: 700;
  text-transform: capitalize;
  display: flex;
  line-height: 1;
  align-items: center;
  margin-bottom: 0;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px rgb(82, 82, 82);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .h7_counter-info-title:hover {
  -webkit-text-fill-color: #0A0A0A;
  -webkit-text-stroke: 1px #0A0A0A;
}
#HomePage .h7_counter-info-text {
  color: #525252;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-transform: uppercase;
}

#HomePage .h8_counter-item {
  display: flex;
}
#HomePage .h8_counter-info-title {
  color: #FFF;
  font-size: 36px;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 5px;
}
#HomePage .h8_counter-info-text {
  color: #525252;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-transform: uppercase;
}

#HomePage .h8_counter-info-icon {
  width: 65px;
  height: 65px;
  border-radius: 64px;
  background: rgba(255, 255, 255, 0.1);
  display: grid;
  place-items: center;
  margin-bottom: 13px;
  margin-left: auto;
  margin-right: auto;
}

#HomePage .h8_counter-info-icon i {
  font-size: 23px;
  color: rgb(255, 255, 255);
}

#HomePage .h8_counter-info {
  text-align: center;
}

#HomePage .h8_counter-info-text {
  color: #FFF;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  display: block;
}

#HomePage .h10_counter-wrap {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  flex-wrap: wrap;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h10_counter-wrap {
    justify-content: center;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h10_counter-wrap {
    justify-content: space-between;
  }
}
#HomePage .h10_counter-item {
  display: flex;
  gap: 20px;
}
#HomePage .h10_counter-icon i {
  font-size: 38px;
  color: var(--clr-theme-primary-10);
  flex-shrink: 0;
  display: block;
  margin-top: 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h10_counter-icon i {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .h10_counter-icon i {
    font-size: 30px;
  }
}
#HomePage .h10_counter-info-title {
  font-size: 55px;
  display: flex;
  font-weight: 600;
  margin-bottom: 0;
  color: #0A0A0A;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h10_counter-info-title {
    font-size: 50px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h10_counter-info-title {
    font-size: 45px;
  }
}
@media (max-width: 767px) {
  .h10_counter-info-title {
    font-size: 45px;
  }
}
#HomePage .h10_counter-info-text {
  font-size: 14px;
  text-transform: uppercase;
  color: #767676;
  display: inline-block;
  line-height: 1;
}

/*
************
*************************
16. blog
******************************************************* 
*************************************************************** */
#HomePage .blog-item {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 10px 50px rgba(30, 30, 30, 0.1);
}
#HomePage .blog-img {
  border-radius: 6px;
  overflow: hidden;
}
#HomePage .blog-img img {
  width: 100%;
  -webkit-transition: all 0.6s linear 0s;
  -moz-transition: all 0.6s linear 0s;
  -ms-transition: all 0.6s linear 0s;
  -o-transition: all 0.6s linear 0s;
  transition: all 0.6s linear 0s;
}
#HomePage .blog-content {
  padding: 40px 0 25px;
}
#HomePage .blog-content-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 27px;
}
#HomePage .blog-content-title:hover a {
  color: var(--clr-theme-primary);
}
#HomePage .blog-content-meta {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 7px;
}
#HomePage .blog-content-meta span {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
}
#HomePage .blog-content-meta span i {
  font-size: 14px;
  margin-right: 7px;
  font-weight: 300;
  color: var(--clr-color-darkPink);
}

#HomePage .blog-item.blog-item-h {
  display: flex;
  align-items: center;
}
#HomePage .blog-item.blog-item-h .blog-content {
  padding: 0 30px;
}
#HomePage .blog-item.blog-item-h .blog-img {
  width: 280px;
  flex-shrink: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-item {
    display: flex;
    align-items: center;
  }
  .blog-item .blog-content {
    padding: 0 30px;
  }
  .blog-item .blog-img {
    width: 280px;
    flex-shrink: 0;
  }
}
@media (max-width: 767px) {
  .blog-item.blog-item-h {
    flex-direction: column;
  }
  .blog-item.blog-item-h .blog-content {
    padding: 40px 0 25px;
  }
  .blog-item.blog-item-h .blog-img {
    width: 100%;
  }
}

#HomePage .blog-item:hover .blog-img img {
  transform: scale(1.2);
}

#HomePage .h2_blog-img {
  overflow: hidden;
}
#HomePage .h2_blog-img img {
  border-radius: 10px 10px 0 0;
  -webkit-transition: all 0.6s linear 0s;
  -moz-transition: all 0.6s linear 0s;
  -ms-transition: all 0.6s linear 0s;
  -o-transition: all 0.6s linear 0s;
  transition: all 0.6s linear 0s;
  width: 100%;
}
#HomePage .h2_blog-content {
  padding: 30px 30px 35px;
  box-shadow: 0px 10px 50px rgba(30, 30, 30, 0.1);
  border-radius: 0 0 10px 10px;
}
#HomePage .h2_blog-content-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 27px;
}
#HomePage .h2_blog-content-title:hover a {
  color: var(--clr-theme-primary);
}
#HomePage .h2_blog-content-meta {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 7px;
}
#HomePage .h2_blog-content-meta span {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
}
#HomePage .h2_blog-content-meta span i {
  font-size: 14px;
  margin-right: 7px;
  font-weight: 300;
  color: var(--clr-color-darkPink);
}

#HomePage .h2_blog-item:hover .h2_blog-img img {
  transform: scale(1.2);
}

#HomePage .h3_blog-item {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 2px 20px 0px rgba(30, 30, 30, 0.14);
  padding: 20px;
}
#HomePage .h3_blog-img {
  overflow: hidden;
  position: relative;
  border-radius: 6px;
  z-index: 1;
}
#HomePage .h3_blog-img img {
  border-radius: 10px;
  -webkit-transition: all 0.6s linear 0s;
  -moz-transition: all 0.6s linear 0s;
  -ms-transition: all 0.6s linear 0s;
  -o-transition: all 0.6s linear 0s;
  transition: all 0.6s linear 0s;
  width: 100%;
}
#HomePage .h3_blog-img-meta {
  position: absolute;
  right: 15px;
  top: 15px;
  border-radius: 6px;
  background: var(--clr-theme-primary-3);
  box-shadow: 0px 2px 5px 0px rgba(32, 42, 61, 0.4);
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding: 10px 15px;
  line-height: 1;
}
#HomePage .h3_blog-content {
  padding-top: 25px;
}
#HomePage .h3_blog-content-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 7px;
}
#HomePage .h3_blog-content-title:hover a {
  color: var(--clr-theme-primary-3);
}
#HomePage .h3_blog-content-meta {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 12px;
}
#HomePage .h3_blog-content-meta span {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
}
#HomePage .h3_blog-content-meta span i {
  font-size: 14px;
  margin-right: 7px;
  font-weight: 300;
  color: var(--clr-theme-primary-3);
}
#HomePage .h3_blog-content p {
  font-size: 15px;
  margin-bottom: 20px;
}
#HomePage .h3_blog-btn {
  font-size: 16px;
  font-weight: 500;
  color: var(--clr-body-heading);
}
#HomePage .h3_blog-btn i {
  margin-left: 10px;
}
#HomePage .h3_blog-btn:hover {
  color: var(--clr-theme-primary-3);
}

#HomePage .h3_blog-item:hover .h3_blog-img img {
  transform: scale(1.2);
}

#HomePage .h4_blog-item {
  border-radius: 10px;
  background: #FFF;
  border: 1px solid rgba(10, 10, 10, 0.1);
  padding: 20px;
}
#HomePage .h4_blog-img {
  overflow: hidden;
  position: relative;
  border-radius: 6px;
  z-index: 1;
}
#HomePage .h4_blog-img img {
  border-radius: 10px;
  -webkit-transition: all 0.6s linear 0s;
  -moz-transition: all 0.6s linear 0s;
  -ms-transition: all 0.6s linear 0s;
  -o-transition: all 0.6s linear 0s;
  transition: all 0.6s linear 0s;
  width: 100%;
}
#HomePage .h4_blog-img-meta {
  position: absolute;
  right: 15px;
  top: 15px;
  border-radius: 6px;
  background: var(--clr-theme-primary-3);
  box-shadow: 0px 2px 5px 0px rgba(32, 42, 61, 0.4);
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding: 10px 15px;
  line-height: 1;
}
#HomePage .h4_blog-content {
  padding-top: 22px;
}
#HomePage .h4_blog-content-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 5px;
}
#HomePage .h4_blog-content-title:hover a {
  color: var(--clr-theme-primary-3);
}
#HomePage .h4_blog-content-meta {
  display: flex;
  align-items: center;
  gap: 35px;
  margin-bottom: 14px;
}
#HomePage .h4_blog-content-meta span {
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  position: relative;
  z-index: 1;
}
#HomePage .h4_blog-content-meta span:not(:first-child)::after {
  position: absolute;
  left: -23px;
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
  height: 5px;
  background-color: var(--clr-body-heading);
  content: "";
  border-radius: 50%;
}
#HomePage .h4_blog-content-meta span:not(:first-child)::before {
  position: absolute;
  left: -13px;
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
  height: 5px;
  background-color: var(--clr-body-heading);
  border-radius: 50%;
  content: "";
}
#HomePage .h4_blog-content p {
  font-size: 15px;
  margin-bottom: 20px;
}
#HomePage .h4_blog-btn {
  font-size: 16px;
  font-weight: 500;
  color: var(--clr-body-heading);
}
#HomePage .h4_blog-btn i {
  margin-left: 10px;
}
#HomePage .h4_blog-btn:hover {
  color: var(--clr-theme-primary-3);
}

#HomePage .h4_blog-item:hover .h4_blog-img img {
  transform: scale(1.2);
}

#HomePage .h5_blog-item {
  border: 1px solid rgba(10, 10, 10, 0.1);
  padding: 100px 50px 91px;
  -webkit-transition: all 0.4s linear 0s;
  -moz-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  -o-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h5_blog-item {
    padding: 100px 40px 91px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h5_blog-item {
    padding: 100px 35px 91px;
  }
}
@media (max-width: 767px) {
  .h5_blog-item {
    padding: 80px 25px 71px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h5_blog-item {
    padding: 100px 50px 91px;
  }
}
#HomePage .h5_blog-content-meta {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 25px;
}
#HomePage .h5_blog-content-meta-tag {
  border-radius: 0px 6px;
  background: var(--clr-theme-primary-5);
  display: inline-block;
  padding: 7px 20px;
  color: var(--clr-body-heading);
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
}
#HomePage .h5_blog-content-meta span {
  position: relative;
  z-index: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  font-size: 15px;
}
#HomePage .h5_blog-content-meta span::before {
  position: absolute;
  left: -15px;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  width: 5px;
  height: 5px;
  background: var(--clr-body-text);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  border-radius: 50%;
}
#HomePage .h5_blog-content-title {
  font-size: 20px;
  font-weight: 500;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .h5_blog-content-title {
    font-size: 18px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h5_blog-content-title {
    font-size: 20px;
  }
}
#HomePage .h5_blog-content p {
  margin-bottom: 21px;
  font-size: 15px;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  line-height: 24px;
}
#HomePage .h5_blog-btn {
  position: relative;
  z-index: 1;
  width: 20px;
  overflow: hidden;
  display: inline-block;
}
#HomePage .h5_blog-btn svg {
  color: var(--clr-body-heading);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  width: 24px;
  height: auto;
  margin-left: -4px;
}

#HomePage .h5_blog-item:hover {
  background-color: var(--clr-body-heading);
  border-color: transparent;
}
#HomePage .h5_blog-item:hover .h5_blog-content-meta span {
  color: rgba(255, 255, 255, 0.6);
}
#HomePage .h5_blog-item:hover .h5_blog-content-meta span::before {
  background-color: rgba(255, 255, 255, 0.6);
}
#HomePage .h5_blog-item:hover .h5_blog-content-title {
  color: #fff;
}
#HomePage .h5_blog-item:hover .h5_blog-content p {
  color: rgba(255, 255, 255, 0.7);
}
#HomePage .h5_blog-item:hover .h5_blog-btn {
  width: 24px;
}
#HomePage .h5_blog-item:hover .h5_blog-btn svg {
  margin-left: 0;
  color: #fff;
}

#HomePage .h6_blog-area .h5_blog-item {
  position: relative;
  z-index: 1;
}
#HomePage .h6_blog-area .h5_blog-item::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  /* background-image: url("../img/blog/6/1.jpg"); */
  z-index: -1;
  opacity: 0;
  -webkit-transition: all 0.4s linear 0s;
  -moz-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  -o-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
}
#HomePage .h6_blog-area .h5_blog-item::after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: rgba(10, 10, 10, 0.6);
  z-index: -1;
  opacity: 0;
  -webkit-transition: all 0.4s linear 0s;
  -moz-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  -o-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
}
#HomePage .h6_blog-area .h5_blog-content-meta-tag {
  background-color: var(--clr-theme-primary-6);
  color: #fff;
}
#HomePage .h6_blog-area .h5_blog-item.active {
  border-color: transparent;
  background-color: transparent;
}
#HomePage .h6_blog-area .h5_blog-item.active::before {
  opacity: 1;
}
#HomePage .h6_blog-area .h5_blog-item.active::after {
  opacity: 1;
}
#HomePage .h6_blog-area .h5_blog-item.active .h5_blog-content-meta-tag {
  background-color: #fff;
  color: var(--clr-theme-primary-6);
}
#HomePage .h6_blog-area .h5_blog-item.active .h5_blog-content-meta span {
  color: rgba(255, 255, 255, 0.6);
}
#HomePage .h6_blog-area .h5_blog-item.active .h5_blog-content-meta span::before {
  background-color: rgba(255, 255, 255, 0.6);
}
#HomePage .h6_blog-area .h5_blog-item.active .h5_blog-content-title {
  color: #fff;
}
#HomePage .h6_blog-area .h5_blog-item.active .h5_blog-content p {
  color: rgba(255, 255, 255, 0.7);
}
#HomePage .h6_blog-area .h5_blog-item.active .h5_blog-btn {
  width: 24px;
}
#HomePage .h6_blog-area .h5_blog-item.active .h5_blog-btn svg {
  margin-left: 0;
  color: #fff;
}
#HomePage .h6_blog-area .h5_blog-item:hover {
  border-color: transparent;
  background-color: transparent;
}
#HomePage .h6_blog-area .h5_blog-item:hover::before {
  opacity: 1;
}
#HomePage .h6_blog-area .h5_blog-item:hover::after {
  opacity: 1;
}
#HomePage .h6_blog-area .h5_blog-item:hover .h5_blog-content-meta-tag {
  background-color: #fff;
  color: var(--clr-theme-primary-6);
}
#HomePage .h6_blog-area .theme-btn-5 {
  color: #fff;
  background-color: var(--clr-theme-primary-6);
}

#HomePage .h8_blog-item {
  border-radius: 10px;
  background: #FFF;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 5px 25px 0px rgba(8, 5, 33, 0.08);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .h8_blog-item:hover {
  box-shadow: 0px 10px 40px 0px rgba(25, 35, 53, 0.15);
}
#HomePage .h8_blog-item-content {
  padding: 25px 30px 25px;
}
#HomePage .h8_blog-item-content-title {
  color: #021936;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 10px;
}
#HomePage .h8_blog-item-content-title:hover a {
  color: #395BDF;
}
#HomePage .h8_blog-item-content p {
  color: rgba(2, 25, 54, 0.7);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 20px;
}
#HomePage .h8_blog-item-content-btn {
  color: #021936;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 10px;
}
#HomePage .h8_blog-item-img {
  overflow: hidden;
}
#HomePage .h8_blog-item-img img {
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}

#HomePage .h8_blog-navigation {
  display: flex;
  gap: 10px;
  justify-content: end;
}
#HomePage .h8_blog-navigation div {
  border-radius: 40px;
  border: 1px solid rgba(2, 25, 54, 0.1);
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .h8_blog-navigation div svg {
  color: #021936;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .h8_blog-navigation div:hover {
  background-color: #395BDF;
}
#HomePage .h8_blog-navigation div:hover svg {
  color: #fff;
}
@media (max-width: 767px) {
  .h8_blog-navigation {
    justify-content: flex-start;
  }
}

#HomePage .h8_blog-item:hover .h8_blog-item-img img {
  transform: scale(1.1);
}

#HomePage .h9_blog-item {
  background-color: #F5F5F5;
  border-radius: 20px;
  overflow: hidden;
  padding: 100px 50px 85px;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h9_blog-item {
    padding: 100px 40px 91px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h9_blog-item {
    padding: 100px 35px 91px;
  }
}
@media (max-width: 767px) {
  .h9_blog-item {
    padding: 80px 25px 71px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h9_blog-item {
    padding: 100px 50px 91px;
  }
}
#HomePage .h9_blog-item::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  /* background-image: url("../img/blog/6/1.jpg"); */
  z-index: -1;
  opacity: 0;
  -webkit-transition: all 0.4s linear 0s;
  -moz-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  -o-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
}
#HomePage .h9_blog-item::after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: rgba(245, 245, 245, 0.7);
  z-index: -1;
  opacity: 0;
  -webkit-transition: all 0.4s linear 0s;
  -moz-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  -o-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
}
#HomePage .h9_blog-content-meta {
  display: flex;
  align-items: center;
  gap: 33px;
  margin-bottom: 25px;
}
#HomePage .h9_blog-content-meta-tag {
  border-radius: 4px;
  background: #1865F2;
  display: inline-block;
  padding: 7px 20px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
}
#HomePage .h9_blog-content-meta-tag:hover {
  color: #fff;
}
#HomePage .h9_blog-content-meta span {
  color: rgba(10, 10, 10, 0.6);
  font-size: 15px;
  font-weight: 400;
  position: relative;
  z-index: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  font-size: 15px;
}
#HomePage .h9_blog-content-meta span::before {
  position: absolute;
  left: -18px;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  width: 5px;
  height: 5px;
  background: rgba(10, 10, 10, 0.6);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  border-radius: 50%;
}
#HomePage .h9_blog-content-title {
  color: #0A0A0A;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  margin-bottom: 19px;
}
#HomePage .h9_blog-content-title:hover a {
  color: #1865F2;
}
@media (max-width: 767px) {
  .h9_blog-content-title {
    font-size: 18px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h9_blog-content-title {
    font-size: 20px;
  }
}
#HomePage .h9_blog-content p {
  color: rgba(10, 10, 10, 0.7);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 24px;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .h9_blog-btn {
  position: relative;
  z-index: 1;
  width: 20px;
  overflow: hidden;
  display: inline-block;
}
#HomePage .h9_blog-btn svg {
  color: var(--clr-body-heading);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  width: 24px;
  height: auto;
  margin-left: -4px;
}

#HomePage .h9_blog-item:hover {
  border-color: transparent;
  background-color: transparent;
}
#HomePage .h9_blog-item:hover::before {
  opacity: 1;
}
#HomePage .h9_blog-item:hover::after {
  opacity: 1;
}
#HomePage .h9_blog-item:hover .h9_blog-btn {
  width: 24px;
}
#HomePage .h9_blog-item:hover .h9_blog-btn svg {
  margin-left: 0;
  color: #1865F2;
}

#HomePage .h9_blog-item.active {
  border-color: transparent;
  background-color: transparent;
}
#HomePage .h9_blog-item.active::before {
  opacity: 1;
}
#HomePage .h9_blog-item.active::after {
  opacity: 1;
}

#HomePage .h10_blog-area {
  background-color: #E1EEDA;
}
#HomePage .h10_blog-area .h4_blog-item {
  border-radius: 0;
}
#HomePage .h10_blog-area .h4_blog-img {
  border-radius: 0;
  overflow: hidden;
}
#HomePage .h10_blog-area .h4_blog-img img {
  border-radius: 0;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
#HomePage .h10_blog-area .h4_blog-img-meta {
  position: absolute;
  right: auto;
  top: auto;
  left: 0;
  bottom: 0;
  border-radius: 0;
  background: var(--clr-theme-primary-10);
  box-shadow: none;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding: 8px 15px;
  line-height: 1;
}
#HomePage .h10_blog-area .h4_blog-content-meta span {
  color: rgba(10, 10, 10, 0.6);
}
#HomePage .h10_blog-area .h4_blog-content-meta span:not(:first-child)::after {
  background-color: #0A0A0A;
}
#HomePage .h10_blog-area .h4_blog-content-meta span:not(:first-child)::before {
  background-color: #0A0A0A;
}
#HomePage .h10_blog-area .h4_blog-content-title {
  color: #0A0A0A;
}
#HomePage .h10_blog-area .h4_blog-content-title:hover a {
  color: var(--clr-theme-primary-10);
}
#HomePage .h10_blog-area .h4_blog-content p {
  color: rgba(10, 10, 10, 0.7);
}
#HomePage .h10_blog-area .h4_blog-btn {
  color: #0A0A0A;
}
#HomePage .h10_blog-area .h4_blog-btn:hover {
  color: var(--clr-theme-primary-10);
}
#HomePage .h10_blog .h4_blog-item:hover .h4_blog-img img {
  transform: scale(1.1);
}

/*
************
*************************
17. blog-details
******************************************************* 
*************************************************************** */
#HomePage .blog_details-img {
  position: relative;
  margin-bottom: -40px;
  z-index: -1;
}
#HomePage .blog_details-top {
  padding: 40px 50px 50px;
  box-shadow: 0px 20px 80px rgba(30, 30, 30, 0.1);
  border-radius: 0 10px 10px 10px;
  background-color: #fff;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog_details-top {
    padding: 40px 40px 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .blog_details-top {
    border-radius: 10px;
  }
}
@media (max-width: 767px) {
  .blog_details-top {
    padding: 40px 25px 50px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog_details-top {
    padding: 40px 30px 50px;
  }
}
#HomePage .blog_details-title {
  font-size: 30px;
  margin-bottom: 25px;
  line-height: 1.4;
}
@media (max-width: 767px) {
  .blog_details-title {
    font-size: 25px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog_details-title {
    font-size: 30px;
  }
}
#HomePage .blog_details-meta {
  display: flex;
  align-items: center;
  gap: 35px;
}
@media (max-width: 767px) {
  .blog_details-meta {
    gap: 20px;
    flex-direction: column;
    align-items: flex-start;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog_details-meta {
    gap: 30px;
    flex-direction: row;
    align-items: center;
  }
}
#HomePage .blog_details-author {
  display: flex;
  align-items: center;
  gap: 10px;
}
#HomePage .blog_details-author-img {
  width: 50px;
  height: 50px;
  border: 1px solid rgba(4, 0, 23, 0.1);
  border-radius: 50%;
  padding: 4px;
}
#HomePage .blog_details-author-img img {
  width: 100%;
}
#HomePage .blog_details-author-info span {
  font-size: 14px;
  font-weight: 500;
  color: #848B97;
  display: block;
  line-height: 20px;
}
#HomePage .blog_details-author-info h5 {
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 500;
  line-height: 1.4;
}
#HomePage .blog_details-author-info h5:hover a {
  color: var(--clr-theme-primary);
}
#HomePage .blog_details-rating {
  position: relative;
  z-index: 1;
  padding-left: 15px;
}
#HomePage .blog_details-rating::before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 1px;
  background: rgba(4, 0, 23, 0.14);
  z-index: 1;
}
@media (max-width: 767px) {
  .blog_details-rating {
    padding-left: 0;
  }
  .blog_details-rating::before {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog_details-rating {
    padding-left: 15px;
  }
  .blog_details-rating::before {
    display: block;
  }
}
#HomePage .blog_details-rating ul {
  display: flex;
  gap: 5px;
}
#HomePage .blog_details-rating ul li {
  display: block;
  font-size: 12px;
  color: #FFA121;
  line-height: 20px;
}
#HomePage .blog_details-rating span {
  font-size: 14px;
  font-weight: 500;
  display: block;
  color: #848B97;
  line-height: 22px;
}
#HomePage .blog_details-category {
  position: relative;
  z-index: 1;
  padding-left: 15px;
}
#HomePage .blog_details-category::before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 40px;
  width: 1px;
  background: rgba(4, 0, 23, 0.14);
  z-index: 1;
}
@media (max-width: 767px) {
  .blog_details-category {
    padding-left: 0;
  }
  .blog_details-category::before {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog_details-category {
    padding-left: 15px;
  }
  .blog_details-category::before {
    display: block;
  }
}
#HomePage .blog_details-category span {
  font-size: 14px;
  font-weight: 500;
  color: #848B97;
  display: block;
  line-height: 20px;
}
#HomePage .blog_details-category h5 {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 1.4;
}
#HomePage .blog_details-category h5:hover a {
  color: var(--clr-theme-primary);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog_details-inner-text {
    margin-right: 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .blog_details-inner-text {
    margin-right: 0;
  }
}
#HomePage .blog_details-inner-text p {
  color: rgba(30, 30, 30, 0.7);
}
#HomePage .blog_details-quote {
  background-color: #F5F5F5;
  padding: 35px 85px 35px 45px;
  position: relative;
  z-index: 1;
  margin: 0;
  margin-bottom: 40px;
  border-radius: 6px;
}
#HomePage .blog_details-quote p {
  font-size: 20px;
  font-style: italic;
  color: var(--clr-body-heading);
  margin-bottom: 15px;
}
#HomePage .blog_details-quote h6 {
  font-size: 16px;
  font-weight: 600;
  padding-left: 15px;
  margin-bottom: 0;
  position: relative;
  z-index: 1;
}
#HomePage .blog_details-quote h6::before {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  width: 6px;
  height: 6px;
  background-color: var(--clr-theme-primary);
}
#HomePage .blog_details-quote-icon {
  position: absolute;
  right: 85px;
  bottom: 25px;
  line-height: 1;
  font-size: 45px;
  color: rgba(0, 88, 252, 0.1);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog_details-quote {
    padding: 35px 65px 35px 30px;
  }
}
@media (max-width: 767px) {
  .blog_details-quote {
    padding: 35px 30px;
  }
  .blog_details-quote p {
    font-size: 18px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog_details-quote {
    padding: 35px 30px 35px 30px;
  }
}
#HomePage .blog_details-highlight {
  display: flex;
  align-items: center;
  background-color: #F5F5F5;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 25px;
}
#HomePage .blog_details-highlight-img {
  width: 310px;
  flex-shrink: 0;
  border-radius: 6px;
  overflow: hidden;
}
#HomePage .blog_details-highlight-img img {
  width: 100%;
}
#HomePage .blog_details-highlight-text {
  padding: 0 40px;
  padding-right: 30px;
}
#HomePage .blog_details-highlight-text h3 {
  font-size: 30px;
  line-height: 1.33;
  margin-bottom: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog_details-highlight-img {
    width: 210px;
  }
  .blog_details-highlight-text {
    padding: 0 30px;
  }
  .blog_details-highlight-text h3 {
    font-size: 28px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_details-highlight-img {
    width: 300px;
  }
  .blog_details-highlight-text {
    padding-right: 20px;
    padding-left: 30px;
  }
  .blog_details-highlight-text h3 {
    font-size: 28px;
  }
}
@media (max-width: 767px) {
  .blog_details-highlight {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
  .blog_details-highlight-img {
    width: 100%;
  }
  .blog_details-highlight-text {
    padding-right: 0;
    padding-left: 0;
  }
  .blog_details-highlight-text h3 {
    font-size: 23px;
  }
}
#HomePage .blog_details-content-meta {
  border: 1px solid rgba(4, 0, 23, 0.1);
  border-radius: 10px;
  padding: 34px 40px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  margin-bottom: 50px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog_details-content-meta {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media (max-width: 767px) {
  .blog_details-content-meta {
    flex-direction: column;
    align-items: flex-start;
    padding: 34px 20px;
  }
}
#HomePage .blog_details-content-tag {
  display: flex;
  align-items: center;
  gap: 10px;
}
#HomePage .blog_details-content-tag h6 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  flex-shrink: 0;
}
#HomePage .blog_details-content-tag ul {
  display: flex;
  align-items: center;
  gap: 10px;
}
#HomePage .blog_details-content-tag ul li a {
  font-size: 14px;
  background: #F5F5F5;
  display: block;
  height: 30px;
  line-height: 30px;
  border-radius: 4px;
  padding: 0 15px;
  color: rgba(4, 0, 23, 0.7);
}
@media (max-width: 767px) {
  .blog_details-content-tag {
    align-items: flex-start;
  }
  .blog_details-content-tag ul {
    flex-wrap: wrap;
  }
}
#HomePage .blog_details-content-social {
  display: flex;
  align-items: center;
  gap: 15px;
}
#HomePage .blog_details-content-social h6 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
}
#HomePage .blog_details-content-social ul {
  display: flex;
  align-items: center;
  gap: 15px;
}
#HomePage .blog_details-content-social ul li a {
  font-size: 14px;
  color: rgba(4, 0, 23, 0.3);
  display: block;
}
#HomePage .blog_details-bottom {
  display: flex;
  align-items: center;
  gap: 30px;
}
#HomePage .blog_details-bottom-item {
  width: 100%;
  background: #F5F5F5;
  display: flex;
  align-items: center;
  padding: 30px 60px 30px 30px;
  border: 1px solid transparent;
  border-radius: 6px;
  gap: 20px;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  cursor: pointer;
}
#HomePage .blog_details-bottom-item.inner-item-2 {
  padding: 30px 30px 30px 60px;
}
#HomePage .blog_details-bottom-item:hover {
  background-color: #fff;
  border-color: rgba(4, 0, 23, 0.1);
}
#HomePage .blog_details-bottom-item h5 {
  font-size: 18px;
  font-weight: 500;
  color: var(--clr-body-heading);
  margin-bottom: 0;
  line-height: 1.33;
}
#HomePage .blog_details-bottom-item span {
  display: block;
  color: var(--clr-body-heading);
  font-size: 18px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog_details-bottom-item {
    padding: 30px 25px 30px 25px;
    gap: 15px;
  }
  .blog_details-bottom-item.inner-item-2 {
    padding: 30px 25px 30px 25px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_details-bottom-item {
    padding: 30px 50px 30px 30px;
  }
  .blog_details-bottom-item.inner-item-2 {
    padding: 30px 30px 30px 50px;
  }
}
@media (max-width: 767px) {
  .blog_details-bottom-item {
    padding: 30px 25px 30px 25px;
    gap: 15px;
  }
  .blog_details-bottom-item.inner-item-2 {
    padding: 30px 25px 30px 25px;
  }
}
@media (max-width: 767px) {
  .blog_details-bottom {
    flex-direction: column;
  }
}
#HomePage .blog_details-related-title {
  font-size: 30px;
  line-height: 1.46;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .blog_details-related-title {
    font-size: 25px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog_details-related-title {
    font-size: 30px;
  }
}
#HomePage .blog_details-comment-item {
  display: flex;
  gap: 20px;
  border-bottom: 1px solid rgba(4, 0, 23, 0.1);
  margin-bottom: 30px;
  padding-bottom: 40px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog_details-comment-item.ml-80 {
    margin-left: 60px;
  }
}
@media (max-width: 767px) {
  .blog_details-comment-item {
    flex-direction: column;
  }
  .blog_details-comment-item.ml-80 {
    margin-left: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog_details-comment-item {
    flex-direction: row;
  }
  .blog_details-comment-item.ml-80 {
    margin-left: 50px;
  }
}
#HomePage .blog_details-comment-title {
  font-size: 24px;
  margin-bottom: 30px;
}
#HomePage .blog_details-comment-img {
  width: 60px;
  flex-shrink: 0;
  border-radius: 50%;
  overflow: hidden;
  height: 60px;
}
#HomePage .blog_details-comment-content h6 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 3px;
}
#HomePage .blog_details-comment-content span {
  font-size: 13px;
  color: var(--clr-theme-primary);
  font-weight: 500;
  text-transform: uppercase;
  display: block;
  margin-bottom: 5px;
}
#HomePage .blog_details-comment-content p {
  margin-bottom: 22px;
}
#HomePage .blog_details-comment-content .comment-reply {
  font-size: 15px;
  display: inline-block;
  border: 1px solid rgba(30, 30, 30, 0.1);
  height: 30px;
  padding: 0 20px;
  font-weight: 500;
  color: var(--clr-body-heading);
  border-radius: 4px;
}
#HomePage .blog_details-comment-content .comment-reply:hover {
  background-color: var(--clr-theme-primary);
  color: #fff;
}
#HomePage .blog_details-form-submit {
  display: flex;
  align-items: center;
  gap: 40px;
}
@media (max-width: 767px) {
  .blog_details-form-submit {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
  .blog_details-form-btn {
    order: 1;
  }
}

#HomePage .blog_details-content-social ul li a:hover {
  color: var(--clr-theme-primary);
}

#HomePage .blog_details-content-tag ul li a:hover {
  background: var(--clr-theme-primary);
  color: #fff;
}

#HomePage .blog_details-message-title {
  font-size: 32px;
  margin-bottom: 25px;
}
@media (max-width: 767px) {
  .blog_details-message-title {
    font-size: 25px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog_details-message-title {
    font-size: 30px;
  }
}
#HomePage .blog_details-form-input {
  position: relative;
  z-index: auto;
}
#HomePage .blog_details-form-input textarea, .blog_details-form-input input {
  width: 100%;
  height: 60px;
  line-height: 60px;
  border: none;
  border-bottom: 1px solid rgba(4, 0, 23, 0.14);
  padding: 0 30px;
  color: rgba(4, 0, 23, 0.6);
  font-size: 16px;
}
#HomePage .blog_details-form-input textarea::-webkit-input-placeholder, .blog_details-form-input input::-webkit-input-placeholder {
  color: rgba(4, 0, 23, 0.6);
  font-size: 16px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .blog_details-form-input textarea:-moz-placeholder, .blog_details-form-input input:-moz-placeholder {
  color: rgba(4, 0, 23, 0.6);
  font-size: 16px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .blog_details-form-input textarea::-moz-placeholder, .blog_details-form-input input::-moz-placeholder {
  color: rgba(4, 0, 23, 0.6);
  font-size: 16px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .blog_details-form-input textarea:-ms-input-placeholder, .blog_details-form-input input:-ms-input-placeholder {
  color: rgba(4, 0, 23, 0.6);
  font-size: 16px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .blog_details-form-input textarea {
  height: 95px;
  line-height: 22px;
  padding-top: 20px;
}
#HomePage .blog_details-form-input .inner-icon {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  color: rgba(4, 0, 23, 0.6);
  z-index: 9;
}
#HomePage .blog_details-form-input-select .inner-icon {
  top: 30px;
}
#HomePage .blog_details-form-textarea .inner-icon {
  top: 17px;
  transform: translateY(0);
}

#HomePage .nice-select.blog_details-form-list {
  width: 100%;
  height: 60px;
  line-height: 60px;
  color: rgba(4, 0, 23, 0.6);
  font-size: 16px;
  border: none;
  border-bottom: 1px solid rgba(4, 0, 23, 0.14);
  border-radius: 0;
  padding: 0 30px;
}
#HomePage .nice-select.blog_details-form-list::after {
  border-bottom: 1.5px solid rgba(30, 30, 30, 0.6);
  border-right: 1.5px solid rgba(30, 30, 30, 0.6);
  content: "";
  display: block;
  height: 5px;
  margin-top: -1px;
  pointer-events: none;
  position: absolute;
  right: 25px;
  top: 50%;
  transform-origin: 50% 50%;
  transform: rotate(45deg) translateY(-50%);
  transition: all 0.3s ease-in-out;
  width: 5px;
}
#HomePage .nice-select.blog_details-form-list ul {
  width: 100%;
  border-radius: 0;
  border: none;
  background-color: #fff;
  margin-top: 0;
}
#HomePage .nice-select.blog_details-form-list ul li {
  color: rgba(4, 0, 23, 0.6);
  font-size: 15px;
  font-weight: 400;
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding: 0 30px;
  min-height: 50px;
}
#HomePage .nice-select.blog_details-form-list ul li.selected {
  font-weight: 400;
}

#HomePage .theme-btn.blog_details-btn {
  height: 50px;
  line-height: 50px;
  padding: 0 45px;
}

#HomePage .blog_details-form-condition label {
  font-size: 15px;
}

#HomePage .blog_details-form-condition .condition_label {
  display: block;
  position: relative;
  padding-left: 22px;
  cursor: pointer;
  font-size: 15px;
  user-select: none;
}
#HomePage .blog_details-form-condition .condition_label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
#HomePage .blog_details-form-condition .check_mark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 14px;
  width: 14px;
  background-color: transparent;
  border: 1px solid rgba(30, 30, 30, 0.2);
  border-radius: 2px;
}
#HomePage .blog_details-form-condition .check_mark:after {
  content: "";
  position: absolute;
  display: none;
}
#HomePage .blog_details-form-condition .condition_label input:checked ~ .check_mark:after {
  display: block;
}
#HomePage .blog_details-form-condition .condition_label .check_mark:after {
  left: 4px;
  top: 1px;
  width: 4px;
  height: 8px;
  border: solid rgba(30, 30, 30, 0.4);
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}

#HomePage .blog_details-price {
  padding-left: 40px;
  padding-right: 40px;
}
#HomePage .blog_details-price del {
  display: block;
  font-size: 16px;
  margin-bottom: 4px;
}
#HomePage .blog_details-price h2 {
  font-size: 44px;
  font-weight: 600;
  margin-bottom: 20px;
}
#HomePage .blog_details-list ul {
  margin-bottom: 40px;
}
#HomePage .blog_details-list ul li {
  border-bottom: 1px solid rgba(30, 30, 30, 0.1);
  font-size: 16px;
  font-weight: 500;
  padding: 10px 40px;
  color: rgba(30, 30, 30, 0.7);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#HomePage .blog_details-list ul li span i {
  margin-right: 10px;
  color: var(--clr-theme-primary);
  font-size: 16px;
}
#HomePage .blog_details-sidebar-btn {
  margin-left: 40px;
  margin-right: 40px;
}
#HomePage .blog_details-widget {
  background: #fff;
  padding: 35px 30px 40px;
  box-shadow: 0px 20px 80px rgba(30, 30, 30, 0.1);
  margin-bottom: 30px;
  border-radius: 10px;
}
@media (max-width: 767px) {
  .blog_details-widget {
    padding: 35px 25px 40px;
  }
}
#HomePage .blog_details-widget.widget-category {
  padding-bottom: 25px;
}
#HomePage .blog_details-widget-title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 25px;
}
#HomePage .blog_details-widget form {
  position: relative;
  z-index: 1;
}
#HomePage .blog_details-widget form button {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  border: none;
  padding: 0 15px;
  background-color: var(--clr-theme-primary);
  color: #fff;
  border-radius: 0 6px 6px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
#HomePage .blog_details-widget form input {
  width: 100%;
  height: 50px;
  line-height: 50px;
  border: none;
  background: #F5F5F5;
  border-radius: 6px;
  padding: 0 20px;
  padding-right: 65px;
  font-size: 14px;
  color: rgba(30, 30, 30, 0.7);
}
#HomePage .blog_details-widget-post {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 15px;
}
#HomePage .blog_details-widget-post:last-child {
  margin-bottom: 0;
}
#HomePage .blog_details-widget-course {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 15px;
}
#HomePage .blog_details-widget-course:last-child {
  margin-bottom: 0;
}

#HomePage .blog_details-list ul li:first-child {
  border-top: 1px solid rgba(30, 30, 30, 0.1);
}

#HomePage .blog_details-post-img {
  width: 90px;
  height: 90px;
  flex-shrink: 0;
  border-radius: 4px;
  overflow: hidden;
}
#HomePage .blog_details-post-info span {
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 6px;
  color: rgba(30, 30, 30, 0.6);
  font-weight: 500;
}
#HomePage .blog_details-post-info h6 {
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 500;
  line-height: 22px;
}
#HomePage .blog_details-post-info h6:hover a {
  color: var(--clr-theme-primary);
}

#HomePage .blog_details-course-img {
  width: 90px;
  height: 90px;
  flex-shrink: 0;
  border-radius: 4px;
  overflow: hidden;
}
#HomePage .blog_details-course-info h6 {
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 500;
  line-height: 22px;
}
#HomePage .blog_details-course-info h6:hover a {
  color: var(--clr-theme-primary);
}
#HomePage .blog_details-course-info .inner-course-rate {
  font-size: 15px;
  font-weight: 600;
  color: var(--clr-theme-primary);
  display: block;
}

#HomePage .blog_details-widget-tag ul {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
#HomePage .blog_details-widget-tag ul li a {
  font-size: 14px;
  background: #F5F5F5;
  display: block;
  height: 30px;
  line-height: 30px;
  border-radius: 4px;
  padding: 0 15px;
  color: rgba(4, 0, 23, 0.7);
}
#HomePage .blog_details-widget-tag ul li a:hover {
  background-color: var(--clr-theme-primary);
  color: #fff;
}
#HomePage .blog_details-widget-category ul li a {
  display: block;
  position: relative;
  z-index: 1;
  font-size: 16px;
  font-weight: 400;
  padding-left: 17px;
  line-height: 44px;
  color: var(--clr-body-heading);
  border-bottom: 1px solid rgba(30, 30, 30, 0.1);
}
#HomePage .blog_details-widget-category ul li a:hover {
  color: var(--clr-theme-primary);
}
#HomePage .blog_details-widget-category ul li a::before {
  position: absolute;
  left: 0;
  top: 50%;
  content: "";
  width: 6px;
  height: 6px;
  background-color: var(--clr-body-heading);
  border-radius: 50%;
  transform: translateY(-50%);
}
#HomePage .blog_details-widget-category ul li:last-child a {
  border: none;
}

/*
************
*************************
18. cta
******************************************************* 
*************************************************************** */
#HomePage .cta-area {
  position: relative;
  z-index: 9;
  margin-bottom: -120px;
}
#HomePage .cta-wrapper {
  background-color: var(--clr-theme-primary);
  border-radius: 10px;
  padding: 50px 80px 40px;
  box-shadow: 0px 30px 80px rgba(30, 30, 30, 0.3);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cta-wrapper {
    padding: 50px 50px 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta-wrapper {
    padding: 50px 50px 50px;
  }
}
@media (max-width: 767px) {
  .cta-wrapper {
    padding: 40px 30px 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cta-wrapper {
    padding: 50px 50px 50px;
  }
}
#HomePage .cta-title {
  font-size: 40px;
  font-weight: 700;
  line-height: 1.25;
  color: #fff;
  margin-bottom: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cta-title {
    font-size: 36px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta-title {
    font-size: 35px;
  }
}
@media (max-width: 767px) {
  .cta-title {
    font-size: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cta-title {
    font-size: 35px;
  }
}
#HomePage .cta-subtitle {
  color: #E8184D;
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
  background-color: #FDF5F7;
  padding: 10px 25px;
  line-height: 1;
  position: relative;
  z-index: 1;
  margin-bottom: 10px;
}
#HomePage .cta-subtitle::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 2px;
  height: 100%;
  background-color: #E8184D;
  content: "";
}
@media (max-width: 767px) {
  .cta-subtitle {
    font-size: 14px;
    padding: 10px 20px;
  }
}
#HomePage .cta-button {
  display: flex;
  gap: 20px;
  justify-content: end;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .cta-button {
    justify-content: flex-start;
  }
}
@media (max-width: 767px) {
  .cta-button {
    flex-direction: column;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cta-button {
    flex-direction: row;
  }
}
#HomePage .cta-btn {
  height: 55px;
  border: 1px solid #fff;
  color: #fff;
  padding: 0 25px;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
  line-height: 55px;
}
#HomePage .cta-btn i {
  font-size: 18px;
  margin-right: 10px;
}
#HomePage .cta-btn:hover {
  background: #fff;
  color: var(--clr-body-heading);
}
@media (max-width: 767px) {
  .cta-btn {
    text-align: center;
  }
}

#HomePage .h2_cta-content-form {
  position: relative;
  z-index: 1;
  margin: 0 40px;
}
@media (max-width: 767px) {
  .h2_cta-content-form {
    margin: 0 15px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h2_cta-content-form {
    margin: 0 40px;
  }
}
#HomePage .h2_cta-content-form input {
  width: 100%;
  height: 55px;
  line-height: 55px;
  padding-left: 25px;
  border-radius: 6px;
  border: none;
  padding-right: 195px;
  color: rgba(30, 30, 30, 0.6);
  background-color: #fff;
  font-size: 15px;
  box-shadow: 0px 6px 30px rgba(30, 30, 30, 0.1);
}
#HomePage .h2_cta-content-form input::-webkit-input-placeholder {
  color: rgba(30, 30, 30, 0.6);
  font-size: 15px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .h2_cta-content-form input:-moz-placeholder {
  color: rgba(30, 30, 30, 0.6);
  font-size: 15px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .h2_cta-content-form input::-moz-placeholder {
  color: rgba(30, 30, 30, 0.6);
  font-size: 15px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .h2_cta-content-form input:-ms-input-placeholder {
  color: rgba(30, 30, 30, 0.6);
  font-size: 15px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
@media (max-width: 767px) {
  .h2_cta-content-form input {
    padding-right: 25px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h2_cta-content-form input {
    padding-right: 100px;
  }
}
#HomePage .h2_cta-content-form button {
  position: absolute;
  right: 7px;
  top: 50%;
  transform: translateY(-50%);
  height: 40px;
  line-height: 40px;
  background: var(--clr-theme-primary);
  color: #fff;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  padding: 0 20px;
}
@media (max-width: 767px) {
  .h2_cta-content-form button {
    position: relative;
    top: 15px;
    transform: none;
    right: 0;
    width: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h2_cta-content-form button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 7px;
    width: auto;
  }
}

#HomePage .h3_cta-area .cta-wrapper {
  background-color: var(--clr-theme-primary-3);
}

#HomePage .h4_cta-area .cta-wrapper {
  background-color: var(--clr-theme-primary-4);
}

#HomePage .h5_cta-area .cta-wrapper {
  background-color: var(--clr-theme-primary-5);
}
#HomePage .h5_cta-area .cta-title {
  color: var(--clr-body-heading);
}
#HomePage .h5_cta-area .cta-btn {
  color: var(--clr-body-heading);
  border-color: var(--clr-body-heading);
}
#HomePage .h5_cta-area .cta-btn:hover {
  border-color: transparent;
}

#HomePage .h6_cta-area {
  position: relative;
  z-index: 9;
  margin-bottom: -120px;
}
#HomePage .h6_cta-wrapper {
  background-color: var(--clr-theme-primary);
  padding: 55px 60px 55px 370px;
  box-shadow: 0px 30px 80px rgba(30, 30, 30, 0.3);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h6_cta-wrapper {
    padding: 55px 60px 55px 280px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h6_cta-wrapper {
    padding: 55px 60px 55px 280px;
  }
}
@media (max-width: 767px) {
  .h6_cta-wrapper {
    flex-wrap: wrap;
    padding: 50px 35px 50px 35px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
  .h6_cta-wrapper {
    padding: 55px 50px 55px 50px;
  }
}
#HomePage .h6_cta-wrapper-img {
  position: absolute;
  left: 50px;
  bottom: 0;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .h6_cta-wrapper-img {
    left: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h6_cta-wrapper-img {
    display: none;
  }
}
#HomePage .h6_cta-title {
  color: #FFF;
  font-size: 48px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h6_cta-title {
    font-size: 36px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h6_cta-title {
    font-size: 35px;
  }
}
@media (max-width: 767px) {
  .h6_cta-title {
    font-size: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h6_cta-title {
    font-size: 35px;
  }
}
#HomePage .h6_cta-content {
  width: 460px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h6_cta-content {
    width: 380px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h6_cta-content {
    width: 65%;
  }
}
@media (max-width: 767px) {
  .h6_cta-content {
    width: 100%;
  }
}
#HomePage .h6_cta-btn {
  height: 55px;
  background-color: #fff;
  color: var(--clr-theme-primary-6);
  padding: 0 30px;
  line-height: 55px;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
}
#HomePage .h6_cta-btn i {
  font-size: 16px;
}
#HomePage .h6_cta-btn:hover {
  background: var(--clr-theme-primary-6);
  color: #fff;
}

#HomePage .h8_cta-area .h6_cta-wrapper {
  border-radius: 10px;
}

#HomePage .h9_cta-area .h6_cta-wrapper {
  border-radius: 10px;
}

/*
************
*************************
19. teacher
******************************************************* 
*************************************************************** */
#HomePage .h2_teacher-section {
  min-height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 50px 60px 60px;
  border-radius: 10px;
  overflow: hidden;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h2_teacher-section {
    padding: 50px 30px 60px 45px;
  }
}
@media (max-width: 767px) {
  .h2_teacher-section {
    padding: 45px 30px 50px;
  }
  .h2_teacher-section .section-title br {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h2_teacher-section {
    padding: 45px 50px 50px 50px;
  }
  .h2_teacher-section .section-title br {
    display: block;
  }
}
#HomePage .h2_teacher-img {
  border-radius: 10px;
  overflow: hidden;
}
#HomePage .h2_teacher-img img {
  width: 100%;
  -webkit-transition: all 0.6s linear 0s;
  -moz-transition: all 0.6s linear 0s;
  -ms-transition: all 0.6s linear 0s;
  -o-transition: all 0.6s linear 0s;
  transition: all 0.6s linear 0s;
}
#HomePage .h2_teacher-item {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
#HomePage .h2_teacher-content {
  position: absolute;
  left: 0;
  right: 0;
  width: calc(100% - 60px);
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  padding: 15px 25px;
  text-align: center;
  bottom: 30px;
  border-radius: 6px;
  opacity: 0;
  visibility: hidden;
  bottom: -50px;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
#HomePage .h2_teacher-content h5 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
  line-height: 24px;
}
#HomePage .h2_teacher-content h5:hover a {
  color: var(--clr-theme-primary);
}
#HomePage .h2_teacher-content span {
  font-size: 14px;
  font-weight: 500;
  display: block;
  line-height: 20px;
}

#HomePage .h2_teacher-item:hover .h2_teacher-content {
  opacity: 1;
  visibility: visible;
  bottom: 30px;
}
#HomePage .h2_teacher-item:hover .h2_teacher-img img {
  transform: scale(1.2);
}

#HomePage .h3_teacher-img {
  position: relative;
  z-index: 1;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 25px;
}
#HomePage .h3_teacher-social {
  position: absolute;
  right: 30px;
  bottom: 30px;
  display: flex;
  align-items: center;
  gap: 10px;
}
#HomePage .h3_teacher-social .share {
  width: 50px;
  height: 50px;
  background: #fff;
  display: grid;
  place-items: center;
  border-radius: 4px;
  font-size: 18px;
  color: var(--clr-body-heading);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  cursor: pointer;
}
#HomePage .h3_teacher-social ul {
  display: flex;
  gap: 10px;
}
#HomePage .h3_teacher-social ul li {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  margin-bottom: -10px;
}
#HomePage .h3_teacher-social ul li a {
  width: 50px;
  height: 50px;
  background: var(--clr-theme-primary-3);
  display: grid;
  place-items: center;
  color: #fff;
  border-radius: 4px;
  font-size: 18px;
}
#HomePage .h3_teacher-social ul li:nth-child(2) {
  -webkit-transition: all 0.4s linear 0s;
  -moz-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  -o-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
}
#HomePage .h3_teacher-social ul li:first-child {
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
#HomePage .h3_teacher-content {
  display: flex;
  align-items: center;
  gap: 45px;
}
@media (max-width: 767px) {
  .h3_teacher-content {
    gap: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h3_teacher-content {
    gap: 45px;
  }
}
#HomePage .h3_teacher-content-title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0;
}
#HomePage .h3_teacher-content-title:hover {
  color: var(--clr-theme-primary-3);
}
#HomePage .h3_teacher-content span {
  font-size: 16px;
  display: inline-block;
  color: rgba(30, 30, 30, 0.7);
}
#HomePage .h3_teacher-img:hover .share {
  background: var(--clr-theme-primary-3);
  color: #fff;
}
#HomePage .h3_teacher-img:hover ul li {
  opacity: 1;
  visibility: visible;
  margin-bottom: 0;
}

#HomePage .teacher-pagination.swiper-pagination-bullets {
  right: 0;
  left: auto;
  text-align: center;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 14px;
}
#HomePage .teacher-pagination .swiper-pagination-bullet {
  width: 7px;
  height: 7px;
  cursor: pointer;
  display: inline-block;
  border-radius: 50%;
  background: #fff;
  border: 1px solid rgba(30, 30, 30, 0.7);
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .teacher-pagination.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0;
}
#HomePage .teacher-pagination.swiper-pagination-bullets .swiper-pagination-bullet:last-child {
  margin-right: 0;
}
#HomePage .teacher-pagination .swiper-pagination-bullet-active {
  opacity: 1;
  width: 14px;
  height: 14px;
  background: var(--clr-body-heading);
  border-color: transparent;
}

#HomePage .h5_teacher-img {
  position: relative;
  z-index: 1;
  border-radius: 0;
  overflow: hidden;
  margin-bottom: 25px;
}
#HomePage .h5_teacher-img::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(243, 235, 30, 0) 0%, #F3EB1E 100%);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .h5_teacher-social {
  position: absolute;
  left: 30px;
  bottom: 30px;
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 5;
}
#HomePage .h5_teacher-social ul {
  display: flex;
  gap: 10px;
}
#HomePage .h5_teacher-social ul li {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  margin-bottom: -10px;
}
#HomePage .h5_teacher-social ul li a {
  width: 50px;
  height: 50px;
  background: var(--clr-body-heading);
  display: grid;
  place-items: center;
  color: var(--clr-theme-primary-5);
  font-size: 18px;
}
#HomePage .h5_teacher-social ul li a:hover {
  background-color: #fff;
  color: var(--clr-body-heading);
}
#HomePage .h5_teacher-social ul li:nth-child(2) {
  -webkit-transition: all 0.4s linear 0s;
  -moz-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  -o-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
}
#HomePage .h5_teacher-social ul li:first-child {
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
#HomePage .h5_teacher-content-title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 3px;
}
#HomePage .h5_teacher-content-title:hover {
  color: var(--clr-theme-primary);
}
#HomePage .h5_teacher-content span {
  font-size: 16px;
  display: inline-block;
  color: rgba(30, 30, 30, 0.7);
}
#HomePage .h5_teacher-img:hover::after {
  opacity: 1;
  visibility: visible;
}
#HomePage .h5_teacher-img:hover ul li {
  opacity: 1;
  visibility: visible;
  margin-bottom: 0;
}

#HomePage .h6_teacher-area {
  background-color: #F6F6F6;
}
@media (max-width: 1799px) {
  .h6_teacher-area.pt-115 {
    padding-top: 0;
  }
}
#HomePage .h6_teacher-area .h3_teacher-img {
  border-radius: 0;
}
#HomePage .h6_teacher-area .h3_teacher-img:hover .share {
  background: var(--clr-theme-primary-6);
}
#HomePage .h6_teacher-area .h3_teacher-social ul li a {
  background: var(--clr-theme-primary-6);
}

#HomePage .h8_teacher-img {
  position: relative;
  z-index: 1;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 25px;
}
#HomePage .h8_teacher-social {
  position: absolute;
  right: 30px;
  top: 30px;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: column;
}
#HomePage .h8_teacher-social .share {
  width: 36px;
  height: 36px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  font-size: 16px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-shadow: drop-shadow(0px 20px 100px rgba(25, 35, 53, 0.3));
  color: #fff;
  -webkit-transition: all 0.4s linear 0s;
  -moz-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  -o-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
  cursor: pointer;
}
#HomePage .h8_teacher-social ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
#HomePage .h8_teacher-social ul li {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  margin-bottom: -10px;
}
#HomePage .h8_teacher-social ul li a {
  width: 36px;
  height: 36px;
  background: #fff;
  display: grid;
  place-items: center;
  color: #395BDF;
  border-radius: 50%;
  font-size: 16px;
}
#HomePage .h8_teacher-social ul li:nth-child(2) {
  -webkit-transition: all 0.4s linear 0s;
  -moz-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  -o-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
}
#HomePage .h8_teacher-social ul li:first-child {
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
#HomePage .h8_teacher-content {
  text-align: center;
}
#HomePage .h8_teacher-content-title {
  color: #021936;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 8px;
  line-height: 1.2;
}
#HomePage .h8_teacher-content-title:hover a {
  color: #395BDF;
}
#HomePage .h8_teacher-content span {
  display: block;
  color: rgba(2, 25, 54, 0.7);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
}
#HomePage .h8_teacher-img:hover .share {
  background: #fff;
  color: #395BDF;
}
#HomePage .h8_teacher-img:hover ul li {
  opacity: 1;
  visibility: visible;
  margin-bottom: 0;
}

#HomePage .h8_teacher-navigation {
  display: flex;
  gap: 10px;
  justify-content: end;
}
#HomePage .h8_teacher-navigation div {
  border-radius: 40px;
  border: 1px solid rgba(2, 25, 54, 0.1);
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .h8_teacher-navigation div svg {
  color: #021936;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .h8_teacher-navigation div:hover {
  background-color: #395BDF;
}
#HomePage .h8_teacher-navigation div:hover svg {
  color: #fff;
}
@media (max-width: 767px) {
  .h8_teacher-navigation {
    justify-content: flex-start;
  }
}

#HomePage .h9_teacher-item {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
#HomePage .h9_teacher-img {
  border-radius: 20px;
  overflow: hidden;
}
#HomePage .h9_teacher-img img {
  width: 100%;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
#HomePage .h9_teacher-content {
  position: absolute;
  left: 0;
  right: 0;
  width: calc(100% - 60px);
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  padding: 30px 30px 24px;
  bottom: 30px;
  border-radius: 10px;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
#HomePage .h9_teacher-content h5 {
  color: #17161C;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
  line-height: 1;
}
#HomePage .h9_teacher-content span {
  color: rgba(23, 22, 28, 0.7);
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  display: block;
  line-height: 24px;
}
#HomePage .h9_teacher-pagination.swiper-pagination-bullets {
  right: 0;
  left: auto;
  text-align: center;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
#HomePage .h9_teacher-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  cursor: pointer;
  display: inline-block;
  border-radius: 50%;
  background: rgba(23, 22, 28, 0.14);
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .h9_teacher-pagination.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0;
}
#HomePage .h9_teacher-pagination.swiper-pagination-bullets .swiper-pagination-bullet:last-child {
  margin-right: 0;
}
#HomePage .h9_teacher-pagination .swiper-pagination-bullet-active {
  opacity: 1;
  width: 40px;
  border-radius: 20px;
  background: #1865F2;
}

#HomePage .h9_teacher-item:hover .h9_teacher-content {
  box-shadow: 5px 0px 0px 0px #1865F2 inset;
}
#HomePage .h9_teacher-item:hover .h9_teacher-img img {
  transform: scale(1.1);
}

/*
************
*************************
20. price
******************************************************* 
*************************************************************** */
#HomePage .h2_price-item {
  background-color: #F5F5F5;
  text-align: center;
  padding: 40px 40px 40px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
#HomePage .h2_price-item-title h5 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .h2_price-item {
    padding: 35px 25px 35px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h2_price-item {
    padding: 35px 40px 35px;
  }
}
#HomePage .h2_price-popular-tag span {
  display: block;
  position: absolute;
  z-index: 1;
  content: "";
  right: 0;
  top: 0;
  font-size: 14px;
  font-weight: 600;
  background-color: var(--clr-color-darkPink);
  color: #fff;
  padding: 3px 20px;
}
#HomePage .h2_price-amount {
  margin-bottom: 15px;
}
#HomePage .h2_price-amount-info {
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
}
#HomePage .h2_price-amount-info h2 {
  font-size: 44px;
  font-weight: 600;
  margin-bottom: 0;
}
#HomePage .h2_price-amount-info p {
  margin-bottom: 0;
}
#HomePage .h2_price-amount-info p span {
  display: block;
  text-align: left;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: var(--clr-body-heading);
}
#HomePage .h2_price-amount del {
  font-size: 16px;
  color: #787878;
  display: inline-block;
  margin-bottom: 10px;
}

#HomePage .h2_price-middle-info {
  margin-bottom: 25px;
}
#HomePage .h2_price-middle-info p {
  font-size: 14px;
  color: #787878;
  margin-bottom: 10px;
  line-height: 20px;
}
#HomePage .h2_price-middle-info-2 {
  color: var(--clr-body-heading);
}
#HomePage .h2_price-button {
  margin-bottom: 20px;
}
#HomePage .h2_price-button a {
  display: block;
  font-size: 15px;
  font-weight: 500;
  border: 1px solid rgba(30, 30, 30, 0.1);
  height: 50px;
  line-height: 50px;
  color: var(--clr-body-heading);
  border-radius: 6px;
}
#HomePage .h2_price-button a:hover {
  background-color: var(--clr-theme-primary);
  color: #fff;
}
#HomePage .h2_price-content-top {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 27px;
}
@media (max-width: 767px) {
  .h2_price-content-top {
    flex-direction: column;
    gap: 15px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h2_price-content-top {
    flex-direction: row;
    gap: 10px;
  }
}
#HomePage .h2_price-content-top a {
  color: var(--clr-body-heading);
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
  display: block;
  line-height: 1;
}
#HomePage .h2_price-content-top a:hover {
  color: var(--clr-theme-primary);
}
#HomePage .h2_price-content-top span {
  display: block;
  color: var(--clr-color-darkPink);
  font-size: 14px;
  font-weight: 500;
  border: 1px solid rgba(210, 9, 59, 0.3);
  border-radius: 4px;
  background: rgba(210, 9, 59, 0.04);
  line-height: 1;
  padding: 3px 10px;
}

#HomePage .h2_price-content-list ul li {
  display: block;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 5px;
  padding-left: 25px;
  position: relative;
  z-index: 1;
}
#HomePage .h2_price-content-list ul li:last-child {
  margin-bottom: 0;
}
#HomePage .h2_price-content-list ul li::before {
  position: absolute;
  left: 8px;
  top: 12px;
  content: "";
  width: 4px;
  height: 4px;
  background-color: var(--clr-body-heading);
  border-radius: 50%;
}

/*
************
*************************
21. breadcrumb
******************************************************* 
*************************************************************** */
#HomePage .breadcrumb-area {
  min-height: 330px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}
@media (max-width: 767px) {
  .breadcrumb-area {
    min-height: 300px;
  }
}
#HomePage .breadcrumb-content {
  text-align: center;
}
#HomePage .breadcrumb-title {
  color: var(--clr-body-heading);
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 10px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-title {
    font-size: 45px;
  }
}
@media (max-width: 767px) {
  .breadcrumb-title {
    font-size: 35px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .breadcrumb-title {
    font-size: 40px;
  }
}
#HomePage .breadcrumb-list {
  display: flex;
  justify-content: center;
  gap: 10px;
}
#HomePage .breadcrumb-list a {
  color: var(--clr-body-heading);
  display: block;
  position: relative;
  z-index: 1;
  font-size: 16px;
  font-weight: 500;
}
#HomePage .breadcrumb-list a::after {
  display: inline-block;
  content: "/";
  font-size: 14px;
  font-weight: 400;
  margin-left: 10px;
}
#HomePage .breadcrumb-list span {
  color: var(--clr-theme-primary);
  display: block;
  font-size: 16px;
  font-weight: 500;
}

#HomePage .breadcrumb-shape {
  position: absolute;
  top: 70px;
  right: 31%;
  animation: animation-popup-1 4s linear 0s infinite alternate;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-shape {
    right: 25%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-shape {
    top: 60px;
    right: 20%;
  }
}
@media (max-width: 767px) {
  .breadcrumb-shape {
    top: 40px;
    right: 15%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .breadcrumb-shape {
    right: 20%;
  }
}

/*
************
*************************
22. gallery
******************************************************* 
*************************************************************** */
#HomePage .innerPage_gallery-tab .nav {
  justify-content: end;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .innerPage_gallery-tab .nav {
    justify-content: flex-start;
  }
}
#HomePage .innerPage_gallery-tab .nav .nav-item {
  margin-bottom: 10px;
}
#HomePage .innerPage_gallery-tab .nav .nav-item .nav-link {
  padding: 0;
  background: transparent;
  color: var(--clr-body-heading);
  font-size: 15px;
  margin-right: 25px;
  display: flex;
  position: relative;
  z-index: 1;
}
#HomePage .innerPage_gallery-tab .nav .nav-item .nav-link span {
  position: absolute;
  left: 0;
  top: -25px;
  background-color: var(--clr-theme-primary);
  color: #fff;
  display: block;
  font-size: 12px;
  padding: 4px 8px;
  line-height: 1;
}
#HomePage .innerPage_gallery-tab .nav .nav-item .nav-link span::before {
  position: absolute;
  left: 3px;
  bottom: -6px;
  content: "";
  width: 9px;
  height: 8px;
  background: var(--clr-theme-primary);
  clip-path: polygon(0 0, 50% 100%, 100% 0);
}
#HomePage .innerPage_gallery-tab .nav .nav-item:last-child .nav-link {
  margin-right: 0;
}

#HomePage .innerPage_gallery-tab .nav-pills .nav-link.active, .innerPage_gallery-tab .nav-pills .show > .nav-link {
  color: var(--clr-theme-primary);
}

#HomePage .innerPage_gallery-item {
  position: relative;
  z-index: 1;
  border-radius: 10px;
  overflow: hidden;
}
#HomePage .innerPage_gallery-img img {
  width: 100%;
}
#HomePage .innerPage_gallery-content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .innerPage_gallery-content::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: rgba(30, 30, 30, 0.6);
  z-index: 1;
}
#HomePage .innerPage_gallery-content a {
  width: 90px;
  height: 90px;
  background: #fff;
  display: grid;
  place-items: center;
  border-radius: 50%;
  font-size: 20px;
  color: var(--clr-theme-primary);
  z-index: 99;
}
#HomePage .innerPage_gallery-content a i {
  font-weight: 300;
}

#HomePage .innerPage_gallery-item:hover .innerPage_gallery-content {
  opacity: 1;
  visibility: visible;
}

/*
************
*************************
23. account
******************************************************* 
*************************************************************** */
#HomePage .account-wrap {
  background: #fff;
  box-shadow: 0px 10px 50px rgba(30, 30, 30, 0.1);
  max-width: 690px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  overflow: hidden;
}
#HomePage .account-top {
  display: flex;
  align-items: center;
  gap: 10px;
}
#HomePage .account-top-link {
  width: 100%;
  background: #F5F5F5;
  height: 55px;
  line-height: 55px;
  text-align: center;
  border-radius: 10px 0 10px 0;
  border: 1px solid rgba(4, 0, 23, 0.1);
  position: relative;
  z-index: 1;
}
#HomePage .account-top-link a {
  color: var(--clr-body-heading);
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  display: inline-block;
}
#HomePage .account-top-current {
  width: 100%;
  border: 1px solid rgba(4, 0, 23, 0.1);
  border-radius: 0 10px 0 10px;
  text-align: center;
  height: 55px;
  line-height: 55px;
  border-top: 2px solid var(--clr-theme-primary);
}
#HomePage .account-top-current span {
  font-size: 16px;
  font-weight: 500;
  color: var(--clr-body-heading);
  width: 100%;
  display: inline-block;
}
#HomePage .account-main {
  padding: 65px 80px 70px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .account-main {
    padding: 65px 60px 70px;
  }
}
@media (max-width: 767px) {
  .account-main {
    padding: 65px 30px 70px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .account-main {
    padding: 65px 50px 70px;
  }
}
#HomePage .account-title {
  font-size: 32px;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .account-title {
    font-size: 28px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .account-title {
    font-size: 32px;
  }
}

#HomePage .sign-up .account-top-link {
  border-radius: 0 10px 0 10px;
}
#HomePage .sign-up .account-top-current {
  border-radius: 10px 0 10px 0;
}

#HomePage .account-form-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#HomePage .account-form-label label {
  font-size: 14px;
  font-weight: 500;
  color: var(--clr-body-heading);
  margin-bottom: 5px;
}
#HomePage .account-form-label a {
  font-size: 14px;
  color: rgba(4, 0, 23, 0.6);
  font-weight: 400;
  display: block;
  margin-bottom: 5px;
}
#HomePage .account-form-input {
  position: relative;
  z-index: 1;
}
#HomePage .account-form-input input {
  width: 100% !important;
  height: 50px !important;
  line-height: 50px !important;
  border: 1px solid rgba(4, 0, 23, 0.1) !important;
  border-radius: 6px !important;
  padding: 0 20px !important;
  font-size: 14px !important;
  color: rgba(4, 0, 23, 0.6) !important;
}
#HomePage .account-form-input span {
  position: absolute;
  right: 20px;
  top: 0;
  height: 50px;
  display: inline-block;
  line-height: 50px;
  font-size: 14px;
  cursor: pointer;
}
#HomePage .account-form-input-pass input {
  padding-right: 50px;
}
#HomePage .account-form-button {
  margin-bottom: 40px;
}
#HomePage .account-form-condition {
  margin-bottom: 35px;
}
#HomePage .account-form-condition span {
  font-size: 14px;
  font-weight: 400;
}
#HomePage .account-btn {
  display: block;
  width: 100%;
  height: 50px;
  line-height: 50px;
  border: none;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  background-color: var(--clr-theme-primary);
  border-radius: 6px;
}
#HomePage .account-break {
  position: relative;
  z-index: 1;
}
#HomePage .account-break::before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 100%;
  height: 1px;
  background: rgba(4, 0, 23, 0.14);
}
#HomePage .account-break span {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  font-size: 14px;
  line-height: 1;
  padding: 5px 11px;
  color: rgba(4, 0, 23, 0.7);
}
#HomePage .account-bottom {
  padding-top: 35px;
}
#HomePage .account-bottom-text {
  text-align: center;
}
#HomePage .account-bottom-text p {
  margin-bottom: 0;
  font-size: 14px;
  color: var(--clr-body-heading);
  line-height: 20px;
}
#HomePage .account-bottom-text p a {
  display: inline-block;
  color: var(--clr-theme-primary);
}
#HomePage .account-option {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 35px;
}
#HomePage .account-option-account {
  display: flex;
  align-items: center;
  border: 1px solid rgba(4, 0, 23, 0.1);
  height: 50px;
  width: 100%;
  justify-content: center;
  gap: 10px;
  border-radius: 6px;
  line-height: 50px;
}
#HomePage .account-option-account img {
  flex-shrink: 0;
  max-width: 20px;
}
#HomePage .account-option-account span {
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
}
@media (max-width: 767px) {
  .account-option {
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .account-option {
    flex-wrap: nowrap;
  }
}

#HomePage .account-form-condition .condition_label {
  display: block;
  position: relative;
  padding-left: 22px;
  cursor: pointer;
  font-size: 15px;
  user-select: none;
}
#HomePage .account-form-condition .condition_label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
#HomePage .account-form-condition .check_mark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 14px;
  width: 14px;
  background-color: transparent;
  border: 1px solid rgba(30, 30, 30, 0.3);
  border-radius: 2px;
}
#HomePage .account-form-condition .check_mark:after {
  content: "";
  position: absolute;
  display: none;
}
#HomePage .account-form-condition .condition_label input:checked ~ .check_mark:after {
  display: block;
}
#HomePage .account-form-condition .condition_label .check_mark:after {
  left: 4px;
  top: 1px;
  width: 4px;
  height: 8px;
  border: solid rgba(30, 30, 30, 0.4);
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}

/*
************
*************************
24. team details
******************************************************* 
*************************************************************** */
#HomePage .teacher_details-title {
  font-size: 28px;
  margin-bottom: 5px;
}
@media (max-width: 767px) {
  .teacher_details-title {
    font-size: 25px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .teacher_details-title {
    font-size: 28px;
  }
}
#HomePage .teacher_detailstitle2 {
  font-size: 20px;
  margin-bottom: 20px;
  line-height: 1.1;
}
#HomePage .teacher_details-admin {
  margin-bottom: 11px;
}
#HomePage .teacher_details-admin span {
  font-size: 16px;
  display: block;
}
#HomePage .teacher_details-rating {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
}
#HomePage .teacher_details-rating ul {
  display: flex;
  align-items: center;
  gap: 5px;
}
#HomePage .teacher_details-rating ul li i {
  font-size: 14px;
  color: #f8b81f;
}
#HomePage .teacher_details-rating span {
  font-size: 14px;
  color: var(--clr-body-heading);
}

#HomePage .teacher_details-info ul {
  margin-bottom: 17px;
}
#HomePage .teacher_details-info ul li {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  margin-bottom: 6px;
}
#HomePage .teacher_details-info ul li span {
  font-size: 16px;
  font-weight: 500;
  color: var(--clr-body-heading);
  min-width: 70px;
}
#HomePage .teacher_details-info ul li a:hover {
  color: var(--clr-theme-primary);
}
#HomePage .teacher_details-info ul li:last-child {
  margin-bottom: 0;
}
#HomePage .teacher_details-social {
  display: flex;
  gap: 10px;
}
#HomePage .teacher_details-social a {
  display: grid;
  width: 40px;
  height: 40px;
  border: 1px solid rgba(30, 30, 30, 0.15);
  place-items: center;
  border-radius: 50%;
  color: rgba(30, 30, 30, 0.3);
  font-size: 14px;
}
#HomePage .teacher_details-social a:hover {
  background: var(--clr-theme-primary);
  color: #fff;
  border-color: transparent;
}

/*
************
*************************
25. contact
******************************************************* 
*************************************************************** */
#HomePage .contact-wrap {
  padding: 80px 80px 60px;
  background: #FFFFFF;
  box-shadow: 0px 10px 50px rgba(30, 30, 30, 0.1);
  position: relative;
  z-index: 1;
  margin-bottom: -160px;
  border-radius: 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-wrap {
    padding: 80px 60px 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-wrap {
    padding: 80px 40px 60px;
  }
}
@media (max-width: 767px) {
  .contact-wrap {
    padding: 60px 20px 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .contact-wrap {
    padding: 60px 40px 40px;
  }
}
#HomePage .contact-content {
  border-right: 1px solid rgba(4, 0, 23, 0.14);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .contact-content {
    padding-right: 60px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-content {
    padding-right: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-content {
    padding-right: 30px;
  }
}
@media (max-width: 767px) {
  .contact-content {
    padding-right: 0;
    border-right: 0;
  }
}
#HomePage .contact-title {
  font-size: 32px;
}
@media (max-width: 767px) {
  .contact-title {
    font-size: 26px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .contact-title {
    font-size: 30px;
  }
}
#HomePage .contact-form-input {
  position: relative;
  z-index: auto;
}
#HomePage .contact-form-input input, .contact-form-input textarea {
  width: 100%;
  height: 60px;
  line-height: 60px;
  border: none;
  border-bottom: 1px solid rgba(4, 0, 23, 0.14);
  padding: 0 30px;
  color: rgba(4, 0, 23, 0.6);
  font-size: 16px;
}
#HomePage .contact-form-input input::-webkit-input-placeholder, .contact-form-input textarea::-webkit-input-placeholder {
  color: rgba(4, 0, 23, 0.6);
  font-size: 16px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .contact-form-input input:-moz-placeholder, .contact-form-input textarea:-moz-placeholder {
  color: rgba(4, 0, 23, 0.6);
  font-size: 16px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .contact-form-input input::-moz-placeholder, .contact-form-input textarea::-moz-placeholder {
  color: rgba(4, 0, 23, 0.6);
  font-size: 16px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .contact-form-input input:-ms-input-placeholder, .contact-form-input textarea:-ms-input-placeholder {
  color: rgba(4, 0, 23, 0.6);
  font-size: 16px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .contact-form-input .inner-icon {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  color: rgba(4, 0, 23, 0.6);
}
#HomePage .contact-form-input .inner-icon-select {
  top: 30px;
  z-index: 99;
}
#HomePage .contact-form-input textarea {
  height: 95px;
  line-height: 22px;
  padding-top: 20px;
}
#HomePage .contact-form-textarea .inner-icon {
  top: 17px;
  transform: translateY(0);
}
#HomePage .contact-form-submit {
  display: flex;
  align-items: center;
  gap: 40px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .contact-form-submit {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .contact-form-btn {
    order: 1;
  }
}
#HomePage .contact-form-condition .condition_label {
  display: block;
  position: relative;
  padding-left: 22px;
  cursor: pointer;
  font-size: 15px;
  user-select: none;
}
#HomePage .contact-form-condition .condition_label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
#HomePage .contact-form-condition .check_mark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 14px;
  width: 14px;
  background-color: transparent;
  border: 1px solid rgba(30, 30, 30, 0.2);
  border-radius: 2px;
}
#HomePage .contact-form-condition .check_mark:after {
  content: "";
  position: absolute;
  display: none;
}
#HomePage .contact-form-condition .condition_label input:checked ~ .check_mark:after {
  display: block;
}
#HomePage .contact-form-condition .condition_label .check_mark:after {
  left: 4px;
  top: 1px;
  width: 4px;
  height: 8px;
  border: solid rgba(30, 30, 30, 0.4);
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}

#HomePage .nice-select.contact-form-list {
  width: 100%;
  height: 60px;
  line-height: 60px;
  color: rgba(4, 0, 23, 0.6);
  font-size: 16px;
  border: none;
  border-bottom: 1px solid rgba(4, 0, 23, 0.14);
  border-radius: 0;
  padding: 0 30px;
}
#HomePage .nice-select.contact-form-list::after {
  border-bottom: 1.5px solid rgba(30, 30, 30, 0.6);
  border-right: 1.5px solid rgba(30, 30, 30, 0.6);
  content: "";
  display: block;
  height: 7px;
  margin-top: -1px;
  pointer-events: none;
  position: absolute;
  right: 3px;
  top: 50%;
  transform-origin: 50% 50%;
  transform: rotate(45deg) translateY(-50%);
  transition: all 0.3s ease-in-out;
  width: 7px;
}
#HomePage .nice-select.contact-form-list ul {
  width: 100%;
  border-radius: 0;
  border: none;
  background-color: #fff;
  margin-top: 0;
}
#HomePage .nice-select.contact-form-list ul li {
  color: rgba(4, 0, 23, 0.6);
  font-size: 15px;
  font-weight: 400;
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding: 0 30px;
  min-height: 50px;
}
#HomePage .nice-select.contact-form-list ul li.selected {
  font-weight: 400;
}

#HomePage .theme-btn.contact-btn {
  height: 50px;
  line-height: 50px;
  padding: 0 45px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .contact-info {
    margin-left: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-info {
    margin-left: 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .contact-info {
    margin-left: 0;
  }
}
#HomePage .contact-info-item {
  margin-bottom: 20px;
}
#HomePage .contact-info-item span {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
}
#HomePage .contact-info-item p {
  margin-bottom: 0;
  color: var(--clr-body-heading);
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
}
#HomePage .contact-info-item a {
  font-size: 16px;
  display: block;
  color: var(--clr-body-heading);
  font-weight: 500;
}
#HomePage .contact-info-item a:hover {
  color: var(--clr-theme-primary);
}
#HomePage .contact-social span {
  color: #747474;
  font-weight: 500;
  line-height: 30px;
  display: block;
  font-size: 16px;
  margin-bottom: 10px;
}
#HomePage .contact-social ul {
  display: flex;
  gap: 10px;
  align-items: center;
}
#HomePage .contact-social ul li a {
  display: grid;
  width: 40px;
  height: 40px;
  border: 1px solid rgba(30, 30, 30, 0.1);
  place-items: center;
  border-radius: 4px;
  color: rgba(30, 30, 30, 0.3);
  font-size: 15px;
}
#HomePage .contact-social ul li a:hover {
  background: var(--clr-theme-primary);
  color: #fff;
}
#HomePage .contact-map {
  height: 540px;
  width: 100%;
}
#HomePage .contact-map iframe {
  width: 100%;
  height: 100%;
}

#HomePage .h10_contact-title {
  font-size: 128px;
  font-weight: 700;
  color: #252839;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #BABABA;
  line-height: 1;
  margin-bottom: 0;
  margin-right: 150px;
  width: max-content !important;
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h10_contact-title {
    font-size: 90px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h10_contact-title {
    font-size: 90px;
    margin-right: 70px;
  }
}
@media (max-width: 767px) {
  .h10_contact-title {
    font-size: 50px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h10_contact-title {
    font-size: 70px;
    margin-right: auto;
    margin-left: auto;
  }
}
#HomePage .h10_contact-title::before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #01fe87;
  -webkit-text-fill-color: var(--clr-theme-primary-10);
  -webkit-text-stroke: 0vw transparent;
  border-right: 2px solid transparent;
  overflow: hidden;
  animation: animate 5s linear infinite;
}

@keyframes animate {
  0%, 10%, 100% {
    width: 0;
  }
  70%, 90% {
    width: 100%;
  }
}
#HomePage .h10_contact-text-wrap {
  position: absolute;
  top: 100px;
  z-index: auto;
  right: 0;
}
@media (max-width: 767px) {
  .h10_contact-text-wrap {
    left: 0;
    right: 0;
  }
}
#HomePage .h10_contact-text-wrap-2 {
  position: absolute;
  bottom: 60px;
}

#HomePage .h10_contact-img {
  position: absolute;
  left: 15px;
  top: 0;
  right: 15px;
  z-index: -1;
}

#HomePage .h10_contact-content {
  background-color: #fff;
  padding: 90px 80px 60px;
  border: 1px solid #000;
  box-shadow: 0px 10px 50px 0px rgba(30, 30, 30, 0.1);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h10_contact-content {
    padding: 90px 60px 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h10_contact-content {
    padding: 60px 40px 30px;
  }
}
@media (max-width: 767px) {
  .h10_contact-content {
    padding: 60px 30px 30px;
  }
}
#HomePage .h10_contact-content .contact-title {
  font-size: 32px;
  color: #0A0A0A;
}
@media (max-width: 767px) {
  .h10_contact-content .contact-title {
    font-size: 26px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h10_contact-content .contact-title {
    font-size: 30px;
  }
}
#HomePage .h10_contact-content .contact-form-input {
  position: relative;
  z-index: auto;
}
#HomePage .h10_contact-content .contact-form-input input, .h10_contact-content .contact-form-input textarea {
  width: 100%;
  height: 60px;
  line-height: 60px;
  border: none;
  border-bottom: 1px solid rgba(10, 10, 10, 0.14);
  padding: 0 30px;
  color: rgba(10, 10, 10, 0.6);
  font-size: 16px;
}
#HomePage .h10_contact-content .contact-form-input input::-webkit-input-placeholder, .h10_contact-content .contact-form-input textarea::-webkit-input-placeholder {
  color: rgba(10, 10, 10, 0.6);
  font-size: 16px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .h10_contact-content .contact-form-input input:-moz-placeholder, .h10_contact-content .contact-form-input textarea:-moz-placeholder {
  color: rgba(10, 10, 10, 0.6);
  font-size: 16px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .h10_contact-content .contact-form-input input::-moz-placeholder, .h10_contact-content .contact-form-input textarea::-moz-placeholder {
  color: rgba(10, 10, 10, 0.6);
  font-size: 16px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .h10_contact-content .contact-form-input input:-ms-input-placeholder, .h10_contact-content .contact-form-input textarea:-ms-input-placeholder {
  color: rgba(10, 10, 10, 0.6);
  font-size: 16px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .h10_contact-content .contact-form-input .inner-icon {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  color: rgba(10, 10, 10, 0.6);
}
#HomePage .h10_contact-content .contact-form-input .inner-icon-select {
  top: 30px;
  z-index: 99;
}
#HomePage .h10_contact-content .contact-form-input textarea {
  height: 95px;
  line-height: 22px;
  padding-top: 20px;
}
#HomePage .h10_contact-content .contact-form-textarea .inner-icon {
  top: 17px;
  transform: translateY(0);
}
#HomePage .h10_contact-content .contact-form-submit {
  display: flex;
  align-items: center;
  gap: 40px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h10_contact-content .contact-form-submit {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
}
#HomePage .h10_contact-content .contact-form-btn .h10_contact-btn {
  padding: 0 45px;
  border-radius: 0 !important;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h10_contact-content .contact-form-btn {
    order: 1;
  }
}
#HomePage .h10_contact-content .contact-form-condition .condition_label {
  display: block;
  position: relative;
  padding-left: 22px;
  cursor: pointer;
  font-size: 15px;
  user-select: none;
}
#HomePage .h10_contact-content .contact-form-condition .condition_label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
#HomePage .h10_contact-content .contact-form-condition .check_mark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 14px;
  width: 14px;
  background-color: transparent;
  border: 1px solid rgba(10, 10, 10, 0.2);
  border-radius: 2px;
}
#HomePage .h10_contact-content .contact-form-condition .check_mark:after {
  content: "";
  position: absolute;
  display: none;
}
#HomePage .h10_contact-content .contact-form-condition .condition_label input:checked ~ .check_mark:after {
  display: block;
}
#HomePage .h10_contact-content .contact-form-condition .condition_label .check_mark:after {
  left: 4px;
  top: 1px;
  width: 4px;
  height: 8px;
  border: solid rgba(10, 10, 10, 0.4);
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}

@media (max-width: 767px) {
  .row.justify-content-end.pt-110.pt-xs-60 {
    padding-top: 60px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .row.justify-content-end.pt-110.pt-xs-60 {
    padding-top: 40px;
  }
}

/*
************
*************************
26. 404
******************************************************* 
*************************************************************** */
#HomePage .error-content h2 {
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 27px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .error-content h2 {
    font-size: 45px;
  }
}
@media (max-width: 767px) {
  .error-content h2 {
    font-size: 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .error-content h2 {
    font-size: 45px;
  }
}

/*
************
*************************
27. admission
******************************************************* 
*************************************************************** */
#HomePage .h3_admission-area {
  position: relative;
  z-index: 1;
  background-color: var(--clr-color-lightPink);
  position: relative;
  z-index: 1;
}
#HomePage .h3_admission-bg {
  position: absolute;
  right: 90px;
  bottom: 0;
  z-index: -1;
}
@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .h3_admission-bg {
    right: 40px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h3_admission-bg {
    display: none;
  }
}
#HomePage .h3_admission-shape-1 {
  position: absolute;
  left: 135px;
  bottom: 210px;
  z-index: -1;
  animation: animation-leftRight-1 3s linear 0s infinite alternate;
}
@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .h3_admission-shape-1 {
    left: 70px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h3_admission-shape-1 {
    left: 20px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h3_admission-shape-1 {
    left: 20px;
    bottom: 170px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h3_admission-shape-1 {
    left: 20px;
    bottom: 170px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h3_admission-shape-1 {
    display: none;
  }
}
#HomePage .h3_admission-shape-2 {
  position: absolute;
  right: 65px;
  top: 120px;
  animation: animation-upDown-2 3s linear 0s infinite alternate;
  z-index: -1;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h3_admission-shape-2 {
    right: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h3_admission-shape-2 {
    display: none;
  }
}
#HomePage .h3_admission-wrap {
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h3_admission-wrap {
    margin-right: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h3_admission-wrap {
    margin-right: 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h3_admission-wrap {
    margin-right: 0;
  }
}
#HomePage .h3_admission-wrap-shape-2 {
  position: absolute;
  right: 0;
  top: 0;
  animation: animation-leftRight-1 3s linear 0s infinite alternate;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h3_admission-wrap-shape-2 {
    right: -30px;
    top: -10px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h3_admission-wrap-shape-2 {
    right: -30px;
    top: -10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h3_admission-wrap-shape-2 {
    right: 0;
    top: -20px;
  }
}
#HomePage .h3_admission-content {
  max-width: 500px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h3_admission-content {
    max-width: 100%;
  }
}
#HomePage .h3_admission-content span {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--clr-body-heading);
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 15px;
  line-height: 1;
}
#HomePage .h3_admission-content span i {
  background: linear-gradient(#20E448, #139E30);
  display: inline-grid;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: #fff;
  place-items: center;
  font-size: 10px;
}
#HomePage .h3_admission-form {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 10px 60px 0px rgba(30, 30, 30, 0.1);
  padding: 45px 40px 50px;
  max-width: 440px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h3_admission-form {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  .h3_admission-form {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h3_admission-form {
    padding-left: 40px;
    padding-right: 40px;
  }
}
#HomePage .h3_admission-form-title {
  margin-bottom: 20px;
}
#HomePage .h3_admission-form-input input, .h3_admission-form-input textarea {
  width: 100%;
  border-radius: 4px;
  border: 1px solid rgba(30, 30, 30, 0.1);
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  font-size: 14px;
  color: var(--clr-body-heading);
  margin-bottom: 15px;
}
#HomePage .h3_admission-form-input input::-webkit-input-placeholder, .h3_admission-form-input textarea::-webkit-input-placeholder {
  color: var(--clr-body-heading);
  font-size: 14px;
  opacity: 1;
}
#HomePage .h3_admission-form-input input:-moz-placeholder, .h3_admission-form-input textarea:-moz-placeholder {
  color: var(--clr-body-heading);
  font-size: 14px;
  opacity: 1;
}
#HomePage .h3_admission-form-input input::-moz-placeholder, .h3_admission-form-input textarea::-moz-placeholder {
  color: var(--clr-body-heading);
  font-size: 14px;
  opacity: 1;
}
#HomePage .h3_admission-form-input input:-ms-input-placeholder, .h3_admission-form-input textarea:-ms-input-placeholder {
  color: var(--clr-body-heading);
  font-size: 14px;
  opacity: 1;
}
#HomePage .h3_admission-form-input textarea {
  height: 100px;
}
#HomePage .h3_admission-btn {
  height: 50px;
}
#HomePage .h3_admission-btn i {
  margin-left: 8px;
}

#HomePage .h6_admission-area {
  background: #F6F6F6;
}
#HomePage .h6_admission-img {
  position: relative;
  z-index: 1;
}
#HomePage .h6_admission-img img {
  transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transform: scale(1);
  width: 100%;
}
#HomePage .h6_admission-img:hover img {
  transform: scale(1.05);
}
#HomePage .h6_admission-form {
  background: #FFF;
  box-shadow: 0px 10px 60px 0px rgba(23, 22, 28, 0.1);
  padding: 45px 40px 50px;
  max-width: 440px;
  position: absolute;
  right: 110px;
  bottom: -200px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h6_admission-form {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  .h6_admission-form {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h6_admission-form {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h6_admission-form {
    position: static;
  }
}
#HomePage .h6_admission-form-title {
  margin-bottom: 20px;
}
#HomePage .h6_admission-form-input input, .h6_admission-form-input textarea {
  width: 100%;
  border: 1px solid rgba(30, 30, 30, 0.1);
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  font-size: 14px;
  color: var(--clr-body-heading);
  margin-bottom: 15px;
}
#HomePage .h6_admission-form-input input::-webkit-input-placeholder, .h6_admission-form-input textarea::-webkit-input-placeholder {
  color: var(--clr-body-heading);
  font-size: 14px;
  opacity: 1;
}
#HomePage .h6_admission-form-input input:-moz-placeholder, .h6_admission-form-input textarea:-moz-placeholder {
  color: var(--clr-body-heading);
  font-size: 14px;
  opacity: 1;
}
#HomePage .h6_admission-form-input input::-moz-placeholder, .h6_admission-form-input textarea::-moz-placeholder {
  color: var(--clr-body-heading);
  font-size: 14px;
  opacity: 1;
}
#HomePage .h6_admission-form-input input:-ms-input-placeholder, .h6_admission-form-input textarea:-ms-input-placeholder {
  color: var(--clr-body-heading);
  font-size: 14px;
  opacity: 1;
}
#HomePage .h6_admission-form-input textarea {
  height: 100px;
}

#HomePage .h6_admission-bottom-text {
  font-size: 128px;
  font-weight: 700;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #BABABA;
  margin-bottom: 20px;
  line-height: 1.25;
  margin-right: 100px;
  width: max-content !important;
}
@media (max-width: 767px) {
  .h6_admission-bottom-text {
    font-size: 80px;
  }
}

/*
************
*************************
28. education
******************************************************* 
*************************************************************** */
#HomePage .h4_education-area {
  position: relative;
  z-index: 1;
  background-color: #36348E;
  overflow: hidden;
}
#HomePage .h4_education-shape-1 {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
}
#HomePage .h4_education-shape-2 {
  position: absolute;
  right: 45%;
  top: 0;
  z-index: -1;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h4_education-shape-2 {
    right: 0;
  }
}
#HomePage .h4_education-img {
  position: absolute;
  right: 0;
  top: 0;
  max-width: 45%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h4_education-img {
    display: none;
  }
}
#HomePage .h4_education-item {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 30px;
}
#HomePage .h4_education-item-icon {
  border-radius: 50px;
  border: 1px solid rgba(255, 255, 255, 0.14);
  width: 70px;
  height: 70px;
  flex-shrink: 0;
  display: grid;
  place-items: center;
}
#HomePage .h4_education-item-icon i {
  font-size: 28px;
  color: #fff;
}
#HomePage .h4_education-item-info h3 {
  font-size: 30px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0;
  line-height: 1;
}
#HomePage .h4_education-item-info p {
  color: #C3C2DD;
  margin-bottom: 0;
}

/*
************
*************************
29. faq
******************************************************* 
*************************************************************** */
#HomePage .h4_faq-img {
  margin-right: -30px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h4_faq-img {
    margin-right: -10px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h4_faq-img {
    margin-right: 0;
  }
}
#HomePage .h4_faq-wrap {
  margin-left: 80px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h4_faq-wrap {
    margin-left: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h4_faq-wrap {
    margin-left: 0;
  }
}
#HomePage .h4_faq-content .accordion-item {
  border: none;
  margin-bottom: 30px;
  border-radius: 4px;
}
#HomePage .h4_faq-content .accordion-button {
  position: relative;
  border: 0;
  padding-left: 25px;
  padding-right: 60px;
  height: 60px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  box-shadow: none;
  color: var(--clr-body-heading);
  border-radius: 6px !important;
  border: 1px solid rgba(10, 10, 10, 0.1);
  background: #FFF;
}
@media (max-width: 767px) {
  .h4_faq-content .accordion-button {
    font-size: 16px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h4_faq-content .accordion-button {
    font-size: 18px;
  }
}
#HomePage .h4_faq-content .accordion-button::after {
  display: none;
}
#HomePage .h4_faq-content .accordion-button::before {
  position: absolute;
  right: 0;
  top: 0;
  content: "\f107";
  font-family: "Font Awesome 5 Pro";
  padding: 0 25px;
  font-size: 15px;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  font-weight: 400;
  height: 60px;
  line-height: 60px;
  color: var(--clr-body-heading);
}
#HomePage .h4_faq-content .accordion-button:not(.collapsed)::before {
  content: "\f107";
  transform: rotate(-180deg);
}
#HomePage .h4_faq-content .accordion-button:not(.collapsed) {
  color: var(--clr-body-heading);
  background: var(--clr-theme-primary-4);
  border-color: transparent;
  border-radius: 6px 6px 0 0 !important;
}
#HomePage .h4_faq-content .accordion-body {
  padding: 25px 25px;
  line-height: 1.5;
  border: 1px solid rgba(10, 10, 10, 0.1);
  border-top: 0;
  border-radius: 6px;
}
#HomePage .h4_faq-content .accordion-body p {
  margin-bottom: 0;
  font-size: 15px;
  line-height: 24px;
}

#HomePage .h9_faq-content .accordion-item {
  border: none;
  margin-bottom: 10px;
  border-radius: 10px;
  overflow: hidden;
}
#HomePage .h9_faq-content .accordion-button {
  position: relative;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  box-shadow: none;
  color: #21242C;
  border-radius: 0px !important;
  border: none;
  background: #F5F5F5;
  display: flex;
  gap: 15px;
  align-items: center;
  height: 80px;
}
@media (max-width: 767px) {
  .h9_faq-content .accordion-button {
    font-size: 16px;
    padding-left: 20px;
    padding-right: 25px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h9_faq-content .accordion-button {
    font-size: 18px;
    padding-left: 25px;
    padding-right: 25px;
  }
}
#HomePage .h9_faq-content .accordion-button::after {
  display: none;
}
#HomePage .h9_faq-content .accordion-button::before {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  content: "+";
  font-family: "Font Awesome 5 Pro";
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  font-weight: 500;
  color: #21242C;
  width: 20px;
  height: 20px;
  font-size: 10px;
  border: 1px solid rgba(33, 36, 44, 0.2);
  border-radius: 50%;
  display: grid;
  place-items: center;
}
@media (max-width: 767px) {
  .h9_faq-content .accordion-button::before {
    right: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h9_faq-content .accordion-button::before {
    right: 25px;
  }
}
#HomePage .h9_faq-content .accordion-button:not(.collapsed)::before {
  content: "\f068";
  border-color: #21242C;
}
#HomePage .h9_faq-content .accordion-button img {
  width: 40px;
}
@media (max-width: 767px) {
  .h9_faq-content .accordion-button img {
    width: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h9_faq-content .accordion-button img {
    width: 40px;
  }
}
#HomePage .h9_faq-content .accordion-body {
  padding: 0 115px 30px 80px;
  background: #F5F5F5;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h9_faq-content .accordion-body {
    padding: 0 55px 30px 80px;
  }
}
@media (max-width: 767px) {
  .h9_faq-content .accordion-body {
    padding: 0 25px 30px 25px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h9_faq-content .accordion-body {
    padding: 0 50px 30px 80px;
  }
}
#HomePage .h9_faq-content .accordion-body ul {
  margin-bottom: 20px;
  line-height: 1;
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
}
#HomePage .h9_faq-content .accordion-body ul li {
  display: inline-block;
  margin-right: 25px;
  color: rgba(10, 10, 10, 0.6);
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
}
@media (max-width: 767px) {
  .h9_faq-content .accordion-body ul li {
    margin-right: 15px;
  }
}
#HomePage .h9_faq-content .accordion-body ul li:last-child {
  margin-right: 0;
}
#HomePage .h9_faq-content .accordion-body ul li i {
  margin-right: 6px;
}
#HomePage .h9_faq-content .accordion-body p {
  color: rgba(10, 10, 10, 0.6);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 45px;
}
#HomePage .h9_faq-content .accordion-body-bottom {
  display: flex;
  align-items: center;
  gap: 35px;
}
@media (max-width: 767px) {
  .h9_faq-content .accordion-body-bottom {
    gap: 20px;
  }
}
#HomePage .h9_faq-content .accordion-body-bottom span {
  color: #1865F2;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  display: flex;
  gap: 6px;
  align-items: center;
}
#HomePage .h9_faq-content .accordion-body-bottom span del {
  color: #787878;
  font-size: 18px;
  font-weight: 500;
}
#HomePage .h9_faq-content .accordion-body-bottom a {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #0A0A0A;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
}

/*
************
*************************
30. feature
******************************************************* 
*************************************************************** */
#HomePage .h5_feature-wrapper {
  display: flex;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h5_feature-wrapper {
    flex-wrap: wrap;
  }
}
#HomePage .h5_feature-item {
  width: 33.3333333333%;
  padding: 110px 40px 105px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h5_feature-item {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .h5_feature-item {
    width: 100%;
  }
}
#HomePage .h5_feature-item.first_item {
  background-color: #F5EBF5;
}
#HomePage .h5_feature-item.second_item {
  background-color: #F5F2EB;
}
#HomePage .h5_feature-item.third_item {
  padding: 0;
}
#HomePage .h5_feature-item.third_item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h5_feature-item.third_item {
    width: 100%;
  }
}
#HomePage .h5_feature-item span {
  width: 40px;
  height: 40px;
  background: #fff;
  display: grid;
  place-items: center;
  border-radius: 50%;
  font-size: 16px;
  font-weight: 600;
  color: rgba(10, 10, 10, 0.4);
  margin-bottom: 13px;
}
#HomePage .h5_feature-item h3 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h5_feature-item h3 {
    font-size: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h5_feature-item h3 {
    font-size: 32px;
  }
}
@media (max-width: 767px) {
  .h5_feature-item h3 {
    font-size: 28px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h5_feature-item h3 {
    font-size: 30px;
  }
}
#HomePage .h5_feature-item a {
  font-size: 16px;
  color: var(--clr-body-heading);
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
}
#HomePage .h5_feature-item a:hover {
  color: var(--clr-theme-primary);
}

/*
************
*************************
31. video
******************************************************* 
*************************************************************** */
#HomePage .h5_video-area {
  min-height: 650px;
  background-repeat: repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  z-index: 1;
  background-attachment: fixed;
}
#HomePage .h5_video-content-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/*
************
*************************
32. program
******************************************************* 
*************************************************************** */
#HomePage .h6_program-area {
  background-color: #f6f6f6;
}
#HomePage .h6_program-item {
  position: relative;
  z-index: 1;
}
#HomePage .h6_program-item-content {
  position: absolute;
  left: 0;
  bottom: 0;
  background: rgba(10, 10, 10, 0.6);
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px 30px 20px;
  justify-content: space-between;
  gap: 10px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h6_program-item-content {
    padding: 20px 25px 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h6_program-item-content {
    flex-wrap: wrap;
    padding-bottom: 25px;
  }
}
@media (max-width: 767px) {
  .h6_program-item-content {
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h6_program-item-content {
    flex-wrap: nowrap;
  }
}
#HomePage .h6_program-item-content-info-title {
  color: #FFF;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}
#HomePage .h6_program-item-content-info-title:hover a {
  color: var(--clr-theme-primary-6);
}
#HomePage .h6_program-item-content-info-link {
  color: #FFF;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  line-height: 22px;
  gap: 10px;
}
#HomePage .h6_program-item-content-info-link:hover {
  color: var(--clr-theme-primary-6);
}
#HomePage .h6_program-item-content-icon svg {
  color: #fff;
  width: 50px;
}
#HomePage .h6_program-item-img {
  overflow: hidden;
}
#HomePage .h6_program-item-img img {
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}

#HomePage .h6_program-item:hover .h6_program-item-img img {
  transform: scale(1.1);
}

#HomePage .h7_program-wrap {
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h7_program-wrap {
    padding-bottom: 90px;
  }
}
#HomePage .h7_program-title {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #C5C5C5;
  font-size: 120px;
  font-weight: 600;
  line-height: 1.12;
  text-align: center;
  margin-right: 150px;
  width: max-content !important;
}
@media (max-width: 767px) {
  .h7_program-title {
    font-size: 80px;
  }
}
#HomePage .h7_program-item {
  background: #466BB8;
  padding: 46px 50px 80px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h7_program-item {
    padding: 46px 30px 80px;
  }
}
@media (max-width: 767px) {
  .h7_program-item {
    padding: 46px 30px 80px;
  }
}
#HomePage .h7_program-item-title {
  color: #fff;
  font-size: 36px;
  font-weight: 600;
  line-height: 1;
  text-transform: capitalize;
  margin-bottom: 25px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h7_program-item-title {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .h7_program-item-title {
    font-size: 30px;
  }
}
#HomePage .h7_program-item p {
  color: #FFF;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 0;
}
#HomePage .h7_program-item-list {
  margin-top: 32px;
  margin-bottom: 53px;
  display: flex;
  flex-direction: column;
  align-items: start;
}
#HomePage .h7_program-item-list li {
  color: #FFF;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-transform: capitalize;
  border: 1px solid #fff;
  display: flex;
  padding: 6px 20px;
  gap: 10px;
  align-items: center;
}
#HomePage .h7_program-item-list li:not(:last-child) {
  margin-bottom: 10px;
}
#HomePage .h7_program-item-list a {
  color: #FFF;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-transform: capitalize;
  border: 1px solid #fff;
  display: flex;
  padding: 6px 20px;
  gap: 10px;
  align-items: center;
  transition: all 0.3s linear 0s;
}
#HomePage .h7_program-item-list a:not(:last-child) {
  margin-bottom: 10px;
}
#HomePage .h7_program-item-list a:hover {
  background-color: var(--clr-theme-primary-6);
  color: #fff;
  border-color: transparent;
}
#HomePage .h7_program-item-time {
  color: #FFF;
  font-size: 15px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 10px;
}
#HomePage .h7_program-item-time i {
  font-size: 14px;
}
#HomePage .h7_program-item-list2 {
  margin-top: 25px;
  margin-bottom: 45px;
}
#HomePage .h7_program-item-list2 li {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  text-transform: capitalize;
  margin-bottom: 10px;
  position: relative;
  padding-left: 20px;
}
#HomePage .h7_program-item-list2 li:last-child {
  margin-bottom: 0;
}
#HomePage .h7_program-item-list2 li::before {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  background: #F6BA2A;
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
#HomePage .h7_program-item2 {
  background-color: #2B7258;
  padding-bottom: 100px;
}
#HomePage .h7_program-more-icon {
  position: absolute;
  right: 0;
  bottom: -95px;
  bottom: 0;
  color: var(--clr-theme-primary-6);
  font-size: 26px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 30px;
}
#HomePage .h7_program-more-icon span {
  width: 60px;
  height: 60px;
  background-color: #F6F6F6;
  display: grid;
  place-items: center;
}
#HomePage .h7_program-more-icon span i {
  position: absolute;
  color: var(--clr-theme-primary-6);
  transform: rotate(45deg) translateY(0);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .h7_program-more-icon span i:last-child {
  transform: rotate(45deg) translateY(100%);
  opacity: 0;
  visibility: hidden;
}
#HomePage .h7_program-more-icon:hover {
  color: var(--clr-theme-primary-6);
}
#HomePage .h7_program-more-icon:hover span i:last-child {
  transform: rotate(45deg) translateY(0);
  opacity: 1;
  visibility: visible;
}
#HomePage .h7_program-more-icon:hover span i:first-child {
  transform: rotate(45deg) translateY(-100%);
  opacity: 0;
  visibility: hidden;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h7_program-more-icon {
    bottom: -1px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h7_program-more-icon {
    bottom: 0;
  }
}

#HomePage .section-width.swiper .swiper-wrapper .swiper-slide {
  width: auto !important;
}

#HomePage .section-width-2.swiper .swiper-wrapper .swiper-slide {
  width: auto !important;
}

/*
************
*************************
33. research
******************************************************* 
*************************************************************** */
#HomePage .h6_research-img {
  position: relative;
  z-index: 1;
  padding-right: 30px;
  margin-right: 25px;
  padding-bottom: 30px;
  transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  clip-path: inset(0 0 0 0);
}
#HomePage .h6_research-img::after {
  position: absolute;
  right: 0;
  bottom: 0;
  content: "";
  width: 389px;
  height: calc(100% - 50px);
  border: 1px solid #DADADA;
  z-index: -1;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h6_research-img {
    margin-right: 0;
  }
}
#HomePage .h6_research-img img {
  transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transform: scale(1);
  width: 100%;
}
#HomePage .h6_research-img:hover {
  clip-path: inset(8px 8px 8px 8px);
}
#HomePage .h6_research-img:hover img {
  transform: scale(1.05);
}
#HomePage .h6_research-content h4 {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.4;
  margin-bottom: 25px;
}
#HomePage .h6_research-content p {
  font-size: 16px;
  line-height: 26px;
  color: #666;
  margin-bottom: 0;
}
#HomePage .h6_research-content-bottom {
  display: flex;
  align-items: center;
  margin-top: 50px;
}
@media (max-width: 767px) {
  .h6_research-content-bottom {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h6_research-content-bottom {
    flex-direction: row;
    align-items: center;
  }
}
#HomePage .h6_research-content-left {
  width: 170px;
  flex-shrink: 0;
  margin-right: 20px;
}
@media (max-width: 767px) {
  .h6_research-content-left {
    margin-right: 0;
    width: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h6_research-content-left {
    margin-right: 20px;
    width: 160px;
  }
}
#HomePage .h6_research-content-left h2 {
  font-size: 80px;
  font-weight: 800;
  line-height: 1;
  margin-bottom: 7px;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #323232;
}
#HomePage .h6_research-content-left p {
  color: #565656;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 0;
}
#HomePage .h6_research-content-right {
  flex-shrink: 0;
  width: calc(100% - 170px);
  padding-left: 45px;
  border-left: 1px solid #E7E7E7;
}
@media (max-width: 767px) {
  .h6_research-content-right {
    padding-left: 0;
    border-left: 0;
    border-top: 1px solid #E7E7E7;
    padding-top: 30px;
    margin-top: 30px;
    width: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h6_research-content-right {
    padding-left: 30px;
    border-left: 1px solid #E7E7E7;
    border-top: 0;
    padding-top: 0;
    margin-top: 0;
    width: calc(100% - 160px);
  }
}
#HomePage .h6_research-single-item {
  display: flex;
  align-items: center;
  gap: 20px;
}
#HomePage .h6_research-single-item:not(:last-child) {
  margin-bottom: 35px;
}
#HomePage .h6_research-bottom-text {
  font-size: 120px;
  font-weight: 600;
  line-height: 1;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #C5C5C5;
  margin-bottom: 0;
  margin-right: 50px;
  width: max-content !important;
}
@media (max-width: 767px) {
  .h6_research-bottom-text {
    font-size: 80px;
  }
}

#HomePage .single-item-icon svg {
  width: 50px;
  color: #0A0A0A;
}
#HomePage .single-item-info h5 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 8px;
}
#HomePage .single-item-info p {
  font-size: 14px;
  line-height: 24px;
  color: #666;
  margin-bottom: 0;
}

/*
************
*************************
34. tuition
******************************************************* 
*************************************************************** */
#HomePage .h6_tuition-content {
  margin-right: 25px;
}
#HomePage .h6_tuition-content h2 {
  color: #17161C;
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 11px;
}
@media (max-width: 767px) {
  .h6_tuition-content h2 {
    font-size: 35px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h6_tuition-content h2 {
    font-size: 40px;
  }
}
#HomePage .h6_tuition-content p {
  color: #666;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 25px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), (max-width: 767px) {
  .h6_tuition-content p br {
    display: none;
  }
}
#HomePage .h6_tuition-content a {
  color: var(--clr-theme-primary-6);
  font-size: 16px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  position: relative;
  z-index: 1;
}
#HomePage .h6_tuition-content a::before {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  width: 100%;
  height: 1px;
  background-color: var(--clr-theme-primary-6);
}
#HomePage .h6_tuition-content img {
  margin-bottom: 23px;
}
#HomePage .h6_tuition-wrap {
  display: flex;
  gap: 30px;
}
@media (max-width: 767px) {
  .h6_tuition-wrap {
    flex-wrap: wrap;
  }
}
#HomePage .h6_tuition-item {
  flex-shrink: 0;
  width: calc(50% - 15px);
  background: #222;
  padding: 55px 45px 45px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 768px) and (max-width: 991px) {
  .h6_tuition-item {
    padding: 55px 40px 45px;
  }
}
@media (max-width: 767px) {
  .h6_tuition-item {
    width: 100%;
    padding: 55px 30px 45px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h6_tuition-item {
    width: 100%;
    padding: 55px 40px 45px;
  }
}
#HomePage .h6_tuition-item-date {
  color: #09DB12;
  font-size: 14px;
  font-weight: 600;
  display: block;
  line-height: 20px;
  margin-bottom: 5px;
}
#HomePage .h6_tuition-item-title {
  color: #FFF;
  font-size: 26px;
  font-weight: 700;
  display: inline-block;
  position: relative;
  z-index: 1;
  padding-bottom: 5px;
  margin-bottom: 50px;
}
#HomePage .h6_tuition-item-title::before {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.14);
}
#HomePage .h6_tuition-item-list {
  padding-bottom: 50px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
#HomePage .h6_tuition-item-list li {
  color: #FFF;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  position: relative;
  z-index: 1;
  padding-left: 16px;
}
#HomePage .h6_tuition-item-list li:last-child {
  margin-bottom: 0;
}
#HomePage .h6_tuition-item-list li::before {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  width: 6px;
  height: 6px;
  background: var(--clr-theme-primary-6);
  border-radius: 50%;
}
#HomePage .h6_tuition-item-total {
  color: #FFF;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 0;
}
#HomePage .h6_tuition-light {
  background-color: #F6F6F6;
}
#HomePage .h6_tuition-light .h6_tuition-item-date {
  color: var(--clr-theme-primary-6);
}
#HomePage .h6_tuition-light .h6_tuition-item-title,
#HomePage .h6_tuition-light .h6_tuition-item-list li,
#HomePage .h6_tuition-light .h6_tuition-item-total {
  color: var(--clr-body-heading);
}
#HomePage .h6_tuition-light .h6_tuition-item-title::before {
  background-color: #D9D9D9;
}
#HomePage .h6_tuition-light .h6_tuition-item-list {
  border-color: #D9D9D9;
}

/*
************
*************************
35. campus
******************************************************* 
*************************************************************** */
#HomePage .h6_campus-area {
  position: relative;
  z-index: 1;
}
#HomePage .h6_campus-wrap {
  display: grid;
  grid-template-columns: 1fr 515px 1fr;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h6_campus-wrap {
    grid-template-columns: 1fr 450px 1fr;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .h6_campus-wrap {
    grid-template-columns: 1fr 400px 1fr;
  }
}
@media (max-width: 767px) {
  .h6_campus-wrap {
    grid-template-columns: 100%;
  }
}
#HomePage .h6_campus-title {
  font-size: 128px;
  font-weight: 700;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #fff;
  line-height: 1;
  margin-bottom: 0;
  margin-right: 150px;
  width: max-content !important;
  opacity: 0.4;
}
@media (max-width: 767px) {
  .h6_campus-title {
    font-size: 80px;
  }
}
#HomePage .h6_campus-item-1 {
  height: 630px;
  position: relative;
  z-index: 1;
}
#HomePage .h6_campus-item-1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 767px) {
  .h6_campus-item-1 {
    height: auto;
  }
}
#HomePage .h6_campus-item-3 {
  height: 630px;
  position: relative;
  z-index: 1;
}
#HomePage .h6_campus-item-3 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 767px) {
  .h6_campus-item-3 {
    height: auto;
  }
}
#HomePage .h6_campus-item-2 {
  background-color: #4846AB;
}

#HomePage .campus-text-wrap {
  position: absolute;
  top: 60px;
  z-index: auto;
}
#HomePage .campus-text-wrap-2 {
  position: absolute;
  bottom: 60px;
}

#HomePage .h6_campus-item-2 {
  padding: 40px 40px 40px;
  z-index: 2;
}
@media (max-width: 767px) {
  .h6_campus-item-2 {
    padding: 40px 30px 40px;
  }
}

#HomePage .h6_campus-item-2 {
  overflow: hidden;
}
#HomePage .h6_campus-item-2 img {
  width: 100%;
  margin-bottom: 35px;
  transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  clip-path: inset(0 0 0 0);
}
#HomePage .h6_campus-item-2:hover img {
  clip-path: inset(8px 8px 8px 8px);
}

#HomePage .h6_campus-item-2 h4 {
  color: #fff;
  font-size: 24px;
  line-height: 1.4;
  margin-bottom: 40px;
}

#HomePage .h6_campus-item-2 ul li a {
  color: #FFF;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-transform: capitalize;
  border: 1px solid #fff;
  display: inline-flex;
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
  gap: 10px;
  align-items: center;
}
#HomePage .h6_campus-item-2 ul li a:hover {
  background-color: var(--clr-theme-primary-6);
  color: #fff;
  border-color: transparent;
}

#HomePage .h6_campus-item-2 ul li:not(:last-child) {
  margin-bottom: 15px;
}

/*
************
*************************
11. about
******************************************************* 
*************************************************************** */
#HomePage .h7_apply {
  position: relative;
  z-index: 1;
}
#HomePage .h7_apply-title {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #C5C5C5;
  font-size: 120px;
  font-weight: 600;
  line-height: 1.12;
  text-align: center;
  width: 5em;
  white-space: nowrap;
  overflow: hidden;
  animation: type 4s steps(120, end) infinite;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .h7_apply-title {
    font-size: 80px;
  }
}
#HomePage .h7_apply-wrap-btn {
  color: #0A0A0A;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-decoration-line: underline;
  display: block;
  margin-top: 70px;
}
#HomePage .h7_apply-wrap-btn:hover {
  color: var(--clr-theme-primary-6);
  text-decoration: underline;
}

@keyframes type {
  from {
    width: 0;
  }
}
#HomePage .h7_apply-item {
  display: flex;
  position: relative;
  z-index: 1;
  align-items: center;
  border-bottom: 1px solid #D9D9D9;
  padding: 43px 0;
}
@media (max-width: 767px) {
  .h7_apply-item {
    flex-direction: column;
    align-items: start;
    gap: 10px;
  }
}
#HomePage .h7_apply-item-number {
  flex-shrink: 0;
  width: auto;
}
#HomePage .h7_apply-item-number span {
  color: #000;
  font-size: 24px;
  font-weight: 600;
  text-transform: capitalize;
}
#HomePage .h7_apply-item-title {
  flex-shrink: 0;
  width: calc(28% + 9px);
  text-align: center;
}
#HomePage .h7_apply-item-title h4 {
  color: #000;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .h7_apply-item-title {
    width: 100%;
    text-align: start;
  }
}
#HomePage .h7_apply-item-text {
  margin-right: 100px;
  margin-left: 120px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h7_apply-item-text {
    margin-right: 0;
    margin-left: 40px;
  }
}
@media (max-width: 767px) {
  .h7_apply-item-text {
    margin-left: 0;
    margin-right: 0;
  }
}
#HomePage .h7_apply-item-text p {
  color: #525252;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 0;
}
#HomePage .h7_apply-item-img {
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%, -50%);
  z-index: -1;
  opacity: 0;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
@media (max-width: 767px) {
  .h7_apply-item-img {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h7_apply-item-img {
    display: block;
  }
}

#HomePage .h7_apply-item:hover .h7_apply-item-img {
  opacity: 1;
  top: 50%;
}

/*
************
*************************
11. about
******************************************************* 
*************************************************************** */
#HomePage .h7_scholarship-img {
  margin-bottom: -370px;
  position: relative;
  z-index: 1;
  transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  clip-path: inset(0 0 0 0);
}
#HomePage .h7_scholarship-img::before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(10, 10, 10, 0.3);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h7_scholarship-img {
    margin-bottom: -255px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h7_scholarship-img {
    margin-bottom: -235px;
  }
}
@media (max-width: 767px) {
  .h7_scholarship-img {
    margin-bottom: -145px;
    margin-bottom: -35%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h7_scholarship-img {
    margin-bottom: -150px;
  }
}
#HomePage .h7_scholarship-img img {
  transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transform: scale(1);
  width: 100%;
}
#HomePage .h7_scholarship-img:hover {
  clip-path: inset(8px 8px 8px 8px);
}
#HomePage .h7_scholarship-img:hover img {
  transform: scale(1.05);
}
#HomePage .h7_scholarship-wrap {
  background-color: #4846AB;
  padding-top: 435px;
  padding-bottom: 110px;
  position: relative;
  z-index: auto;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h7_scholarship-wrap {
    padding-top: 300px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h7_scholarship-wrap {
    padding-top: 270px;
  }
}
@media (max-width: 767px) {
  .h7_scholarship-wrap {
    padding-top: 170px;
    padding-top: calc(33% + 24px);
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h7_scholarship-wrap {
    padding-top: 240px;
  }
}

#HomePage .h7_scholarship-content {
  width: 420px;
  margin-left: auto;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h7_scholarship-content {
    width: 320px;
  }
}
@media (max-width: 767px) {
  .h7_scholarship-content {
    width: 100%;
  }
}

#HomePage .h7_scholarship-content a {
  color: #FFF;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  display: inline-flex;
  height: 50px;
  padding: 0 30px;
  align-items: center;
  gap: 8px;
  border: 1px solid #FFF;
}
#HomePage .h7_scholarship-content a:hover {
  background-color: var(--clr-theme-primary-6);
  border-color: var(--clr-theme-primary-6);
}

#HomePage .h7_scholarship-content p {
  color: #EFEFEF;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 30px;
}

#HomePage .h7_scholarship-title {
  position: absolute;
  left: 75px;
  bottom: 65px;
  z-index: 2;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h7_scholarship-title {
    bottom: 90px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h7_scholarship-title {
    left: 50px;
    bottom: 100px;
  }
}
@media (max-width: 767px) {
  .h7_scholarship-title {
    position: static;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h7_scholarship-title {
    position: absolute;
    left: 15px;
    bottom: 140px;
  }
}

#HomePage .h7_scholarship-title h1 {
  color: #FFF;
  font-size: 140px;
  font-weight: 800;
  line-height: 1.15;
  text-align: end;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h7_scholarship-title h1 {
    font-size: 110px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .h7_scholarship-title h1 {
    font-size: 85px;
    text-align: start;
  }
}
@media (max-width: 767px) {
  .h7_scholarship-title h1 {
    font-size: 54px;
    text-align: start;
  }
}
#HomePage .h7_scholarship-title h1 span {
  display: block;
}

#HomePage .h7_scholarship-title h1 span.scholar-bottom {
  font-size: 128px;
  font-weight: 700;
  line-height: 1.2;
  display: block;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px rgb(255, 255, 255);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h7_scholarship-title h1 span.scholar-bottom {
    font-size: 85px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h7_scholarship-title h1 span.scholar-bottom {
    font-size: 70px;
  }
}
@media (max-width: 767px) {
  .h7_scholarship-title h1 span.scholar-bottom {
    font-size: 45px;
    display: inline-block;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h7_scholarship-title h1 span.scholar-bottom {
    font-size: 60px;
    display: block;
  }
}

/*
************
*************************
15. career
******************************************************* 
*************************************************************** */
#HomePage .h8_career-area {
  background-color: #F4F7FF;
}

#HomePage .h8_career-item {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 6px 100px 0px rgba(8, 5, 33, 0.1);
  display: flex;
  align-items: center;
  gap: 35px;
  padding: 55px 40px 60px 30px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h8_career-item {
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .h8_career-item {
    flex-direction: column;
    padding: 45px 40px 50px 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h8_career-item {
    flex-direction: row;
    padding: 45px 30px 50px 30px;
    gap: 20px;
  }
}

#HomePage .h8_career-item-img {
  flex-shrink: 0;
  width: 244px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), (max-width: 767px) {
  .h8_career-item-img {
    width: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h8_career-item-img {
    width: 224px;
  }
}

#HomePage .h8_career-item-img img {
  width: 100%;
}

#HomePage .h8_career-item-content span {
  color: #395BDF;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 5px;
  display: block;
}

#HomePage .h8_career-item-content h4 {
  color: #021936;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 43px;
}

#HomePage .h8_career-btn {
  height: 40px;
  padding: 0 20px;
}

/*
************
*************************
14. testimonial
******************************************************* 
*************************************************************** */
#HomePage .h8_instagram-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}
#HomePage .h8_instagram-item {
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  z-index: 1;
}
#HomePage .h8_instagram-item::before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(2, 25, 54, 0.8);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .h8_instagram-item-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .h8_instagram-item-icon svg {
  color: rgba(241, 77, 93, 0.9);
}

#HomePage .h8_instagram-item:hover::before {
  opacity: 1;
  visibility: visible;
}
#HomePage .h8_instagram-item:hover .h8_instagram-item-icon {
  opacity: 1;
  visibility: visible;
}

/*
************
*************************
29. faq
******************************************************* 
*************************************************************** */
#HomePage .h9_choose-item {
  background: #F5F5F5;
  padding: 55px 45px 68px;
  text-align: center;
  border-radius: 20px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h9_choose-item {
    padding: 55px 40px 68px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h9_choose-item {
    padding: 55px 30px 68px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h9_choose-item {
    padding: 55px 30px 68px;
  }
}
@media (max-width: 767px) {
  .h9_choose-item {
    padding: 55px 30px 68px;
  }
}

#HomePage .h9_choose-item-icon {
  margin-bottom: 50px;
}

#HomePage .h9_choose-item-icon img {
  width: 85px;
  transition: all 0.3s linear 0s;
}

#HomePage .h9_choose-item:hover .h9_choose-item-icon > img {
  transform: scale(1.1) translateY(-5px);
}

#HomePage .h9_choose-item-title {
  color: #21242C;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 19px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h9_choose-item-title {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .h9_choose-item-title {
    font-size: 20px;
  }
}

#HomePage .h9_choose-item p {
  color: rgba(33, 36, 44, 0.7);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 0;
}

#HomePage .h9_choose-item-big {
  border-radius: 20px;
  background: #FFF;
  box-shadow: 0px 30px 40px 0px rgba(33, 36, 44, 0.08);
  overflow: hidden;
}

#HomePage .h9_choose-item-big-content {
  padding: 40px 40px 70px;
}
@media (max-width: 767px) {
  .h9_choose-item-big-content {
    padding: 40px 30px 70px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h9_choose-item-big-content {
    padding: 40px 40px 70px;
  }
}

#HomePage .h9_choose-item-big-content span {
  border-radius: 4px;
  border: 1px solid rgba(24, 101, 242, 0.1);
  background: rgba(24, 101, 242, 0.06);
  display: inline-block;
  padding: 5px 12px;
  color: #1865F2;
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 10px;
}

#HomePage .h9_choose-item-big-content h3 {
  color: #21242C;
  font-size: 36px;
  font-weight: 600;
  line-height: 1.27;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h9_choose-item-big-content h3 {
    font-size: 28px;
  }
}
@media (max-width: 767px) {
  .h9_choose-item-big-content h3 {
    font-size: 25px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h9_choose-item-big-content h3 {
    font-size: 28px;
  }
}
#HomePage .h9_choose-item-big-content h3:hover a {
  color: #1865F2;
}

#HomePage .h9_choose-item-big-content p {
  color: rgba(33, 36, 44, 0.7);
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 40px;
}

#HomePage .h9_choose-item-big-img {
  overflow: hidden;
}
#HomePage .h9_choose-item-big-img img {
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}

#HomePage .h9_choose-item-big:hover .h9_choose-item-big-img img {
  transform: scale(1.1);
}

/*
************
*************************
05. section
******************************************************* 
*************************************************************** */
#HomePage .h10_class-area {
  background: #4846AB;
  position: relative;
  z-index: 1;
}

#HomePage .h10_class-content-title {
  color: #fff;
  font-size: 48px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 18px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h10_class-content-title {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .h10_class-content-title {
    font-size: 38px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h10_class-content-title {
    font-size: 48px;
  }
}

#HomePage .h10_class-content p {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 37px;
}

#HomePage .h10_class-content-title span {
  display: inline-block;
  padding: 5px 15px;
  position: relative;
  z-index: 1;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  border: 1px solid #fff;
  line-height: 1;
  border-radius: 4px;
  padding-left: 40px;
  margin-left: 5px;
}

#HomePage .h10_class-content-title span::before {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  background: #f20;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  border-radius: 50%;
  animation: scaleup 1s linear 0s infinite alternate;
}

@keyframes scaleup {
  0% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1.2);
  }
}
#HomePage .h10_class-shape-1 {
  position: absolute;
  left: 65px;
  top: 50%;
  animation: animation-upDown-1 3s linear 0s infinite alternate;
  transform: translateY(-50%);
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h10_class-shape-1 {
    left: 10px;
    width: 40px;
  }
}

#HomePage .h10_class-shape-2 {
  position: absolute;
  top: 60px;
  left: 46%;
  transform: translateX(-50%);
  animation: animation-popup-2 1.2s linear 0s infinite alternate;
}

#HomePage .h10_class-shape-3 {
  position: absolute;
  left: 42%;
  bottom: 160px;
  transform: translateX(-50%);
  animation: animation-leftRight-1 3s linear 0s infinite alternate;
}

/*
************
*************************
32. footer
******************************************************* 
*************************************************************** */
#HomePage .footer-area {
  background-color: var(--clr-color-lightPurple);
}
#HomePage .footer-logo {
  margin-bottom: 15px;
}

#HomePage .footer-social ul {
  display: flex;
  gap: 10px;
  align-items: center;
}
#HomePage .footer-social ul li a {
  display: grid;
  width: 40px;
  height: 40px;
  place-items: center;
  border-radius: 4px;
  color: rgba(30, 30, 30, 0.3);
  border: 1px solid rgba(30, 30, 30, 0.3);
  font-size: 15px;
  background: rgba(255, 255, 255, 0.08);
}
#HomePage .footer-social ul li a:hover {
  background: var(--clr-theme-primary);
  color: #fff;
  border-color: transparent;
}

@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .footer-widget.ml-80 {
    margin-left: 60px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .footer-widget.ml-80 {
    margin-left: 40px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .footer-widget.ml-80 {
    margin-left: 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .footer-widget.ml-80 {
    margin-left: 0;
  }
}
#HomePage .footer-widget-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 30px;
}
#HomePage .footer-widget-list ul li {
  margin-bottom: 10px;
}
#HomePage .footer-widget-list ul li:last-child {
  margin-bottom: 0;
}
#HomePage .footer-widget-list ul li a {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: rgba(30, 30, 30, 0.6);
}
#HomePage .footer-widget-list ul li a:hover {
  color: var(--clr-theme-primary);
}

#HomePage .footer-subscribe-form {
  position: relative;
  margin-bottom: 13px;
  z-index: 1;
}
#HomePage .footer-subscribe-form input {
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding-left: 20px;
  border-radius: 6px;
  border: 1px solid rgba(30, 30, 30, 0.1);
  padding-right: 124px;
  color: rgba(30, 30, 30, 0.4);
  background-color: transparent;
  font-size: 14px;
}
#HomePage .footer-subscribe-form input::-webkit-input-placeholder {
  color: rgba(30, 30, 30, 0.4);
  font-size: 14px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .footer-subscribe-form input:-moz-placeholder {
  color: rgba(30, 30, 30, 0.4);
  font-size: 14px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .footer-subscribe-form input::-moz-placeholder {
  color: rgba(30, 30, 30, 0.4);
  font-size: 14px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .footer-subscribe-form input:-ms-input-placeholder {
  color: rgba(30, 30, 30, 0.4);
  font-size: 14px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .footer-subscribe-form button {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  height: 40px;
  line-height: 40px;
  background: var(--clr-theme-primary);
  color: #fff;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  padding: 0 20px;
  z-index: 1;
  overflow: hidden;
}
#HomePage .footer-subscribe-form button::after {
  content: "";
  position: absolute;
  top: 10px;
  left: 0;
  width: 300px;
  height: 200px;
  background-color: #08449E;
  border-color: transparent;
  border-radius: 50%;
  transform: translate(-40px, -80px) scale(0.1);
  opacity: 0;
  z-index: -1;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
#HomePage .footer-subscribe-form button:hover {
  color: #fff;
}
#HomePage .footer-subscribe-form button:hover::after {
  opacity: 0.5;
  transform-origin: 100px 100px;
  transform: scale(1) translate(-10px, -70px);
}
#HomePage .footer-subscribe-condition {
  display: flex;
  align-items: center;
  gap: 10px;
}
#HomePage .footer-subscribe-condition label {
  font-size: 14px;
  font-weight: 400;
  color: rgba(30, 30, 30, 0.6);
}

#HomePage .copyright-area {
  border-top: 1px solid rgba(30, 30, 30, 0.1);
  padding: 30px 0;
}
#HomePage .copyright-text p {
  text-align: center;
  margin-bottom: 0;
  color: rgba(30, 30, 30, 0.5);
}

#HomePage .footer-subscribe-condition .condition_label {
  display: block;
  position: relative;
  padding-left: 21px;
  cursor: pointer;
  font-size: 15px;
  user-select: none;
}
#HomePage .footer-subscribe-condition .condition_label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
#HomePage .footer-subscribe-condition .check_mark {
  position: absolute;
  top: 7px;
  left: 0;
  height: 12px;
  width: 12px;
  background-color: transparent;
  border: 1px solid rgba(30, 30, 30, 0.3);
  border-radius: 2px;
}
#HomePage .footer-subscribe-condition .check_mark:after {
  content: "";
  position: absolute;
  display: none;
}
#HomePage .footer-subscribe-condition .condition_label input:checked ~ .check_mark:after {
  display: block;
}
#HomePage .footer-subscribe-condition .condition_label .check_mark:after {
  left: 3px;
  top: 0px;
  width: 4px;
  height: 8px;
  border: solid rgba(30, 30, 30, 0.4);
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}



























#HomePage .h2_footer-area {
  background-color: #212229;
}
#HomePage .h2_footer-area .footer-widget-text {
  color: rgba(255, 255, 255, 0.6);
}
#HomePage .h2_footer-area .footer-social ul li a {
  color: rgba(255, 255, 255, 0.3);
  border-color: rgba(255, 255, 255, 0.14);
}
#HomePage .h2_footer-area .footer-social ul li a:hover {
  color: #fff;
}
#HomePage .h2_footer-area .footer-widget-list ul li a {
  color: rgba(255, 255, 255, 0.6);
}
#HomePage .h2_footer-area .footer-widget-list ul li a:hover {
  color: #fff;
}
#HomePage .h2_footer-area .footer-widget-title {
  color: #fff;
}
#HomePage .h2_footer-area .footer-subscribe-form input {
  border-color: rgba(255, 255, 255, 0.13);
  color: rgba(255, 255, 255, 0.4);
}
#HomePage .h2_footer-area .footer-subscribe-form input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
#HomePage .h2_footer-area .footer-subscribe-form input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
#HomePage .h2_footer-area .footer-subscribe-form input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
#HomePage .h2_footer-area .footer-subscribe-form input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
#HomePage .h2_footer-area .footer-subscribe-condition label {
  color: rgba(255, 255, 255, 0.6);
}
#HomePage .h2_footer-area .copyright-area {
  border-color: rgba(255, 255, 255, 0.1);
}
#HomePage .h2_footer-area .copyright-text p {
  color: rgba(255, 255, 255, 0.5);
}
#HomePage .h2_footer-area .footer-subscribe-condition .check_mark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 14px;
  width: 14px;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 2px;
}
#HomePage .h2_footer-area .footer-subscribe-condition .condition_label .check_mark:after {
  left: 4px;
  top: 1px;
  width: 4px;
  height: 8px;
  border: solid rgba(255, 255, 255, 0.4);
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}

#HomePage .h3_footer-area {
  background-color: #0A0A0A;
}
#HomePage .h3_footer-area .footer-social ul {
  display: flex;
  gap: 10px;
  align-items: center;
}
#HomePage .h3_footer-area .footer-social ul li a {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.6);
}
#HomePage .h3_footer-area .footer-social ul li a:hover {
  background-color: var(--clr-theme-primary-3);
  color: #fff;
}
#HomePage .h3_footer-area .footer-widget-text {
  color: rgba(255, 255, 255, 0.6);
}
#HomePage .h3_footer-area .footer-widget-title {
  color: #fff;
}
#HomePage .h3_footer-area .footer-widget-list ul li a {
  color: rgba(255, 255, 255, 0.6);
}
#HomePage .h3_footer-area .footer-widget-list ul li a:hover {
  color: #fff;
}
#HomePage .h3_footer-area .copyright-text p {
  color: rgba(255, 255, 255, 0.6);
}
#HomePage .h3_footer-area .footer-subscribe-condition .check_mark {
  border-color: rgba(255, 255, 255, 0.6);
}
#HomePage .h3_footer-area .footer-subscribe-condition .check_mark:after {
  border-color: rgba(255, 255, 255, 0.6);
}
#HomePage .h3_footer-area .footer-subscribe-condition .condition_label {
  color: rgba(255, 255, 255, 0.6);
}
#HomePage .h3_footer-area .footer-subscribe-form input {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.6);
}
#HomePage .h3_footer-area .footer-subscribe-form input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
#HomePage .h3_footer-area .footer-subscribe-form input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
#HomePage .h3_footer-area .footer-subscribe-form input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
#HomePage .h3_footer-area .footer-subscribe-form input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
#HomePage .h3_footer-area .footer-subscribe-form button {
  background-color: var(--clr-theme-primary-3);
}
#HomePage .h3_footer-area .copyright-area {
  border-color: rgba(255, 255, 255, 0.1);
}

#HomePage .h4_footer-area {
  background-color: #0A0A0A;
}
#HomePage .h4_footer-area .footer-social ul {
  display: flex;
  gap: 10px;
  align-items: center;
}
#HomePage .h4_footer-area .footer-social ul li a {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.6);
  border-radius: 50%;
}
#HomePage .h4_footer-area .footer-social ul li a:hover {
  background-color: var(--clr-theme-primary-4);
  color: #fff;
}
#HomePage .h4_footer-area .footer-widget-text {
  color: rgba(255, 255, 255, 0.6);
}
#HomePage .h4_footer-area .footer-widget-title {
  color: #fff;
}
#HomePage .h4_footer-area .footer-widget-list ul li a {
  color: rgba(255, 255, 255, 0.6);
}
#HomePage .h4_footer-area .footer-widget-list ul li a:hover {
  color: #fff;
}
#HomePage .h4_footer-area .copyright-text p {
  color: rgba(255, 255, 255, 0.6);
}
#HomePage .h4_footer-area .footer-subscribe-condition .check_mark {
  border-color: rgba(255, 255, 255, 0.6);
}
#HomePage .h4_footer-area .footer-subscribe-condition .check_mark:after {
  border-color: rgba(255, 255, 255, 0.6);
}
#HomePage .h4_footer-area .footer-subscribe-condition .condition_label {
  color: rgba(255, 255, 255, 0.6);
}
#HomePage .h4_footer-area .footer-subscribe-form input {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.6);
  border-radius: 30px;
}
#HomePage .h4_footer-area .footer-subscribe-form input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
#HomePage .h4_footer-area .footer-subscribe-form input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
#HomePage .h4_footer-area .footer-subscribe-form input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
#HomePage .h4_footer-area .footer-subscribe-form input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}

#HomePage .h4_footer-area .footer-subscribe-form button {
  background-color: var(--clr-theme-primary-4);
  border-radius: 30px;
}









#HomePage .h4_footer-area .copyright-area {
  border-color: rgba(255, 255, 255, 0.1);
}

#HomePage .h5_footer-area {
  background-color: #0A0A0A;
}
#HomePage .h5_footer-area .footer-social ul {
  display: flex;
  gap: 10px;
  align-items: center;
}
#HomePage .h5_footer-area .footer-social ul li a {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.6);
  border-radius: 0;
}
#HomePage .h5_footer-area .footer-social ul li a:hover {
  background-color: var(--clr-theme-primary-5);
  color: var(--clr-body-heading);
}
#HomePage .h5_footer-area .footer-widget-text {
  color: rgba(255, 255, 255, 0.6);
}
#HomePage .h5_footer-area .footer-widget-title {
  color: #fff;
}
#HomePage .h5_footer-area .footer-widget-list ul li a {
  color: rgba(255, 255, 255, 0.6);
}
#HomePage .h5_footer-area .footer-widget-list ul li a:hover {
  color: #fff;
}
#HomePage .h5_footer-area .copyright-text p {
  color: rgba(255, 255, 255, 0.6);
}
#HomePage .h5_footer-area .footer-subscribe-condition .check_mark {
  border-color: rgba(255, 255, 255, 0.6);
}
#HomePage .h5_footer-area .footer-subscribe-condition .check_mark:after {
  border-color: rgba(255, 255, 255, 0.6);
}
#HomePage .h5_footer-area .footer-subscribe-condition .condition_label {
  color: rgba(255, 255, 255, 0.6);
}
#HomePage .h5_footer-area .footer-subscribe-form input {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.6);
  border-radius: 0;
}
#HomePage .h5_footer-area .footer-subscribe-form input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
#HomePage .h5_footer-area .footer-subscribe-form input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
#HomePage .h5_footer-area .footer-subscribe-form input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
#HomePage .h5_footer-area .footer-subscribe-form input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
#HomePage .h5_footer-area .footer-subscribe-form button {
  background-color: var(--clr-theme-primary-5);
  border-radius: 0;
  color: var(--clr-body-heading);
}
#HomePage .h5_footer-area .copyright-area {
  border-color: rgba(255, 255, 255, 0.1);
}

#HomePage .h6_footer-area {
  background-color: #F6F6F6;
}
#HomePage .h6_footer-logo {
  margin-bottom: 15px;
}
#HomePage .h6_footer-social ul {
  display: flex;
  gap: 10px;
  align-items: center;
}
#HomePage .h6_footer-social ul li a {
  display: grid;
  width: 40px;
  height: 40px;
  place-items: center;
  border-radius: 50%;
  color: #0A0A0A;
  background-color: rgba(10, 10, 10, 0.1);
  font-size: 16px;
}
#HomePage .h6_footer-social ul li a:hover {
  background: var(--clr-theme-primary-6);
  color: #fff;
  border-color: transparent;
}
#HomePage .h6_footer-inner {
  width: 100%;
  display: flex;
  justify-content: center;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  #HomePage .h6_footer-inner {
    justify-content: flex-start;
  }
}
@media (max-width: 767px) {
  #HomePage .h6_footer-inner {
    width: 50%;
    justify-content: flex-start;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  #HomePage .h6_footer-inner {
    width: 100%;
    justify-content: flex-start;
  }
}
@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  #HomePage .h6_footer-widget.ml-80 {
    margin-left: 60px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  #HomePage .h6_footer-widget.ml-80 {
    margin-left: 40px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  #HomePage .h6_footer-widget.ml-80 {
    margin-left: 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  #HomePage .h6_footer-widget.ml-80 {
    margin-left: 0;
  }
}
@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  #HomePage .h6_footer-widget.mr-80 {
    margin-right: 60px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  #HomePage .h6_footer-widget.mr-80 {
    margin-right: 40px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  #HomePage .h6_footer-widget.mr-80 {
    margin-right: 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  #HomePage .h6_footer-widget.mr-80 {
    margin-right: 0;
  }
}
#HomePage .h6_footer-widget-title {
  color: #0A0A0A;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 30px;
}
#HomePage .h6_footer-widget-list ul li {
  margin-bottom: 10px;
}
#HomePage .h6_footer-widget-list ul li:last-child {
  margin-bottom: 0;
}
#HomePage .h6_footer-widget-list ul li a {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  color: rgba(10, 10, 10, 0.6);
}
#HomePage .h6_footer-widget-list ul li a:hover {
  color: var(--clr-theme-primary-6);
}
#HomePage .h6_footer-widget-text {
  color: rgba(10, 10, 10, 0.6);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 27px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  #HomePage .h6_footer-widget-text br {
    display: none;
  }
}
#HomePage .h6_footer-subscribe-form {
  position: relative;
  margin-bottom: 8px;
  z-index: 1;
}
#HomePage .h6_footer-subscribe-form input {
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding-left: 20px;
  border: 1px solid #DDD;
  padding-right: 124px;
  color: rgba(30, 30, 30, 0.6);
  background-color: transparent;
  font-size: 14px;
}
#HomePage .h6_footer-subscribe-form input::-webkit-input-placeholder {
  color: rgba(30, 30, 30, 0.4);
  font-size: 14px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .h6_footer-subscribe-form input:-moz-placeholder {
  color: rgba(30, 30, 30, 0.4);
  font-size: 14px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .h6_footer-subscribe-form input::-moz-placeholder {
  color: rgba(30, 30, 30, 0.4);
  font-size: 14px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .h6_footer-subscribe-form input:-ms-input-placeholder {
  color: rgba(30, 30, 30, 0.4);
  font-size: 14px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
#HomePage .h6_footer-subscribe-form button {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  height: 40px;
  line-height: 40px;
  background: var(--clr-theme-primary-6);
  color: #fff;
  border: none;
  border-radius: 0;
  font-size: 14px;
  font-weight: 600;
  padding: 0 20px;
  z-index: 1;
  overflow: hidden;
}
#HomePage .h6_footer-subscribe-form button::after {
  content: "";
  position: absolute;
  top: 10px;
  left: 0;
  width: 300px;
  height: 200px;
  background-color: #B1040E;
  border-color: transparent;
  border-radius: 50%;
  transform: translate(-40px, -80px) scale(0.1);
  opacity: 0;
  z-index: -1;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
#HomePage .h6_footer-subscribe-form button:hover {
  color: #fff;
}
#HomePage .h6_footer-subscribe-form button:hover::after {
  opacity: 0.5;
  transform-origin: 100px 100px;
  transform: scale(1) translate(-10px, -70px);
}
#HomePage .h6_footer-subscribe-condition {
  display: flex;
  align-items: center;
  gap: 10px;
}
#HomePage .h6_footer-subscribe-condition label {
  font-size: 14px;
  font-weight: 400;
  color: rgba(30, 30, 30, 0.6);
}
#HomePage .h6_footer-subscribe-condition .condition_label {
  display: block;
  position: relative;
  padding-left: 21px;
  cursor: pointer;
  font-size: 15px;
  user-select: none;
}
#HomePage .h6_footer-subscribe-condition .condition_label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
#HomePage .h6_footer-subscribe-condition .check_mark {
  position: absolute;
  top: 7px;
  left: 0;
  height: 12px;
  width: 12px;
  background-color: transparent;
  border: 1px solid #878787;
  border-radius: 0;
}
#HomePage .h6_footer-subscribe-condition .check_mark:after {
  content: "";
  position: absolute;
  display: none;
}
#HomePage .h6_footer-subscribe-condition .condition_label input:checked ~ .check_mark:after {
  display: block;
}
#HomePage .h6_footer-subscribe-condition .condition_label .check_mark:after {
  left: 3px;
  top: 0px;
  width: 4px;
  height: 8px;
  border: solid var(--clr-theme-primary-6);
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}

#HomePage .h6_copyright-area {
  border-top: 1px solid rgba(10, 10, 10, 0.1);
  padding: 30px 0;
}
#HomePage .h6_copyright-text p {
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 0;
  color: rgba(30, 30, 30, 0.5);
}

#HomePage .h8_footer-area .h6_footer-subscribe-form button {
  background-color: var(--clr-theme-primary-8);
}

#HomePage .h9_footer-area .h6_footer-subscribe-form button {
  background-color: var(--clr-theme-primary-9);
}

#HomePage .h10_footer-area .h6_footer-subscribe-form button {
  background-color: var(--clr-theme-primary-10);
}

/*# sourceMappingURL=main.css.map */



/* #HomePage .p-dropdown-panel {
  z-index: 1100 !important;
} */





#HomePageHeader .p-button {
  color: #000 !important;
  background: none !important;
  border: none !important;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: none  !important;
}


#HomePageHeader .p-menu.p-menu-overlay {
  background: #ffffff !important;
  padding: 5px  !important;
  border-radius: 14px !important;
  border: 0 none !important;
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 0%), 0 4px 5px 0 rgb(0 0 0 / 5%), 0 1px 10px 0 rgb(0 0 0 / 0%) !important;
}


#HomePageHeader .menuitem:hover, #HomePageHeader .p-highlight{
  border-radius: var(--bs-border-radius);
  background-color: var(--adminuiux-bg-1) !important;
  color: #000 !important;

}






#HomePageHeader .p-component.btn-primary {
  color: #fff !important;
  background-color: #08449E !important;
  display: inline-flex;
  align-items: center;
  height: 40px;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  cursor: pointer;
  margin-bottom: 0px;
  text-align: center;
  touch-action: manipulation;
  position: relative;
  z-index: 1;
  background: var(--clr-theme-primary);
  padding: 0px 25px;
  transition: 0.3s;
  white-space: nowrap;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  border-radius: 10px !important;
  overflow: hidden;
  float: right !important;
}



#HomePageHeader .p-button-label {
  font-size: 15px !important;
  text-transform: uppercase !important;
}

#HomePage .p-button-icon{
  font-size: 8px !important;
}



h1, h2, h3, h4, h5, h6 {
  margin: 1.5rem 0 1rem 0 !important;
  font-family: inherit !important;
  font-weight: 500 !important;
  line-height: 1.2 !important;
  color: inherit !important;
}



h3 {
  font-size: 1.75rem !important;
}

h2 {
  font-size: 2rem !important;
}

h1 {
  font-size: 2.5rem !important;
}


/* .layout-main{
  padding-top: 110px !important;
} */
/*
************
*************************
07. category
******************************************************* 
*************************************************************** */
.category-wrap {
    position: relative;
    z-index: 1;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .category-wrap {
      margin-left: 30px;
    }
  }
  .category-content {
    padding: 21px 25px 22px;
  }
  .category-content h5 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .category-content h5:hover a {
    color: var(--clr-theme-primary);
  }
  .category-content p {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
  }
  .category-content p a {
    line-height: 1;
    position: relative;
    font-size: 15px;
    font-weight: 500;
    padding-right: 15px;
    display: inline-block;
  }
  .category-content p a i {
    font-weight: 300;
    font-size: 12px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s ease-in-out;
  }
  .category-content p a i:first-child {
    right: 10%;
    visibility: hidden;
    opacity: 0;
  }
  .category-content p a i:last-child {
    right: 0;
  }
  .category-content p a:hover i:last-child {
    right: -10%;
    visibility: hidden;
    opacity: 0;
  }
  .category-content p a:hover i:first-child {
    right: 0;
    visibility: visible;
    opacity: 1;
  }
  .category-content p:hover a {
    color: var(--clr-theme-primary);
  }
  .category-item {
    background-color: #fff;
    box-shadow: 0px 10px 40px rgba(30, 30, 30, 0.1);
    border-radius: 10px;
    overflow: hidden;
  }
  
  .category-navigation {
    display: flex;
    gap: 10px;
  }
  .category-navigation i {
    font-weight: 300;
  }
  .category-navigation div {
    width: 50px;
    height: 50px;
    background: #FFFFFF;
    box-shadow: 0px 2px 0px #E1E2E6;
    border-radius: 100px;
    display: grid;
    place-items: center;
    font-size: 14px;
    color: #1E1E1E;
    -webkit-transition: all 0.4s linear 0s;
    -moz-transition: all 0.4s linear 0s;
    -ms-transition: all 0.4s linear 0s;
    -o-transition: all 0.4s linear 0s;
    transition: all 0.4s linear 0s;
  }
  .category-navigation div:hover {
    background-color: var(--clr-theme-primary);
    color: #fff;
  }
  
  .category-shape img {
    position: absolute;
  }
  .category-shape-1 {
    top: -75px;
    left: -25px;
    animation: animation-popup-1 4s linear 0s infinite alternate;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .category-shape-1 {
      left: 150px;
    }
  }
  @media (max-width: 767px) {
    .category-shape-1 {
      top: -65px;
      left: 150px;
    }
  }
  .category-shape-2 {
    top: -40px;
    right: 190px;
    animation: animation-leftRight-1 3s linear 0s infinite alternate;
  }
  @media (max-width: 767px) {
    .category-shape-2 {
      right: 15px;
    }
  }
  .category-shape-3 {
    left: 49%;
    transform: translateX(-50%);
    top: 80px;
    animation: animation-popup-2 1.2s linear 0s infinite alternate;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .category-shape-3 {
      left: calc(32% - 4px);
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .category-shape-3 {
      left: 31%;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .category-shape-3 {
      left: 31%;
    }
  }
  @media (max-width: 767px) {
    .category-shape-3 {
      display: none;
    }
  }
  .category-shape-4 {
    right: -40px;
    top: 40px;
    animation: animation-upDown-1 3s linear 0s infinite alternate;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .category-shape-4 {
      right: -25px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .category-shape-4 {
      right: -30px;
    }
  }
  @media (max-width: 767px) {
    .category-shape-4 {
      display: none;
    }
  }
  .category-img img {
    width: 100%;
  }
  
  @keyframes animation-popup-1 {
    0% {
      transform: scale(0.2) rotate(0deg);
    }
    50% {
      transform: scale(0.5) rotate(180deg);
    }
    90% {
      transform: scale(1) rotate(360deg);
    }
    100% {
      transform: scale(1) rotate(360deg);
    }
  }
  @keyframes animation-popup-2 {
    0% {
      transform: scale(0.2);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes animation-upDown-1 {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-40px);
    }
  }
  @keyframes animation-leftRight-1 {
    0% {
      transform: translateX(15px);
    }
    100% {
      transform: translateX(-15px);
    }
  }
  .h2_category-item {
    border: 1px solid rgba(30, 30, 30, 0.1);
    padding: 10px;
    background-color: #fff;
    border-radius: 6px;
  }
  .h2_category-content h5 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 2px;
  }
  .h2_category-content h5:hover a {
    color: var(--clr-theme-primary);
  }
  .h2_category-content p {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
  }
  .h2_category-item {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .h2_category-img {
    width: 90px;
    flex-shrink: 0;
    border-radius: 6px 0 0 6px;
    overflow: hidden;
  }
  
  .h3_category-area {
    background: var(--clr-color-lightPink);
  }
  .h3_category-item {
    background: #FFF;
    box-shadow: 0px 2px 4px rgba(23, 22, 28, 0.1);
    padding: 40px;
    display: flex;
    gap: 25px;
    align-items: center;
    border-radius: 10px;
    position: relative;
    z-index: 1;
  }
  @media (max-width: 767px) {
    .h3_category-item {
      padding: 40px 25px;
      align-items: flex-start;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .h3_category-item {
      padding: 40px 40px;
      align-items: center;
    }
  }
  .h3_category-item-icon {
    width: 70px;
    height: 70px;
    background: linear-gradient(135deg, #E2C3E8 0%, rgba(226, 195, 232, 0) 100%);
    border-radius: 50%;
    display: grid;
    place-items: center;
  }
  .h3_category-item-icon i {
    color: var(--clr-body-heading);
    font-size: 30px;
  }
  .h3_category-item-content h5 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 5px;
  }
  .h3_category-item-content h5:hover a {
    color: var(--clr-theme-primary-3);
  }
  .h3_category-item-content p {
    margin-bottom: 0;
  }
  .h3_category-btn {
    position: absolute;
    right: 35px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
  }
  .h3_category-btn a {
    width: 40px;
    height: 40px;
    background: var(--clr-theme-primary-3);
    display: grid;
    place-items: center;
    color: #fff;
    font-size: 14px;
    border-radius: 6px;
    box-shadow: 0px 0px 5px 0px rgba(32, 42, 61, 0.4);
  }
  @media (max-width: 575px) {
    .h3_category-btn {
      position: static;
      transform: unset;
      right: unset;
      top: unset;
      opacity: 1;
      visibility: visible;
      margin-top: 12px;
    }
  }
  
  .h3_category-item:hover .h3_category-btn {
    opacity: 1;
    visibility: visible;
  }
  
  .h4_category-area {
    background-color: #ECECF9;
  }
  .h4_category-item {
    display: grid;
    place-items: center;
    text-align: center;
    padding: 40px 0 35px;
    border: 1px solid rgba(30, 30, 30, 0.09);
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
  }
  .h4_category-item-icon {
    width: 80px;
    height: 80px;
    background: #fff;
    display: grid;
    place-items: center;
    border-radius: 5px;
    box-shadow: 0px 6px 30px 0px rgba(10, 10, 10, 0.1);
    margin-bottom: 25px;
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
    border: 1px solid transparent;
  }
  .h4_category-item-icon i {
    font-size: 40px;
    color: var(--clr-theme-primary-4);
  }
  .h4_category-item-content h5 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 5px;
  }
  .h4_category-item-content h5:hover a {
    color: var(--clr-theme-primary-4);
  }
  .h4_category-item-content p {
    margin-bottom: 0;
  }
  .h4_category-item:hover {
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 14px 50px 0px rgba(10, 10, 10, 0.14);
  }
  .h4_category-item:hover .h4_category-item-icon {
    border-color: rgba(10, 10, 10, 0.1);
    box-shadow: none;
  }
  
  .h4_category-area .col-xl-3:first-child .h4_category-item {
    border-width: 0;
  }
  @media (max-width: 767px) {
    .h4_category-area .col-xl-3:first-child .h4_category-item {
      border-width: 0 0 1px 0;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .h4_category-area .col-xl-3:first-child .h4_category-item {
      border-width: 0;
    }
  }
  .h4_category-area .col-xl-3:nth-child(2) .h4_category-item {
    border-width: 0 0 0 1px;
  }
  @media (max-width: 767px) {
    .h4_category-area .col-xl-3:nth-child(2) .h4_category-item {
      border-width: 0 0 1px 0;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .h4_category-area .col-xl-3:nth-child(2) .h4_category-item {
      border-width: 0 0 0 1px;
    }
  }
  .h4_category-area .col-xl-3:nth-child(3) .h4_category-item {
    border-width: 0 1px 0 1px;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .h4_category-area .col-xl-3:nth-child(3) .h4_category-item {
      border-width: 1px 0 0 0;
    }
  }
  @media (max-width: 767px) {
    .h4_category-area .col-xl-3:nth-child(3) .h4_category-item {
      border-width: 0 0 1px 0;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .h4_category-area .col-xl-3:nth-child(3) .h4_category-item {
      border-width: 1px 0 0 0;
    }
  }
  .h4_category-area .col-xl-3:nth-child(4) .h4_category-item {
    border-width: 0;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .h4_category-area .col-xl-3:nth-child(4) .h4_category-item {
      border-width: 1px 0 0 1px;
    }
  }
  @media (max-width: 767px) {
    .h4_category-area .col-xl-3:nth-child(4) .h4_category-item {
      border-width: 0 0 1px 0;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .h4_category-area .col-xl-3:nth-child(4) .h4_category-item {
      border-width: 1px 0 0 1px;
    }
  }
  .h4_category-area .col-xl-3:nth-child(5) .h4_category-item {
    border-width: 1px 0 0 0;
  }
  @media (max-width: 767px) {
    .h4_category-area .col-xl-3:nth-child(5) .h4_category-item {
      border-width: 0 0 1px 0;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .h4_category-area .col-xl-3:nth-child(5) .h4_category-item {
      border-width: 1px 0 0 0;
    }
  }
  .h4_category-area .col-xl-3:nth-child(6) .h4_category-item {
    border-width: 1px 0 0 1px;
  }
  @media (max-width: 767px) {
    .h4_category-area .col-xl-3:nth-child(6) .h4_category-item {
      border-width: 0 0 1px 0;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .h4_category-area .col-xl-3:nth-child(6) .h4_category-item {
      border-width: 1px 0 0 1px;
    }
  }
  .h4_category-area .col-xl-3:nth-child(7) .h4_category-item {
    border-width: 1px 1px 0 1px;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .h4_category-area .col-xl-3:nth-child(7) .h4_category-item {
      border-width: 1px 0 0 0;
    }
  }
  @media (max-width: 767px) {
    .h4_category-area .col-xl-3:nth-child(7) .h4_category-item {
      border-width: 0 0 1px 0;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .h4_category-area .col-xl-3:nth-child(7) .h4_category-item {
      border-width: 1px 0 0 0;
    }
  }
  .h4_category-area .col-xl-3:last-child .h4_category-item {
    border-width: 1px 0 0 0;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .h4_category-area .col-xl-3:last-child .h4_category-item {
      border-width: 1px 0 0 1px;
    }
  }
  @media (max-width: 767px) {
    .h4_category-area .col-xl-3:last-child .h4_category-item {
      border-width: 0 0 0 0;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .h4_category-area .col-xl-3:last-child .h4_category-item {
      border-width: 1px 0 0 1px;
    }
  }
  
  .h5_category-item {
    border: 1px solid rgba(10, 10, 10, 0.14);
    padding: 41px 45px 41px;
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .h5_category-item {
      padding: 41px 30px 41px;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .h5_category-item {
      padding: 41px 30px 41px;
    }
  }
  .h5_category-item-icon {
    margin-bottom: 85px;
  }
  .h5_category-item-icon i {
    font-size: 50px;
    color: var(--clr-body-heading);
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
  }
  .h5_category-item-content h5 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 4px;
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
  }
  .h5_category-item-content h5:hover a {
    color: var(--clr-theme-primary-5);
  }
  .h5_category-item-content p {
    font-size: 16px;
    margin-bottom: 0;
    color: var(--clr-body-heading);
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
  }
  .h5_category-item:hover {
    background-color: var(--clr-body-heading);
    border-color: transparent;
  }
  .h5_category-item:hover .h5_category-item-icon i {
    color: #fff;
  }
  .h5_category-item:hover .h5_category-item-content h5 {
    color: #fff;
  }
  .h5_category-item:hover .h5_category-item-content p {
    color: #fff;
  }
  
  .h8_category-item {
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 5px 25px 0px rgba(8, 5, 33, 0.08);
    padding: 40px 35px 32px;
    text-align: center;
  }
  
  .h8_category-icon i {
    color: rgb(57, 91, 223);
    font-size: 35px;
    display: inline-block;
    margin-bottom: 20px;
  }
  
  .h8_category-title {
    color: #021936;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.33;
    margin-bottom: 5px;
  }
  
  .h8_category-text {
    color: rgba(2, 25, 54, 0.7);
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 0;
  }
  
  .h8_category-wrap {
    position: relative;
    z-index: 1;
  }
  
  .h8_category-navigation div {
    border-radius: 40px;
    border: 1px solid rgba(2, 25, 54, 0.1);
    width: 40px;
    height: 40px;
    display: grid;
    place-items: center;
    position: absolute;
    left: -70px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
  }
  @media only screen and (min-width: 1400px) and (max-width: 1599px) {
    .h8_category-navigation div {
      left: -50px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .h8_category-navigation div {
      left: -40px;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .h8_category-navigation div {
      left: -25px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .h8_category-navigation div {
      left: -25px;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .h8_category-navigation div {
      left: -25px;
    }
  }
  .h8_category-navigation div.h8_category-next {
    left: auto;
    right: -70px;
  }
  @media only screen and (min-width: 1400px) and (max-width: 1599px) {
    .h8_category-navigation div.h8_category-next {
      right: -50px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .h8_category-navigation div.h8_category-next {
      right: -40px;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .h8_category-navigation div.h8_category-next {
      right: -25px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .h8_category-navigation div.h8_category-next {
      right: -25px;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .h8_category-navigation div.h8_category-next {
      right: -25px;
    }
  }
  .h8_category-navigation div svg {
    color: #021936;
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
  }
  .h8_category-navigation div:hover {
    background-color: #395BDF;
  }
  .h8_category-navigation div:hover svg {
    color: #fff;
  }
  
  .h8_category-item .h8_category-icon > i {
    transition: all 0.3s linear 0s;
  }
  
  .h8_category-item:hover .h8_category-icon > i {
    transform: scale(1.1) translateY(-5px);
  }
  
  .h10_category-area {
    background: #E1EEDA;
  }
  
  .h10_category-item {
    background: #F2F4F8;
    padding: 40px 40px 30px;
    border: 1px dashed transparent;
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .h10_category-item {
      padding: 40px 25px 30px;
    }
  }
  .h10_category-item-icon {
    margin-bottom: 20px;
  }
  .h10_category-item-icon svg {
    width: 50px;
    color: #2C2C2C;
  }
  .h10_category-item-title {
    color: #2C2C2C;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 5px;
  }
  .h10_category-item p {
    color: #2C2C2C;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0;
  }
  .h10_category-item:hover {
    border-color: #4846AB;
  }
  
  .h10_category_bg-2 {
    background-color: #F8F2F2;
  }
  
  .h10_category_bg-3 {
    background-color: #E8F6EB;
  }
  
  .h10_category_bg-4 {
    background-color: #F8F6F2;
  }
  
  .h10_category_bg-5 {
    background-color: #F4F8F2;
  }
  
  .h10_category_bg-6 {
    background-color: #E8F6F5;
  }
  
  .h10_category_bg-7 {
    background-color: #F7F2F8;
  }
  
  .h10_category_bg-8 {
    background-color: #E8F6F5;
  }
  
  .h10_category-item-btn {
    display: flex;
    justify-content: center;
  }
  .h10_category-item-btn a {
    color: #4846AB;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    gap: 8px;
    border: 1px solid #5F5DB4;
    border-radius: 4px;
    line-height: 1;
  }
  .h10_category-item-btn a:hover {
    background-color: var(--clr-theme-primary-10);
    color: #fff;
  }
  
  .h10_category-item .h10_category-item-icon > svg {
    transition: all 0.3s linear 0s;
  }
  
  .h10_category-item:hover .h10_category-item-icon > svg {
    transform: scale(1.1) translateY(-5px);
  }